import Home from "views/Home/Home.js";
import MyPolicies from "views/MyPolicies/MyPolicies.js";
import MyVehicles from "views/MyVehicles/MyVehicles.js";
import SavedQuotes from "views/SavedQuotes/SavedQuotes.js";
import Wizard from "views/Forms/Wizard.js";
import Guest from "views/Forms/Guest.js";
import GuestRedesing from "views/Forms/GuestRedesing";
import SummaryRedesign from "views/Forms/SummaryRedesign";
import Document from "views/Pages/PdfView";
import HomePage from "views/Pages/Home";
import Widgets from "views/Widgets/Widgets.js";
import LoginPage from "views/Pages/LoginPage.js";
import LoginPageRedesign from "views/Pages/LoginPageRedesign.js";
import PaymentPayPal from "views/Pages/PaymentPayPal.js";
import ViewPolicy from "views/Pages/viewPolicy";
import Welcome from "views/Pages/Welcome";
import RecoveryPasswordPage from "views/Pages/RecoveryPasswordPage";
import ResetPasswordPage from "views/Pages/ResetPassowrd";
import LoginRedirected from "views/Pages/LoginRedirected";
import Resources from "views/Resources/Resources";
import Formatos from "views/Pages/Formatos";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import { Save } from "@material-ui/icons";
import FlashOn from "@material-ui/icons/FlashOn";
import Article from "@material-ui/icons/FileCopy";

import { checkIfIsKiosko } from "components/Utils/utils.js";

const isKiosko = checkIfIsKiosko();
const redesign = process.env.REACT_APP_REDESIGN;
const userType = localStorage.getItem("userType");
let customerOptions = {};
if (userType == 3 || userType == null) {
  customerOptions = [
    {
      path: "/savedQuotes",
      name: "Saved Quotes",
      icon: Save,
      component: SavedQuotes,
      layout: "/admin",
    },
    {
      path: "/myPolicies",
      name: "My Policies",
      icon: Apps,
      component: MyPolicies,
      layout: "/admin",
    },
    {
      path: "/myVehicles",
      name: "My Vehicles",
      icon: Apps,
      component: MyVehicles,
      layout: "/admin",
    },
    {
      path: "/resources",
      name: "Documentation",
      icon: Article,
      component: Resources,
      layout: "/admin",
    },
  ];
}
const route =
  redesign === "true"
    ? {
        path: "/:language?/:id?",
        name: "New Quote",
        icon: Article,
        component: GuestRedesing,
        layout: "/guestRedesing",
      }
    : {
        path: "/wizard/:id?/:type?",
        name: "New Quote",
        icon: Article,
        component: Wizard,
        layout: "/admin",
      };

let Routes = [
  {
    path: "/recovery-password-page/:type?/:producerCode?",
    name: "Recovery password Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: RecoveryPasswordPage,
    layout: "/auth",
  },
  {
    path: "/reset_password/:userId/:newPassKey/:producerCode?",
    name: "Recovery password Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/redirected/:token/:userType",
    name: "Recovery password Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: LoginRedirected,
    layout: "/auth",
  },
  {
    path: "/checkTokenOneClick/:userId/:token",
    name: "One click",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/paymentPayPal",
    name: "Payment PayPal",
    redirect: true,
    component: PaymentPayPal,
    layout: "/admin",
  },
  {
    path: "/paymentPayPal",
    name: "Payment PayPal",
    redirect: true,
    component: PaymentPayPal,
    layout: "/guest",
  },
  {
    path: "/formatos",
    name: "Formatos",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "VP",
    rtlMini: "شع",
    component: Formatos,
    redirect: true,
    layout: "/auth",
  },
  {
    path: "/viewPolicy/:id?/:type",
    name: "Policy view kiosko",
    redirect: true,
    component: ViewPolicy,
    layout: "/guest",
  },
  {
    path: "/login-page/:type?/:producerCode?",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/login-customer/:type?/:producerCode?",
    name: "Login Page Redesign Customer",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: LoginPageRedesign,
    layout: "/auth",
  },
  {
    path: "/login-agent/:type?/:producerCode?",
    name: "Login Page Redesign Agent",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: LoginPageRedesign,
    layout: "/auth",
  },
  {
    path: "/confirmation/:producerCode?",
    name: "Confirmation sale",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    redirect: true,
    component: HomePage,
    layout: "/auth",
  },
  {
    path: "/guest/:id?",
    name: "Fast Quote",
    icon: FlashOn,
    component: Guest,
    redirect: true,
    layout: "/auth",
  },
  {
    path: "/summary/:id?",
    name: "Summary Quote",
    icon: FlashOn,
    component: SummaryRedesign,
    redirect: true,
    layout: "/guestRedesing",
  },
  {
    path: "/:type?/:producerCode?",
    name: "Fast Quote Guest",
    icon: FlashOn,
    component: Guest,
    redirect: true,
    layout: "/guest",
  },
  {
    path: "/:type/:policyNumber",
    name: "Policy Pdf",
    icon: FlashOn,
    component: Document,
    redirect: true,
    layout: "/documents",
  },
  {
    path: "/view/:id/:type?",
    name: "View Policy",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "VP",
    rtlMini: "شع",
    component: ViewPolicy,
    redirect: true,
    layout: "/admin",
  },
  {
    path: "/welcome",
    name: "Welcome",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "VP",
    rtlMini: "شع",
    component: Welcome,
    redirect: true,
    layout: "/admin",
  },
  {
    path: isKiosko
      ? "/login-page/:type"
      : redesign === "true" ? ([1, 2].includes(userType)
      ? "/login-agent"
      : "/login-customer") : "/login-page",
    name: "Logout",
    rtlName: "هعذاتسجيل الدخول",
    icon: "logout",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/home",
    name: "Home",
    icon: DashboardIcon,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: Person,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/:language?/:id?",
    name: "New Quote",
    icon: Article,
    component: GuestRedesing,
    redirect: true,
    layout: "/guestRedesing",
  },
  route,
];

let newRoutes = Routes;

if (userType == 3 || userType == null) {
  newRoutes = [...Routes, ...customerOptions];
}

const dashRoutes = newRoutes;

export default dashRoutes;
