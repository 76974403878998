import Payment from "payment";
import DOMPurify from "dompurify";

const monthListToString = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const buttonStyleStatusPolicy = {
  cardStyleExpire: {
    border: "2px solid #F44336",
    borderRadius: "5px",
    background: "#F44336",
    color: "#FFF",
  },
  cardStyleWarning: {
    border: "2px solid #FF9802",
    borderRadius: "5px",
    background: "#FF9802",
    color: "#FFF",
  },
  cardStyleSuccess: {
    border: "2px solid #4CAF50",
    borderRadius: "5px",
    background: "#4CAF50",
    color: "#FFF",
  },
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

function generatePassword() {
  var limit = 8;
  var password = "";
  var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var list = chars.split("");
  var len = list.length,
    i = 0;
  do {
    i++;
    var index = Math.floor(Math.random() * len);
    password += list[index];
  } while (i < limit);
  return password;
}

export function separateComma(val) {
  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }
  // trim the number decimal point if it exists
  let num = val.toString().includes(".")
    ? val.toString().split(".")[0]
    : val.toString();
  let len = num.toString().length;
  let result = "";
  let count = 1;

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = "," + result;
    }
    count++;
  }

  // add number after decimal point
  if (val.toString().includes(".")) {
    result = result + "." + val.toString().split(".")[1];
  }
  // return result with - sign if negative
  return sign < 0 ? "-" + result : result;
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;
  let cardTypeIdList = {
    visa: 3,
    mastercard: 2,
    discover: 4,
    amex: 5,
  };

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return {
    value: nextValue.trim(),
    issuer: issuer,
    cardTypeId: cardTypeIdList[issuer] ?? issuer,
  };
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function setDataPolicy(data, defaultData) {
  let policyObjData = {
    flag_draft: typeof data.flag_draft != "undefined" ? 1 : 0,
    agencyfee_HDI: "",
    ccv: "",
    creditcardnumber: "000",
    agencyfeeoption: "0",
    csrinitials: "",
    company: "1",
    specialdiscount: "1",
    prefix: "",
    paymenttype: typeof data.paymentType != "undefined" ? data.paymentType : 2,
  };

  console.log("data to set in issue policy", data);

  if (defaultData) {
    policyObjData = setDefaultPolicyData(data);
  } else {
    if (typeof data.orderData != "undefined") {
      policyObjData.quote_id = data.orderData.quote_id;
      policyObjData.vehicle_id = data.orderData.vehicle_id;
      policyObjData.vehicle_order_id = data.orderData.vehicle_order_id;
      policyObjData.client_id = data.orderData.client_id;
      policyObjData.client_order_id = data.orderData.client_order_id;
    }

    if (typeof data.data_customer != "undefined") {
      policyObjData.agency = data.data_customer.agency_id;
      policyObjData.clientnumber = data.data_customer.id;
      if (data.data_customer.id == "New Client") {
        policyObjData.password = generatePassword();
      }
      policyObjData.dlnumber = data.data_customer.dl_number;
      policyObjData.dlcountry = data.data_customer.dl_country_id;
      policyObjData.validlicense = "1";
      policyObjData.dlstate = data.data_customer.dl_state_id;
      policyObjData.fname = data.data_customer.first_name;
      policyObjData.mname = data.data_customer.hasOwnProperty("middle_name") ? data.data_customer.middle_name : "";
      policyObjData.lname = data.data_customer.last_name;
      policyObjData.address = data.data_customer.address;
      policyObjData.zipcode = data.data_customer.hasOwnProperty("zipcode") ? data.data_customer.zipcode : "";
      policyObjData.city = data.data_customer.hasOwnProperty("city") ? data.data_customer.city : "";
      policyObjData.country = data.data_customer.country_id;
      policyObjData.state = data.data_customer.state_id;
      policyObjData.phone = data.data_customer.phone;
      policyObjData.email = data.data_customer.email;
      policyObjData.additionalinsured = data.data_customer.hasOwnProperty("additional_insured") ? data.data_customer.additional_insured : "";
      policyObjData.destination = data.data_customer.destination;
      if (data.data_customer.diplomat == "1") {
        policyObjData.diplomat = data.data_customer.diplomat;
      }
      policyObjData.customertype = data.data_customer.customer_type;
      policyObjData.repeat =
        data.data_customer.repeat == "" ? "0" : data.data_customer.repeat;
    }

    if (typeof data.selectedVehicle != "undefined") {
      policyObjData.vehicle_type_model =
        data.selectedVehicle.vehicle_type_model;
      policyObjData.trailer = data.selectedVehicle.trailer;
      policyObjData.id_vehicle = data.selectedVehicle.id;
      policyObjData.vehtype = data.selectedVehicle.vehicle_type_id;
      policyObjData.insurancecompany = data.selectedVehicle.insurance_company;
      policyObjData.insurancepolnumber =
        data.selectedVehicle.insurance_policy_number;
      policyObjData.year_vehicle = data.selectedVehicle.year_model;
      policyObjData.make_vehicle =
        data.selectedVehicle.makeId == null ? data.selectedVehicle.make : "";
      policyObjData.make_vehicle_select =
        data.selectedVehicle.makeId != null ? data.selectedVehicle.makeId : "";
      policyObjData.vehicle_type_make = data.selectedVehicle.vehicle_type;
      policyObjData.model_vehicle =
        data.selectedVehicle.modelId == null ? data.selectedVehicle.model : "";
      policyObjData.model_vehicle_select =
        data.selectedVehicle.modelId != null
          ? data.selectedVehicle.modelId
          : "";
      policyObjData.value = data.selectedVehicle.total_value;
      policyObjData.plates_vehicle = data.selectedVehicle.plates;
      policyObjData.vin_vehicle = data.selectedVehicle.vin;
      policyObjData.mileage = data.selectedVehicle.mileage;
      policyObjData.sentri =
        typeof data.selectedVehicle.sentri_number != "undefined"
          ? data.selectedVehicle.sentri_number
          : data.selectedVehicle.sentri;
      policyObjData.commercial = data.selectedVehicle.commercial;
      policyObjData.lienholder_vehicle = data.selectedVehicle.lienholder;
      policyObjData.lienholder_vehicle_address =
        data.selectedVehicle.lienholder_vehicle_address;
      policyObjData.vehtype_trailer = data.selectedVehicle.vehtype_trailer;
      policyObjData.active_other1 = data.selectedVehicle.trailer;
      policyObjData.other_policy_company_trailer =
        data.selectedVehicle.other_policy_company_trailer;
      policyObjData.other_policy_number_trailer =
        data.selectedVehicle.other_policy_number_trailer;
      policyObjData.year_trailer = data.selectedVehicle.year_model_trailer;
      policyObjData.make_trailer = data.selectedVehicle.make_trailer;
      policyObjData.model_trailer = data.selectedVehicle.model_trailer;
      policyObjData.value_trailer = data.selectedVehicle.total_value_trailer;
      policyObjData.plates_trailer = data.selectedVehicle.plates_trailer;
      policyObjData.vin_trailer = data.selectedVehicle.vin_trailer;
      policyObjData.description_trailer =
        data.selectedVehicle.description_trailer;
      policyObjData.vehtype_other = data.selectedVehicle.vehtype_other;
      policyObjData.active_other2 = data.selectedVehicle.other;
      policyObjData.other_policy_company_other =
        data.selectedVehicle.other_policy_company_other;
      policyObjData.other_policy_number_other =
        data.selectedVehicle.other_policy_number_other;
      policyObjData.year_other = data.selectedVehicle.year_model_other;
      policyObjData.make_other = data.selectedVehicle.make_other;
      policyObjData.model_other = data.selectedVehicle.model_other;
      policyObjData.value_other = data.selectedVehicle.total_value_other;
      policyObjData.plates_other = data.selectedVehicle.plates_other;
      policyObjData.vin_other = data.selectedVehicle.vin_other;
      policyObjData.description_other = data.selectedVehicle.description_other;
    }

    if (typeof data.coverages != "undefined") {
      policyObjData.csrinitials = typeof data.coverages.csrinitials != "undefined" ? data.coverages.csrinitials : "";
      policyObjData.specialdiscount = typeof data.coverages.specialdiscount != "undefined" ? data.coverages.specialdiscount : "1";
      policyObjData.additional_charges = data.coverages.additional_charges;
      policyObjData.days = data.coverages.days;
      policyObjData.policytype = data.coverages.policy_type;
      policyObjData.timeset = data.coverages.timeset;
      if (typeof data.coverages.meridian != "undefined") {
        policyObjData.timeset += " " + data.coverages.meridian;
      }
      policyObjData.timezone = data.coverages.timezone;
      policyObjData.bordercross = data.coverages.bordercross;
      policyObjData.effectivedate = data.coverages.effectivedate;
      policyObjData.expirationdate = data.coverages.expirationdate;
      policyObjData.policynotes =
        typeof data.coverages.policynotes == "undefined"
          ? ""
          : data.coverages.policynotes;
      policyObjData.damageded_customer = data.coverages.damageded_customer;
      policyObjData.theftded_customer = data.coverages.theftded_customer;
      policyObjData.csllimit = data.coverages.csllimit;
      policyObjData.excessliab = data.coverages.excessliab;
      policyObjData.medicallimit = data.coverages.medicallimit == "none" ? 0 : data.coverages.medicallimit;
      policyObjData.premium = data.coverages.premium;
      policyObjData.policy_fee = data.coverages.policy_fee;
      policyObjData.iva_tax = data.coverages.iva;
      policyObjData.total = data.coverages.total;
      let checkboxList = [
        "physical_damage",
        "theft",
        "roadside",
        "premierepackage",
        "gapcoverage",
        "fixeddeductible",
        "legalaid",
      ];

      checkboxList.forEach((element) => {
        if (validValueCoverage(data.coverages[element])) {
          policyObjData[element] = true;
        }
      });
    }
  }

  if (typeof data.card != "undefined") {
    policyObjData.creditcardtype = data.card["type-ccard"];
    policyObjData.creditcardnumber_string = data.card[
      "cnumber-card_string"
    ].replaceAll(" ", "");
    policyObjData["month-expiration-card"] =
      monthListToString[parseInt(data.card["month-card"])];
    policyObjData["year-expiration-card"] = data.card["year-card"];
    policyObjData["card-sec"] = "";
    policyObjData.creditcardverificator_string =
      data.card.creditcardverificator_string;
    policyObjData.card_select = "on";
    policyObjData.terms = "on";
    if (data.card["id-card"] == "none") {
      let cardTemp = { ...data.card };
      cardTemp["month-card"] =
        monthListToString[parseInt(cardTemp["month-card"])];
      cardTemp["cnumber-card"] = data.card["cnumber-card_string"].replaceAll(
        " ",
        ""
      );
      cardTemp["year-card"] = "20" + cardTemp["year-card"];
      cardTemp["co-card"] =
        typeof cardTemp["co-card"] != "undefined" ? cardTemp["co-card"] : "";
      policyObjData.card = cardTemp;
    } else {
      policyObjData.cc_id = data.card["id-card"];
    }
  }
  return policyObjData;
}

function setDefaultPolicyData(data) {
  let objectData = {
    flag_draft: 0,
    agencyfee_HDI: "",
    ccv: "",
    creditcardnumber: "000",
    agencyfeeoption: "0",
    csrinitials: "",
    company: "1",
    specialdiscount: "1",
    prefix: "",
    paymenttype: typeof data.paymentType != "undefined" ? data.paymentType : 2,
    agency: "638",
    clientnumber: "44",
    dlnumber: "A12345111",
    dlcountry: "1",
    validlicense: "1",
    dlstate: "2",
    fname: "test",
    password: "testing" + getRandomValue(),
    mname: "",
    lname: "test",
    address: "stes",
    zipcode: "12345",
    city: "test",
    country: "1",
    state: "3",
    phone: "123123123",
    email: "guicas@gmail.com",
    additionalinsured: "",
    destination: "Monterrey",
    customertype: "4",
    repeat: "1",
    trailer: "0",
    id_vehicle: "",
    vehtype: "1",
    insurancecompany: "AXAX",
    insurancepolnumber: "No",
    year_vehicle: "2022",
    make_vehicle: "",
    make_vehicle_select: "48575",
    model_vehicle: "",
    model_vehicle_select: "408827",
    value: "0",
    plates_vehicle: "45454GH",
    vin_vehicle: "234TRY56TR4456R42",
    mileage: "0",
    sentri: "0",
    commercial: "1",
    lienholder_vehicle: "",
    lienholder_vehicle_address: "",
    vehtype_trailer: "0",
    active_other1: "0",
    other_policy_company_trailer: "",
    other_policy_number_trailer: "",
    year_trailer: "0",
    make_trailer: "",
    model_trailer: "0",
    value_trailer: "0",
    plates_trailer: "",
    vin_trailer: "",
    description_trailer: "",
    vehtype_other: "0",
    active_other2: "0",
    other_policy_company_other: "",
    other_policy_number_other: "",
    year_other: "0",
    make_other: "",
    model_other: "",
    value_other: "0",
    plates_other: "",
    vin_other: "",
    description_other: "",
    additional_charges: false,
    days: 3,
    policytype: 1,
    timeset: "10:30 PM",
    timezone: 33,
    bordercross: "4",
    effectivedate: "11-27-2025",
    expirationdate: "11-30-2025",
    policynotes: "",
    damageded_customer: "2",
    theftded_customer: "5",
    csllimit: "500000",
    excessliab: "150000",
    medicallimit: "5000",
    premium: 35,
    policy_fee: "15",
    iva_tax: 0,
    total: 50,
    roadside: true,
    fixeddeductible: true,
    legalaid: true,
  };

  return objectData;
}

function getRandomValue() {
  const arrayRandomValues = new Uint32Array(1);
  crypto.getRandomValues(arrayRandomValues);
  return arrayRandomValues[0];
}

export function formatNumber(number) {
  let numberFormat = parseFloat(number);
  numberFormat = numberFormat.toFixed(2);
  numberFormat = separateComma(numberFormat);
  return numberFormat;
}

export function getEffectiveTime() {
  let current_time = new Date();
  let current_hours = current_time.getHours();
  let current_minutes = current_time.getMinutes();
  let dn = "";
  if (current_minutes >= 0 && current_minutes < 30) {
    current_minutes = "30";
  } else if (current_minutes >= 30) {
    current_minutes = "00";
    current_hours++;
  }
  if (current_hours >= 12) {
    dn = "PM";
    if (current_hours > 12) {
      current_hours = current_hours - 12;
    }
  } else {
    dn = "AM";
  }
  current_hours = current_hours == "0" ? "12" : current_hours;
  return current_hours + ":" + current_minutes + " " + dn;
}

export function fixDaysMonths(value) {
  return value < 10 ? "0" + value : value;
}

export function getDaysFromDates(effectiveDate, expirationDate) {
  let daysData = {
    days: 0,
    policyType: 0,
  };
  let formatEffectiveDate = effectiveDate.replace(/-/g, "/");
  let formatExpirationDate = expirationDate.replace(/-/g, "/");
  formatExpirationDate = new Date(formatExpirationDate);
  formatEffectiveDate = new Date(formatEffectiveDate);
  let days = Math.floor(
    (Date.UTC(
      formatExpirationDate.getFullYear(),
      formatExpirationDate.getMonth(),
      formatExpirationDate.getDate()
    ) -
      Date.UTC(
        formatEffectiveDate.getFullYear(),
        formatEffectiveDate.getMonth(),
        formatEffectiveDate.getDate()
      )) /
      (1000 * 60 * 60 * 24)
  );
  daysData.days = days;
  if (days <= 365) {
    if (days > 0 && days <= 5) {
      daysData.policyType = 1;
    } else if (days > 5 && days <= 30) {
      daysData.policyType = 2;
    } else {
      daysData.policyType = 3;
    }
  }
  return daysData;
}

export function currentDateEffective() {
  let currentDate = new Date();
  let currentDay =
    currentDate.getDate() < 10
      ? "0" + currentDate.getDate()
      : currentDate.getDate();
  let currentMonth =
    currentDate.getMonth() + 1 < 10
      ? "0" + (currentDate.getMonth() + 1)
      : currentDate.getMonth() + 1;
  let current_year = currentDate.getFullYear();

  return currentMonth + "-" + currentDay + "-" + current_year;
}

export function addDays(days, type, data) {
  let dates = {
    effectiveDate: {
      date: data.effectiveDate,
      utcDate: "",
    },
    expirationDate: {
      date: data.expirationDate,
      utcDate: "",
    },
  };
  let valid = true;
  if (type == "effectivedate") {
    valid = data.effectivedate == "" ? false : true;
  } else {
    valid = data.expirationdate == "" ? false : true;
  }
  if (valid) {
    let date =
      type == "effectivedate" ? data.effectivedate : data.expirationdate;
    let sDate = date;
    let sep = sDate.indexOf("/") != -1 ? "/" : "-";
    let aDate = sDate.split(sep);
    date = aDate[0] + "/" + aDate[1] + "/" + aDate[2];
    date = new Date(date);
    if (type == "effectivedate") {
      date.setDate(date.getDate() + parseInt(days));
    } else {
      date.setDate(date.getDate() - parseInt(days));
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    let dateFinal = month + sep + day + sep + year;
    let utcDate = year + "-" + month + "-" + day;
    utcDate = new Date(utcDate);
    utcDate = utcDate.setDate(utcDate.getDate() + 1);
    if (type == "effectivedate") {
      dates.expirationDate.date = dateFinal;
      dates.expirationDate.utcDate = utcDate;
    } else {
      dates.effectiveDate = dateFinal;
      dates.effectiveDate.utcDate = utcDate;
    }
  }
  return dates;
}

export function getUTCDate(date) {
  let arrayDate = date.replaceAll("/", "-").split("-");
  let newDate = arrayDate[2] + "-" + arrayDate[0] + "-" + arrayDate[1];
  newDate = new Date(newDate);
  newDate = newDate.setDate(newDate.getDate() + 2);
  newDate = new Date(newDate);
  let year = newDate.getFullYear();
  let month = newDate.getMonth();
  let day = newDate.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  let newDateUTC = Date.UTC(year, month, day);
  return newDateUTC;
}

export function formatDateToEffectiveAndExpiration(date, type) {
  let newDate = new Date(date);
  if (type == "quote") {
    newDate = newDate.setDate(newDate.getDate() + 1);
    newDate = new Date(newDate);
  }
  let day = newDate.getDate();
  let year = newDate.getFullYear();
  day = day < 10 ? "0" + day : day;
  let month = newDate.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let newStringEffective = `${month}-${day}-${year}`;
  return { date: newStringEffective, year: year };
}

export function getMaskNumberCard(number) {
  let mask = number.replaceAll(" ", "");
  return mask.slice(-4);
}

export function validValueCoverage(coverage) {
  return coverage == "true" || coverage == "1" || coverage === true
    ? true
    : false;
}

export function sanatizeMessageHtml(message) {
  return DOMPurify.sanitize(message, {
    USE_PROFILES: { html: true },
  });
}

export const verifyLength = (value, length) => {
  return value.length >= length;
};

export const validateField = (value) => {
  let isValid = true;
  if (value == "" || value == null || typeof value == "undefined") {
    isValid = false;
  }
  return isValid;
};

export const checkIfIsKiosko = () => {
  const pathName = window.location.href;
  return pathName.includes("kiosko") ? true : false;
};
