import React, { useEffect, useState, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "components/CustomIcons/Info";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Face from "@material-ui/icons/Face";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDialog from "components/CustomDialog/CustomDialog.js";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";
import PriceCard from "components/CardComponents/PriceCard";
import CustomToogle from "components/CustomToogle/CustomToogle.js";
import Button from "components/CustomButtons/CustomButton";
import AccidentIcon from "components/CustomIcons/Accident";
import LegalIcon from "components/CustomIcons/Legal";
import TheftIcon from "components/CustomIcons/Theft";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomAlerts from "components/Alerts/CustomAlerts";
import StepperComponent from "components/Stepper/StepperComponent";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import SelectCustomerModal from "components/Customer/SelectCustomerModal";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  validValueCoverage,
  getUTCDate,
} from "components/Utils/utils.js";
import { useTranslation } from "react-i18next";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formStyles);
let current_date = new Date();

const Step1 = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classesForm = useFormStyles();
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [checked, setChecked] = React.useState([]);
  const { isSmall, submitAction, previewAction, getCustomer } = props;
  const routeParams = useParams();
  const { userId, token, type } = routeParams;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const classesPrincipal = useStylesPrincipal();
  const [toastMessage, setToastMessage] = useState(false);
  const [refreshQuote, setRefreshQuote] = useState(false);
  const [oneClicValidate, setOneClicValidate] = useState(false);
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [states, setStates] = useState([]);
  const [dlstates, setDLStates] = useState([]);
  const [seePassword, setSeePassword] = useState(false);
  const [openCustomerSelectModal, setOpenCustomerSelectModal] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    login: "",
    password: "",
  });
  const [sended, setSended] = useState(false);
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState({ vehtype: 1, agent: 441 });
  const [personData, setPersonData] = useState({
    clientnumber: "New Client",
    vehtype: 1,
    agent: 441,
  });
  const [valores, setValores] = useState([]);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setValuesForTypeVehicle(1, {});
    }
    if (props.personData) {
      
      setPersonData(props.personData);
      if (props.personData?.dl_country_id) {
        setDLStates(
          props.data.catalogs.states.filter(
            (e) => e.country_id == props.personData?.dl_country_id
          )
        );
      }
      if (props.personData?.country_id) {
        setStates(
          props.data.catalogs.states.filter(
            (e) => e.country_id == props.personData?.country_id
          )
        );
      }
    }
    if (props.vehicleData) {
      setVehicleData(props.vehicleData);
    }
  }, [props]);

  useEffect(() => {
    if (type === "oneClickLogin") {
      setOneClickLogin(true);
    }

    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClicValidate(true);
      validateToken();
    }
  }, [dispatch]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setPersonData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "country_id") {
      setStates(data.catalogs.states.filter((e) => e.country_id == value));
    }
    if (name === "dl_country_id") {
      setDLStates(data.catalogs.states.filter((e) => e.country_id == value));
    }
  };
  useEffect(() => {
    validateComplete(personData);
  }, [personData, checked]);
  const validateComplete = (data) => {
    let reqProperties = [
      "first_name",
      "last_name",
      "country_id",
      "address",
      "city",
      "state_id",
      "zipcode",
      "phone",
      "email",
      "dl_country_id",
      "dl_state_id",
      "dl_number",
    ];
    if (checked.indexOf(1) === -1) {
      reqProperties = [
        "first_name",
        "last_name",
        "address",
        "email",
        "dl_country_id",
        "dl_state_id",
        "dl_number",
      ];
    }

    const allPropertiesValid = reqProperties.every((prop) => {
      if (prop === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(data[prop]);
      }
      return (
        data[prop] !== undefined && data[prop] !== null && data[prop] !== ""
      );
    });

    setIsCompleted(allPropertiesValid);
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    formatValueField(limitValue);
  };

  const selectCustomer = async (customerId) => {};

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...vehicleData };
      vehicleDataToEdit.value = 0;
      setVehicleData(vehicleDataToEdit);
    }
    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 &&
          arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };
  const handleSubmitStep = async (event) => {
    submitAction(personData, vehicleData);
  };
  const handlePreviewAction = async (event) => {
    previewAction(personData);
  };
  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const formattedAddress = place.formatted_address || "";
      const addressComponents = place.address_components || [];
      const getAddressComponent = (type) => {
        const component = addressComponents.find((comp) =>
          comp.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const countryName = getAddressComponent("country");
      const stateShortName = getAddressComponent("administrative_area_level_1");
      const street = `${getAddressComponent(
        "street_number"
      )} ${getAddressComponent("route")}`.trim();
      const city = getAddressComponent("locality");
      const postalCode = getAddressComponent("postal_code");
      let findCounty = countryName;
      const usaVariants = [
        "estados unidos",
        "estados unidos de america",
        "united states",
        "united states of america",
        "usa",
        "eeuu",
        "e.e.u.u.",
      ];

      if (
        usaVariants.some(
          (variant) => removeAccents(variant) === removeAccents(countryName)
        )
      ) {
        findCounty = "USA";
      }
      const country = data.catalogs.countries.find(
        (item) => removeAccents(item.name) === removeAccents(findCounty)
      );
      const state = data.catalogs.states.find(
        (item) =>
          removeAccents(item.name) === removeAccents(stateShortName) &&
          item.country_id === (country ? country.id : null)
      );
      handleFormChange({
        target: { name: "addressG", value: formattedAddress },
      });
      handleFormChange({
        target: {
          name: "country_id",
          value: country ? country.id : "",
        },
      });
      handleFormChange({
        target: {
          name: "dl_country_id",
          value: country ? country.id : "",
        },
      });
      handleFormChange({ target: { name: "address", value: street } });
      handleFormChange({ target: { name: "city", value: city } });
      handleFormChange({
        target: {
          name: "state_id",
          value: state ? state.id : "",
        },
      });
      handleFormChange({
        target: {
          name: "dl_state_id",
          value: state ? state.id : "",
        },
      });
      handleFormChange({ target: { name: "zipcode", value: postalCode } });
    }
  };
  const removeAccents = (str) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        {open ? (
          <CustomDialog open={true} title={"Edit Quote"}></CustomDialog>
        ) : null}
        <div className={`${classesPrincipal.title} ${classesPrincipal.black}`}>
          {t("step2.title")}
        </div>
        <GridContainer style={{ marginTop: "20px" }}>
          {["1", "2"].includes(localStorage.getItem("userType")) && (
            <GridItem xs={12} sm={12} md={8}>
              <Button
                id="loginMobile"
                variant="green"
                onClick={() => setOpenCustomerSelectModal(true)}
                style={{
                  width: "100%",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                {t("step2.buttons.selectCustomer")}
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={8}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.firstName")}
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    name: "first_name",
                    value: personData.first_name,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.lastName")}
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    name: "last_name",
                    value: personData.last_name,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
              {checked.indexOf(1) === -1 && (
                <GridItem xs={12} sm={12} md={9}>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <CustomInput
                      labelText={t("step2.address")}
                      secondaryText={t("step2.addressSecondary")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        required: true,
                        name: "addressG",
                        value: personData.addressG,
                        onChange: handleFormChange,
                        placeholder: t("step2.addressG"),
                      }}
                    />
                  </Autocomplete>
                </GridItem>
              )}

              <GridItem xs={12} sm={12} md={9}>
                <div
                  className={
                    classesForm.checkboxAndRadio +
                    " " +
                    classesForm.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={(e) => handleToggle(e, 1)}
                        checkedIcon={
                          <CloseIcon className={classesForm.checkedIcon} />
                        }
                        icon={
                          <CloseIcon className={classesForm.uncheckedIcon} />
                        }
                        classes={{
                          checked: classesForm.greenCheck,
                          root: classesForm.checkRoot,
                        }}
                        inputProps={{
                          name: "terms",
                          id: "terms",
                        }}
                      />
                    }
                    classes={{
                      label: classesForm.label,
                      root: classesForm.labelRoot,
                    }}
                    label={t("step2.manuallyEnterAddress")}
                  />
                </div>
              </GridItem>
            </GridContainer>

            {checked.indexOf(1) === -1 ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t("step2.aptUnit")}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      name: "aptUnit",
                      value: personData.aptUnit,
                      onChange: handleFormChange,
                      placeholder: t("step2.optional"),
                    }}
                  />
                </GridItem>
              </GridContainer>
            ) : (
              <>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomSelect
                      labelText={t("step2.country")}
                      menuItems={
                        data?.catalogs
                          ? data.catalogs.countries.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="country_id"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "country_id",
                        value: personData.country_id,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText={t("step2.street")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        required: true,
                        name: "address",
                        value: personData.address,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("step2.aptUnit")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        name: "aptUnit",
                        value: personData.aptUnit,
                        onChange: handleFormChange,
                        placeholder: t("step2.optional"),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("step2.city")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        required: true,
                        name: "city",
                        value: personData.city,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText={t("step2.state")}
                      menuItems={
                        states
                          ? states.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="state_id"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "state_id",
                        value: personData.state_id,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("step2.postalCode")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        required: true,
                        name: "zipcode",
                        value: personData.zipcode,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </>
            )}

            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.phone")}
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    name: "phone",
                    value: personData.phone,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.email")}
                  type="email"
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    type: "email",
                    name: "email",
                    value: personData.email,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "10px", marginBottom: "10px" }}>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  className={`${classesPrincipal.text} ${classesPrincipal.black}`}
                >
                  {t("step2.driverLicenseInfo")}{" "}
                  <InformationTooltip text={t("step2.tooltipText")} />{" "}
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomSelect
                  labelText={t("step2.dlCountry")}
                  menuItems={
                    data?.catalogs
                      ? data.catalogs.countries.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  name="dl_country_id"
                  value={personData.dl_country_id}
                  onChange={(e) => handleFormChange(e)}
                  inputProps={{
                    required: true,
                    name: "dl_country_id",
                    value: personData.dl_country_id,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomSelect
                  labelText={t("step2.dlState")}
                  menuItems={
                    dlstates
                      ? dlstates.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  name="dl_state_id"
                  value={personData.dl_state_id}
                  onChange={(e) => handleFormChange(e)}
                  inputProps={{
                    required: true,
                    name: "dl_state_id",
                    value: personData.dl_state_id,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.dlNumber")}
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    name: "dl_number",
                    value: personData.dl_number,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("step2.additionalInsured")}
                  error={!loginCredentials.login && sended ? true : null}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    name: "additionalInsured",
                    value: personData.additionalInsured,
                    onChange: handleFormChange,
                    placeholder: t("step2.optional"),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  className={`${classesPrincipal.textMultiLine} ${classesPrincipal.black}`}
                  dangerouslySetInnerHTML={{
                    __html: t("step2.agreementText", {
                      privacyPolicy: `<span class="${
                        classesPrincipal.textMultiLine
                      } ${classesPrincipal.green}">${t(
                        "step2.privacyPolicy"
                      )}</span>`,
                      termsOfUse: `<span class="${
                        classesPrincipal.textMultiLine
                      } ${classesPrincipal.green}">${t(
                        "step2.termsOfUse"
                      )}</span>`,
                    }),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ minHeight: "100px", marginTop: "20px" }}
            >
              <GridItem xs={4}>
                <Button
                  id="backButton"
                  onClick={handlePreviewAction}
                  variant="white"
                  style={{ width: "100%" }}
                >
                  {t("common.previous")}
                </Button>
              </GridItem>
              <GridItem xs={4} />
              <GridItem xs={4}>
                <Button
                  id="loginMobile"
                  type="submit"
                  onClick={handleSubmitStep}
                  disabled={!isCompleted}
                  variant="green"
                  style={{ width: "100%" }}
                >
                  {t("common.continue")}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <SelectCustomerModal
          open={openCustomerSelectModal}
          setOpen={setOpenCustomerSelectModal}
          selectCustomer={getCustomer}
        ></SelectCustomerModal>
      </GridItem>
    </div>
  );
});

Step1.displayName = "Step1";

export default Step1;
