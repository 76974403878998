import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const getPolicyData = createAsyncThunk(
  "policiesView/getPolicyData",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${BASE_URL}/Orders/getPolicyById`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendEmailAPI = createAsyncThunk(
  "policiesView/sendEmailAPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/Orders/sendEmail`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
