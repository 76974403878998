import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Card from "react-credit-cards";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/CustomButton.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomCreditCard from "components/CustomCreditCard/CustomCreditCard.js";
import CreditCard from "components/CreditCard/CreditCard.js";
import CardHeader from "components/Card/CardHeader.js";
import { formatNumber, sanatizeMessageHtml } from "components/Utils/utils.js";
import { CircularProgress } from "@material-ui/core";

import { styled } from "@mui/material/styles";

const useStyles = makeStyles(styles);

import "react-credit-cards/es/styles-compiled.css";
import "../../assets/css/stylesPage.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(stylesDialogs);

import { useTranslation } from "react-i18next";

export default function PaymentType(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDialogs = useStylesDialog();
  const {
    isOpen,
    onClose,
    inputHandler,
    callBackCard,
    handlerSaveCard,
    cardData,
    saveCard,
    paymentType,
    setPaymentType,
    paymentTypeList,
    messageToast,
    creditCardListModal,
    closeCardListModal,
    handleChangeRadio,
    inputHandlerCcv,
    clientCards,
    handlerCardData,
    selectedValue,
    verifyPaymentType,
    savingCard,
    cancelSaveCard,
    data,
    loadingCircle,
  } = props;

  if (!isOpen) {
    return null;
  }

  const payment = t("payment", { returnObjects: true }); // Obtiene todas las traducciones bajo "payment"

  const monthCard =
    typeof cardData["month-card"] === "undefined"
      ? ""
      : parseInt(cardData["month-card"]);
  const yearCard =
    typeof cardData["month-card"] === "undefined" ? "" : cardData["year-card"];

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      id={"paymentDialog"}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h3
          className={
            classesDialogs.modalTitle + " " + classesDialogs.modalTitlePayment
          }
        >
          {payment.paymentInformation}
        </h3>
        <Button
          justIcon
          className={
            classesDialogs.modalCloseButton +
            " " +
            classesDialogs.buttonPaymentClose
          }
          key="close"
          aria-label="Close"
          onClick={onClose}
        >
          <Close className={classesDialogs.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.modalBody + " modalCreditCard"}>
        <GridContainer>
          <GridItem xs={12}>
            <h4>{payment.touristAutoInsurancePolicy}</h4>
            <div className={classesDialogs.priceTitle}>
              ${formatNumber(data?.coverages?.total || 0)}
            </div>
          </GridItem>
          <GridItem xs={12}>
            <CustomSelect
              labelText={payment.paymentType}
              menuItems={paymentTypeList}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              placeholder={payment.paymentTypePlaceholder}
              name="paymentType"
              information={payment.tooltipText}
              value={paymentType}
              inputProps={{
                name: "paymentType",
                value: paymentType ? paymentType : "placeholder",
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  setPaymentType(event.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            {parseInt(paymentType || 0) == 2 && (
              <>
                <CustomCreditCard
                  inputHandler={inputHandler}
                  callBackCard={callBackCard}
                  cardData={cardData}
                  handlerSaveCard={handlerSaveCard}
                  handlerCardData={handlerCardData}
                  saveCard={saveCard}
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  paymentTypeList={paymentTypeList}
                  messageToast={messageToast}
                  clientCards={clientCards}
                  handleChangeRadio={handleChangeRadio}
                  inputHandlerCcv={inputHandlerCcv}
                  creditCardListModal={creditCardListModal}
                  closeCardListModal={closeCardListModal}
                  selectedValue={selectedValue}
                  savingCard={savingCard}
                  cancelSaveCard={cancelSaveCard}
                ></CustomCreditCard>
              </>
            )}
          </GridItem>
        </GridContainer>
        <GridItem xs={12}>
          <p style={{ color: "red" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanatizeMessageHtml(messageToast),
              }}
            ></div>
          </p>
        </GridItem>
        {!savingCard && (
          <GridContainer>
            <GridItem xs={12}>
              <p style={{ textAlign: "left" }}>
                {payment.paymentConfirmation}
              </p>
            </GridItem>
            <GridItem xs={12}>
              <Button
                id="loginMobile"
                type="submit"
                onClick={() => verifyPaymentType()}
                variant="green"
                block
              >
                {payment.payNow}{" "}
                {loadingCircle && (
                  <CircularProgress
                    size="30px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                )}
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </DialogContent>
      <DialogActions
        className={classesDialogs.modalFooter}
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <GridItem xs={12} className={classesDialogs.center}>
          <a href="#" className={classesDialogs.linkPayment}>
            {payment.privacyPolicy}
          </a>{" "}
          | {payment.disclaimer}
        </GridItem>
      </DialogActions>
    </Dialog>
  );
}

