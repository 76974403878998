import React from "react";
export default function Icon(props) {
  return (
    <svg width="20" height="20" style={{paddingTop:"2px"}} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1.5C3.46243 1.5 1 3.96243 1 7C1 10.0376 3.46243 12.5 6.5 12.5C9.53757 12.5 12 10.0376 12 7C12 3.96243 9.53757 1.5 6.5 1.5ZM0 7C0 3.41015 2.91015 0.5 6.5 0.5C10.0899 0.5 13 3.41015 13 7C13 10.5899 10.0899 13.5 6.5 13.5C2.91015 13.5 0 10.5899 0 7Z" fill="#13816F" />
      <path d="M6.745 5.365C6.57167 5.365 6.425 5.305 6.305 5.185C6.185 5.065 6.125 4.91833 6.125 4.745C6.125 4.57167 6.185 4.425 6.305 4.305C6.425 4.185 6.57167 4.125 6.745 4.125C6.91167 4.125 7.05167 4.185 7.165 4.305C7.285 4.425 7.345 4.57167 7.345 4.745C7.345 4.91833 7.285 5.065 7.165 5.185C7.05167 5.305 6.91167 5.365 6.745 5.365ZM7.185 6.255V10.375H6.275V6.255H7.185Z" fill="#13816F" />
    </svg>

  );
}
