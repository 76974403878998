import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Card from "react-credit-cards";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/CustomButton.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CardHeader from "components/Card/CardHeader.js";
import CreditCardList from "components/CreditCard/CreditCardList.js";
import CreditCardItem from "components/CreditCardItem/CustomCreditCardItem.js";

import { styled } from "@mui/material/styles";

const useStyles = makeStyles(styles);

import "react-credit-cards/es/styles-compiled.css";
import "../../assets/css/stylesPage.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(stylesDialogs);
import { useTranslation } from "react-i18next";

export default function CustomCreditCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDialogs = useStylesDialog();
  const {
    paymentModal,
    closeModal,
    inputHandler,
    verifyPaymentType,
    cardData,
    saveCard,
    paymentType,
    setPaymentType,
    paymentTypeList,
    inputHandlerCcv,
    messageToast,
    selectedValue,
    creditCardListModal,
    closeCardListModal,
    selectedCard,
    clientCards,
    handlerSaveCard,
    cancelSaveCard,
    savingCard,
  } = props;
  const creditCard = t("creditCard", { returnObjects: true });

  return (
    <GridContainer>
      <GridItem xs={12}>
        {clientCards.length > 0 && !savingCard && (
          <Button
            id="openCreditCardList"
            type="submit"
            onClick={() => closeCardListModal()}
            variant="green"
            block
          >
            {creditCard.creditCardListButton}
          </Button>
        )}

        {selectedValue == null && (
          <>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText={creditCard.nameOnCard}
                  error={
                    (cardData["fname-card"] === "" ||
                      typeof cardData["fname-card"] === "undefined") &&
                    saveCard
                      ? true
                      : false
                  }
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    name: "fname-card",
                    id: "fname-card",
                    value: cardData["fname-card"],
                    onChange: (e) => inputHandler(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText={creditCard.lastNameOnCard}
                  error={
                    (cardData["lname-card"] === "" ||
                      typeof cardData["lname-card"] === "undefined") &&
                    saveCard
                      ? true
                      : false
                  }
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    name: "lname-card",
                    id: "lname-card",
                    value: cardData["lname-card"],
                    onChange: (e) => inputHandler(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={creditCard.cardNumber}
                  error={
                    (cardData["cnumber-card_string"] === "" ||
                      typeof cardData["cnumber-card_string"] === "undefined") &&
                    saveCard
                      ? true
                      : false
                  }
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  id="cnumber-card_string"
                  name="cnumber-card_string"
                  inputProps={{
                    value: cardData["cnumber-card_string"],
                    onChange: (e) => inputHandler(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={savingCard ? 12 : 6}>
                <CustomInput
                  labelText={creditCard.expiration}
                  error={
                    (cardData["expirationCard"] === "" ||
                      typeof cardData["expirationCard"] === "undefined" ||
                      cardData["month-card"] > 12 ||
                      cardData["year-card"] == "" ||
                      cardData["month-card"] == "") &&
                    saveCard
                      ? true
                      : false
                  }
                  id="expirationCard"
                  name="expirationCard"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    type: "text",
                    value: cardData["expirationCard"],
                    onChange: (e) => inputHandler(e),
                  }}
                />
              </GridItem>
              {!savingCard && (
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={creditCard.ccv}
                    id={`ccv`}
                    type={"password"}
                    formControlProps={{
                      fullWidth: true,
                      value: cardData["creditcardverificator_string"],
                      onChange: (e) => inputHandlerCcv(e),
                    }}
                    inputProps={{
                      type: "password",
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </>
        )}
        {selectedValue != null && (
          <>
            <GridContainer>
              <CreditCardItem
                idCard={0}
                numberCard={cardData["cnumber-card_string"]}
                monthCard={cardData["month-card"]}
                yearCard={cardData["year-card"]}
                nameCard={cardData["fname-card"]}
                lastNameCard={cardData["lname-card"]}
                typeCard={cardData["type-ccard"]}
                selectedValue={selectedValue}
                onlyShowData={true}
              />
              <GridItem xs={12}>
                <CustomInput
                  labelText={creditCard.ccv}
                  formControlProps={{
                    fullWidth: true,
                    onChange: (e) => inputHandlerCcv(e),
                  }}
                  inputProps={{
                    type: "password",
                  }}
                />
              </GridItem>
            </GridContainer>
          </>
        )}
        {savingCard && (
          <GridContainer>
            <GridItem xs="6">
              <Button
                type="submit"
                onClick={() => handlerSaveCard(true)}
                variant="green"
                block
              >
                {creditCard.saveButton}
              </Button>
            </GridItem>
            <GridItem xs="6">
              <Button
                type="submit"
                onClick={() => cancelSaveCard()}
                variant="yellow"
                block
              >
                {creditCard.cancelButton}
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}
