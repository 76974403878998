import React from "react";
export default function Icon(props) {
    return (
        <svg width="25" height="25"  style={{ marginRight: "20px" }}  viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.8925 47.3426V51.0541C53.8925 52.4189 52.8023 53.5423 51.4708 53.5423H48.5665C47.235 53.5423 46.1532 52.4272 46.1532 51.0541V44.4133H53.8841V47.3426H53.8925Z" fill="#727A91" />
            <path d="M13.5228 47.3426V51.0541C13.5228 52.4189 12.4326 53.5423 11.1095 53.5423H8.20518C6.8737 53.5423 5.79187 52.4272 5.79187 51.0541V44.4133H13.5228V47.3426Z" fill="#727A91" />
            <path d="M56.7881 44.014V29.8254C56.7881 29.8254 56.7881 25.4814 54.1584 24.3996C54.1584 24.3996 52.2361 22.5521 51.2458 20.946L51.1542 21.0043C46.028 20.5632 40.8685 20.3469 36.0086 20.2553C31.7146 20.1804 27.6452 20.2054 24.0169 20.2886C14.9628 20.4883 8.71317 21.0126 8.71317 21.0126L8.62163 20.9544C8.44687 21.2373 8.23882 21.5036 8.00581 21.7449C6.99888 22.8018 5.709 24.3996 5.709 24.3996C3.07932 25.4814 3.07932 29.8254 3.07932 29.8254V44.014C3.07932 44.014 2.88792 47.1097 5.5176 47.1097H54.3415C56.9711 47.1097 56.7797 44.014 56.7797 44.014H56.7881Z" fill="#C63037" />
            <path d="M3.07098 42.6824C3.07098 42.6824 2.40524 47.1512 5.53423 47.1512H54.9572C54.9572 47.1512 57.3539 46.9847 56.7547 42.6824C56.7547 42.6824 56.5134 43.6893 54.1167 43.6893H5.40108C5.40108 43.6893 3.96973 43.7725 3.06266 42.6824H3.07098Z" fill="#DAE1EA" />
            <path d="M39.5453 27.9944H20.322C19.3523 27.9944 18.5661 28.7805 18.5661 29.7503V33.0374C18.5661 34.0071 19.3523 34.7933 20.322 34.7933H39.5453C40.5151 34.7933 41.3012 34.0071 41.3012 33.0374V29.7503C41.3012 28.7805 40.5151 27.9944 39.5453 27.9944Z" fill="#767C93" />
            <path d="M10.4108 14.222C10.4108 14.222 12.8158 6.56592 19.4732 6.56592H40.4274C47.0849 6.56592 49.4815 14.222 49.4815 14.222" fill="#C63037" />
            <path d="M24.0252 20.2968C14.9711 20.4965 8.72148 21.0208 8.72148 21.0208L8.62994 20.9625C8.84631 20.6214 9.00442 20.2386 9.09596 19.8474L10.4108 14.2386C10.4108 14.2386 11.5342 9.48682 16.6105 9.48682H43.2735C48.3415 9.48682 49.4732 14.2386 49.4732 14.2386L50.7881 19.8474C50.8879 20.2386 51.046 20.6214 51.2541 20.9625L51.1625 21.0208C46.0363 20.5797 40.8768 20.3634 36.0169 20.2718C31.7229 20.1969 27.6535 20.2219 24.0335 20.3051L24.0252 20.2968Z" fill="#B9DDFC" />
            <g opacity="0.3">
                <path d="M13.598 47.1179H5.70898V49.24H13.598V47.1179Z" fill="#384169" />
            </g>
            <g opacity="0.3">
                <path d="M53.9749 47.1179H46.0858V49.24H53.9749V47.1179Z" fill="#384169" />
            </g>
            <g opacity="0.2">
                <path d="M5.70898 24.541C5.70898 24.541 29.1514 19.032 54.5245 24.541C54.5245 24.541 51.7118 22.419 50.9628 20.8711C50.9628 20.8711 19.7894 19.1319 7.95586 21.2539L5.70898 24.5327V24.541Z" fill="white" />
            </g>
            <g opacity="0.3">
                <path d="M30.3331 9.22878L19.3983 20.4798L8.72147 21.0041L10.4108 14.2052C10.4108 14.2052 11.1597 9.45347 17.9254 9.45347C24.691 9.45347 30.3331 9.22046 30.3331 9.22046V9.22878Z" fill="white" />
            </g>
            <path d="M57.562 15.6116H53.7756C53.1919 15.6116 52.7187 16.0847 52.7187 16.6684V18.9736C52.7187 19.5573 53.1919 20.0304 53.7756 20.0304H57.562C58.1457 20.0304 58.6188 19.5573 58.6188 18.9736V16.6684C58.6188 16.0847 58.1457 15.6116 57.562 15.6116Z" fill="#C63037" />
            <g opacity="0.3">
                <path d="M37.2652 9.35377L26.6882 20.2386L34.3193 20.2054L44.4968 9.73657C44.4968 9.73657 42.4663 8.97096 37.2652 9.35377Z" fill="white" />
            </g>
            <g opacity="0.3">
                <path d="M10.0946 15.57C10.0946 15.57 11.3512 11.8585 17.3595 11.8585H42.1417C42.1417 11.8585 48.5079 11.9834 50.0308 15.9778C50.0308 15.9778 49.8394 9.47019 42.5245 9.47019H17.9254C17.9254 9.47019 11.2846 8.3301 10.1029 15.57H10.0946Z" fill="white" />
            </g>
            <g opacity="0.15">
                <path d="M55.2815 47.3095H4.77663L3.27039 45.4204H56.5963L55.2815 47.3095Z" fill="#384169" />
            </g>
            <path d="M6.34977 15.6116H2.56337C1.97968 15.6116 1.5065 16.0847 1.5065 16.6684V18.9736C1.5065 19.5573 1.97968 20.0304 2.56337 20.0304H6.34977C6.93346 20.0304 7.40664 19.5573 7.40664 18.9736V16.6684C7.40664 16.0847 6.93346 15.6116 6.34977 15.6116Z" fill="#C63037" />
            <path d="M8.00619 22.3273C7.88968 22.3273 7.77318 22.294 7.67331 22.2191L5.57623 20.7461C5.30993 20.5631 5.24336 20.1969 5.43476 19.9306C5.61784 19.6643 5.98399 19.5977 6.25029 19.7891L8.34738 21.2621C8.61367 21.4452 8.67193 21.8113 8.48885 22.0776C8.38067 22.2357 8.19759 22.3273 8.00619 22.3273Z" fill="#494456" />
            <g opacity="0.3">
                <path d="M13.5227 47.3426V51.3454C13.5227 51.3454 13.3146 53.5507 11.4589 53.5507V47.2095L13.5227 47.3426Z" fill="#384169" />
            </g>
            <g opacity="0.3">
                <path d="M46.1366 47.3426V51.3454C46.1366 51.3454 46.3446 53.5507 48.2004 53.5507V47.2095L46.1366 47.3426Z" fill="#384169" />
            </g>
            <g opacity="0.3">
                <path d="M46.4354 51.595H53.7752C53.7752 51.595 53.3092 53.5256 51.7863 53.5256H48.9569C48.9569 53.5256 46.4354 53.5672 46.4354 51.595Z" fill="#384169" />
            </g>
            <g opacity="0.3">
                <path d="M5.99988 51.595H13.3397C13.3397 51.595 12.8737 53.5256 11.3508 53.5256H8.52138C8.52138 53.5256 5.99988 53.5672 5.99988 51.595Z" fill="#384169" />
            </g>
            <g opacity="0.15">
                <path d="M3.07933 39.6699C3.07933 39.6699 2.7631 43.5728 5.03494 43.5728H54.3498C54.3498 43.5728 56.7881 43.6228 56.7881 41.2011C56.7881 41.2011 56.3886 41.8419 54.7076 41.8419H5.15977C5.15977 41.8419 3.07933 41.8419 3.07933 39.6699Z" fill="#384169" />
            </g>
            <g opacity="0.25">
                <path d="M18.6078 31.5729C18.6078 31.5729 18.9573 30.3079 21.2374 30.3079H38.7881C38.7881 30.3079 41.1182 30.3079 41.2513 31.6977C41.2513 31.6977 41.8754 27.9862 38.7881 27.9862H21.071C21.071 27.9862 18.5745 27.878 18.6078 31.5645V31.5729Z" fill="#384169" />
            </g>
            <g opacity="0.5">
                <path d="M24.899 35.3924C24.5745 35.3924 24.3165 35.1262 24.3165 34.8099V27.9944C24.3165 27.6698 24.5828 27.4119 24.899 27.4119C25.2153 27.4119 25.4815 27.6782 25.4815 27.9944V34.8099C25.4815 35.1345 25.2153 35.3924 24.899 35.3924Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M31.5228 35.3924C31.1983 35.3924 30.9403 35.1262 30.9403 34.8099V27.9944C30.9403 27.6698 31.2066 27.4119 31.5228 27.4119C31.8391 27.4119 32.1054 27.6782 32.1054 27.9944V34.8099C32.1054 35.1345 31.8391 35.3924 31.5228 35.3924Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M28.2111 35.3924C27.8865 35.3924 27.6285 35.1262 27.6285 34.8099V27.9944C27.6285 27.6698 27.8948 27.4119 28.2111 27.4119C28.5273 27.4119 28.7936 27.6782 28.7936 27.9944V34.8099C28.7936 35.1345 28.5273 35.3924 28.2111 35.3924Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M38.1556 35.3924C37.831 35.3924 37.5731 35.1262 37.5731 34.8099V27.9944C37.5731 27.6698 37.8394 27.4119 38.1556 27.4119C38.4718 27.4119 38.7381 27.6782 38.7381 27.9944V34.8099C38.7381 35.1345 38.4718 35.3924 38.1556 35.3924Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M34.8431 35.3924C34.5186 35.3924 34.2606 35.1262 34.2606 34.8099V27.9944C34.2606 27.6698 34.5269 27.4119 34.8431 27.4119C35.1594 27.4119 35.4257 27.6782 35.4257 27.9944V34.8099C35.4257 35.1345 35.1594 35.3924 34.8431 35.3924Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M21.5786 35.3924C21.2541 35.3924 20.9961 35.1262 20.9961 34.8099V27.9944C20.9961 27.6698 21.2624 27.4119 21.5786 27.4119C21.8948 27.4119 22.1611 27.6782 22.1611 27.9944V34.8099C22.1611 35.1345 21.8948 35.3924 21.5786 35.3924Z" fill="#494456" />
            </g>
            <path d="M38.3217 43.6812H21.4452V49.8309H38.3217V43.6812Z" fill="#F0F3F7" />
            <g opacity="0.5">
                <path d="M35.2267 46.7434C35.2267 46.4189 35.493 46.1609 35.8092 46.1609C36.1338 46.1609 36.3918 46.4272 36.3918 46.7434C36.3918 47.068 36.1255 47.3259 35.8092 47.3259C35.4847 47.3259 35.2267 47.0596 35.2267 46.7434ZM32.88 46.7434C32.88 46.4189 33.1463 46.1609 33.4625 46.1609C33.787 46.1609 34.045 46.4272 34.045 46.7434C34.045 47.068 33.7787 47.3259 33.4625 47.3259C33.1379 47.3259 32.88 47.0596 32.88 46.7434ZM30.5415 46.7434C30.5415 46.4189 30.8078 46.1609 31.1241 46.1609C31.4486 46.1609 31.7066 46.4272 31.7066 46.7434C31.7066 47.068 31.4403 47.3259 31.1241 47.3259C30.7995 47.3259 30.5415 47.0596 30.5415 46.7434ZM28.1948 46.7434C28.1948 46.4189 28.4611 46.1609 28.7773 46.1609C29.1019 46.1609 29.3599 46.4272 29.3599 46.7434C29.3599 47.068 29.0936 47.3259 28.7773 47.3259C28.4528 47.3259 28.1948 47.0596 28.1948 46.7434ZM25.8564 46.7434C25.8564 46.4189 26.1227 46.1609 26.4389 46.1609C26.7635 46.1609 27.0214 46.4272 27.0214 46.7434C27.0214 47.068 26.7551 47.3259 26.4389 47.3259C26.1144 47.3259 25.8564 47.0596 25.8564 46.7434ZM23.5096 46.7434C23.5096 46.4189 23.7759 46.1609 24.0922 46.1609C24.4167 46.1609 24.6747 46.4272 24.6747 46.7434C24.6747 47.068 24.4084 47.3259 24.0922 47.3259C23.7676 47.3259 23.5096 47.0596 23.5096 46.7434Z" fill="#494456" />
            </g>
            <g opacity="0.15">
                <path d="M7.46489 20.0888H1.57307L1.39832 18.3828H7.61468L7.46489 20.0888Z" fill="#384169" />
            </g>
            <g opacity="0.15">
                <path d="M58.6688 20.0888H52.777L52.6022 18.3828H58.8269L58.6688 20.0888Z" fill="#384169" />
            </g>
            <g opacity="0.7">
                <path d="M5.88419 49.5479C5.55964 49.5479 5.30167 49.2816 5.30167 48.9653C5.30167 48.6491 5.56796 48.3828 5.88419 48.3828H7.9147C8.23925 48.3828 8.49723 48.6491 8.49723 48.9653C8.49723 49.2816 8.23093 49.5479 7.9147 49.5479H5.88419Z" fill="#494456" />
                <path d="M5.8588 51.7197C5.53425 51.7197 5.27628 51.4534 5.27628 51.1372C5.27628 50.821 5.54257 50.5547 5.8588 50.5547H7.93924C8.26379 50.5547 8.52177 50.821 8.52177 51.1372C8.52177 51.4534 8.25547 51.7197 7.93924 51.7197H5.8588Z" fill="#494456" />
            </g>
            <g opacity="0.7">
                <path d="M11.3842 49.5479C11.0596 49.5479 10.8017 49.2816 10.8017 48.9653C10.8017 48.6491 11.068 48.3828 11.3842 48.3828H13.4147C13.7393 48.3828 13.9972 48.6491 13.9972 48.9653C13.9972 49.2816 13.7309 49.5479 13.4147 49.5479H11.3842Z" fill="#494456" />
                <path d="M11.3676 51.7197C11.043 51.7197 10.785 51.4534 10.785 51.1372C10.785 50.821 11.0513 50.5547 11.3676 50.5547H13.4563C13.7809 50.5547 14.0388 50.821 14.0388 51.1372C14.0388 51.4534 13.7726 51.7197 13.4563 51.7197H11.3676Z" fill="#494456" />
            </g>
            <g opacity="0.7">
                <path d="M46.1949 49.5479C45.8703 49.5479 45.6123 49.2816 45.6123 48.9653C45.6123 48.6491 45.8786 48.3828 46.1949 48.3828H48.2254C48.5499 48.3828 48.8079 48.6491 48.8079 48.9653C48.8079 49.2816 48.5416 49.5479 48.2254 49.5479H46.1949Z" fill="#494456" />
                <path d="M46.1695 51.7197C45.8449 51.7197 45.5869 51.4534 45.5869 51.1372C45.5869 50.821 45.8532 50.5547 46.1695 50.5547H48.2582C48.5828 50.5547 48.8408 50.821 48.8408 51.1372C48.8408 51.4534 48.5745 51.7197 48.2582 51.7197H46.1695Z" fill="#494456" />
            </g>
            <g opacity="0.7">
                <path d="M51.8119 49.5479C51.4874 49.5479 51.2294 49.2816 51.2294 48.9653C51.2294 48.6491 51.4957 48.3828 51.8119 48.3828H53.8424C54.167 48.3828 54.425 48.6491 54.425 48.9653C54.425 49.2816 54.1587 49.5479 53.8424 49.5479H51.8119Z" fill="#494456" />
                <path d="M51.7953 51.7197C51.4707 51.7197 51.2128 51.4534 51.2128 51.1372C51.2128 50.821 51.4791 50.5547 51.7953 50.5547H53.8841C54.2086 50.5547 54.4666 50.821 54.4666 51.1372C54.4666 51.4534 54.2003 51.7197 53.8841 51.7197H51.7953Z" fill="#494456" />
            </g>
            <path d="M5.44226 30.2497V30.8323C5.44226 32.1638 6.59067 33.2456 7.98872 33.2456H14.0054C15.4117 33.2456 16.5518 32.1554 16.5518 30.8323V30.2497C16.5518 28.9183 15.4034 27.8364 14.0054 27.8364L7.49774 27.2539C6.09968 27.2539 5.44226 28.9266 5.44226 30.2581V30.2497Z" fill="#DAE1EA" />
            <g opacity="0.5">
                <path d="M10.8523 32.4717C11.891 32.4717 12.733 31.6073 12.733 30.541C12.733 29.4747 11.891 28.6104 10.8523 28.6104C9.81359 28.6104 8.97156 29.4747 8.97156 30.541C8.97156 31.6073 9.81359 32.4717 10.8523 32.4717Z" fill="white" />
            </g>
            <g opacity="0.2">
                <path d="M16.319 31.0071C16.319 31.0071 16.2524 31.7394 14.1886 31.7394H7.58112C7.58112 31.7394 5.79194 31.7394 5.30928 31.0071C5.30928 31.0071 4.87655 33.3538 7.46462 33.3538H14.0471C14.0471 33.3538 16.6602 33.6451 16.319 31.0071Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M5.3099 30.158C5.3099 30.158 5.37647 28.8349 7.44027 28.8349C9.50407 28.8349 14.0478 29.4174 14.0478 29.4174C14.0478 29.4174 15.8369 29.4174 16.3196 30.1497C16.3196 30.1497 16.7523 27.803 14.1643 27.803C11.5762 27.803 7.58174 27.2205 7.58174 27.2205C7.58174 27.2205 4.96871 27.5117 5.3099 30.1497V30.158Z" fill="white" />
            </g>
            <path d="M54.5079 30.2497V30.8323C54.5079 32.1638 53.3595 33.2456 51.9614 33.2456H45.9448C44.5384 33.2456 43.3983 32.1554 43.3983 30.8323V30.2497C43.3983 28.9183 44.5467 27.8364 45.9448 27.8364L52.4524 27.2539C53.8588 27.2539 54.5079 28.9266 54.5079 30.2581V30.2497Z" fill="#DAE1EA" />
            <g opacity="0.5">
                <path d="M49.0984 32.4717C50.1371 32.4717 50.9791 31.6073 50.9791 30.541C50.9791 29.4747 50.1371 28.6104 49.0984 28.6104C48.0597 28.6104 47.2177 29.4747 47.2177 30.541C47.2177 31.6073 48.0597 32.4717 49.0984 32.4717Z" fill="white" />
            </g>
            <g opacity="0.2">
                <path d="M43.6313 31.0071C43.6313 31.0071 43.6979 31.7394 45.7617 31.7394H52.3692C52.3692 31.7394 54.1584 31.7394 54.641 31.0071C54.641 31.0071 55.0738 33.3538 52.4857 33.3538H45.9032C45.9032 33.3538 43.2901 33.6451 43.6313 31.0071Z" fill="#494456" />
            </g>
            <g opacity="0.5">
                <path d="M54.641 30.158C54.641 30.158 54.5745 28.8349 52.5107 28.8349C50.4469 28.8349 45.9032 29.4174 45.9032 29.4174C45.9032 29.4174 44.114 29.4174 43.6313 30.1497C43.6313 30.1497 43.1986 27.803 45.7867 27.803C48.3747 27.803 52.3692 27.2205 52.3692 27.2205C52.3692 27.2205 54.9822 27.5117 54.641 30.1497V30.158Z" fill="white" />
            </g>
            <path d="M41.3096 43.6894L40.3109 40.3441C40.3109 40.3441 39.8116 38.4717 38.139 38.4717H21.8532C20.1806 38.4717 19.6813 40.3441 19.6813 40.3441L18.6826 43.6894" fill="#767C93" />
            <g opacity="0.25">
                <path d="M19.5985 41.5423C19.5985 41.5423 19.9231 40.4105 21.9952 40.4105H38.0146C38.0146 40.4105 40.1367 40.4105 40.2615 41.6588C40.2615 41.6588 40.8274 38.3301 38.0146 38.3301H21.8537C21.8537 38.3301 19.5819 38.2385 19.6068 41.5423H19.5985Z" fill="#384169" />
            </g>
            <g opacity="0.2">
                <path d="M56.6759 37.6391L40.2909 39.1245L40.4667 41.0639L56.8517 39.5785L56.6759 37.6391Z" fill="#494456" />
            </g>
            <g opacity="0.2">
                <path d="M3.29334 37.4822L3.11813 39.4299L19.5704 40.9098L19.7456 38.9621L3.29334 37.4822Z" fill="#494456" />
            </g>
            <path d="M11.1096 54.1415H8.20527C6.54924 54.1415 5.20111 52.7601 5.20111 51.0707V47.1262C5.20111 46.8017 5.46741 46.5437 5.78363 46.5437C6.09986 46.5437 6.36616 46.81 6.36616 47.1262V51.0707C6.36616 52.1193 7.19002 52.9764 8.19695 52.9764H11.1013C12.1082 52.9764 12.932 52.1193 12.932 51.0707V47.1262C12.932 46.8017 13.1983 46.5437 13.5146 46.5437C13.8308 46.5437 14.0971 46.81 14.0971 47.1262V51.0707C14.0971 52.7684 12.749 54.1498 11.0929 54.1498L11.1096 54.1415Z" fill="#494456" />
            <path d="M51.4785 54.1415H48.5742C46.9182 54.1415 45.57 52.7601 45.57 51.0708V47.3176C45.57 46.9931 45.8363 46.7351 46.1526 46.7351C46.4688 46.7351 46.7351 47.0014 46.7351 47.3176V51.0708C46.7351 52.1193 47.5589 52.9764 48.5659 52.9764H51.4702C52.4854 52.9764 53.301 52.1193 53.301 51.0708V47.3176C53.301 46.9931 53.5673 46.7351 53.8835 46.7351C54.1997 46.7351 54.466 47.0014 54.466 47.3176V51.0708C54.466 52.7601 53.1179 54.1415 51.4619 54.1415H51.4785Z" fill="#494456" />
            <path d="M54.3492 47.7004H38.3797C38.0552 47.7004 37.7972 47.4341 37.7972 47.1179C37.7972 46.8017 38.0635 46.5354 38.3797 46.5354H54.3492C54.8902 46.5354 55.2979 46.3773 55.5975 46.0694C56.2716 45.362 56.205 44.0805 56.205 44.0638C56.205 44.0555 56.205 44.0389 56.205 44.0305V30.8738C56.205 30.8738 56.3215 26.7545 53.8083 24.8821C53.6752 24.7823 50.8125 22.5271 50.2133 19.9806L48.8985 14.3634C48.8569 14.1886 47.8 10.0694 43.273 10.0694H16.6017C12.0746 10.0694 11.0178 14.197 10.9761 14.3717L9.66131 19.9806C9.07878 22.4605 6.3326 24.6658 6.08294 24.8655C3.56145 27.0874 3.66131 30.8405 3.66963 30.8738V44.0638C3.66963 44.0638 3.61138 45.362 4.27712 46.0694C4.5767 46.3856 4.97615 46.5354 5.52538 46.5354H21.3118C21.6363 46.5354 21.8943 46.8017 21.8943 47.1179C21.8943 47.4341 21.628 47.7004 21.3118 47.7004H5.52538C4.65992 47.7004 3.95257 47.4175 3.41998 46.8599C2.44633 45.828 2.47961 44.2136 2.49626 44.0056V30.8988C2.48794 30.5909 2.41304 26.5132 5.32566 23.9667C5.33398 23.9667 5.34231 23.9501 5.35063 23.9418C5.37559 23.9251 8.01359 21.8447 8.5129 19.706L9.83606 14.0971C10.094 13.1567 10.5351 12.2746 11.1343 11.5007C12.0497 10.3107 13.739 8.896 16.5933 8.896H43.2563C46.1107 8.896 47.8 10.3107 48.7154 11.5007C49.3146 12.2746 49.7556 13.1567 50.0219 14.0971L51.3368 19.7143C51.8444 21.8613 54.4824 23.9334 54.5074 23.9501C57.4533 26.1554 57.37 30.5909 57.3617 30.9071V44.0139C57.37 44.2219 57.4117 45.8364 56.438 46.8683C55.9054 47.4258 55.1981 47.7088 54.3326 47.7088L54.3492 47.7004Z" fill="#494456" />
            <path d="M57.5617 20.6213H53.7836C52.8765 20.6213 52.1442 19.889 52.1442 18.9819V16.6684C52.1442 15.7614 52.8765 15.0291 53.7836 15.0291H57.5617C58.4688 15.0291 59.2011 15.7614 59.2011 16.6684V18.9736C59.2011 19.8806 58.4771 20.6213 57.57 20.6213H57.5617ZM53.7753 16.2024C53.5173 16.2024 53.3093 16.4105 53.3093 16.6684V18.9736C53.3093 19.2315 53.5173 19.4396 53.7753 19.4396H57.5534C57.8114 19.4396 58.0194 19.2315 58.0194 18.9736V16.6684C58.0194 16.4105 57.8114 16.2024 57.5534 16.2024H53.7753Z" fill="#494456" />
            <path d="M6.3495 20.6213H2.5631C1.65602 20.6213 0.923706 19.889 0.923706 18.9819V16.6684C0.923706 15.7614 1.65602 15.0291 2.5631 15.0291H6.34118C7.24826 15.0291 7.98057 15.7614 7.98057 16.6684V18.9736C7.98057 19.8806 7.25658 20.6213 6.3495 20.6213ZM2.5631 16.1941C2.30512 16.1941 2.09708 16.4021 2.09708 16.6601V18.9653C2.09708 19.2232 2.30512 19.4313 2.5631 19.4313H6.34118C6.59916 19.4313 6.8072 19.2232 6.8072 18.9653V16.6601C6.8072 16.4021 6.59916 16.1941 6.34118 16.1941H2.5631Z" fill="#494456" />
            <path d="M51.9115 22.3273C51.587 22.3273 51.329 22.061 51.329 21.7448C51.329 21.5534 51.4205 21.3786 51.5786 21.2621L53.6757 19.7892C53.942 19.6061 54.3082 19.6643 54.4913 19.9306C54.6743 20.1969 54.6161 20.5631 54.3498 20.7462L52.2527 22.2191C52.1528 22.2857 52.0363 22.3273 51.9198 22.3273H51.9115Z" fill="#494456" />
            <path d="M49.4732 14.8128C49.2152 14.8128 48.9905 14.6463 48.9156 14.405C48.4496 13.0902 47.7922 11.8419 46.9517 10.7268C45.1708 8.36341 42.9656 7.16507 40.4191 7.16507H19.4649C16.9101 7.16507 14.7131 8.36341 12.9323 10.7268C12.1001 11.8502 11.4344 13.0902 10.9683 14.405C10.8685 14.7129 10.5439 14.8877 10.236 14.7878C9.92812 14.688 9.75336 14.3634 9.85322 14.0555C10.3525 12.6242 11.0682 11.2677 11.9753 10.0527C13.9642 7.38976 16.5606 5.9917 19.4732 5.9917H40.4274C43.34 5.9917 45.9364 7.39808 47.917 10.0527C48.8241 11.2677 49.5398 12.6242 50.0391 14.0555C50.1389 14.3634 49.9642 14.6963 49.6563 14.7878C49.598 14.8045 49.5398 14.8128 49.4815 14.8128H49.4732Z" fill="#494456" />
            <path d="M39.5451 35.3841H20.3301C19.0319 35.3841 17.9833 34.3356 17.9833 33.0374V29.7586C17.9833 28.4604 19.0319 27.4119 20.3301 27.4119H39.5451C40.8433 27.4119 41.8918 28.4604 41.8918 29.7586V33.0374C41.8918 34.3356 40.8433 35.3841 39.5451 35.3841ZM20.3301 28.5852C19.681 28.5852 19.1567 29.1095 19.1567 29.7586V33.0374C19.1567 33.6865 19.681 34.2108 20.3301 34.2108H39.5451C40.1942 34.2108 40.7184 33.6865 40.7184 33.0374V29.7586C40.7184 29.1095 40.1942 28.5852 39.5451 28.5852H20.3301Z" fill="#494456" />
            <path d="M53.8752 44.2802H5.75867C4.5853 44.2802 3.71151 43.9806 3.13731 43.3731C2.72954 42.957 2.50485 42.3995 2.50485 41.8086C2.51317 41.4841 2.78779 41.2344 3.11234 41.2428C3.43689 41.2511 3.68654 41.5257 3.67822 41.8502C3.69487 42.1249 3.80305 42.3828 4.00277 42.5742C4.34396 42.9154 4.95145 43.0985 5.75867 43.0985H53.8835C53.8835 43.0985 56.0888 43.0735 56.172 41.792C56.1969 41.4674 56.4716 41.2261 56.7961 41.2428C57.1207 41.2677 57.362 41.5423 57.3454 41.8669C57.2788 42.8655 56.613 43.6228 55.4646 44.0056C54.957 44.1637 54.4244 44.2552 53.8835 44.2636L53.8752 44.2802Z" fill="#494456" />
            <path d="M38.3222 50.4132H21.4457C21.1211 50.4132 20.8632 50.1469 20.8632 49.8307V43.6809C20.8632 43.3564 21.1295 43.0984 21.4457 43.0984H38.3222C38.6468 43.0984 38.9048 43.3647 38.9048 43.6809V49.8307C38.9048 50.1553 38.6385 50.4132 38.3222 50.4132ZM22.0282 49.2399H37.7314V44.2634H22.0282V49.2399Z" fill="#494456" />
            <path d="M51.154 21.6033H51.104C30.3994 19.8391 8.9792 21.5867 8.76283 21.6033C8.43828 21.6283 8.15534 21.387 8.13038 21.0624C8.10541 20.7379 8.34674 20.4549 8.67129 20.43C8.88766 20.4133 30.3994 18.6491 51.2039 20.43C51.5284 20.4466 51.7781 20.7129 51.7614 21.0375C51.7448 21.362 51.4785 21.6117 51.154 21.595V21.6033Z" fill="#494456" />
            <path d="M13.9972 33.8282H8.08042C6.3495 33.8282 4.94312 32.48 4.94312 30.824V30.1333C4.94312 29.2761 4.94312 28.2026 5.69208 27.4454C6.22467 26.9128 7.02356 26.6548 8.12203 26.6548C8.13868 26.6548 8.16364 26.6548 8.18029 26.6548L14.0471 27.2373H14.0887C15.9362 27.5535 17.1262 28.7269 17.1262 30.2332V30.8157C17.1262 32.48 15.7198 33.8198 13.9889 33.8198L13.9972 33.8282ZM8.10539 27.8365C7.35643 27.8365 6.82384 27.9946 6.53257 28.2859C6.12481 28.6936 6.12481 29.4259 6.12481 30.1416V30.8323C6.12481 31.8393 7.00692 32.6631 8.08875 32.6631H14.0055C15.0874 32.6631 15.9695 31.8393 15.9695 30.8323V30.2498C15.9695 29.1596 14.871 28.5854 13.9306 28.4107L8.11371 27.8282L8.10539 27.8365Z" fill="#494456" />
            <path d="M51.8616 33.8281H45.9448C44.2139 33.8281 42.8075 32.48 42.8075 30.824V30.2414C42.8075 28.7352 43.9975 27.5618 45.8449 27.2456H45.8866L51.7534 26.6631C51.7534 26.6631 51.795 26.6631 51.8117 26.6631C52.9185 26.6631 53.709 26.9211 54.2416 27.4537C54.9906 28.2109 54.9906 29.2761 54.9906 30.1416V30.8323C54.9906 32.48 53.5842 33.8281 51.8533 33.8281H51.8616ZM46.028 28.4107C45.0793 28.5771 43.9892 29.1596 43.9892 30.2498V30.8323C43.9892 31.8392 44.8713 32.6631 45.9531 32.6631H51.8699C52.9517 32.6631 53.8339 31.8392 53.8339 30.8323V30.1416C53.8339 29.4342 53.8338 28.6936 53.4261 28.2858C53.1348 27.9946 52.6022 27.8365 51.8533 27.8365L46.0363 28.419L46.028 28.4107Z" fill="#494456" />
            <path d="M41.3088 44.2803C41.0509 44.2803 40.8179 44.1055 40.7513 43.8642L39.7527 40.5188C39.7527 40.5188 39.7527 40.5105 39.7527 40.5022C39.736 40.4439 39.3449 39.0625 38.1549 39.0625H21.8692C20.6625 39.0625 20.2714 40.4856 20.2714 40.5022C20.2714 40.5022 20.2714 40.5105 20.2714 40.5188L19.2728 43.8642C19.1896 44.1804 18.8733 44.3635 18.5571 44.2803C18.2409 44.1971 18.0578 43.8808 18.141 43.5646C18.141 43.5563 18.141 43.5396 18.1493 43.5313L19.1396 40.1943C19.356 39.3954 20.1965 37.8892 21.8692 37.8892H38.1549C39.8276 37.8892 40.6681 39.3871 40.8844 40.1943L41.8747 43.5313C41.9663 43.8392 41.7915 44.1638 41.4836 44.2636C41.4253 44.2803 41.3671 44.2886 41.3088 44.2886V44.2803Z" fill="#494456" />
            <path d="M20.372 39.7116H20.322L3.03772 38.1388C2.71317 38.1221 2.46351 37.8475 2.48016 37.5229C2.4968 37.1984 2.77142 36.9487 3.09597 36.9654C3.11261 36.9654 3.12925 36.9654 3.1459 36.9654L20.4302 38.5299C20.7548 38.5465 21.0044 38.8211 20.9878 39.1457C20.9711 39.4702 20.6965 39.7199 20.372 39.7033V39.7116Z" fill="#494456" />
            <path d="M39.6203 39.7033C39.2957 39.72 39.0211 39.4703 39.0128 39.1458C39.0045 38.8212 39.2458 38.5466 39.5703 38.5383L56.63 37.0653C56.9545 37.0403 57.2375 37.2734 57.2624 37.5979C57.2874 37.9225 57.0544 38.2054 56.7298 38.2304L39.6702 39.7033H39.6203Z" fill="#494456" />
            <path d="M40.311 41.8086H19.5482C19.2237 41.8086 18.9657 41.5423 18.9657 41.2261C18.9657 40.9099 19.232 40.6436 19.5482 40.6436H40.311C40.6356 40.6436 40.8936 40.9099 40.8936 41.2261C40.8936 41.5423 40.6273 41.8086 40.311 41.8086Z" fill="#494456" />
        </svg>
    );
}
