import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
  defaultFont300,
  defaultFontRedisign,
  whiteColor,
  grayColor,
  greenColor
} from "assets/jss/material-dashboard-pro-react.js";
import { blackColor } from "assets/jss/material-dashboard-pro-react";

const customInputStyle = {
  disabled: {
    backgroundColor:"#EDEDED",
    "&:before": {
      borderColor: "transparent !important",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[3] + "!important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
    "& + p": {
      fontWeight: "300",
    },
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0],
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor[0],
    },
  },
   labelRoot: {
    ...defaultFontRedisign,
    color: blackColor + " !important",
    fontSize: "18px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  labelRootSuccess: {
    color: successColor[0] + " !important",
  },
  formControl: {
    margin: "0 0 15px 0",
    paddingTop: "27px",

    position: "relative",
    borderColor: blackColor,
    verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[3],
    },
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: whiteColor,
    },
    "&:after": {
      backgroundColor: whiteColor,
    },
  },
  input: {
    color: blackColor,
    padding: "11px",
    height: "unset",
    padding: "12px 14px",
    "&,&::placeholder": {
      ...defaultFont,
      fontSize: "14px !important",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: grayColor[3],
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important", // Cambia el borde a rojo al hacer hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green !important", // Cambia el borde a verde cuando está enfocado
    },
  },
  whiteInput: {
    "&,&::placeholder": {
      color: whiteColor,
      opacity: "1",
    },
  },
  labelRTL: {
    right: 0,
    transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    "&.MuiInputLabel-shrink": {
      transform: "translate(0, 1.5px)",
      fontSize: "10px",
    },
  },
  labelOutlined: {
    left: "-10px !important",
    lineHeight: 1.5, /* Ajusta la altura de línea */
    
  },
  secondaryText: {
    ...defaultFont300,
    color:   "#1D1D1D !important",
    fontSize: "12px !important",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    marginLeft: "3px !important",
    marginBottom: "10px !important",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  toogleSelected: {
    color: whiteColor + " !important",
    backgroundColor: greenColor + " !important",
  },
  optionInput: {
    marginRight:"10px"
  },
};


export default customInputStyle;