import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { styled } from "@mui/material/styles";
//import { DateRangePicker } from "react-dates";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Button from "components/CustomButtons/Button.js";
import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  getUTCDate,
  verifyLength,
} from "components/Utils/utils.js";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomDatePicker(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
    style: {
      padding: "12px 14px"
    }
  };
  const renderCalendarInfo = () => {
    return (
      <div style={{ textAlign: "center", padding: "0 20px" }}>
        <Button
          color="info"
          onClick={() => {
            setFocusedInput(null);
          }}
          fullWidth
        >
          Done
        </Button>
      </div>
    );
  };
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const CustomInput = styled(OutlinedInput)(() => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#888888",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#008670",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#008670",
        borderWidth: "1px",
      },
    },
  }));
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      variant="outlined"
    >
      {labelText !== undefined ? (
        <InputLabel
          className={
            classes.labelRoot + " " + classes.labelOutlined + " " + labelClasses
          }
          htmlFor={id}
          shrink={true}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <CustomInput
        
        type="date"
        
        classes={{
          select: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
        }}
        id={id}
        {...inputProps}
        inputProps={newInputProps}
      />

      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomDatePicker.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
};
