import { React } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import logo from "assets/img/logo.png";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { Grid } from "react-loader-spinner";
import CardBody from "components/Card/CardBody";
import { useTranslation } from "react-i18next";

function LoginBase({ children, ...props }) {
  const { type } = props;
  const { t, i18n } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card className={classes.containerLogin}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                {children}
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={6}
                style={{ display: "flex", paddingLeft: "0 !important" }}
              >
                <CardBody
                  style={{
                    display: "flex",
                    backgroundColor: type == "customer" ? "#13816F" : "#FFC14B",
                    color: type == "customer" ? "white" : "black",
                    padding: "15px",
                    alignItems: "flex-end",
                  }}
                >
                  © {1900 + new Date().getYear()} {t("footer.redesign")}
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginBase.propTypes = {
  children: PropTypes.object,
};

export default LoginBase;
