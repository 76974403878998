import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles((theme) => ({
  ...styles,
  errorBorder: {
    border: "1px solid red",
    borderRadius: "4px",
    padding: "4px", 
  },
}));

export default function CustomToogle(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    items,
    defaultValue,
    information,
    inline,
    secondaryText,
  } = props;

  const [valor, setValor] = useState(defaultValue || null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValor(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (inputProps?.required && !valor) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [valor, inputProps]);

  const handleValorChange = (event, newValor) => {
    if (newValor !== null) {
      setValor(newValor);
      event.target.name = inputProps.name;
      inputProps.onChange(event);
    }
  };

  const formControlClasses = classNames(
    formControlProps?.className,
    classes.formControl
  );

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });

  const toggleGroupClasses = classNames({
    [classes.errorBorder]: hasError,
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      variant="outlined"
    >
      {labelText && (
        <Typography component="p">
          <InputLabel
            shrink
            className={
              classes.labelRoot +
              " " +
              classes.labelOutlined +
              " " +
              labelClasses
            }
            {...labelProps}
          >
            {labelText}
            {information && <InformationTooltip text={information} />}
          </InputLabel>
          <br/>
        </Typography>
      )}

      <div >
        <ToggleButtonGroup
          value={valor}
          exclusive
          style={{ marginTop: inline ? "-5px" : "10px" }}
          onChange={handleValorChange}
          aria-label="coverage option"
          className={toggleGroupClasses}
        >
          {items.map((item) => (
            <ToggleButton
              key={item.value}
              className={valor === item.value ? classes.toogleSelected : ""}
              value={item.value}
            >
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>

      {secondaryText && (
        <FormHelperText
          id={`${id}-secondaryText`}
          className={classNames(classes.secondaryText, classes.labelOutlined)}
        >
          {secondaryText}
        </FormHelperText>
      )}

      {helperText && (
        <FormHelperText id={id + "-text"} className={classes.labelRootError}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CustomToogle.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
  }),
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  defaultValue: PropTypes.any,
  information: PropTypes.string,
  inline: PropTypes.bool,
  secondaryText: PropTypes.string,
};
