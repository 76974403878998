import React from "react";
export default function Icon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.52891 9.30247H6.66894M12 9.30247H12.14M17.3295 9.30247H17.4695M11.4 14.7025H4.53336C3.35515 14.7025 2.40002 13.7473 2.40002 12.5691V6.0358C2.40002 4.85759 3.35515 3.90247 4.53336 3.90247H19.4667C20.6449 3.90247 21.6 4.85759 21.6 6.0358V9.30247M15.4112 13.5975L16.8012 12.9025C17.8147 12.3958 19.0077 12.3958 20.0212 12.9025L21.4112 13.5975C21.4112 13.5975 21.4112 15.7875 21.4112 16.9425C21.4112 18.0975 20.3435 18.8772 18.4112 20.0975C16.4789 18.8772 15.4112 17.8475 15.4112 16.9425V13.5975Z"
        stroke="#13816F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
