import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

class Wizard extends React.Component {
  isValidVehicleTab = true;
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s",
      },
      allStates: {},
      toastMessage: false,
      messageToast: "",
      typeToast: "",
      showButtonCheckout: false,
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }
  wizard = React.createRef();
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }
  showSnackbar = (type, message) => {
    this.setState({
      toastMessage: true,
      typeToast: type,
      messageToast: message,
    });
  };
  hideSnackbar = () => {
    this.setState({
      toastMessage: false,
    });
  };
  navigationStepChange(key) {
    console.log("CLICK!! NEXT STEP NAVBAR");
    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [this.props.steps[i].stepId]: this[
                  this.props.steps[i].stepId
                ].sendState(),
              },
            });
            this.hideSnackbar();
          } else {
            this.showSnackbar(
              "danger",
              "Complete the required fields to continue."
            );
          }
          if (
            this[this.props.steps[i].stepId].isValidated !== undefined &&
            this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            this.showSnackbar(
              "danger",
              "Complete the required fields to continue."
            );
            break;
          }
          if (
            (this.props.data.selectedVehicle === undefined ||
              this.props.showAddVehicle ||
              (this.props.data.selectedVehicle.insurance_policy_number ==
                "No" &&
                this.props.data.selectedVehicle.total_value != "0")) &&
            this.props.steps[this.state.currentStep].stepName === "Vehicle"
          ) {
            validationState = false;
            let message = this.setMessageVehicle(this.props);
            this.showSnackbar("danger", message);
          }
          if (
            this.props.data.selectedVehicle === undefined &&
            this.props.steps[this.state.currentStep].stepName === "Client" &&
            key === 2
          ) {
            validationState = false;
            this.showSnackbar("danger", "Select a vehicle to continue.");
          }
        }
      } else {
        let messageSnack =
          this.props.showAddVehicle &&
          this.props.steps[this.state.currentStep].stepName === "Vehicle"
            ? "Complete the required fields and save to continue"
            : "Complete the required fields to continue.";
        this.showSnackbar("danger", messageSnack);
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false,
        });
        this.hideSnackbar();
        this.refreshAnimation(key);
      }
    }
  }
  nextButtonClick() {
    console.log("CLICK!! NEXT STEP");
    console.log(this.state.currentStep);
    this.isValidVehicleTab = true;
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        });
      } else {
        this.showSnackbar(
          "danger",
          "Complete the required fields to continue."
        );
        this.isValidVehicleTab = false;
      }
      if (
        (this.props.data.selectedVehicle === undefined ||
          this.props.showAddVehicle ||
          (this.props.data.selectedVehicle.insurance_policy_number == "No" &&
            this.props.data.selectedVehicle.total_value != "0")) &&
        this.props.steps[this.state.currentStep].stepName === "Vehicle"
      ) {
        let message = this.setMessageVehicle(this.props);
        this.showSnackbar("danger", message);
        this.isValidVehicleTab = false;
      } else {
        var key = this.state.currentStep + 1;
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false,
        });
        this.refreshAnimation(key);
      }
    } else {
      let messageSnack =
        this.props.showAddVehicle &&
        this.props.steps[this.state.currentStep].stepName === "Vehicle"
          ? "Complete the required fields and save to continue"
          : "Complete the required fields to continue.";
      this.showSnackbar("danger", messageSnack);
      this.isValidVehicleTab = false;
    }
  }
  previousButtonClick() {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: this[
            this.props.steps[this.state.currentStep].stepId
          ].sendState(),
        },
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.refreshAnimation(key);
    }
  }
  finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState?.(),
          },
        },
        () => {
          this.props.finishButtonClick(this.state.allStates);
        }
      );
    }
  }
  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }
  handleViewButtonCheckout = (valor) => {
    this.setState({ showButtonCheckout: valor });
  };

  setMessageVehicle = (props) => {
    let message = "Select a vehicle to continue.";
    message = props.showAddVehicle
      ? "Complete the required fields and save to continue"
      : message;
    if (typeof props.data.selectedVehicle != "undefined") {
      message =
        props.data.selectedVehicle.insurance_policy_number == "No" &&
        props.data.selectedVehicle.total_value != "0"
          ? "You must have full coverage on this vehicle in the U.S./Canada to set value greater 0."
          : message;
    }
    return message;
  };

  render() {
    const {
      classes,
      title,
      subtitle,
      color,
      steps,
      inputHandlerCoverage,
      setStepData,
      setData,
      fastQuote,
      finishClasses,
      fullQuoteVehicle,
      fullQuoteRates,
      calculateRates,
      addVehicle,
      showAddVehicle,
      quoteId,
      setAgencyContinue,
      vehicleDataFromSite,
      type,
      setEmailDLFastquote
    } = this.props;
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <a
                      href="#"
                      className={classes.stepsAnchor}
                      onClick={(e) => {
                        e.preventDefault();
                        this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    data={this.props.data}
                    setData={setData}
                    ref={(node) => (this[prop.stepId] = node)}
                    allStates={this.state.allStates}
                    inputHandlerCoverage={inputHandlerCoverage}
                    setStepData={setStepData}
                    fastQuote={fastQuote}
                    fullQuoteVehicle={fullQuoteVehicle}
                    fullQuoteRates={fullQuoteRates}
                    calculateRates={calculateRates}
                    addVehicle={addVehicle}
                    showAddVehicle={showAddVehicle}
                    quoteId={quoteId}
                    type={type}
                    setEmailDLFastquote={setEmailDLFastquote}
                    setAgencyContinue={setAgencyContinue}
                    vehicleDataFromSite={vehicleDataFromSite}
                    sendValueErrorRates={this.handleViewButtonCheckout}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer} style={{ marginTop: "10px" }}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  id={
                    this.props.previousButtonId
                      ? this.props.previousButtonId
                      : "previousButton"
                  }
                  color={this.props.previousButtonColor}
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton && ((this.state.currentStep == 1 && !this.props.showAddVehicle) || this.state.currentStep != 1) ? (
                <Button
                  id={
                    this.props.nextButtonId
                      ? this.props.nextButtonId
                      : "nextButton"
                  }
                  color={
                    this.state.currentStep != 1
                      ? "danger"
                      : this.state.currentStep == 1 && this.isValidVehicleTab
                      ? "danger"
                      : "gray"
                  }
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              {this.state.finishButton ? (
                this.state.showButtonCheckout ? (
                  <Button
                    id={
                      this.props.checkoutButtonId
                        ? this.props.checkoutButtonId
                        : "checkoutButton"
                    }
                    color="success"
                    className={finishClasses}
                    onClick={() => this.finishButtonClick()}
                    style={{ padding: "12px 20px" }}
                  >
                    {this.props.finishButtonText}
                  </Button>
                ) : null
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
        <GridItem xs={12} sm={12} md={3}>
          <Snackbar
            place="br"
            color={this.state.typeToast}
            message={this.state.messageToast}
            open={this.state.toastMessage}
            closeNotification={this.hideSnackbar}
            close
          />
        </GridItem>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "danger",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonColor: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Checkout",
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonColor: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
};

export default withStyles(wizardStyle)(Wizard);
