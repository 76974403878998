import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";
import Apps from "@material-ui/icons/Apps";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";

import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Phone from "@material-ui/icons/Phone";
import Download from "@material-ui/icons/CloudDownload";
import { Edit, Delete } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {
  getQuotesByClient,
  deleteQuoteAPI,
  getAgencyDataByClient,
} from "views/Services/OrdersService";
import environment from "../../environment/environment";
const server = `${environment.SERVER}`;

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);

export default function Dashboard() {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const history = useHistory();
  const reactRedesign = process.env.REACT_APP_REDESIGN;
  const isRedesign = reactRedesign === "true" ? true : false;
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [agencyData, setAgencyData] = useState(null);
  const cardStyleExpire = {
    border: "2px solid #F44336",
  };
  const cardStyleWarning = {
    border: "2px solid #FF9802",
  };
  const cardStyleSuccess = {
    border: "2px solid #4CAF50",
  };
  useEffect(() => {
    getOrders();
    getAgencyData();
  }, [dispatch]);
  const getOrders = async () => {
    const resultSubmit = await dispatch(getQuotesByClient());
    setOrders(resultSubmit.payload.data);
    const ordenesT = [];
    resultSubmit.payload.data.forEach((order) => {
      ordenesT.push([
        <span>
          <br />
          <span style={{ fontSize: "1.4em", marginTop: "10px" }}>
            <strong>{order.quote_number}</strong>
          </span>
          <br />
          <span style={{ color: "#00acc1" }}>Producer</span> <br />
          {order.producer_code}
          <br />
          <span style={{ color: "#00acc1" }}>Date Saved</span> <br />
          {order.created_date}
          <br />
          <span style={{ color: "#00acc1" }}> Last Name</span>
          <br />
          {order.last_name}
          <br />
          <span style={{ color: "#00acc1" }}> First Name</span>
          <br />
          {order.first_name}
          <br />
          <span style={{ color: "#00acc1" }}>Year</span>
          <br />
          {order.year}
          <br />
          <span style={{ color: "#00acc1" }}>Make</span>
          <br />
          {order.vehicle_make_name ? order.vehicle_make_name : order.make}
          <br />
          <span style={{ color: "#00acc1" }}>Model:</span>
          <br />
          {order.vehicle_model_name ? order.vehicle_model_name : order.model}
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              justIcon
              round
              color="twitter"
              style={{ marginRight: "20px" }}
              onClick={() => {
                window.open(`${server}quotes_mobile/downloadquote/${order.id}`);
              }}
            >
              <Download />
            </Button>
            <Button
              justIcon
              onClick={() =>
                history.push(
                  isRedesign
                    ? `/guestRedesing/en/${order.id}`
                    : `/admin/wizard/${order.id}`
                )
              }
              round
              color="twitter"
              style={{ marginRight: "20px" }}
            >
              <Edit />
            </Button>
            <Button
              justIcon
              onClick={() => showAlertConfirm(order.id)}
              round
              color="danger"
              style={{ marginRight: "7px" }}
            >
              <Delete />
            </Button>
          </div>
        </span>,
      ]);
    });
    setOrdenes(ordenesT);
  };
  const getAgencyData = async () => {
    const resultSubmit = await dispatch(getAgencyDataByClient());
    setAgencyData(resultSubmit.payload.data);
    console.log(agencyData);
  };
  const deleteQuote = async (id) => {
    const resultSubmit = await dispatch(deleteQuoteAPI({ id: id }));
    setAlert(null);
    getOrders();
  };
  const classes = useStyles();
  const classesAlerts = useStylesAlert();

  // Hooks
  const [showActivePolicies, setActivePolicies] = React.useState(false);
  const [showAllPolicies, setAllPolicies] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const showAlertConfirm = (id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Do you want delete this quote?"
        onConfirm={() => deleteQuote(id)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classesAlerts.button + " " + classesAlerts.success}
        cancelBtnCssClass={classesAlerts.button + " " + classesAlerts.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  return (
    <div>
      {alert}
      <div></div>
      <GridContainer
        style={{
          marginTop: "20px",
        }}
      >
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon></CardHeader>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <h3
                  className={classes.cardIconTitle}
                  style={{ marginLeft: "25px" }}
                >
                  My Quotes
                </h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <CardBody>
                  <Table tableData={[ordenes]} />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
