import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import EditIcon from "@material-ui/icons/Edit";
import GridItem from "components/Grid/GridItem.js";
import CustomButton from "components/CustomButtons/CustomButton.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const useStylesPrincipal = makeStyles(stylePrincipal);

import { formatNumber } from "components/Utils/utils.js";

export default function CustomInput(props) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const classesPrincipal = useStylesPrincipal();
    const { edit, size, variant, text, cost, buy, clicked, onEdit, buyEvent } = props;
    return (
        <Card

            sx={{
                width: "100%",
                maxWidth: "100%",
                boxShadow: "lg",
                marginBottom: "20px",
               
            }}
        >
            <CardOverflow sx={{
                cursor: "pointer"
            }} onClick={edit ? onEdit : clicked}>
                <Button
                    className={
                        classes[variant] + " " + classes.radius + " " + classes.noUpper
                    }
                    variant="solid"
                    color="warning"
                    size="lg"

                >
                    {edit ? (
                        <div
                            style={{
                                width: "100%",
                                textTransform: "none !important",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}

                        >
                            <span style={{ textAlign: "left" }}>{text}</span>
                            <span style={{ textAlign: "right" }}>
                            {t("common.editQuote")}<EditIcon></EditIcon>
                            </span>
                        </div>
                    ) : (
                            <div
                                style={{
                                    textTransform: "none !important",
                                }}
                            >
                                {text}
                            </div>
                        )}
                </Button>
            </CardOverflow>
            <CardContent>
                {edit || buy ? (
                    <div
                        className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
                        style={{
                            width: "100%",
                            textTransform: "none !important",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <span className={`${classesPrincipal.title} ${classesPrincipal.black} `}
                                style={{ textAlign: "right" }}>${cost}</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <span style={{ textAlign: "right" }}>

                                <CustomButton
                                    variant="green"
                                    size="md"
                                    onClick={buyEvent}
                                >
                                   {t("common.buy")}
                                </CustomButton>
                            </span>
                        </GridItem>
                    </div>
                ) : (
                        <GridItem xs={12} sm={12} md={12}>
                            <div
                                className={`${classesPrincipal.priceText} ${classesPrincipal.black} ${classesPrincipal.center} ${classesPrincipal.green}`}
                                style={{
                                    width: "100%",
                                    textTransform: "none !important",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                ${cost}
                            </div>
                        </GridItem>
                    )}
            </CardContent>
        </Card>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
    rtlActive: PropTypes.bool,
};
