import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Vehicle from "components/Vehicle/Vehicle.js";
import cardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Button from "components/CustomButtons/Button.js";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { getMakes, getModels } from "views/Services/VehiclesService";

import { formatNumber } from "components/Utils/utils.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  iconAligth: {
    position: "absolute",
    top: 70,
    right: 20,
    color: "red",
    with: 30,
    heigth: 30,
  },
  iconEdit: {
    position: "absolute",
    top: 70,
    right: 50,
    color: "black",
    with: 30,
    heigth: 30,
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);
const useExtFormStyles = makeStyles(extendedFormstyles);
const useStylesAlert = makeStyles(stylesAlerts);

let current_date = new Date();

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const trailerTypes = [
  { id: 1, name: "Trailer" },
  { id: 2, name: "Boat" },
  { id: 3, name: "Other" },
  { id: 4, name: "ATV/UTV" },
];

const Step2 = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const classesExtForm = useExtFormStyles();
  const classesAlerts = useStylesAlert();

  const [firstnameState, setfirstnameState] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [lastnameState, setlastnameState] = React.useState("");
  const [email, setemail] = React.useState("");
  const [emailState, setemailState] = React.useState("");
  const [data, setData] = useState({});
  const [fastQuote, setFastQuote] = useState(true);
  const [years, setYears] = useState([]);
  const [makesCatalog, setMakesCatalog] = useState([]);
  const [modelsCatalog, setModelsCatalog] = useState([]);

  const [vehType, setVehType] = React.useState("");
  const [vehTypeState, setVehTypeState] = React.useState("");
  const [yearVehicleState, setYearVehicleState] = React.useState("");
  const [yearVehicle, setYearVehicle] = React.useState("");
  const [makeSelectState, setMakeSelectState] = React.useState("");
  const [makeSelect, setMakeSelect] = React.useState("");
  const [makeVehicleState, setMakeVehicleState] = React.useState("");
  const [makeVehicle, setMakeVehicle] = React.useState("");
  const [modelSelectState, setModelSelectState] = React.useState("");
  const [modelSelect, setModelSelect] = React.useState("");
  const [modelVehicleState, setModelVehicleState] = React.useState("");
  const [modelVehicle, setModelVehicle] = React.useState("");
  const [valueVehicleState, setValueVehicleState] = React.useState("");
  const [valueVehicle, setValueVehicle] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      vehType,
      vehTypeState,
      yearVehicle,
      yearVehicleState,
      makeSelectState,
      makeSelect,
      makeVehicleState,
      makeVehicle,
      modelSelectState,
      modelSelect,
      modelVehicleState,
      modelVehicle,
      valueVehicleState,
      valueVehicle,
    },
  }));
  const sendState = () => {
    return {
      vehType,
      vehTypeState,
      yearVehicle,
      yearVehicleState,
      makeSelectState,
      makeSelect,
      makeVehicleState,
      makeVehicle,
      modelSelectState,
      modelSelect,
      modelVehicleState,
      modelVehicle,
      valueVehicleState,
      valueVehicle,
    };
  };
  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    if (
      vehTypeState === "success" &&
      yearVehicleState === "success" &&
      makeSelectState === "success" &&
      makeVehicleState === "success" &&
      modelSelectState === "success" &&
      modelVehicleState === "success" &&
      valueVehicleState === "success"
    ) {
      return true;
    } else {
      if (vehTypeState !== "success") {
        setVehTypeState("error");
      }
      if (yearVehicleState !== "success") {
        setYearVehicleState("error");
      }
      if (makeSelectState !== "success") {
        setMakeSelectState("error");
      }
      if (makeVehicleState !== "success") {
        setMakeVehicleState("error");
      }
      if (modelSelectState !== "success") {
        setModelSelectState("error");
      }
      if (modelVehicleState !== "success") {
        setModelVehicleState("error");
      }
      if (valueVehicleState !== "success") {
        setValueVehicleState("error");
      }
    }
    return false;
  };

  const editVehicle = (data) => {
    const vehicleTemp = data;
    vehicleTemp.vehtype = comprobarProps(
      vehicleTemp,
      "vehtype",
      "vehicle_type_id"
    );
    vehicleTemp.insurancecompany = comprobarProps(
      vehicleTemp,
      "insurancecompany",
      "insurance_company"
    );
    vehicleTemp.insurancepolnumber = comprobarProps(
      vehicleTemp,
      "insurancepolnumber",
      "insurance_policy_number"
    );
    vehicleTemp.year_vehicle = comprobarProps(
      vehicleTemp,
      "year_vehicle",
      "year_model",
      true
    );
    vehicleTemp.make_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "makeId"
        : "make_vehicle",
      "make"
    );
    vehicleTemp.model_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "modelId"
        : "model_vehicle",
      "model"
    );
    vehicleTemp.value = comprobarProps(
      vehicleTemp,
      "value",
      "total_value",
      true
    );
    vehicleTemp.vin_vehicle = comprobarProps(vehicleTemp, "vin_vehicle", "vin");
    vehicleTemp.sentri = comprobarProps(vehicleTemp, "sentri", "sentri_number");
    vehicleTemp.plates_vehicle = comprobarProps(
      vehicleTemp,
      "plates_vehicle",
      "plates"
    );
    vehicleTemp.lienholder_vehicle = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle",
      "lienholder"
    );
    vehicleTemp.haveLienholder = vehicleTemp.lienholder_vehicle ? "Yes" : "No";
    vehicleTemp.lienholder_vehicle_address = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle_address",
      "lienholder_vehicle_address"
    );
    setVehicleDataEdit(vehicleTemp);
    setshowEditVehicle(false);
    setshowVehicle(false);
    setshowAddVehicle(true);
    props.setStepData("showAddVehicle", true);
  };

  const comprobarProps = (
    objeto,
    propObjeto,
    propDatabase,
    parseToInt = false
  ) => {
    const valor = objeto[propObjeto]
      ? objeto[propObjeto]
      : objeto[propDatabase];
    if (parseToInt) {
      return parseInt(valor);
    } else {
      return valor;
    }
  };

  const [validDL, setvalidDL] = React.useState("");
  const [vehicleType, setvehicleType] = React.useState("");
  const [value, setvalue] = React.useState("");
  const [year, setYear] = React.useState("");
  const [make, setMake] = React.useState("");
  const [model, setModel] = React.useState("");

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const [showVehicle, setshowVehicle] = React.useState(false);
  const [showEditVehicle, setshowEditVehicle] = React.useState(false);
  const [showAddVehicle, setshowAddVehicle] = React.useState(true);
  const [vehicleDataEdit, setVehicleDataEdit] = React.useState({});
  const [showSelectMake, setShowSelectMake] = useState(false);
  const [valores, setValores] = useState([]);

  const [alert, setAlert] = React.useState(null);
  const confirmAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure to remove the vehicle?"
        onConfirm={() => {
          setSelectedVehicle(null);
          setshowAddVehicle(true);
          props.setStepData("showAddVehicle", true);
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        cancelBtnText={"Cancel"}
        cancelBtnCssClass={classesAlerts.button + " " + classesAlerts.danger}
        showCancel={true}
        confirmBtnCssClass={classesAlerts.button + " " + classesAlerts.success}
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const closeCreateVehicle = () => {
    setshowEditVehicle(false);
    setshowAddVehicle(false);
    setshowVehicle(true);
    setVehTypeState("success");
    setYearVehicleState("success");
    setMakeSelectState("success");
    setMakeVehicleState("success");
    setModelSelectState("success");
    setModelVehicleState("success");
    setValueVehicleState("success");
    props.setStepData("fullQuoteRates", true);
    props.setStepData("addVehicle", true);
    props.setStepData("fullQuoteVehicle", false);
    props.setStepData("showAddVehicle", false);
    setVehicleDataEdit({});
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      if (!years.length) {
        const currentYear = new Date().getFullYear() + 1;
        const arreglo = [];
        for (let index = currentYear; index >= currentYear - 59; index--) {
          arreglo.push(index);
        }
        setYears(arreglo);
      }
    }
    if (props.fastQuote) {
      setFastQuote(true);
      if (
        props.data.selectedVehicle &&
        data?.catalogs?.vehicle_types &&
        props.vehicleDataFromSite
      ) {
        setValuesForTypeVehicle(
          parseInt(props.data.selectedVehicle.vehType),
          props.data.selectedVehicle
        );
        setSelectedVehicle(props.data.selectedVehicle);
        setVehType(props.data.selectedVehicle.vehtype);
        setValueVehicle(props.data.selectedVehicle.total_value);
        setVehTypeState("success");
        setValueVehicleState("success");
        checkMakeModelStatus(props.data.selectedVehicle.vehtype);
        props.setStepData("vehicleDataFromSite", false);
        props.setStepData("addVehicle", true);
        props.setStepData("fullQuoteRates", true);
      }
    } else {
      if (props.fullQuoteVehicle) {
        props.setStepData("fullQuoteVehicle", false);
        if (props.quoteId > 0) {
          setshowAddVehicle(false);
          setshowVehicle(true);
          setVehTypeState("success");
          setYearVehicleState("success");
          setMakeSelectState("success");
          setMakeVehicleState("success");
          setModelSelectState("success");
          setModelVehicleState("success");
          setValueVehicleState("success");
          setSelectedVehicle(props.data.selectedVehicleFromQuote);
          props.setStepData(
            "selectedVehicle",
            props.data.selectedVehicleFromQuote
          );
        } else {
          setSelectedVehicle(props.data.selectedVehicle);
          editVehicle(props.data.selectedVehicle);
        }
      }
      setFastQuote(false);
    }
  }, [props]);

  useEffect(() => {
    if (selectedVehicle) {
      let vehicle = { ...selectedVehicle };
      vehicle = setSelectedVehicleData(vehicle);
      props.setStepData("selectedVehicle", vehicle);
    }
  }, [selectedVehicle]);

  const setSelectedVehicleData = (vehicle) => {
    vehicle.total_value = vehicle.value;
    vehicle.plates = vehicle.plates_vehicle;
    vehicle.vin = vehicle.vin_vehicle;
    vehicle.insurance_company = vehicle.insurancecompany;
    vehicle.insurance_policy_number = vehicle.insurancepolnumber;
    vehicle.client_have_lienholder = vehicle.haveLienholder;
    vehicle.sentri_number =
      typeof vehicle.sentri != "undefined" ? vehicle.sentri : "";
    vehicle.vehicle_type_id = vehicle.vehtype;
    vehicle.mileage = "";
    vehicle.lienholder = vehicle.lienholder ? vehicle.lienholder : "";
    vehicle.lienholder_vehicle_address = vehicle.lienholder_vehicle_address
      ? vehicle.lienholder_vehicle_address
      : "";
    vehicle.vehtype_trailer = vehicle.vehtype_trailer
      ? vehicle.vehtype_trailer
      : "";
    let tempTrailer =
      typeof vehicle.vehtype_trailer != "undefined"
        ? vehicle.vehtype_trailer
        : "";
    vehicle.trailer = tempTrailer != "" ? "1" : "0";
    vehicle.other_policy_company_trailer = vehicle.other_policy_company_trailer
      ? vehicle.other_policy_company_trailer
      : "";
    vehicle.other_policy_number_trailer = vehicle.other_policy_number_trailer
      ? vehicle.other_policy_number_trailer
      : "";
    vehicle.year_model_trailer = vehicle.year_model_trailer
      ? vehicle.year_model_trailer
      : "";
    vehicle.make_trailer = vehicle.make_trailer ? vehicle.make_trailer : "";
    vehicle.model_trailer = vehicle.model_trailer ? vehicle.model_trailer : "";
    vehicle.total_value_trailer = vehicle.total_value_trailer
      ? vehicle.total_value_trailer
      : "";
    vehicle.plates_trailer = vehicle.plates_trailer
      ? vehicle.plates_trailer
      : "";
    vehicle.vin_trailer = vehicle.vin_trailer ? vehicle.vin_trailer : "";
    vehicle.description_trailer = vehicle.description_trailer
      ? vehicle.description_trailer
      : "";
    vehicle.vehtype_other = vehicle.vehtype_other ? vehicle.vehtype_other : "";
    let tempOther =
      typeof vehicle.vehtype_other != "undefined" ? vehicle.vehtype_other : "";
    vehicle.other = tempOther != "" ? "1" : "0";
    vehicle.other_policy_company_other = vehicle.other_policy_company_other
      ? vehicle.other_policy_company_other
      : "";
    vehicle.other_policy_number_other = vehicle.other_policy_number_other
      ? vehicle.other_policy_number_other
      : "";
    vehicle.year_model_other = vehicle.year_model_other
      ? vehicle.year_model_other
      : "";
    vehicle.make_other = vehicle.make_other ? vehicle.make_other : "";
    vehicle.model_other = vehicle.model_other ? vehicle.model_other : "";
    vehicle.total_value_other = vehicle.total_value_other
      ? vehicle.total_value_other
      : "";
    vehicle.plates_other = vehicle.plates_other ? vehicle.plates_other : "";
    vehicle.vin_other = vehicle.vin_other ? vehicle.vin_other : "";
    vehicle.description_other = vehicle.description_other
      ? vehicle.description_other
      : "";
    return vehicle;
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    console.log(limitValue);
    formatValueField(limitValue);
  };

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...selectedVehicle };
      vehicleDataToEdit.value = "0";
      setSelectedVehicle(vehicleDataToEdit);
    }

    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 && arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };

  const sendVehicleData = (vehicleData) => {
    setSelectedVehicle(vehicleData);
    vehicleData = setSelectedVehicleData(vehicleData);
    props.setStepData("selectedVehicle", vehicleData);
  };

  const handleFormChange = (e) => {
    const valor = e.target.value;
    let vehData = { ...selectedVehicle };
    vehData[e.target.name] = valor;
    const sendedData = {};
    if (e.target.name === "vehtype" || e.target.name === "year_vehicle") {
      if (e.target.name === "vehtype") {
        sendedData.vehtype = valor;
        sendedData.year_vehicle = selectedVehicle?.year_vehicle;
        vehData.type_name = e.nativeEvent.target.textContent;
        checkMakeModelStatus(valor);
      } else {
        sendedData.vehtype = selectedVehicle?.vehtype;
        sendedData.year_vehicle = valor;
      }
      setValuesForTypeVehicle(valor, vehData);
      getMakesData(sendedData);
    } else if (e.target.name === "make_vehicle" && showSelectMake) {
      sendedData.vehtype = selectedVehicle.vehtype;
      sendedData.make_vehicle = valor;
      getModelsData(sendedData);
    }
    if (e.target.name === "insurancepolnumber") {
      if (valor == "No") {
        vehData.value = "0";
        formatValueField(0);
      } else {
        setValuesForTypeVehicle(1, vehData);
      }
    }
    if (e.target.name === "year_vehicle") {
      vehData.year_model = e.nativeEvent.target.textContent;
    }
    if (e.target.name === "make_vehicle") {
      vehData.make_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.makeId = valor;
    }
    if (e.target.name === "model_vehicle") {
      vehData.model_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.modelId = valor;
    }
    setSelectedVehicle(vehData);
    if (e.target.name === "value" || e.target.name === "insurancepolnumber") {
      props.setStepData("addVehicle", true);
    }
  };

  const checkMakeModelStatus = (valor) => {
    if ([1, 5].includes(parseInt(valor))) {
      setMakeVehicleState("success");
      setModelVehicleState("success");
      setMakeSelectState("error");
      setModelSelectState("error");
    } else {
      setMakeSelectState("success");
      setModelSelectState("success");
      setMakeVehicleState("error");
      setModelVehicleState("error");
    }
  };

  const getMakesData = async (sendedData) => {
    if (sendedData.vehtype && sendedData.year_vehicle) {
      const sendedVehicle =
        sendedData.vehtype === "1"
          ? "P"
          : sendedData.vehtype === "5"
          ? "M"
          : null;
      if (sendedVehicle) {
        setShowSelectMake(true);
        const resultSubmit = await dispatch(
          getMakes({
            year: sendedData.year_vehicle,
            type_vehicle: sendedVehicle,
          })
        );
        let makeList = resultSubmit.payload.makes;
        if (!makeList) {
          makeList = [{ id: "Other", name: "Other" }];
        } else {
          makeList.unshift({ id: "Other", name: "Other" });
        }
        setMakesCatalog(makeList);
      } else {
        setShowSelectMake(false);
      }
    }
  };
  const getModelsData = async (sendedData) => {
    const sendedVehicle =
      sendedData.vehtype === "1"
        ? "P"
        : sendedData.vehtype === "5"
        ? "M"
        : null;
    const resultSubmit = await dispatch(
      getModels({
        name: sendedData.make_vehicle,
        type_vehicle: sendedVehicle,
      })
    );
    let modelList = resultSubmit.payload.makes;
    if (!modelList) {
      modelList = [{ id: "Other", name: "Other" }];
    } else {
      modelList.unshift({ id: "Other", name: "Other" });
    }
    setModelsCatalog(modelList);
  };

  return (
    <GridContainer justify="center">
      {fastQuote && (
        <GridItem xs={12}>
          <FormControl
            fullWidth
            error={vehTypeState === "error" ? true : false}
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              style={{fontStyle: "bold !important"}}
              htmlFor="vehtype"
              className={
                vehTypeState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
            >
              Vehicle Type
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              value={vehType}
              inputProps={{
                name: "vehtype",
                id: "simple-select",
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setVehTypeState("error");
                  } else {
                    setVehTypeState("success");
                  }
                  setVehType(event.target.value);
                  handleFormChange(event);
                },
              }}
            >
              {data?.catalogs?.vehicle_types?.map((item) => (
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            error={yearVehicleState === "error" ? true : false}
          >
            <InputLabel
              htmlFor="year_vehicle"
              className={
                yearVehicleState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
            >
              Year
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              inputProps={{
                name: "year_vehicle",
                id: "year_vehicle",
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setYearVehicleState("error");
                  } else {
                    setYearVehicleState("success");
                  }
                  setYearVehicle(event.target.value);
                  handleFormChange(event);
                },
                value: selectedVehicle?.year_vehicle,
              }}
            >
              {years.map((item) => (
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showSelectMake && makesCatalog.length > 0 ? (
            <FormControl
              fullWidth
              error={makeSelectState === "error" ? true : false}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="make_vehicle"
                className={
                  makeSelectState !== "error"
                    ? classesExtForm.selectLabel
                    : classesExtForm.selectLabelDanger
                }
              >
                Make
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={selectedVehicle?.make_vehicle}
                inputProps={{
                  name: "make_vehicle",
                  id: "make_vehicle",
                  onChange: (event) => {
                    event.target.value = event.target.value.toString();
                    if (!verifyLength(event.target.value, 1)) {
                      setMakeSelectState("error");
                    } else {
                      setMakeSelectState("success");
                    }
                    setMakeSelect(event.target.value);
                    handleFormChange(event);
                  },
                }}
              >
                {makesCatalog.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <CustomInput
              success={
                makeVehicleState === "success" ||
                (selectedVehicle?.make_vehicle !== "" &&
                  ![1, 5].includes(selectedVehicle?.vehtype))
                  ? "success"
                  : ""
              }
              error={
                makeVehicleState === "error" ||
                (selectedVehicle?.make_vehicle === "" &&
                  ![1, 5].includes(selectedVehicle?.vehtype))
                  ? "error"
                  : ""
              }
              labelText={<span>Make</span>}
              id="make_vehicle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "make_vehicle",
                value: selectedVehicle?.make_vehicle,
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setMakeVehicleState("error");
                  } else {
                    setMakeVehicleState("success");
                  }
                  setMakeVehicle(event.target.value);
                  handleFormChange(event);
                },
              }}
            />
          )}
          {showSelectMake && modelsCatalog.length > 0 ? (
            <FormControl
              fullWidth
              error={modelSelectState === "error" ? true : false}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="model_vehicle"
                className={
                  modelSelectState !== "error"
                    ? classesExtForm.selectLabel
                    : classesExtForm.selectLabelDanger
                }
              >
                Model
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={selectedVehicle?.model_vehicle}
                inputProps={{
                  name: "model_vehicle",
                  id: "model_vehicle",
                  onChange: (event) => {
                    event.target.value = event.target.value.toString();
                    if (!verifyLength(event.target.value, 1)) {
                      setModelSelectState("error");
                    } else {
                      setModelSelectState("success");
                    }
                    setModelSelect(event.target.value);
                    handleFormChange(event);
                  },
                }}
              >
                {modelsCatalog.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <CustomInput
              success={
                modelVehicleState === "success" ||
                (selectedVehicle?.model_vehicle !== "" &&
                  ![1, 5].includes(selectedVehicle?.vehtype))
                  ? "success"
                  : ""
              }
              error={
                modelVehicleState === "error" ||
                (vehicleDataEdit.model_vehicle === "" &&
                  ![1, 5].includes(selectedVehicle?.vehtype))
                  ? "error"
                  : ""
              }
              labelText={<span>Model</span>}
              id="model_vehicle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "model_vehicle",
                value: selectedVehicle?.model_vehicle,
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setModelVehicleState("error");
                  } else {
                    setModelVehicleState("success");
                  }
                  setModelVehicle(event.target.value);
                  handleFormChange(event);
                },
              }}
            />
          )}

          <FormControl
            fullWidth
            error={valueVehicleState === "error" ? true : false}
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              htmlFor="value"
              className={
                valueVehicleState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
            >
              Value
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              inputProps={{
                name: "value",
                id: "value",
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setValueVehicleState("error");
                  } else {
                    setValueVehicleState("success");
                  }
                  setValueVehicle(event.target.value);
                  handleFormChange(event);
                },
                value: valueVehicle,
              }}
            >
              {valores.map((item) => (
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value={item.value}
                >
                  {item.traduccion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      )}

      {!fastQuote && (
        <>
          <GridItem xs={12} style={{ display: showVehicle ? "block" : "none" }}>
            {selectedVehicle ? (
              <p
                className={cardClasses.cardCategory}
                style={{ textAlign: "left", color: "#000000" }}
              >
                {props.quoteId == 0 && (
                  <>
                    <HighlightOffIcon
                      style={styles.iconAligth}
                      onClick={() => confirmAlert()}
                    />
                    <EditIcon
                      onClick={() => editVehicle(selectedVehicle)}
                      style={styles.iconEdit}
                    />
                  </>
                )}
                <strong>Type:</strong>
                <br />
                {selectedVehicle.type_name ? selectedVehicle.type_name : "-"}
                <br />
                <strong>Year:</strong>
                <br />
                {selectedVehicle.year_model ? selectedVehicle.year_model : "-"}
                <br />
                <strong>Make:</strong>
                <br />
                {selectedVehicle.make_name ? selectedVehicle.make_name : "-"}
                <br />
                <strong>Model:</strong>
                <br />
                {selectedVehicle.model_name ? selectedVehicle.model_name : "-"}
                <br />
                <strong>Value:</strong>
                <br />
                {selectedVehicle.value
                  ? `$${formatNumber(selectedVehicle.value)}`
                  : "-"}
                <br />
                <strong>Plates:</strong>
                <br />
                {selectedVehicle.plates_vehicle
                  ? selectedVehicle.plates_vehicle
                  : "-"}
                <br />
                <strong>VIN:</strong>
                <br />
                {selectedVehicle.vin_vehicle
                  ? selectedVehicle.vin_vehicle
                  : "-"}
                <br />
                <strong>Auto Ins Company:</strong>
                <br />
                {selectedVehicle.insurancecompany
                  ? selectedVehicle.insurancecompany
                  : "-"}
                <br />
                <strong>Do you carry "full coverage" in the US/Canada?</strong>
                <br />
                {selectedVehicle.insurancepolnumber
                  ? selectedVehicle.insurancepolnumber
                  : "-"}
                <br />
                <strong>Do you have a lienholder?</strong>
                <br />
                {selectedVehicle.haveLienholder
                  ? selectedVehicle.haveLienholder
                  : "-"}
                {selectedVehicle.haveLienholder == "Yes" && (
                  <>
                    <br />
                    <strong>Lienholder</strong>
                    <br />
                    {selectedVehicle.lienholder
                      ? selectedVehicle.lienholder
                      : "-"}
                    <br />
                    <strong>Lienholder address</strong>
                    <br />
                    {selectedVehicle.lienholder_vehicle_address
                      ? selectedVehicle.lienholder_vehicle_address
                      : "-"}
                  </>
                )}
                <br />
                <strong>Trusted Traveler #</strong>
                <br />
                {selectedVehicle.sentri ? selectedVehicle.sentri : "-"}
                <br />

                <br />
                {selectedVehicle.vehtype_trailer &&
                selectedVehicle.vehtype_trailer != "0" ? (
                  <>
                    <strong>Other vehicle (1) </strong>
                    <br />
                    <strong>Type</strong>
                    <br />
                    {trailerTypes.find(
                      (o) => o.id == parseInt(selectedVehicle.vehtype_trailer)
                    )
                      ? trailerTypes.find(
                          (o) =>
                            o.id == parseInt(selectedVehicle.vehtype_trailer)
                        ).name
                      : ""}
                    <br />
                    <strong>Insurance Company (If Different):</strong>
                    <br />
                    {selectedVehicle.other_policy_company_trailer
                      ? selectedVehicle.other_policy_company_trailer
                      : "-"}
                    <br />
                    <strong>Ins. Policy #:</strong>
                    <br />
                    {selectedVehicle.other_policy_number_trailer
                      ? selectedVehicle.other_policy_number_trailer
                      : "-"}
                    <br />
                    <strong>Year:</strong>
                    <br />
                    {selectedVehicle.year_trailer
                      ? selectedVehicle.year_trailer
                      : "-"}
                    <br />
                    <strong>Make:</strong>
                    <br />
                    {selectedVehicle.make_trailer
                      ? selectedVehicle.make_trailer
                      : "-"}
                    <br />
                    <strong>Model:</strong>
                    <br />
                    {selectedVehicle.model_trailer
                      ? selectedVehicle.model_trailer
                      : "-"}
                    <br />
                    <strong>VIN:</strong>
                    <br />
                    {selectedVehicle.vin_trailer
                      ? selectedVehicle.vin_trailer
                      : "-"}
                    <br />
                    <strong>Value:</strong>
                    <br />
                    {USDollar.format(selectedVehicle.value_trailer)}
                    <br />
                    <strong>Plates:</strong>
                    <br />
                    {selectedVehicle.plates_trailer
                      ? selectedVehicle.plates_trailer
                      : "-"}
                    <br />
                    <strong>Description:</strong>
                    <br />
                    {selectedVehicle.description_trailer
                      ? selectedVehicle.description_trailer
                      : "-"}
                    <br />
                  </>
                ) : null}
                {selectedVehicle.vehtype_other &&
                selectedVehicle.vehtype_other != "0" ? (
                  <>
                    <strong>Other vehicle (2) </strong>
                    <br />
                    <strong>Type</strong>
                    <br />
                    {trailerTypes.find(
                      (o) => o.id == parseInt(selectedVehicle.vehtype_other)
                    )
                      ? trailerTypes.find(
                          (o) => o.id == parseInt(selectedVehicle.vehtype_other)
                        ).name
                      : ""}
                    <br />
                    <strong>Insurance Company (If Different):</strong>
                    <br />
                    {selectedVehicle.other_policy_company_other
                      ? selectedVehicle.other_policy_company_other
                      : "-"}
                    <br />
                    <strong>Ins. Policy #:</strong>
                    <br />
                    {selectedVehicle.other_policy_number_other
                      ? selectedVehicle.other_policy_number_other
                      : "-"}
                    <br />
                    <strong>Year:</strong>
                    <br />
                    {selectedVehicle.year_other
                      ? selectedVehicle.year_other
                      : "-"}
                    <br />
                    <strong>Make:</strong>
                    <br />
                    {selectedVehicle.make_other
                      ? selectedVehicle.make_other
                      : "-"}
                    <br />
                    <strong>Model:</strong>
                    <br />
                    {selectedVehicle.model_other
                      ? selectedVehicle.model_other
                      : "-"}
                    <br />
                    <strong>VIN:</strong>
                    <br />
                    {selectedVehicle.vin_other
                      ? selectedVehicle.vin_other
                      : "-"}
                    <br />
                    <strong>Value:</strong>
                    <br />
                    {USDollar.format(selectedVehicle.value_other)}
                    <br />
                    <strong>Plates:</strong>
                    <br />
                    {selectedVehicle.plates_other
                      ? selectedVehicle.plates_other
                      : "-"}
                    <br />
                    <strong>Description:</strong>
                    <br />
                    {selectedVehicle.description_other
                      ? selectedVehicle.description_other
                      : "-"}
                    <br />
                  </>
                ) : null}
              </p>
            ) : null}
          </GridItem>

          <GridItem
            xs={12}
            style={{ display: showAddVehicle ? "block" : "none" }}
          >
            <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
              <CardBody style={{ textAlign: "left" }}>
                <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                  <strong>Edit Vehicle</strong>
                </h3>
                <Vehicle
                  showAddVehicle={showAddVehicle}
                  vehicleDataEdit={vehicleDataEdit}
                  setSelectedVehicle={sendVehicleData}
                  closeCreateVehicle={closeCreateVehicle}
                  data={props.data}
                  sendToAPI={false}
                />
              </CardBody>
            </Card>
          </GridItem>
          {alert}
        </>
      )}
    </GridContainer>
  );
});

Step2.displayName = "Step2";

export default Step2;
