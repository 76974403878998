import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip"; // Cambia a la versión MUI si estás usando MUI v5
import InfoIcon from "components/CustomIcons/Info";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function InformationTooltip(props) {
  const classes = useStyles();
  const { text } = props;

  return (
    <Tooltip
      id="tooltip-top"
      title={text}
      placement="right"
      style={{marginLeft:"5px"}}
      slotProps={{
        tooltip: {
          className: classes.tooltip,
        },
      }}
    >
      <span>
        <InfoIcon />
      </span>
    </Tooltip>
  );
}

InformationTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};
