import React, { useEffect, useState } from "react";
// @material-ui/core components
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Vehicle from "components/Vehicle/Vehicle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import cardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { formatNumber } from "components/Utils/utils.js";

const useCardStyles = makeStyles(cardStyles);
const useStylesAlert = makeStyles(stylesAlerts);

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  iconAligth: {
    position: "absolute",
    top: 70,
    right: 20,
    color: "red",
    with: 30,
    heigth: 30,
  },
  iconEdit: {
    position: "absolute",
    top: 70,
    right: 50,
    color: "black",
    with: 30,
    heigth: 30,
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const Step2 = React.forwardRef((props, ref) => {
  const classesAlerts = useStylesAlert();
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const [data, setData] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setVehicles(props.data.vehicles);
      if (props.data.selectedVehicle) {
        setSelectedVehicle(props.data.selectedVehicle);
      }
    }
  }, [props]);
  useEffect(() => {
    if (selectedVehicle) {
      const tempData = data;
      tempData.selectedVehicle = selectedVehicle;
      props.setData(tempData);
    }
  }, [selectedVehicle]);
  const cardClasses = useCardStyles();
  const closeCreateVehicle = () => {
    setshowEditVehicle(false);
    setshowAddVehicle(false);
    setshowVehicle(true);
    props.setStepData("addVehicle", true);
    props.setStepData("showAddVehicle", false);
  };

  const [showVehicle, setshowVehicle] = React.useState(true);
  const [showEditVehicle, setshowEditVehicle] = React.useState(false);
  const [showAddVehicle, setshowAddVehicle] = React.useState(false);
  const [vehicleDataEdit, setVehicleDataEdit] = React.useState(null);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {},
  }));
  const sendState = () => {
    return {};
  };
  const isValidated = () => {
    return true;
  };
  const editVehicle = (data) => {
    const vehicleTemp = data;
    vehicleTemp.vehtype = comprobarProps(
      vehicleTemp,
      "vehtype",
      "vehicle_type_id"
    );
    vehicleTemp.insurancecompany = comprobarProps(
      vehicleTemp,
      "insurancecompany",
      "insurance_company"
    );
    vehicleTemp.insurancepolnumber = comprobarProps(
      vehicleTemp,
      "insurancepolnumber",
      "insurance_policy_number"
    );
    vehicleTemp.year_vehicle = comprobarProps(
      vehicleTemp,
      "year_vehicle",
      "year_model",
      true
    );
    vehicleTemp.make_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "makeId"
        : "make_vehicle",
      "make"
    );
    vehicleTemp.model_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "modelId"
        : "model_vehicle",
      "model"
    );
    vehicleTemp.value = comprobarProps(
      vehicleTemp,
      "value",
      "total_value",
      true
    );
    vehicleTemp.vin_vehicle = comprobarProps(vehicleTemp, "vin_vehicle", "vin");
    vehicleTemp.sentri = comprobarProps(vehicleTemp, "sentri", "sentri_number");
    vehicleTemp.plates_vehicle = comprobarProps(
      vehicleTemp,
      "plates_vehicle",
      "plates"
    );
    vehicleTemp.lienholder_vehicle = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle",
      "lienholder"
    );
    vehicleTemp.haveLienholder = vehicleTemp.lienholder_vehicle ? "Yes" : "No";
    vehicleTemp.lienholder_vehicle_address = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle_address",
      "lienholder_vehicle_address"
    );
    setVehicleDataEdit(vehicleTemp);
    setshowEditVehicle(false);
    setshowVehicle(false);
    setshowAddVehicle(true);
    props.setStepData("showAddVehicle", true);
  };
  const comprobarProps = (
    objeto,
    propObjeto,
    propDatabase,
    parseToInt = false
  ) => {
    const valor = objeto[propObjeto]
      ? objeto[propObjeto]
      : objeto[propDatabase];
    if (parseToInt) {
      return parseInt(valor);
    } else {
      return valor;
    }
  };
  const [alert, setAlert] = React.useState(null);
  const confirmAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure to remove the vehicle?"
        onConfirm={() => {
          setSelectedVehicle(null);
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        cancelBtnText={"Cancel"}
        cancelBtnCssClass={classesAlerts.button + " " + classesAlerts.danger}
        showCancel={true}
        confirmBtnCssClass={classesAlerts.button + " " + classesAlerts.success}
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} style={{ display: showVehicle ? "block" : "none" }}>
          {selectedVehicle ? (
            <p
              className={cardClasses.cardCategory}
              style={{ textAlign: "left", color: "#000000" }}
            >
              <HighlightOffIcon
                style={styles.iconAligth}
                onClick={() => confirmAlert()}
              />
              <EditIcon
                id={"editVehicle"}
                onClick={() => editVehicle(selectedVehicle)}
                style={styles.iconEdit}
              />
              <strong>Type:</strong>
              <br />
              {selectedVehicle.type_name ? selectedVehicle.type_name : "-"}
              <br />
              <strong>Year:</strong>
              <br />
              {selectedVehicle.year_model ? selectedVehicle.year_model : "-"}
              <br />
              <strong>Make:</strong>
              <br />
              {[1, 5].includes(parseInt(selectedVehicle.vehicle_type_id))
                ? (selectedVehicle.make_vehicle == null ? selectedVehicle.make : selectedVehicle.make_vehicle)
                : selectedVehicle.make}
              <br />
              <strong>Model:</strong>
              <br />
              {[1, 5].includes(parseInt(selectedVehicle.vehicle_type_id))
                ? (selectedVehicle.model_vehicle == null ? selectedVehicle.model : selectedVehicle.model_vehicle)
                : selectedVehicle.model}
              <br />
              <strong>Value:</strong>
              <br />$
              {formatNumber(selectedVehicle.total_value)
                ? formatNumber(selectedVehicle.total_value)
                : "-"}
              <br />
              <strong>Plates:</strong>
              <br />
              {selectedVehicle.plates ? selectedVehicle.plates : "-"}
              <br />
              <strong>VIN:</strong>
              <br />
              {selectedVehicle.vin ? selectedVehicle.vin : "-"}
              <br />
              <strong>Auto Ins Company:</strong>
              <br />
              {selectedVehicle.insurance_company
                ? selectedVehicle.insurance_company
                : "-"}
              <br />
              <strong>Do you carry "full coverage" in the US/Canada?</strong>
              <br />
              {selectedVehicle.insurance_policy_number
                ? selectedVehicle.insurance_policy_number
                : "-"}
              <br />
              <strong>Do you have a lienholder?</strong>
              <br />
              {selectedVehicle.client_have_lienholder
                ? selectedVehicle.client_have_lienholder
                : "-"}
              {selectedVehicle.client_have_lienholder == "Yes" && (
                <>
                  <br />
                  <strong>Lienholder</strong>
                  <br />
                  {selectedVehicle.lienholder
                    ? selectedVehicle.lienholder
                    : "-"}
                  <br />
                  <strong>Lienholder address</strong>
                  <br />
                  {selectedVehicle.lienholder_vehicle_address
                    ? selectedVehicle.lienholder_vehicle_address
                    : "-"}
                </>
              )}
              <br />
              <strong>Trusted Traveler #</strong>
              <br />
              {selectedVehicle.sentri_number
                ? selectedVehicle.sentri_number
                : "-"}
              <br />
              {selectedVehicle.vehtype_trailer &&
              selectedVehicle.vehtype_trailer != "0" ? (
                <>
                  <strong>Other vehicle (1) </strong>
                  <br />
                  <strong>Type</strong>
                  <br />
                  {trailerTypes.find(
                    (o) => o.id == parseInt(selectedVehicle.vehtype_trailer)
                  )
                    ? trailerTypes.find(
                        (o) => o.id == parseInt(selectedVehicle.vehtype_trailer)
                      ).name
                    : ""}
                  <br />
                  <strong>Insurance Company (If Different):</strong>
                  <br />
                  {selectedVehicle.other_policy_company_trailer
                    ? selectedVehicle.other_policy_company_trailer
                    : "-"}
                  <br />
                  <strong>Ins. Policy #:</strong>
                  <br />
                  {selectedVehicle.other_policy_number_trailer
                    ? selectedVehicle.other_policy_number_trailer
                    : "-"}
                  <br />
                  <strong>Year:</strong>
                  <br />
                  {selectedVehicle.year_model_trailer
                    ? selectedVehicle.year_model_trailer
                    : "-"}
                  <br />
                  <strong>Make:</strong>
                  <br />
                  {selectedVehicle.make_trailer
                    ? selectedVehicle.make_trailer
                    : "-"}
                  <br />
                  <strong>Model:</strong>
                  <br />
                  {selectedVehicle.model_trailer
                    ? selectedVehicle.model_trailer
                    : "-"}
                  <br />
                  <strong>VIN:</strong>
                  <br />
                  {selectedVehicle.vin_trailer
                    ? selectedVehicle.vin_trailer
                    : "-"}
                  <br />
                  <strong>Value:</strong>
                  <br />
                  {USDollar.format(selectedVehicle.total_value_trailer)}
                  <br />
                  <strong>Plates:</strong>
                  <br />
                  {selectedVehicle.plates_trailer
                    ? selectedVehicle.plates_trailer
                    : "-"}
                  <br />
                  <strong>Description:</strong>
                  <br />
                  {selectedVehicle.description_trailer
                    ? selectedVehicle.description_trailer
                    : "-"}
                  <br />
                </>
              ) : null}
              {selectedVehicle.vehtype_other &&
              selectedVehicle.vehtype_other != "0" ? (
                <>
                  <strong>Other vehicle (2) </strong>
                  <br />
                  <strong>Type</strong>
                  <br />
                  {trailerTypes.find(
                    (o) => o.id == parseInt(selectedVehicle.vehtype_other)
                  )
                    ? trailerTypes.find(
                        (o) => o.id == parseInt(selectedVehicle.vehtype_other)
                      ).name
                    : ""}
                  <br />
                  <strong>Insurance Company (If Different):</strong>
                  <br />
                  {selectedVehicle.other_policy_company_other
                    ? selectedVehicle.other_policy_company_other
                    : "-"}
                  <br />
                  <strong>Ins. Policy #:</strong>
                  <br />
                  {selectedVehicle.other_policy_number_other
                    ? selectedVehicle.other_policy_number_other
                    : "-"}
                  <br />
                  <strong>Year:</strong>
                  <br />
                  {selectedVehicle.year_model_other
                    ? selectedVehicle.year_model_other
                    : "-"}
                  <br />
                  <strong>Make:</strong>
                  <br />
                  {selectedVehicle.make_other
                    ? selectedVehicle.make_other
                    : "-"}
                  <br />
                  <strong>Model:</strong>
                  <br />
                  {selectedVehicle.model_other
                    ? selectedVehicle.model_other
                    : "-"}
                  <br />
                  <strong>VIN:</strong>
                  <br />
                  {selectedVehicle.vin_other ? selectedVehicle.vin_other : "-"}
                  <br />
                  <strong>Value:</strong>
                  <br />
                  {USDollar.format(selectedVehicle.total_value_other)}
                  <br />
                  <strong>Plates:</strong>
                  <br />
                  {selectedVehicle.plates_other
                    ? selectedVehicle.plates_other
                    : "-"}
                  <br />
                  <strong>Description:</strong>
                  <br />
                  {selectedVehicle.description_other
                    ? selectedVehicle.description_other
                    : "-"}
                  <br />
                </>
              ) : null}
            </p>
          ) : (
            <h4>
              <strong> No vehicles selected </strong>
            </h4>
          )}
          <div style={{ textAlign: "center" }}>
            <Button
              color="info"
              id="changeVehicleCustomer"
              onClick={() => {
                setshowVehicle(false);
                setshowEditVehicle(true);
              }}
            >
              Change Vehicle
            </Button>
          </div>
        </GridItem>

        <GridItem
          xs={12}
          style={{ display: showEditVehicle ? "block" : "none" }}
        >
          <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
            <CardBody style={{ textAlign: "left" }}>
              <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                <strong>Vehicle</strong>
              </h3>
              <div style={{ textAlign: "right", paddingBottom: "20px" }}>
                <Button
                  color="danger"
                  onClick={() => {
                    setVehicleDataEdit({});
                    setshowEditVehicle(false);
                    setshowVehicle(false);
                    setshowAddVehicle(true);
                    props.setStepData("showAddVehicle", true);
                  }}
                >
                  Add New Vehicle
                </Button>
              </div>
              {vehicles &&
                vehicles.map((item, index) => (
                  <GridContainer
                    style={{
                      background:
                        index % 2 === 0
                          ? "rgb(170 170 170 / 15%)"
                          : "rgb(255, 255, 255)",
                      paddingBottom: "15px",
                      paddingTop: "15px",
                    }}
                  >
                    <GridItem xs={12}>
                      <strong>Type:</strong>{" "}
                      {item.type_name ? item.type_name : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Year:</strong>{" "}
                      {item.year_model ? item.year_model : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Make:</strong>{" "}
                      {[1, 5].includes(parseInt(item.vehicle_type_id))
                        ? item.make_vehicle
                          ? item.make_vehicle
                          : "-"
                        : item.make
                        ? item.make
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Model:</strong>{" "}
                      {[1, 5].includes(parseInt(item.vehicle_type_id))
                        ? item.model_vehicle
                          ? item.model_vehicle
                          : "-"
                        : item.model
                        ? item.model
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Value:</strong> $
                      {formatNumber(item.total_value)
                        ? formatNumber(item.total_value)
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Plates:</strong> {item.plates ? item.plates : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>VIN:</strong> {item.vin ? item.vin : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Trusted Traveler #:</strong>{" "}
                      {item.vehicle_id ? item.vehicle_id : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          color="info"
                          size="sm"
                          onClick={() => {
                            setSelectedVehicle(item);
                            setshowEditVehicle(false);
                            setshowVehicle(true);
                            props.setStepData("addVehicle", true);
                          }}
                        >
                          Select Vehicle
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                ))}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          style={{ display: showAddVehicle ? "block" : "none" }}
        >
          <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
            <CardBody style={{ textAlign: "left" }}>
              <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                <strong>Add/Edit Vehicle</strong>
              </h3>
              <Vehicle
                vehicleDataEdit={vehicleDataEdit}
                showAddVehicle={showAddVehicle}
                sendToAPI={true}
                setSelectedVehicle={setSelectedVehicle}
                closeCreateVehicle={closeCreateVehicle}
                data={props.data}
              />
              <div style={{ textAlign: "right", paddingBottom: "20px" }}></div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
});

Step2.displayName = "Step2";

export default Step2;
