import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import LoginBase from "components/LoginBase/LoginBase";


import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { getDocument } from "../Services/OrdersService";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { policyNumber } = routeParams;

  const classes = useStyles();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      localStorage.clear();
      if (window.location.href.includes("documents/policyPDF")) {
        getDataDocument(policyNumber);
      }
    } else {
      window.location.replace("/auth/login-page");
    }
  }, [dispatch]);

  const getDataDocument = async (policyNumber) => {
    const resultSubmit = await dispatch(getDocument(policyNumber));
    if (["errorFileStatus", "errorNoExists"].includes(resultSubmit.payload)) {
      window.location.href = "/auth/login";
    } else {
      let a = document.createElement("a");
      a.href = "data:application/octet-stream;base64," + resultSubmit.payload;
      a.download = `${policyNumber}.pdf`;
      a.click();
    }
  };

  return (
    <LoginBase>
      <div style={{ marginTop: "10px" }} className={classes.center}>
        <Button
          onClick={() => getDataDocument(policyNumber)}
          style={{ backgroundColor: "#008670" }}
          block
        >
          Download Policy
        </Button>
      </div>
    </LoginBase>
  );
}
