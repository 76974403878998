import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
const API_V1 = `${environment.SERVER}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const checkExistCustomer = createAsyncThunk(
  "guestView/checkExistCustomer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/Customer/checkUserGuest`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const saveCustomerSettingsAPI = createAsyncThunk(
  "guestView/saveUserSettingsAPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/Customer`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserSettingsAPI = createAsyncThunk(
  "guestView/saveUserSettingsAPI",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.put(`${BASE_URL}/Customer/updateCustomerUser`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getResources = createAsyncThunk(
  "resources/getResources",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.post(`${BASE_URL}/Customer/resources`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getZipCodeData = createAsyncThunk(
  "resources/getZipCodeData",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.post(`${BASE_URL}/Customer/getCityStateByCode`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
