import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import logo from "assets/img/logo.png";

// @material-ui/icons
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import Speed from "@material-ui/icons/Speed";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { checkIfIsKiosko } from "components/Utils/utils";

import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { recoverPassword } from "../Services/LoginService";

const useStyles = makeStyles(styles);

export default function RecoveryPasswordPage() {
  const dispatch = useDispatch();
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
  });
  const routeParams = useParams();
  const { type, producerCode } = routeParams;
  const isKiosko = checkIfIsKiosko();
  const [sended, setSended] = useState(false);
  const [sendedMail, setSendedMail] = useState(false);
  const handleFormChange = (e) => {
    const valor = e.target.value;
    setLoginCredentials({ ...loginCredentials, [e.target.name]: valor });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const value = loginCredentials;
    value.url = location.protocol + "//" + location.hostname;
    if (isKiosko) {
      value.kiosko = true;
      value.agencyCode = producerCode;
    }
    if (loginCredentials.email) {
      const resultSubmit = await dispatch(recoverPassword(value));
      if (resultSubmit.payload.result) {
        setSendedMail(true);
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.error) {
          mensaje += "\u2B24" + " " + resultSubmit.error[key] + "\n";
        }
        //handleError(resultSubmit.payload.detail + mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.cardSignup}>
            <CardHeader
              image
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="white"
            >
              <div className={classes.socialLine}>&nbsp;</div>
              <img
                src={logo}
                alt="Sanborn's Mexico Auto Insurance"
                style={{ boxShadow: "none", width: "50%" }}
              />
            </CardHeader>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.center}>
                    <h4 className={classes.socialTitle}>
                      Forgot your password?
                    </h4>
                    <span className={classes.socialTitle}>
                      <a href="/auth/login-page/oneClickLogin">
                        Having trouble? Try our "One Click Login"
                      </a>
                    </span>
                  </div>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <CustomInput
                      helperText={
                        !loginCredentials.email && sended
                          ? "The email field is required."
                          : null
                      }
                      error={!loginCredentials.email && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="email"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email",
                        name: "email",
                        onChange: handleFormChange,
                      }}
                    />
                    {sendedMail ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        An email has been sent to udpate your password. Please
                        check your Junk/Spam. <br />
                        If you select “Get a new password” again, the previous
                        email link will no longer work.
                      </p>
                    ) : null}

                    <div className={classes.center}>
                      <Button type="submit" color="info" block>
                        Get a new password
                      </Button>
                      <a href={"/auth/login-page" + (isKiosko ? "/kiosko/" + producerCode : "")}>Back to home</a>
                      <a href="#pablo"> </a>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
