import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;

export function getTokenClient(data, issuePolicy, clientToken) {
  braintree.client.create(
    {
      authorization: clientToken,
    },
    function (clientErr, clientInstance) {
      if (clientErr) {
        alert("Error creating client:");
        return;
      }
      braintree.applePay.create(
        {
          client: clientInstance,
        },
        function (applePayErr, applePayInstance) {
          if (applePayErr) {
            alert("Error creating applePayInstance:");
            return;
          }

          var paymentRequest = applePayInstance.createPaymentRequest({
            total: {
              label: "Sanborns sandbox",
              amount: data.coverages.total,
            },
            requiredBillingContactFields: ["postalAddress"],
          });

          var session = new ApplePaySession(3, paymentRequest);

          session.onvalidatemerchant = function (event) {
            applePayInstance.performValidation(
              {
                validationURL: event.validationURL,
                displayName: "Sanborns sandbox",
              },
              function (err, merchantSession) {
                if (err) {
                  // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                  alert("Apple Pay failed to load");
                  return;
                }
                session.completeMerchantValidation(merchantSession);
              }
            );
          };

          session.onpaymentauthorized = function (event) {
            applePayInstance.tokenize(
              {
                token: event.payment.token,
              },
              async function (tokenizeErr, payload) {
                if (tokenizeErr) {
                  alert("Error tokenizing Apple Pay:");
                  session.completePayment(ApplePaySession.STATUS_FAILURE);
                  return;
                }

                console.log(payload);

                // If requested, address information is accessible in event.payment
                // and may also be sent to your server.

                // After you have transacted with the payload.nonce,
                // call 'completePayment' to dismiss the Apple Pay sheet.
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                issuePolicy(payload);
              }
            );
          };

          session.begin();
        }
      );

      braintree.dataCollector.create(
        {
          client: clientInstance,
        },
        function (err, dataCollectorInstance) {
          if (err) {
            // Handle error in creation of data collector
            console.error("Error creating dataCollectorInstance:", err);
            return;
          }
          console.log("deviceData: " + dataCollectorInstance);
          // At this point, you should access the dataCollectorInstance.deviceData value and provide it
          // to your server, e.g. by injecting it into your form as a hidden input.
          deviceData = dataCollectorInstance.deviceData;
        }
      );
    }
  );
}
