import React from "react";
export default function Icon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7692 3.38458C14.1325 3.82645 14.0589 4.47123 13.6047 4.82473C12.5467 5.64818 11.4612 7.04659 10.5061 8.54105C9.56386 10.0153 8.80314 11.4952 8.37578 12.4023C8.21296 12.7479 7.86681 12.9774 7.47661 12.9984C7.08642 13.0195 6.71634 12.8286 6.51528 12.5025C6.21485 12.0154 5.65722 11.3591 5.03434 10.7247C4.41392 10.0928 3.80221 9.55701 3.4338 9.29635C2.9634 8.96351 2.8594 8.3227 3.20151 7.86506C3.54362 7.40741 4.2023 7.30623 4.67271 7.63907C5.18564 8.00199 5.89594 8.63544 6.55747 9.3092C6.78127 9.53713 7.00798 9.77827 7.22628 10.0241C7.64007 9.24406 8.14629 8.35244 8.71733 7.45898C9.70512 5.91345 10.9432 4.27188 12.2889 3.22456C12.7431 2.87106 13.4058 2.9427 13.7692 3.38458Z"
        fill="#C2C2C2"
      />
    </svg>
  );
}
