import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    menuItems,
    placeholder,
    information,
    secondaryText,
  } = props;
  const CustomSelect = styled(Select)(({ theme, error, required, value }) => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#888888", // Rojo si hay error, azul si es requerido y tiene valor
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#008670", // Mismos colores al pasar el mouse
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#008670", // Mismos colores al enfocar
        borderWidth: "1px",
      },
    },
  }));

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  };
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      variant="outlined"
    >
      {labelText !== undefined ? (
        <Typography component="pre">
          <InputLabel
            style={{ pointerEvents: "auto" }}
            shrink={true}
            className={
              classes.labelRoot +
              " " +
              classes.labelOutlined +
              " " +
              labelClasses
            }
            //htmlFor={id}
            t
          >
            {labelText}
            {information ? <InformationTooltip text={information} /> : null}
          </InputLabel>
        </Typography>
      ) : null}
      {secondaryText && (
        <FormHelperText
          id={`${id}-secondaryText`}
          className={classNames(classes.secondaryText, classes.labelOutlined)}
        >
          {secondaryText}
        </FormHelperText>
      )}

      <CustomSelect
        id={id}
        required={inputProps?.required}
        value={inputProps?.value}
        error={
          inputProps?.required &&
          (inputProps?.value === null ||
            inputProps?.value === undefined ||
            inputProps?.value === "placeholder")
            ? true
            : false
        }
        {...inputProps}
        inputProps={newInputProps}
        classes={{
          select: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
        }}
        sx={{
          "&.MuiOutlinedInput-root": {
            borderColor: "red !important", // Borde inicial
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "red", // Cambiar el borde a rojo cuando se hace hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "green", // Cambiar el borde a verde cuando está enfocado
          },
        }}
      >
        {placeholder ? (
          <MenuItem disabled value="placeholder">
            <span style={{ color: "#888" }}>{placeholder}</span>
          </MenuItem>
        ) : null}
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Asegura que el contenido se mantenga en una sola línea
              paddingRight: "16px", // Ajusta el espaciado interno para evitar que se rompa el texto
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {item.icon && item.icon}

              <div>{item.label}</div>
            </div>
          </MenuItem>
        ))}
      </CustomSelect>

      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
};
