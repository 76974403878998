import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";
import Apps from "@material-ui/icons/Apps";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";

import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Phone from "@material-ui/icons/Phone";
import Download from "@material-ui/icons/CloudDownload";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { PictureAsPdf } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { getResources } from "views/Services/ClientService";
import environment from "../../environment/environment";
const server = `${environment.SERVER}`;

const useStyles = makeStyles(styles);

export default function Dashboard() {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [resources, setResources] = useState([]);
  const [agencyData, setAgencyData] = useState(null);
  const cardStyleExpire = {
    border: "2px solid #F44336",
  };
  const cardStyleWarning = {
    border: "2px solid #FF9802",
  };
  const cardStyleSuccess = {
    border: "2px solid #4CAF50",
  };
  useEffect(() => {
    getOrders();
  }, [dispatch]);
  const getOrders = async () => {
    const resultSubmit = await dispatch(getResources({}));
    setResources(resultSubmit.payload.files);
  };
  const calculateExpiration = (orderExpirationDate) => {
    const fechaActual = new Date();
    const fechaExpiracion = new Date(orderExpirationDate);
    const diferenciaMilisegundos = fechaExpiracion - fechaActual;
    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    if (diferenciaDias <= 5) {
      return cardStyleExpire;
    } else if (diferenciaDias >= 6 && diferenciaDias <= 15) {
      return cardStyleWarning;
    } else if (diferenciaDias >= 16) {
      return cardStyleSuccess;
    }
  };
  const handleWhatsApp = () => {
    const url = `https://wa.me/9566863601`;
    window.open(url);
  };
  const handleOpenSMSApp = () => {
    window.location.href = `sms:9566863601`;
  };
  const handleMakePhoneCall = () => {
    window.location.href = `tel:9566863601`;
  };
  const goToGoogleMaps = () => {
    window.open("https://maps.app.goo.gl/LWdzksnYxUcc2ZYBA");
  };
  const classes = useStyles();

  // Hooks
  const [showActivePolicies, setActivePolicies] = React.useState(false);
  const [showAllPolicies, setAllPolicies] = React.useState(false);

  return (
    <div>
      <div></div>
      <GridContainer
        style={{
          marginTop: "20px",
        }}
      >
        {resources &&
          resources.map((item) => {
            if (item.name) {
              return (
                <GridItem xs={12} sm={12} md={4}>
                  <a href={`${item.url}/${item.path}`} target="_blank">
                    <Card>
                      <GridContainer justify="space-between">
                        <GridItem xs={12}>
                          <CardBody>
                            <GridItem
                              xs={12}
                              style={{
                                display: "inline-block",
                                height: "80px",
                              }}
                            >
                              <PictureAsPdf
                                style={{ fontSize: "80px" }}
                              ></PictureAsPdf>
                              <span
                                style={{
                                  position: "absolute",
                                  top: "40%",
                                  left: "35%",
                                  width: "180px"
                                }}
                              >
                                {item.name}
                              </span>
                            </GridItem>
                          </CardBody>
                        </GridItem>
                      </GridContainer>
                    </Card>
                  </a>
                </GridItem>
              );
            }
          })}
      </GridContainer>
    </div>
  );
}
