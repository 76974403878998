import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// @material-ui/icons

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);
const useStylesRadio = makeStyles(stylesRadio);

import "react-credit-cards/es/styles-compiled.css";

export default function OneClickFields(props) {
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  const [seePassword, setSeePassword] = useState(false);
  const {
    loginCredentials,
    handleFormChange,
    sended,
    changeLogin,
    typeCode,
    setTypeCode,
    sendCode,
  } = props;

  const handleChange = (event) => {
    setTypeCode(event.target.value);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          {loginCredentials.login}
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Button
            id="changeLoginData"
            type="button"
            style={{ backgroundColor: "#008670" }}
            block
            onClick={changeLogin}
          >
            Change
          </Button>
        </GridItem>
      </GridContainer>

      <div
        className={
          classesRadio.checkboxAndRadio +
          " " +
          classesRadio.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={typeCode === "sms"}
              onChange={handleChange}
              value={"sms"}
              name="testing"
              aria-label="B"
              icon={
                <FiberManualRecord className={classesRadio.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={classesRadio.radioChecked} />
              }
              classes={{
                checked: classesRadio.radio,
                root: classesRadio.radioRoot,
              }}
            />
          }
          classes={{
            label: classesRadio.label,
            root: classesRadio.labelRoot,
          }}
          label={"Text me a verification code " + loginCredentials.maskPhone}
        />
      </div>
      <div
        className={
          classesRadio.checkboxAndRadio +
          " " +
          classesRadio.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={typeCode === "email"}
              onChange={handleChange}
              value={"email"}
              name="testing"
              aria-label="B"
              icon={
                <FiberManualRecord className={classesRadio.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={classesRadio.radioChecked} />
              }
              classes={{
                checked: classesRadio.radio,
                root: classesRadio.radioRoot,
              }}
            />
          }
          classes={{
            label: classesRadio.label,
            root: classesRadio.labelRoot,
          }}
          label={"Email me a verification code " + loginCredentials.maskEmail}
        />
      </div>
      <div
        className={
          classesRadio.checkboxAndRadio +
          " " +
          classesRadio.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={typeCode === "password"}
              onChange={handleChange}
              value={"password"}
              name="testing"
              aria-label="B"
              icon={
                <FiberManualRecord className={classesRadio.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={classesRadio.radioChecked} />
              }
              classes={{
                checked: classesRadio.radio,
                root: classesRadio.radioRoot,
              }}
            />
          }
          classes={{
            label: classesRadio.label,
            root: classesRadio.labelRoot,
          }}
          label={"Password"}
        />
      </div>
      {typeCode != "password" && typeCode != "" && (
        <div>
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <Button
              id="sendCodeMobile"
              type="button"
              style={{ backgroundColor: "#008670" }}
              block
              onClick={sendCode}
            >
              Send Code
            </Button>
          </div>
        </div>
      )}
      {typeCode === "password" && (
        <div>
          <CustomInput
            helperText={
              !loginCredentials.password && sended
                ? "The Password field is required."
                : null
            }
            error={!loginCredentials.password && sended ? true : null}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                  onClick={() => setSeePassword(!seePassword)}
                >
                  <Icon
                    style={{
                      color: seePassword ? "#9c27b0" : "black",
                    }}
                    className={classes.inputAdornmentIcon}
                  >
                    remove_red_eye
                  </Icon>
                </InputAdornment>
              ),
              placeholder: "Password...",
              name: "password",
              type: seePassword ? "text" : "password",
              onChange: handleFormChange,
            }}
          />
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <Button
              id="loginMobile"
              type="submit"
              style={{ backgroundColor: "#008670" }}
              block
            >
              Login
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
