import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import '../../assets/css/stylesPage.css';

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Card from "react-credit-cards";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles(styles);

import "react-credit-cards/es/styles-compiled.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreditCard(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    inputHandler,
    callBackCard,
    handlerSaveCard,
    cardData,
    saveCard
  } = props;

  if (!isOpen) {
    return null;
  }

  const monthCard = typeof cardData["month-card"] === "undefined" ? "" : parseInt(cardData["month-card"]);
  const yearCard = typeof cardData["month-card"] === "undefined" ? "" : cardData["year-card"];

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent id="modalCreditCard" className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.modalTitle}>Credit Card Information</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card
              number={cardData["cnumber-card_string"]}
              name={
                cardData["fname-card"] == "" && cardData["lname-card"] == ""
                  ? "Your name here"
                  : cardData["fname-card"] + " " + cardData["lname-card"]
              }
              expiry={cardData["month-card"] + cardData["year-card"]}
              focused={cardData["focused"]}
              callback={callBackCard}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Name</span>}
              error={(cardData["fname-card"] === "" || typeof cardData["fname-card"] === "undefined") && saveCard ? true : false}
              id="fname-card"
              name="fname-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["fname-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Last Name</span>}
              error={(cardData["lname-card"] === "" || typeof cardData["lname-card"] === "undefined") && saveCard ? true : false}
              id="lname-card"
              name="lname-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["lname-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Card Number</span>}
              error={(cardData["cnumber-card_string"] === "" || typeof cardData["cnumber-card_string"] === "undefined") && saveCard ? true : false}
              id="cnumber-card_string"
              name="cnumber-card_string"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["cnumber-card_string"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Expiration Date (MM/YY)</span>}
              error={(cardData["expirationCard"] === "" || typeof cardData["expirationCard"] === "undefined" || monthCard > 12 || yearCard == "" || monthCard == "") && saveCard ? true : false}
              id="expirationCard"
              name="expirationCard"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["expirationCard"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.modalTitle}>Billing Address</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Address</span>}
              error={(cardData["street-card"] === "" || typeof cardData["street-card"] === "undefined") && saveCard ? true : false}
              id="street-card"
              name="street-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["street-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Apt, Suite, bldg, c/o (optional)</span>}
              id="co-card"
              name="co-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["co-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>City</span>}
              error={(cardData["city-card"] === "" || typeof cardData["city-card"] === "undefined") && saveCard ? true : false}
              id="city-card"
              name="city-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["city-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth style={{ marginTop: "5%" }}>
              <InputLabel htmlFor="age-native-helper">State</InputLabel>
              <NativeSelect
                value={cardData["state-card"]}
                onChange={(e) => inputHandler(e)}
                inputProps={{
                  name: "state-card",
                  id: "state-card",
                }}
              >
                <option aria-label="None" value="State" />
                <option value="1">AL</option>
                <option value="2">AK</option>
                <option value="3">AZ</option>
                <option value="4">AR</option>
                <option value="5">CA</option>
                <option value="6">CO</option>
                <option value="7">CT</option>
                <option value="8">DE</option>
                <option value="9">MD</option>
                <option value="10">FL</option>
                <option value="11">GA</option>
                <option value="12">HI</option>
                <option value="13">ID</option>
                <option value="14">IL</option>
                <option value="15">IN</option>
                <option value="16">IA</option>
                <option value="17">KS</option>
                <option value="18">KY</option>
                <option value="19">LA</option>
                <option value="20">ME</option>
                <option value="21">MD</option>
                <option value="22">MA</option>
                <option value="23">MI</option>
                <option value="24">MN</option>
                <option value="25">MS</option>
                <option value="26">MO</option>
                <option value="27">MT</option>
                <option value="28">NE</option>
                <option value="29">NV</option>
                <option value="30">NH</option>
                <option value="31">NJ</option>
                <option value="32">NM</option>
                <option value="33">NY</option>
                <option value="34">NC</option>
                <option value="35">ND</option>
                <option value="36">OH</option>
                <option value="37">OK</option>
                <option value="38">OR</option>
                <option value="39">PA</option>
                <option value="40">RI</option>
                <option value="41">SC</option>
                <option value="42">SD</option>
                <option value="43">TN</option>
                <option value="44">TX</option>
                <option value="45">UT</option>
                <option value="46">VT</option>
                <option value="47">VA</option>
                <option value="48">WA</option>
                <option value="49">WV</option>
                <option value="50">WI</option>
                <option value="51">WY</option>
                <option value="52">AGS</option>
                <option value="53">BCN</option>
                <option value="54">BCS</option>
                <option value="55">CAM</option>
                <option value="56">CHP</option>
                <option value="57">CHH</option>
                <option value="58">COA</option>
                <option value="59">COL</option>
                <option value="60">DF</option>
                <option value="61">DUR</option>
                <option value="62">GUA</option>
                <option value="63">GRO</option>
                <option value="64">HID</option>
                <option value="65">JAL</option>
                <option value="66">MEX</option>
                <option value="67">MIC</option>
                <option value="68">MOR</option>
                <option value="69">NAY</option>
                <option value="70">NLE</option>
                <option value="71">OAX</option>
                <option value="72">PUE</option>
                <option value="73">QUE</option>
                <option value="74">ROO</option>
                <option value="75">SLP</option>
                <option value="76">SIN</option>
                <option value="77">SON</option>
                <option value="78">TAB</option>
                <option value="79">TAM</option>
                <option value="80">TLA</option>
                <option value="81">VER</option>
                <option value="82">YUC</option>
                <option value="83">ZAC</option>
                <option value="84">AB</option>
                <option value="85">BC</option>
                <option value="86">MB</option>
                <option value="87">NB</option>
                <option value="88">NL</option>
                <option value="89">NT</option>
                <option value="90">NS</option>
                <option value="91">OT</option>
                <option value="92">ON</option>
                <option value="93">PE</option>
                <option value="94">QC</option>
                <option value="95">SK</option>
                <option value="96">YT</option>
                <option value="97">-</option>
                <option value="98">BU</option>
                <option value="99">ED</option>
                <option value="100">HA</option>
                <option value="101">KI</option>
                <option value="102">MO</option>
                <option value="103">RE</option>
                <option value="104">TH</option>
                <option value="105">TO</option>
                <option value="106">WI</option>
                <option value="107">DC</option>
              </NativeSelect>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={<span>Zip Code</span>}
              error={(cardData["code-card"] === "" || typeof cardData["code-card"] === "undefined") && saveCard ? true : false}
              id="code-card"
              name="code-card"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: cardData["code-card"],
                onChange: (e) => inputHandler(e),
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={handlerSaveCard} color="success">
          Save
        </Button>
        <Button onClick={onClose} color="danger">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
