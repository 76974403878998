import {
  container,
  defaultFont,
  defaultFont400,
  defaultFont300,
  defaultFont700,
  defaultFont500,
  defaultFontRedisign,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  boxShadow,
  drawerWidth,
  transition,
  whiteColor,
  grayColor,
  blackColor,
  greenColor,
  yellowColor,
  redColor,
  hexToRgb,
  tooltip
} from "assets/jss/material-dashboard-pro-react.js";

const pagesHeaderStyle = (theme) => ({
  tooltip,
  title: {
    ...defaultFont700,
    lineHeight: "42px",
    fontSize: "32px !important",
    borderRadius: "3px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  priceText: {
    ...defaultFont700,
    lineHeight: "42px",
    fontSize: "25px !important",
    borderRadius: "3px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  stepTitle: {
    ...defaultFont,
    lineHeight: "24px",
    fontSize: "18px !important",
    borderRadius: "3px",
    marginLeft: "10px",
    marginBottom: "15px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  text: {
    ...defaultFont,
    lineHeight: "24px",
    fontSize: "12px !important",
    borderRadius: "3px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  textNormal: {
    ...defaultFont300,
    lineHeight: "24px",
    fontSize: "12px !important",
    borderRadius: "3px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  textMultiLine:{
    ...defaultFont300,
    lineHeight: "18pxpx",
    fontSize: "12px !important",
    textTransform: "none",
    letterSpacing: "unset"
  },
  subTitle: {
    ...defaultFont400,
    lineHeight: "24px",
    fontSize: "18px !important",
    borderRadius: "3px",
    textTransform: "none",
    letterSpacing: "unset"
  },
  font700: {
    ...defaultFont700,
    overflowWrap: "break-word !important"
  },
  fontSize14:{
    fontSize: "14px !important",
  },
  red: {
    color: `${redColor} !important`
  },
  black: {
    color: `${blackColor} !important`
  },
  gray: {
    color: `#888 !important`
  },
  white: {
    color: `${whiteColor} !important`
  },
  green: {
    color: `${greenColor} !important`
  },
  center: {
    textAlign: `center !important`
  },
  titleAccordion: {
    justifyContent: "center",

  },

  greenBackground: {
    display: "flex",
    backgroundColor: `${greenColor} !important`,
    width: "100% !important"
  },
  yellowBackground: {
    display: "flex",
    backgroundColor: `${yellowColor} !important`,
    width: "100% !important"
  },
  labels: {
    ...defaultFont500,
    fontSize: "18px !important",
    lineHeight: "24px",

  },
  labelRoot: {
    ...defaultFontRedisign,
    color: blackColor + " !important",
    fontSize: "15px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },

});

export default pagesHeaderStyle;
