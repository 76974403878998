import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import sanbornsLogo from "assets/img/logo.jpg";

const useStyles = makeStyles(styles);

export default function Welcome() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} style={{ marginTop: "10%" }}>
          <Card product className={classes.cardHover}>
            <CardBody>
              <a href="/home" onClick={(e) => e.preventDefault()}>
                <img src={sanbornsLogo} alt="..." style={{ width: "100%" }} />
              </a>
              <h4 className={classes.cardProductTitle}>
                <a href="/home" onClick={(e) => e.preventDefault()}>
                  Welcome to Sanborn's Mexico Auto Insurance
                </a>
              </h4>
              <p className={classes.cardProductDesciprion}>
              We have refreshed our mobile website to improve your purchase experience. We are glad to assist with any questions or issues.
              800-222-0158
              </p>
            </CardBody>
            <CardFooter product>
              <Button
                onClick={() => {
                  window.location.href = "/admin/home";
                }}
                style={{ backgroundColor: "#008670" }}
                block
              >
                Home
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
