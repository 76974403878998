import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Car from "@material-ui/icons/DirectionsCar";
import Vehicles from "./Vehicles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import jwt_decode from "jwt-decode";
import Snackbar from "components/Snackbar/Snackbar.js";

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import { validateField } from "components/Utils/utils";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";
import { getCustomerByToken } from "views/Services/OrdersService";
import {
  saveCustomerSettingsAPI,
  saveUserSettingsAPI,
  getZipCodeData,
} from "views/Services/ClientService";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useExtFormStyles = makeStyles(extendedFormstyles);

export default function Widgets() {
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const classesExtForm = useExtFormStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [statesDl, setStatesDl] = React.useState([]);
  const [states, setStates] = React.useState([]);
  useEffect(() => {
    getCustomer();
  }, [dispatch]);
  const getCustomer = async () => {
    const resultSubmit = await dispatch(getCustomerByToken({}));
    setData(resultSubmit.payload.data);
    setCustomerData(resultSubmit.payload.data.data_customer);
    setUserData({
      email: resultSubmit.payload.data.data_customer.email,
      password: "",
      confirmPassword: "",
    });
    if (resultSubmit.payload.data?.data_customer?.dl_country_id) {
      setStatesDl(
        resultSubmit.payload.data.catalogs.states.filter(
          (e) =>
            e.country_id ==
            resultSubmit.payload.data.data_customer.dl_country_id
        )
      );
    }
    if (resultSubmit.payload.data?.data_customer?.country_id) {
      setStates(
        resultSubmit.payload.data.catalogs.states.filter(
          (e) =>
            e.country_id == resultSubmit.payload.data.data_customer.country_id
        )
      );
    }
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "dl_country_id") {
      setStatesDl(data.catalogs.states.filter((e) => e.country_id == value));
    }
    if (name === "country_id") {
      setStates(data.catalogs.states.filter((e) => e.country_id == value));
    }
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleFormChangeUser = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const classes = useStyles();
  const saveUserSettings = async () => {
    const token = localStorage.getItem("userToken");
    if (userData.password === userData.confirmPassword) {
      const sended = {
        token: token,
        email: userData.email,
        password: userData.password,
      };
      const resultSubmit = await dispatch(saveUserSettingsAPI(sended));
      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast("Information updated.");
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast("Passwords don't match.");
    }
  };
  const saveClientSettings = async () => {
    const token = localStorage.getItem("userToken");
    const sended = customerData;
    let isValidForm = validSendFormProfile();
    sended.token = token;
    if (isValidForm) {
      const resultSubmit = await dispatch(saveCustomerSettingsAPI(sended));
      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast("Information updated.");
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    }
  };

  const getCodeData = async (zipCode) => {
    let currentCustomerData = { ...customerData, zipcode: zipCode };
    if (validateField(zipCode)) {
      let dateToSend = { zipCode };
      const resultSubmit = await dispatch(getZipCodeData(dateToSend));
      if (resultSubmit.payload.result) {
        currentCustomerData.city = resultSubmit.payload.data.city_name;
        currentCustomerData.country_id = resultSubmit.payload.data.country_id;
        currentCustomerData.state_id = resultSubmit.payload.data.state_id;
        setStates(data.catalogs.states.filter((e) => e.country_id == resultSubmit.payload.data.country_id));
      }
      setCustomerData(currentCustomerData);
    }else{
      setCustomerData(currentCustomerData);
    }
  };

  const validSendFormProfile = () => {
    let isValidForm = true;
    if (
      !validateField(customerData.address) ||
      !validateField(customerData.zipcode) ||
      !validateField(customerData.city) ||
      !validateField(customerData.phone) ||
      !validateField(customerData.email)
    ) {
      isValidForm = false;
      let messageError = "<ul>";
      messageError += !validateField(customerData.address)
        ? "<li>The address is required</li>"
        : "";
      messageError += !validateField(customerData.zipcode)
        ? "<li>The zipCode is required</li>"
        : "";
      messageError += !validateField(customerData.city)
        ? "<li>The City is required</li>"
        : "";
      messageError += !validateField(customerData.phone)
        ? "<li>The Phone is required</li>"
        : "";
      messageError += !validateField(customerData.email)
        ? "<li>The Email is required</li>"
        : "";
      messageError += "</ul>";
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(messageError);
    }
    return isValidForm;
  };

  const ClientData = useMemo(() => {
    return (
      <div>
        {customerData ? (
          <GridItem xs={12}>
            <CustomInput
              labelText={<span>Client Number</span>}
              id="clientnumber"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.id,
              }}
            />
            <CustomInput
              labelText={
                <span>
                  Driver License <small></small>
                </span>
              }
              id="driverlicense"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.dl_number,
                onChange: (event) => {
                  setLicence(event.target.value);
                },
              }}
            />
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                D.L. Country
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.dl_country_id}
                name="dl_country_id"
                onChange={handleFormChange}
                inputProps={{
                  name: "dl_country_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  Country:
                </MenuItem>
                {data.catalogs.countries.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                Do you have a valid D.L.?
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.valid_license}
                onChange={handleFormChange}
                inputProps={{
                  name: "valid_license",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value="1"
                >
                  Yes
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value="0"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                D.L. State
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.dl_state_id}
                onChange={handleFormChange}
                inputProps={{
                  name: "dl_state_id",
                  id: "simple-select",
                }}
              >
                {statesDl.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomInput
              labelText={
                <span>
                  First Name <small></small>
                </span>
              }
              id="firstname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.first_name,
                onChange: (event) => {
                  setfirstname(event.target.value);
                },
              }}
            />
            <CustomInput
              labelText={<span>Middle Name</span>}
              id="middlename"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.middle_name,
              }}
            />
            <CustomInput
              labelText={
                <span>
                  Last Name <small></small>
                </span>
              }
              id="lastname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.last_name,
              }}
            />
            <CustomInput
              labelText={
                <span>
                  Address <small></small>
                </span>
              }
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "address",
                value: customerData.address,
                onChange: (event) => {
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              labelText={
                <span>
                  Zip Code <small></small>
                </span>
              }
              id="zipcode"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "zipcode",
                value: customerData.zipcode,
                onChange: (event) => {
                  handleFormChange(event);
                },
                onBlur: (event) => {
                  const {id, value} = event.target;
                  getCodeData(value);
                }
              }}
            />
            <CustomInput
              labelText={
                <span>
                  City <small></small>
                </span>
              }
              id="city"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "city",
                value: customerData.city,
                onChange: (event) => {
                  handleFormChange(event);
                },
              }}
            />
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                Country
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.country_id}
                name="country_id"
                onChange={handleFormChange}
                inputProps={{
                  name: "country_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  Country:
                </MenuItem>
                {data.catalogs.countries.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                State
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.state_id}
                onChange={handleFormChange}
                inputProps={{
                  name: "state_id",
                  id: "simple-select",
                }}
              >
                {states.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomInput
              labelText={
                <span>
                  Phone # <small></small>
                </span>
              }
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "phone",
                value: customerData.phone,
                onChange: (event) => {
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              labelText={
                <span>
                  Email <small></small>
                </span>
              }
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "email",
                value: customerData.email,
                onChange: (event) => {
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              labelText={<span>Additional Insured</span>}
              id="additionalInsured"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "additional_insured",
                value: customerData.additional_insured,
                onChange: handleFormChange,
              }}
            />
          </GridItem>
        ) : null}
        <GridContainer>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <Button
              color="info"
              className={classes.updateProfileButton}
              style={{ marginTop: "20px" }}
              onClick={() => saveClientSettings()}
            >
              Save Client Data
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }, [customerData, handleFormChange]);
  return (
    <div>
      {customerData && userData ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Snackbar
              style={{
                position: "absolute",
                top: "50", // Asegúrate de que el Snackbar esté en la parte superior
              }}
              place="bc"
              color={typeToast}
              message={messageToast}
              open={toastMessage}
              closeNotification={() => setToastMessage(false)}
              close
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" text>
                <CardText color="warning">
                  <h4 className={classes.cardTitleWhite}>User Settings</h4>
                  <h4 className={classes.cardCategoryWhite}>
                    System Configuration
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={<span>Client Number</span>}
                      id="clientnumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        value: customerData.id,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Email address"
                      email="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "email",
                        value: userData.email,
                        onChange: (event) => {
                          handleFormChangeUser(event);
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Password"
                      name="password"
                      type="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "password",
                        type: "password",
                        value: userData.password,
                        onChange: (event) => {
                          handleFormChangeUser(event);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Confirm Password"
                      name="confirmPassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "confirmPassword",
                        type: "password",
                        value: userData.confirmPassword,
                        onChange: (event) => {
                          handleFormChangeUser(event);
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <Button
                      color="info"
                      className={classes.updateProfileButton}
                      style={{ marginTop: "20px" }}
                      onClick={() => saveUserSettings()}
                    >
                      Save User Settings
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomTabs
              headerColor="rose"
              tabs={[
                {
                  tabName: "Client Data",
                  tabIcon: Person,
                  tabContent: <span>{ClientData}</span>,
                },
                {
                  tabName: "Vehicles",
                  tabIcon: Car,
                  tabContent: <Vehicles data={data} vehicles={data.vehicles} />,
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      ) : null}
    </div>
  );
}
