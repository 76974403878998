import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import logo from "assets/img/logo.png";

// @material-ui/icons
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import Speed from "@material-ui/icons/Speed";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import LabelIcon from "@material-ui/icons/Label";

import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { issue } from "../Services/OrdersService";

import { checkExistPolicyNumber } from "../Services/OrdersService";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import SweetAlert from "react-bootstrap-sweetalert";
import { RotatingLines } from "react-loader-spinner";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function LoginPage() {
  const dispatch = useDispatch();
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const token = params.get("token");
  const PayerID = params.get("PayerID");
  const paymentId = params.get("paymentId");

  const classes = useStyles();
  const classesAlerts = useStylesAlert();

  const [alert, setAlert] = React.useState(null);
  const [alertLoading, setAlertLoading] = React.useState(null);
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");

  useEffect(() => {
    issuePolicy();
  }, [dispatch]);

  const issuePolicy = async () => {
    let dataPolicyPayPal = {
      token: token,
      PayerID: PayerID,
      paymentId: paymentId,
    };
    loadingAlert();
    const resultSubmit = await dispatch(issue(dataPolicyPayPal));
    setAlertLoading(null);
    if (resultSubmit.payload.result) {
      successAlert(resultSubmit.payload);
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.message);
    }
  };

  const loadingAlert = () => {
    setAlertLoading(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{"Loading"}</h5>}
        allowEscape={false}
        closeOnClickOutside={false}
        showConfirm={false}
        onConfirm={() => {}}
        showCancel={false}
      >
        <RotatingLines
          strokeColor="gray"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </SweetAlert>
    );
  };

  const successAlert = (orderData) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{"Policy issued"}</h5>}
        onConfirm={() => successPolicy(orderData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlerts.button + " " + classesAlerts.success}
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };

  const successPolicy = (orderData) => {
    setAlert(null);
    /*if (typeof orderData.data.dataLogin !== "undefined") {
      localStorage.setItem("userToken", orderData.data.dataLogin.data);
    }*/
    window.location.href = `/guestRedesing/summary/${orderData.data.orderId}`;
  };

  return (
    <div className={classes.container}>
      {alert}
      {alertLoading}
      <Snackbar
        style={{
          position: "absolute",
          top: "50", // Asegúrate de que el Snackbar esté en la parte superior
        }}
        place="bc"
        color={typeToast}
        message={messageToast}
        open={toastMessage}
        closeNotification={() => setToastMessage(false)}
        close
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardHeader
              image
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="white"
            >
              <div className={classes.socialLine}>&nbsp;</div>
              <img
                src={logo}
                alt="Sanborn's Mexico Auto Insurance"
                style={{ boxShadow: "none", width: "50%" }}
              />
              <div
                style={{ marginTop: "10px" }}
                className={classes.center}
              ></div>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
