import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import UserLogin from "components/CustomIcons/UserLogin.js";

// @material-ui/icons
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import Button from "components/CustomButtons/CustomButton.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomToogle from "components/CustomToogle/CustomToogle";

import ReactInputVerificationCode from "react-input-verification-code";

import OneClickFields from "components/OneClickFields/CustomOneClickFields.js";

import { checkIfIsKiosko } from "components/Utils/utils";

import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {
  login,
  oneClickLoginAPI,
  oneClickLoginValidation,
  checkDataLogin,
  sendTypeCode,
  checkCode,
} from "../Services/LoginService";
import LoginBaseRedesign from "components/LoginBaseRedesign/LoginBase";
import CardHeader from "components/Card/CardHeader";
import logo from "assets/img/logo.png";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const emptyLogin = {
  login: "",
  password: "",
  maskEmail: "",
  maskPassword: "",
  userId: "",
  producerCode: "",
};

export default function LoginPage() {
  const { t, i18n } = useTranslation();

  const [loginCredentials, setLoginCredentials] = useState(emptyLogin);
  const routeParams = useParams();

  const classes = useStyles();
  const currentUrl = window.location.href;
  const typeLogin = currentUrl.includes("customer") ? "customer" : "agent";
  const { token, userType } = routeParams;
  const isKiosko = checkIfIsKiosko();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("userToken", token);
    localStorage.setItem("userType", userType);
    window.location.replace("/guestRedesing");
  }, [dispatch]);

  return <></>;
}
