import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Icon from "@material-ui/core/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MexicoFlagIcon from "components/CustomIcons/MexicoFlag";
import UsaFlagIcon from "components/CustomIcons/UsaFlag";

// @material-ui/icons
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import AllInbox from "@material-ui/icons/AllInbox";
import Download from "@material-ui/icons/CloudDownload";
import PolicyCard from "@material-ui/icons/CardMembership";
import EmailIcon from "@material-ui/icons/Email";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import SmsIcon from "@material-ui/icons/Sms";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/CustomButton";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import { sendEmailAPI } from "views/Services/PoliciesService";
const useStyles = makeStyles(styles);
import environment from "../../environment/environment";

import { checkIfIsKiosko } from "components/Utils/utils";
import CustomDialog from "components/CustomDialog/CustomDialog.js";
import { formatNumber } from "components/Utils/utils.js";
import { useTranslation } from "react-i18next";
const server = `${environment.SERVER}`;
const useStylesPrincipal = makeStyles(stylePrincipal);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function PolicySummary(props) {
  const { t } = useTranslation();
  const classesPrincipal = useStylesPrincipal();
  const history = useHistory();
  const dispatch = useDispatch();
  const isKiosko = checkIfIsKiosko();
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const classes = useStyles();

  const { id, data, email, setEmail, isLogged } = props;

  const [sendEmail, setSendEmail] = useState(false);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const sendEmailProcess = async () => {
    const resultSubmit = await dispatch(
      sendEmailAPI({
        orderid: data.encryptId,
        to_name: "test test",
        uri: "/admin/look_up/" + id,
        to_email: email,
        csr_email: "test",
      })
    );
    if (resultSubmit.payload.result) {
      setSendEmail(false);
    }
  };
  const daysOptions = [
    { value: 1, label: t("daysOptions.1day") },
    { value: 2, label: t("daysOptions.2days") },
    { value: 3, label: t("daysOptions.3days") },
    { value: 4, label: t("daysOptions.4days") },
    { value: 5, label: t("daysOptions.5days") },
    { value: 6, label: t("daysOptions.6days") },
    { value: 7, label: t("daysOptions.7days") },
    { value: 8, label: t("daysOptions.8days") },
    { value: 9, label: t("daysOptions.9days") },
    { value: 10, label: t("daysOptions.10days") },
    { value: 11, label: t("daysOptions.11days") },
    { value: 12, label: t("daysOptions.12days") },
    { value: 13, label: t("daysOptions.13days") },
    { value: 14, label: t("daysOptions.14days") },
    { value: 15, label: t("daysOptions.15days") },
    { value: 16, label: t("daysOptions.16days") },
    { value: 17, label: t("daysOptions.17days") },
    { value: 18, label: t("daysOptions.18days") },
    { value: 19, label: t("daysOptions.19days") },
    { value: 20, label: t("daysOptions.20days") },
    { value: 21, label: t("daysOptions.21days") },
    { value: 22, label: t("daysOptions.22days") },
    { value: 23, label: t("daysOptions.23days") },
    { value: 24, label: t("daysOptions.24days") },
    { value: 25, label: t("daysOptions.25days") },
    { value: 26, label: t("daysOptions.26days") },
    { value: 27, label: t("daysOptions.27days") },
    { value: 28, label: t("daysOptions.28days") },
    { value: 29, label: t("daysOptions.29days") },
    { value: 30, label: t("daysOptions.30days") },
    { value: 90, label: t("daysOptions.90days") },
    { value: 182, label: t("daysOptions.6months") },
    { value: 365, label: t("daysOptions.1year") },
  ];
  function formatDateTime(date, time) {
    const [year, month, day] = date.split("-").map(Number);
    const [hours, minutes] = time
      .split(/[: ]/)
      .map((v, i) => (i === 0 ? parseInt(v, 10) : v));
    const period = time.slice(-2);
    let hour = hours;
    if (period === "PM" && hour !== 12) hour += 12;
    if (period === "AM" && hour === 12) hour = 0;
    const combinedDateTime = new Date(
      Date.UTC(year, month - 1, day, hour, parseInt(minutes, 10))
    );

    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = combinedDateTime.toLocaleDateString("en-US", options);
    const finalHours = combinedDateTime.getUTCHours() % 12 || 12;
    const finalMinutes = combinedDateTime
      .getUTCMinutes()
      .toString()
      .padStart(2, "0");
    const periodOutput = combinedDateTime.getUTCHours() >= 12 ? "pm" : "am";

    return `${formattedDate} at ${finalHours}:${finalMinutes} ${periodOutput}`;
  }
  function getPaymentTypeString(paymentType) {
    const paymentTypes = {
      1: "Cash",
      2: "Credit Card - System will charge",
      3: "Check",
      4: "Agent will charge",
      5: "PayPal",
      default: "Agent will charge",
    };

    return `${paymentTypes[paymentType] || paymentTypes.default}`;
  }

  return (
    <div>
      {data ? (
        <>
          <GridContainer>
            <GridContainer style={{ justifyContent: "center" }}>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  className={`${classesPrincipal.title} ${classesPrincipal.black}`}
                  style={{ textAlign: "center" }}
                >
                  {t("touristAutoInsurancePolicy")}
                  <div
                    className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
                  >
                    {t("policyNumber")}:
                    <span
                      className={`${classesPrincipal.subTitle} ${classesPrincipal.green}`}
                    >
                      {data.policy_nbr}
                    </span>
                  </div>
                </div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{
                  marginTop: "20px",
                  border: "1px solid #13816F",
                  borderRadius: "16px",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <div
                      style={{ marginTop: "15px" }}
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("transaction")}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {data.issue_date}
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("clientInfo")}
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black} ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                    >
                      {data.first_name} {data.middle_name} {data.last_name}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {data.email}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {data.phone}
                    </div>
                    <div
                      style={{ marginBottom: "15px" }}
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {data.city} {data.state_name} {data.zipcode}
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                      style={{ marginTop: "15px" }}
                    >
                      {t("vehicleInfo")}
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black} ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                    >
                      {data.year_model} {data.make_vehicle} {data.model_vehicle}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      *********
                      {data.vin.substr(data.vin.length - 5)}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("effectiveDate")}:
                      <span className={`${classesPrincipal.font700}`}>
                        {data.effective_date}
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("daysOfCoverage")}:
                      <span className={`${classesPrincipal.font700}`}>
                        {
                          daysOptions.find((e) => e.value+"" === data.num_of_days)
                            .label
                        }
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black} ${classesPrincipal.font700}`}
                    >
                      {t("policyWillExpire")}
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.red} ${classesPrincipal.font700}`}
                    >
                      {formatDateTime(
                        data.expiration_date,
                        data.effective_time
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{
                  textAlign: "center",
                  marginTop: "35px",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={12}>
                    <div
                      className={`${classesPrincipal.title} ${classesPrincipal.black}`}
                      style={{ textAlign: "center" }}
                    >
                      {t("paymentDetails")}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{
                  marginTop: "20px",
                  border: "1px solid #13816F",
                  borderRadius: "16px",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <div
                      style={{ marginTop: "15px" }}
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("paymentMethod")}
                    </div>
                    <div
                      style={{ marginBottom: "15px" }}
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}  ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                    >
                      {getPaymentTypeString(data.payment_type_id)}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <div
                      style={{ marginTop: "15px" }}
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("chargeAmount")}
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.green} ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                    >
                      ${formatNumber(data.total)}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{ textAlign: "center", marginTop: "35px" }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <Button
                      type="button"
                      onClick={() => {
                        window.open(
                          `${server}quotes_mobile/policy/${data.order_id}`
                        );
                      }}
                      variant="green"
                      block
                    >
                      <Icon>add_card_outlined</Icon>
                      {t("downloadPolicy")}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Button
                      type="button"
                      onClick={() => {
                        window.open(
                          `${server}quotes_mobile/policy_card/${data.order_id}`
                        );
                      }}
                      variant="yellow"
                      block
                    >
                      <Icon>add_card_outlined</Icon>
                      {t("downloadPolicyCard")}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    {true && (
                      <Button
                        onClick={() => setSendEmail(true)}
                        type="button"
                        variant="white"
                        block
                      >
                        <EmailOutlinedIcon></EmailOutlinedIcon>
                        {t("sendEmail")}
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{
                  textAlign: "center",
                  marginTop: "55px",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={12}>
                    <div
                      className={`${classesPrincipal.title} ${classesPrincipal.black}`}
                      style={{ textAlign: "center" }}
                    >
                      {t("needAssistance")}
                      <div
                        className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
                      >
                        {t("friendlyAgents")}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={8}
                style={{
                  textAlign: "center",
                  marginTop: "35px",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "15px" }}
                        xs={2}
                        sm={2}
                        md={2}
                      >
                        <MexicoFlagIcon></MexicoFlagIcon>
                      </GridItem>
                      <GridItem xs={10} sm={10} md={10}>
                        <Button
                          type="button"
                          onClick={() => {
                            window.open(`tel:333333333`);
                          }}
                          variant="white"
                          block
                        >
                          {t("callUs")} (000) 000 0000
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "15px" }}
                        xs={2}
                        sm={2}
                        md={2}
                      >
                        <UsaFlagIcon></UsaFlagIcon>
                      </GridItem>
                      <GridItem xs={10} sm={10} md={10}>
                        <Button
                          type="button"
                          onClick={() => {
                            window.open(`tel:333333333`);
                          }}
                          variant="white"
                          block
                        >
                          {t("callUs")} (000) 000 0000
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridContainer>
          <React.Fragment>
            <BootstrapDialog
              onClose={() => setOpen(false)}
              aria-labelledby="customized-dialog-title"
              open={sendEmail}
              fullWidth={true}
              maxWidth={"md"}
            >
              <DialogTitle
                className={`${classesPrincipal.titleAccordion} ${classesPrincipal.black} ${classesPrincipal.title} ${classesPrincipal.yellowBackground}`}
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
              >
                {t("sendEmail")}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => setEmail(false)}
                sx={(theme) => ({
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                })}
              ></IconButton>
              <DialogContent dividers>
                <GridItem
                  xs={12}
                  md={12}
                  style={{ textAlign: "right", marginTop: "15px" }}
                ></GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={<span>{t("email")}</span>}
                    id="clientnumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: email,
                      onChange: (event) => {
                        setEmail(event.target.value);
                      },
                    }}
                  />
                </GridItem>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      variant="green"
                      block
                      onClick={() => {
                        sendEmailProcess();
                      }}
                    >
                      {t("continue")}
                    </Button>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      variant="white"
                      block
                      onClick={() => {
                        setSendEmail(false);
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </BootstrapDialog>
          </React.Fragment>
        </>
      ) : null}
    </div>
  );
}
