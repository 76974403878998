import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import DatePicker from "react-datepicker";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import { Tooltip, IconButton } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";

import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/lib/css/_datepicker.css";
import "../../../assets/css/stylesPage.css";

import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  validValueCoverage,
  getUTCDate,
} from "components/Utils/utils.js";
import { getRates } from "views/Services/OrdersService";
import { checkExistCustomer } from "views/Services/ClientService.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  noMaxWidth: {
    maxWidth: "none",
  },
};

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formStyles);
const currentDate = currentDateEffective();
const useExtFormStyles = makeStyles(extendedFormstyles);
const useStylesAlert = makeStyles(stylesAlerts);
const useStylesDialog = makeStyles(stylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Step3 = React.forwardRef((props, ref) => {
  const { type, setEmailDLFastquote } = props;
  const classes = useStyles();
  const classesForm = useFormStyles();
  const classesExtForm = useExtFormStyles();
  const [data, setData] = useState({});
  const classesAlerts = useStylesAlert();
  const classesDialogs = useStylesDialog();
  const dispatch = useDispatch();
  const [agency, setAgency] = useState("");
  const [fastquote, setFastquote] = useState(true);

  React.useImperativeHandle(ref, () => ({}));

  const [coverages, setCoverages] = React.useState({});

  const [dateRange, setDateRange] = useState([new Date(), null]);
  const [startDate, endDate] = dateRange;

  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const [months, setMonths] = useState(false);
  const [showErrorRates, setShowErrorRates] = useState(true);
  const [showRates, setshowRates] = React.useState(false);
  const [oneYear, setOneYear] = useState(false);
  const [readOnlyDates, setReadOnlyDates] = useState(false);
  const [days, setDays] = useState(3);
  const [resultRates, setResultRates] = useState({});
  const [alertCustomer, setAlertCustomer] = React.useState(false);
  const [emailState, setemailState] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState(null);
  const [driverLicenceState, setDriverLicenceState] = React.useState("");
  const [customerDl, setCustomerDl] = React.useState(null);
  const [showErrorCustomer, setShowErrorCustomer] = React.useState(false);
  const [successCustomer, setSuccessCustomer] = React.useState(false);
  const [messageAlertCustomer, setMessageAlertCustomer] = React.useState(null);
  const [daysFastquote, setDaysFastquote] = React.useState(0);
  const [dateState, setDateState] = React.useState("error");
  const [timeState, setTimeState] = React.useState("error");
  const [borderCrossState, setBorderCrossState] = React.useState("error");
  const [csllimitState, setCslLimitState] = React.useState("error");
  const [excessLiabState, setExcessLiabState] = React.useState("error");
  const [medicalLimitState, setMedicalLimitState] = React.useState("error");
  const [open, setOpen] = React.useState(false);
  const [typeExistUser, setTypeExistUser] = React.useState(false);

  const [effectiveDate, seteffectiveDate] = React.useState(new Date());

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      if (props.data.coverages) {
        setDays(props.data.coverages.days);
        addCoveragesInfo(props.data.coverages);
        if (props.data.coverages.daysFastQuote > 0 && !fastquote) {
          let coveragesData = props.data.coverages;
          let newExpiration = addDays(
            coveragesData.daysFastQuote,
            "effectivedate",
            coveragesData
          );
          coveragesData.expirationdate = newExpiration.expirationDate.date;
          let daysAndType = getDaysFromDates(
            coveragesData.effectivedate,
            newExpiration.expirationDate.date
          );
          coveragesData.policy_type = daysAndType.policyType;
          coveragesData.days = coveragesData.daysFastQuote;
          setDays(coveragesData.daysFastQuote);
          coveragesData.daysFastQuote = 0;
          setDaysFastquote(0);
          props.setStepData("coverages", coveragesData);
          props.setStepData("fullQuoteRates", true);
        } else {
          if (
            props.data.coverages.effectivedate != "" &&
            props.data.coverages.effectivedate != undefined
          ) {
            if (endDateRange == null) {
              let effectiveData = addDays(
                props.data.coverages.days,
                "effectivedate",
                {
                  effectivedate: props.data.coverages.effectivedate,
                }
              );
              setStartDateRange(
                moment(new Date(getUTCDate(props.data.coverages.effectivedate)))
              );
              if (!fastquote) {
                setEndDateRange(
                  moment(new Date(effectiveData.expirationDate.utcDate))
                );
              }
            } else {
              if (typeof props.data.coverages.effectivedate == "undefined") {
                let currentDate = currentDateEffective();
                addDaysToDate(0, currentDate, "effectivedate");
              }
              if (props.data.coverages.expirationdate != "") {
                setEndDateRange(
                  moment(
                    new Date(getUTCDate(props.data.coverages.expirationdate))
                  )
                );
              }
            }
          }
        }
      }
    }

    if (props.addVehicle) {
      props.setStepData("addVehicle", false);
      if (props.fullQuoteRates) {
        updateCoveragesByValue(true);
        props.setStepData("fullQuoteRates", false);
      } else {
        updateCoveragesByValue(false);
      }
    }

    if (!props.fastQuote) {
      setFastquote(false);
      if (props.fullQuoteRates && props.quoteId == 0) {
        props.setStepData("fullQuoteRates", false);
        getQuoteRates();
      }
      if (props.fullQuoteRates && props.quoteId > 0) {
        props.setStepData("fullQuoteRates", false);
        if (typeof props.data.coveragesFromQuote != "undefined") {
          setCoverages(props.data.coveragesFromQuote);
          addCoveragesInfo(props.data.coveragesFromQuote);
          props.setStepData("coverages", props.data.coveragesFromQuote);
        }
      }
      if (coverages.bordercross) {
        setBorderCrossState("success");
      }
      if (coverages.csllimit) {
        setCslLimitState("");
      }
      if (coverages.excessliab) {
        setExcessLiabState("success");
      }
      if (coverages.medicallimit) {
        setMedicalLimitState("success");
      }
    }

    if (data?.data_customer?.agency_id != agency) {
      setAgency(data.data_customer?.agency_id);
      getQuoteRates();
    }

    if (props.calculateRates) {
      props.setStepData("calculateRates", false);
      getQuoteRates();
    }
  }, [props]);

  useEffect(() => {
    if (typeof coverages != "undefined" && data != null) {
      handleSendCoverageToData();
    }
  }, [coverages]);

  const formatMedicalLimit = (number) => {
    let numberFormat = parseFloat(number);
    return numberFormat * 5;
  };

  const addDaysToDate = (days, date, type) => {
    let newDate = addDays(days, type, {
      effectivedate: date,
    });
    setStartDateRange(moment(new Date(getUTCDate(date))));
    return newDate;
  };

  const updateCoveragesByValue = (updateInfo) => {
    let dataCoverages = { ...props.data.coverages };
    let selectedVehicleTotal =
      typeof props.data.selectedVehicle?.total_value == "undefined"
        ? ""
        : props.data.selectedVehicle?.total_value;
    let selectedVehicleVehicleType =
      typeof props.data.selectedVehicle?.vehicle_type_id == "undefined"
        ? ""
        : props.data.selectedVehicle?.vehicle_type_id;
    if (parseInt(selectedVehicleTotal) == 0) {
      dataCoverages.physical_damage = false;
      dataCoverages.theft = false;
      dataCoverages.gapcoverage = false;
      dataCoverages.premierepackage = false;
    } else {
      dataCoverages.physical_damage = true;
      dataCoverages.theft = true;
      dataCoverages.gapcoverage = false;
      dataCoverages.premierepackage = true;
      dataCoverages.roadside = true;
    }
    if (typeof props.data.coverages.csllimit === "undefined") {
      dataCoverages.csllimit = "500000";
    }
    if (typeof props.data.coverages.excessliab === "undefined") {
      dataCoverages.excessliab = "150000";
    }
    if (typeof props.data.coverages.medicallimit === "undefined") {
      dataCoverages.medicallimit = "5000";
    }
    if (typeof props.data.coverages.pd_ded === "undefined") {
      dataCoverages.pd_ded = 500;
    }
    if (typeof props.data.coverages.theft_ded === "undefined") {
      dataCoverages.theft_ded = 1000;
    }
    if (typeof props.data.coverages.fixeddeductible === "undefined") {
      dataCoverages.fixeddeductible = true;
    }
    if (typeof props.data.coverages.legalaid === "undefined") {
      dataCoverages.legalaid = true;
    }
    if (parseInt(selectedVehicleVehicleType) == 10) {
      dataCoverages.roadside = false;
    }
    setCoverages(dataCoverages);
    if (updateInfo) {
      addCoveragesInfo(dataCoverages);
    }
  };

  const validMedicalLimit = (name) => {
    return name == "none" ? "0" : name;
  };

  const handleValueErrorRates = () => {
    // Llama a la función del padre y pasa el valor como argumento
    props.sendValueErrorRates(true);
  };

  const getQuoteRates = async () => {
    let valueVehicle =
      typeof data?.selectedVehicle?.total_value != "undefined"
        ? parseInt(data?.selectedVehicle?.total_value || 0)
        : 0;
    let valueTrailer =
      typeof data?.selectedVehicle?.total_value_trailer != "undefined"
        ? parseInt(data?.selectedVehicle?.total_value_trailer || 0)
        : 0;
    let valueOther =
      typeof data?.selectedVehicle?.total_value_other != "undefined"
        ? parseInt(data?.selectedVehicle?.total_value_other || 0)
        : 0;
    let totalValue = valueVehicle + valueTrailer + valueOther;
    if (
      typeof data?.selectedVehicle?.total_value != "undefined" &&
      coverages.effectivedate != "" &&
      coverages.effectivedate != undefined &&
      coverages.expirationdate != "" &&
      coverages.expirationdate != undefined &&
      coverages.medicallimit != "" &&
      coverages.medicallimit != undefined &&
      coverages.bordercross != "" &&
      coverages.bordercross != undefined &&
      coverages.csllimit != "" &&
      coverages.csllimit != undefined &&
      coverages.excessliab != "" &&
      coverages.excessliab != undefined &&
      data.data_customer.agency_id != ""
    ) {
      let stringRates = `csl=${coverages.csllimit}&excessliab=${coverages.excessliab}&value=${totalValue}&days=${coverages.days}&
      country=${data.data_customer?.country_id}&policy_type=${coverages.policy_type}&
      medicallimit=${coverages.medicallimit}&damageded=2&theftded=5&vehtype=${data.selectedVehicle?.vehicle_type_id}&year_vehicle=${data.selectedVehicle?.year_model}&
      specialdiscount=1&effectivedate=${coverages.effectivedate}&vehicle_type_model=&bordercross=${coverages.bordercross}&agency=${data.data_customer.agency_id}&
      gapcoverage=${coverages.gapcoverage}&premierepackage=${coverages.premierepackage}&fixeddeductible=${coverages.fixeddeductible}&policycaravan=false&
      roadside=${coverages.roadside}&physical_damage=${coverages.physical_damage}&theft=${coverages.theft}&legalaid=${coverages.legalaid}&
      hdiautoguard=false&accessories_and_safety=false&ter_ext=false
    `;
      if (typeof data?.selectedVehicle?.trailer != "undefined") {
        stringRates +=
          data?.selectedVehicle?.trailer != "0" ? "&withtrailer=true" : "";
      }
      const resultSubmit = await dispatch(getRates(stringRates));

      if (resultSubmit.payload.result) {
        setResultRates(resultSubmit.payload.data);
        setshowRates(true);
        props.setStepData("coveragesRates", resultSubmit.payload.data);
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
      setShowErrorRates(false);
      handleValueErrorRates();
    } else {
      setShowErrorRates(true);
      setResultRates({});
      setshowRates(false);
    }
  };

  const [checked, setChecked] = React.useState([7, 24, 22]);

  const fullQuoteView = () => {
    setAlertCustomer(false);
    setDays(daysFastquote);
    setFastquote(false);
    getQuoteRates();
    handleSendCoverageToData();
    props.setAgencyContinue();
    props.setStepData("fastquote", false);
    props.setStepData("fullQuoteVehicle", true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const hideAlert = () => {
    setAlertCustomer(null);
  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const handlerAlertCustomer = (event) => {
    const type = event.target.name;
    type == "customerEmail"
      ? setCustomerEmail(event.target.value)
      : setCustomerDl(event.target.value);
    setEmailDLFastquote(event.target.value, type);
  };

  const alertCheckCustomer = (daysAlert) => {
    setAlertCustomer(true);
    let dataCoverages = { ...data };
    dataCoverages.coverages.daysFastQuote = daysAlert;
    setData(dataCoverages);
  };

  const checkCustomer = async () => {
    let userDataCheck = { email: customerEmail, driver_license: customerDl };

    if (type == "kiosko") {
      userDataCheck.kiosko = true;
    }

    const resultSubmit = await dispatch(checkExistCustomer(userDataCheck));

    if (!resultSubmit.payload.result) {
      setShowErrorCustomer(false);
      setSuccessCustomer(true);
    } else {
      setSuccessCustomer(false);
      setShowErrorCustomer(true);
      setTypeExistUser(resultSubmit.payload.type);
    }
    if (type == "kiosko") {
      resultSubmit.payload.message = resultSubmit.payload.message.replace(
        "Please login to purchase a policy",
        "Please Login or Continue as Guest"
      );
    }
    setMessageAlertCustomer(resultSubmit.payload.message);
  };

  const addCoveragesInfo = (coveragesData) => {
    const newChecked = [...checked];
    const physical = validateCoverage(coveragesData.physical_damage, 1);
    const roadside = validateCoverage(coveragesData.roadside, 4);
    const gapcoverage = validateCoverage(coveragesData.gapcoverage, 5);
    const premierepackage = validateCoverage(coveragesData.premierepackage, 6);
    const fixeddeductible = validateCoverage(coveragesData.fixeddeductible, 7);

    if (!physical["valid"] && physical["index"] !== -1) {
      newChecked.splice(physical["index"]);
    }
    if (physical["valid"] && physical["index"] === -1) {
      newChecked.push(1);
    }

    if (!roadside["valid"] && roadside["index"] !== -1) {
      newChecked.splice(roadside["index"]);
    }
    if (roadside["valid"] && roadside["index"] === -1) {
      newChecked.push(4);
    }

    if (!gapcoverage["valid"] && gapcoverage["index"] !== -1) {
      newChecked.splice(gapcoverage["index"]);
    }
    if (gapcoverage["valid"] && gapcoverage["index"] === -1) {
      newChecked.push(5);
    }

    if (!premierepackage["valid"] && premierepackage["index"] !== -1) {
      newChecked.splice(premierepackage["index"]);
    }
    if (premierepackage["valid"] && premierepackage["index"] === -1) {
      newChecked.push(6);
    }

    if (!fixeddeductible["valid"] && fixeddeductible["index"] !== -1) {
      newChecked.splice(fixeddeductible["index"]);
    }
    if (fixeddeductible["valid"] && fixeddeductible["index"] === -1) {
      newChecked.push(7);
    }
    setChecked(newChecked);
  };

  const validateCoverage = (coverage, id) => {
    let coverageItem = {};
    coverageItem["valid"] = validValueCoverage(coverage);
    coverageItem["index"] = checked.indexOf(id);
    return coverageItem;
  };

  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
      event.target.value = true;
    } else {
      newChecked.splice(currentIndex, 1);
      event.target.value = false;
    }
    if (["months", "oneYear"].includes(event.target.name)) {
      let value = event.target.value == "true" ? true : false;
      event.target.name == "months" ? setMonths(value) : setOneYear(value);
      if (value) {
        let indexToRemove = "";
        if (event.target.name == "months") {
          setOneYear(false);
          indexToRemove = checked.indexOf(9);
        } else {
          setMonths(false);
          indexToRemove = checked.indexOf(8);
        }
        if (indexToRemove != -1) {
          newChecked.splice(indexToRemove, 1);
        }
        handleAddDaysToDates(event.target.name);
      } else {
        setReadOnlyDates(false);
      }
    } else {
      handleCoverageTab(event);
    }
    setChecked(newChecked);
  };

  const handleCoverageTab = (event) => {
    let dataCoverages = { ...coverages };
    let newChecked = [...checked];
    if (event.target.name == "dates") {
      let daysData = getDaysFromDates(
        event.target.effectiveDate,
        event.target.expirationDate
      );
      if (daysData.days > 365) {
        setStartDateRange(null);
        setEndDateRange(null);
        dataCoverages["effectivedate"] = "";
        dataCoverages["expirationdate"] = "";
        dataCoverages.days = 0;
        setDays(0);
        dataCoverages.policy_type = 1;
        setOneYear(false);
        let indexToRemove = checked.indexOf(9);
        if (indexToRemove != -1) {
          newChecked.splice(indexToRemove, 1);
        }
        setMonths(false);
        indexToRemove = checked.indexOf(8);
        if (indexToRemove != -1) {
          newChecked.splice(indexToRemove, 1);
        }
        setChecked(newChecked);
      } else {
        dataCoverages["effectivedate"] = event.target.effectiveDate;
        dataCoverages["expirationdate"] = event.target.expirationDate;
        dataCoverages.days = daysData.days;
        dataCoverages.policy_type = daysData.policyType;
        setDays(daysData.days);

        if (daysData.days != 182) {
          setMonths(false);
          let indexToRemove = checked.indexOf(8);
          if (indexToRemove != -1) {
            newChecked.splice(indexToRemove);
          }
        }
        if (daysData.days != 365) {
          setOneYear(false);
          let indexToRemove = checked.indexOf(9);
          if (indexToRemove != -1) {
            newChecked.splice(indexToRemove);
          }
        }
      }
      setDateState("success");
    } else {
      if (event.target.name == "bordercross") {
        var timeZoneList = {
          1: 12,
          2: 8,
          3: 17,
          4: 33,
          5: 12,
          6: 33,
        };
        dataCoverages[event.target.name + "_name"] =
          event.nativeEvent.target.textContent;
        dataCoverages["timezone"] = timeZoneList[event.target.value];
      }
      if (event.target.name == "physical_damage") {
        dataCoverages["theft"] = event.target.value;
      }
      if (event.target.name == "csllimit" && event.target.value == "50000") {
        dataCoverages.premierepackage = false;
        let indexToRemove = checked.indexOf(6);
        if (indexToRemove != -1) {
          newChecked.splice(indexToRemove);
        }
      }
      if (event.target.name == "timeset") {
        setTimeState("success");
      }
      if (event.target.name == "bordercross") {
        setBorderCrossState("success");
      }
      if (event.target.name == "csllimit") {
        setCslLimitState("success");
      }
      if (event.target.name == "excessliab") {
        setExcessLiabState("success");
      }
      if (event.target.name == "medicallimit") {
        setMedicalLimitState("success");
      }
      dataCoverages[event.target.name] = event.target.value;
    }
    setChecked(newChecked);
    setCoverages(dataCoverages);
  };

  const handleSendCoverageToData = () => {
    const tempData = data;
    tempData.coverages = coverages;
    props.setStepData("coverages", tempData.coverages);
    props.setStepData("calculateRates", true);
  };

  const handleAddDaysToDates = (type) => {
    let coveragesData = { ...coverages };
    if (coverages.effectivedate != "") {
      let days = type == "months" ? 182 : 365;
      let effectiveData = addDays(days, "effectivedate", {
        effectivedate: coveragesData.effectivedate,
      });
      setDateState("success");
      setEndDateRange(moment(effectiveData.expirationDate.utcDate));
      coveragesData["expirationdate"] = effectiveData.expirationDate.date;
      coveragesData["days"] = days;
      coveragesData["policy_type"] = 3;
      setDays(days);
      setCoverages(coveragesData);
    }
  };

  const handlerDates = (event, inputName) => {
    let stringDate = event._d.toLocaleDateString("en-US");
    stringDate = stringDate.replaceAll("/", "-");
    let arrayDate = stringDate.split("-");
    let newStringDate =
      fixDaysMonths(arrayDate[0]) +
      "-" +
      fixDaysMonths(arrayDate[1]) +
      "-" +
      arrayDate[2];
    let inputData = {
      target: {
        name: inputName,
        value: newStringDate,
      },
    };
    handleCoverageTab(inputData);
  };

  const renderCalendarInfo = () => {
    return (
      <div style={{ textAlign: "center", padding: "0 20px" }}>
        <Button
          color="info"
          onClick={() => {
            setFocusedInput(null);
          }}
          fullWidth
        >
          Done
        </Button>
      </div>
    );
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      //Poner aqui las validaciones
      // return isValidated();
      return true;
    },
    sendState: () => {
      return sendState();
    },
    state: {
      effectiveDate,
    },
  }));
  const sendState = () => {
    return {
      effectiveDate,
    };
  };
  const [showTiles, setTiles] = React.useState(true);
  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
          <strong>Policy information</strong>
        </h4>
        <CustomInput
          labelText={<span>Number of days</span>}
          id="days"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            disabled: true,
            value: days,
          }}
        />

        <InputLabel
          // className={classesExtForm.label}
          style={{
            fontSize: "12px",
            marginTop: "15px",
            color: endDateRange != null ? "" : "#F44336",
          }}
          error={endDateRange != null ? false : true}
        >
          Dates: <small>(required)</small>
        </InputLabel>
        <FormControl
          fullWidth
          style={{
            borderColor: endDateRange != null ? "" : "#F44336",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "3px",
          }}
        >
          <DateRangePicker
            startDate={startDateRange}
            startDateId="startDate"
            endDate={endDateRange}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) => {
              setStartDateRange(startDate);
              setEndDateRange(endDate);

              let effectiveDate = formatDateToEffectiveAndExpiration(startDate);
              let expirationDate = formatDateToEffectiveAndExpiration(endDate);
              let currentYear = currentDate.split("-")[2];

              if (
                effectiveDate.year >= currentYear &&
                expirationDate.year >= currentYear
              ) {
                setDateState("success");
                handleCoverageTab({
                  target: {
                    effectiveDate: effectiveDate.date,
                    expirationDate: expirationDate.date,
                    name: "dates",
                  },
                });
              }
              if (startDate == null && endDate == null) {
                let data = { ...coverages };
                data.effectivedate = "";
                data.expirationdate = "";
                setCoverages(data);
              }
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            showDefaultInputIcon
            numberOfMonths={1}
            small
            displayFormat={"MMM-DD-YYYY"}
            calendarInfoPosition="bottom"
            renderCalendarInfo={renderCalendarInfo}
            openDirection="down"
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel={true}
            readOnly
            noBorder
          />
        </FormControl>

        <FormControl fullWidth>
          <GridContainer>
            <GridItem xs={6}>
              <div
                className={
                  classesForm.checkboxAndRadio +
                  " " +
                  classesForm.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={months}
                      checkedIcon={
                        <Check className={classesForm.checkedIcon} />
                      }
                      icon={<Check className={classesForm.uncheckedIcon} />}
                      onClick={(e) => handleToggle(e, 8)}
                      classes={{
                        checked: classesForm.checked,
                        root: classesForm.checkRoot,
                      }}
                      value={months}
                      inputProps={{
                        name: "months",
                        id: "months",
                      }}
                    />
                  }
                  classes={{
                    label: classesForm.label,
                    root: classesForm.labelRoot,
                  }}
                  label="6 months"
                />
              </div>
            </GridItem>
            <GridItem xs={6}>
              <div
                className={
                  classesForm.checkboxAndRadio +
                  " " +
                  classesForm.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={oneYear}
                      checkedIcon={
                        <Check className={classesForm.checkedIcon} />
                      }
                      icon={<Check className={classesForm.uncheckedIcon} />}
                      onClick={(e) => handleToggle(e, 9)}
                      classes={{
                        checked: classesForm.checked,
                        root: classesForm.checkRoot,
                      }}
                      inputProps={{
                        name: "oneYear",
                        id: "oneYear",
                      }}
                    />
                  }
                  classes={{
                    label: classesForm.label,
                    root: classesForm.labelRoot,
                  }}
                  label="1 Year"
                />
              </div>
            </GridItem>
          </GridContainer>
        </FormControl>
        <div>
          <div style={{ width: "90%", display: "inline-block" }}>
            <FormControl
              justify="center"
              fullWidth
              error={coverages.timeset ? false : true}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={
                  coverages.timeset
                    ? classesExtForm.selectLabel
                    : classesExtForm.selectLabelDanger
                }
                id="timeset"
              >
                Effective time <small>(required)</small>
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={coverages.timeset || ""}
                name="timeset"
                inputProps={{
                  id: "timeset",
                  // onChange: (e) => handleCoverageTab(e),
                  onChange: (event) => {
                    event.target.value = event.target.value.toString();
                    if (!verifyLength(event.target.value, 1)) {
                      setTimeState("error");
                    } else {
                      setTimeState("success");
                    }
                    handleCoverageTab(event);
                  },
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  Efective Time:
                </MenuItem>

                {data?.catalogs?.timeset &&
                  data.catalogs.timeset.map((item) => (
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              style={{ position: "absolute", marginTop: "7%" }}
            >
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    arrow
                    placement="right-start"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="The policy is effective in 24 hour intervals. It will start and end at the same
                time on the specified dates. Please make sure you will be back across the
                border when the policy expires. If you plan to cross after that time, you will
                need to extend coverage until the next day."
                  >
                    <IconButton onClick={handleTooltipOpen}>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </FormControl>
          </div>
        </div>
        <FormControl
          fullWidth
          className={
            borderCrossState !== "error"
              ? classesExtForm.selectFormControl
              : classesExtForm.selectFormControlDanger
          }
          error={borderCrossState !== "error" ? false : true}
          style={{ marginTop: "10px" }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={
              borderCrossState !== "error"
                ? classesExtForm.selectLabel
                : classesExtForm.selectLabelDanger
            }
            id="bordercross"
          >
            Border Crossing: <small>(required)</small>
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            classes={{
              select: classesExtForm.select,
            }}
            value={coverages.bordercross || ""}
            inputProps={{
              name: "bordercross",
              id: "bordercross",
              // onChange: (e) => handleCoverageTab(e),
              onChange: (event) => {
                event.target.value = event.target.value.toString();
                if (!verifyLength(event.target.value, 1)) {
                  setBorderCrossState("error");
                } else {
                  setBorderCrossState("success");
                }
                handleCoverageTab(event);
              },
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classesExtForm.selectMenuItem,
              }}
            >
              Border Crossing:
            </MenuItem>
            {data?.catalogs &&
              data.catalogs.borderCrossing.map((item) => (
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div style={{ display: showTiles ? "block" : "none" }}>
          <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
            <strong>Coverage Info</strong>
          </h4>
          <div className={"coverage-info"}>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Physical Damage and Theft:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {validValueCoverage(coverages.physical_damage) ? "Yes" : "No"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Liability limit:</strong>
              </GridItem>
              <GridItem xs={7}>
                $
                <span>
                  {coverages.csllimit
                    ? formatNumber(coverages.csllimit)
                    : "0.00"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Excess limit:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  $
                  {coverages.excessliab
                    ? formatNumber(coverages.excessliab)
                    : "0.00"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Medical limit:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {`${formatNumber(coverages.medicallimit || 0)}` +
                    (data?.selectedVehicle?.vehicle_type_id != 5
                      ? "/" +
                        formatNumber(
                          formatMedicalLimit(coverages.medicallimit || 0)
                        )
                      : "")}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Legal Aid:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>{coverages.legalaid ? "Yes" : "No"}</span>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Roadside:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {validValueCoverage(coverages.roadside) ? "Yes" : "No"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Gap Coverage:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {validValueCoverage(coverages.gapcoverage) ? "Yes" : "No"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Premiere package:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {validValueCoverage(coverages.premierepackage) ? "Yes" : "No"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Fixed Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>
                  {validValueCoverage(coverages.fixeddeductible) ? "Yes" : "No"}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>PD Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>${formatNumber(data?.coverages?.pd_ded || 0)}</span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Theft Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                <span>${formatNumber(data?.coverages?.theft_ded || 0)}</span>
              </GridItem>
            </GridContainer>
          </div>
          {props.quoteId == 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                id="editCoverageGuest"
                color="info"
                onClick={() => {
                  setTiles(false);
                }}
              >
                Edit Coverage
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: showTiles ? "none" : "block" }}>
          <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
            <strong>Coverage information</strong>
          </h4>
          <div
            className={
              classesForm.checkboxAndRadio +
              " " +
              classesForm.checkboxAndRadioHorizontal
            }
          >
            <InputLabel
              className={classesExtForm.label}
              style={{
                fontSize: "15px",
                paddingTop: "5px",
                paddingBottom: "15px",
                color: "#000",
              }}
            >
              Legal Aid: Included
            </InputLabel>
          </div>
          <div
            className={
              classesForm.checkboxAndRadio +
              " " +
              classesForm.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={(e) => handleToggle(e, 1)}
                  checked={checked.indexOf(1) !== -1 ? true : false}
                  disabled={
                    parseInt(props.data?.selectedVehicle?.total_value || 0) == 0
                      ? true
                      : false
                  }
                  checkedIcon={<Check className={classesForm.checkedIcon} />}
                  icon={<Check className={classesForm.uncheckedIcon} />}
                  classes={{
                    checked: classesForm.checked,
                    root: classesForm.checkRoot,
                  }}
                  inputProps={{
                    name: "physical_damage",
                    id: "physical_damage",
                  }}
                />
              }
              classes={{
                label: classesForm.label,
                root: classesForm.labelRoot,
              }}
              label="Physical Damage and Theft"
            />
          </div>
          <div
            className={
              classesForm.checkboxAndRadio +
              " " +
              classesForm.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={(e) => handleToggle(e, 4)}
                  checked={checked.indexOf(4) !== -1 ? true : false}
                  checkedIcon={<Check className={classesForm.checkedIcon} />}
                  disabled={
                    parseInt(
                      props.data?.selectedVehicle?.vehicle_type_id || 0
                    ) == 10
                      ? true
                      : false
                  }
                  icon={<Check className={classesForm.uncheckedIcon} />}
                  classes={{
                    checked: classesForm.checked,
                    root: classesForm.checkRoot,
                  }}
                  inputProps={{
                    name: "roadside",
                    id: "roadside",
                  }}
                />
              }
              classes={{
                label: classesForm.label,
                root: classesForm.labelRoot,
              }}
              label="Roadside"
            />
          </div>
          <div
            className={
              classesForm.checkboxAndRadio +
              " " +
              classesForm.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={(e) => handleToggle(e, 5)}
                  checked={checked.indexOf(5) !== -1 ? true : false}
                  disabled={
                    parseInt(props.data?.selectedVehicle?.total_value || 0) == 0
                      ? true
                      : false
                  }
                  checkedIcon={<Check className={classesForm.checkedIcon} />}
                  icon={<Check className={classesForm.uncheckedIcon} />}
                  classes={{
                    checked: classesForm.checked,
                    root: classesForm.checkRoot,
                  }}
                  inputProps={{
                    name: "gapcoverage",
                    id: "gapcoverage",
                  }}
                />
              }
              classes={{
                label: classesForm.label,
                root: classesForm.labelRoot,
              }}
              label="GAP Coverage"
            />
          </div>
          <div
            className={
              classesForm.checkboxAndRadio +
              " " +
              classesForm.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={(e) => handleToggle(e, 6)}
                  checked={checked.indexOf(6) !== -1 ? true : false}
                  checkedIcon={<Check className={classesForm.checkedIcon} />}
                  disabled={
                    parseInt(props.data?.selectedVehicle?.total_value || 0) == 0
                      ? true
                      : false
                  }
                  icon={<Check className={classesForm.uncheckedIcon} />}
                  classes={{
                    checked: classesForm.checked,
                    root: classesForm.checkRoot,
                  }}
                  inputProps={{
                    name: "premierepackage",
                    id: "premierepackage",
                  }}
                />
              }
              classes={{
                label: classesForm.label,
                root: classesForm.labelRoot,
              }}
              label="Premiere package"
            />
          </div>
          <FormControl
            fullWidth
            error={csllimitState === "error" ? true : false}
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              htmlFor="simple-select"
              className={
                csllimitState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
              id="csllimit"
            >
              Liability Limit: <small>(required)</small>
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              value={coverages.csllimit || ""}
              inputProps={{
                name: "csllimit",
                id: "csllimit",
                // onChange: (e) => handleCoverageTab(e),
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setCslLimitState("error");
                  } else {
                    setCslLimitState("success");
                  }
                  handleCoverageTab(event);
                },
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classesExtForm.selectMenuItem,
                }}
              >
                Liability Limit:
              </MenuItem>
              {data?.catalogs &&
                data.catalogs.CSL_limits.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.name}
                  >
                    ${formatNumber(item.name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            error={excessLiabState === "error" ? true : false}
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              htmlFor="simple-select"
              className={
                excessLiabState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
              id="excessliab"
            >
              Excess Limit: <small>(required)</small>
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              value={coverages.excessliab || ""}
              inputProps={{
                name: "excessliab",
                id: "excessliab",
                // onChange: (e) => handleCoverageTab(e),
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setExcessLiabState("error");
                  } else {
                    setExcessLiabState("success");
                  }
                  handleCoverageTab(event);
                },
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classesExtForm.selectMenuItem,
                }}
              >
                Excess Limit:
              </MenuItem>
              {data?.catalogs &&
                data.catalogs.EXC_limits.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.name}
                  >
                    {item.name == 0 ? "None" : "$" + formatNumber(item.name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            error={medicalLimitState === "error" ? true : false}
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              htmlFor="simple-select"
              className={
                medicalLimitState !== "error"
                  ? classesExtForm.selectLabel
                  : classesExtForm.selectLabelDanger
              }
              id="medicallimit"
            >
              Medical Limit: <small>(required)</small>
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classesExtForm.select,
              }}
              value={coverages.medicallimit || ""}
              inputProps={{
                name: "medicallimit",
                id: "medicallimit",
                // onChange: (e) => handleCoverageTab(e),
                onChange: (event) => {
                  event.target.value = event.target.value.toString();
                  if (!verifyLength(event.target.value, 1)) {
                    setMedicalLimitState("error");
                  } else {
                    setMedicalLimitState("success");
                  }
                  handleCoverageTab(event);
                },
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classesExtForm.selectMenuItem,
                }}
              >
                Medical Limit:
              </MenuItem>
              {data?.catalogs &&
                data.catalogs.medical_limits.map((item) => {
                  return data?.selectedVehicle?.vehicle_type_id == 5 &&
                    parseInt(validMedicalLimit(item.name)) != 5000 &&
                    parseInt(validMedicalLimit(item.name)) != 0 ? null : (
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value={validMedicalLimit(item.name)}
                    >
                      {item.name != "none"
                        ? `${formatNumber(item.name)}` +
                          (data?.selectedVehicle?.vehicle_type_id != 5
                            ? "/" + formatNumber(formatMedicalLimit(item.name))
                            : "")
                        : item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <br />
          <br />
          {data?.selectedVehicle?.vehicle_type_id == 4 && (
            <div
              className={
                classesForm.checkboxAndRadio +
                " " +
                classesForm.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={true}
                    disabled={true}
                    checkedIcon={<Check className={classesForm.checkedIcon} />}
                    icon={<Check className={classesForm.uncheckedIcon} />}
                    classes={{
                      checked: classesForm.checked,
                      root: classesForm.checkRoot,
                    }}
                    inputProps={{
                      name: "fixeddeductible",
                      id: "fixeddeductible",
                    }}
                  />
                }
                classes={{
                  label: classesForm.label,
                  root: classesForm.labelRoot,
                }}
                label="Fixed Deductible"
              />
            </div>
          )}

          <Card style={{ marginTop: "0px" }}>
            <CardBody style={{ textAlign: "left" }}>
              <label style={{ color: "#000000" }}>
                <strong>Physical Damage</strong>
              </label>

              {data?.selectedVehicle?.vehicle_type_id == 4 && (
                <FormControl
                  fullWidth
                  className={classesExtForm.selectFormControl}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classesExtForm.selectLabel}
                  >
                    % Deductible
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    value={coverages.damageded_customer}
                    disabled={validValueCoverage(coverages.fixeddeductible)}
                    inputProps={{
                      name: "damageded_customer",
                      id: "damageded_customer",
                      onChange: (e) => handleCoverageTab(e),
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classesExtForm.selectMenuItem,
                      }}
                    >
                      % Deductible:
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value="2"
                    >
                      2%
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value="5"
                    >
                      5%
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value="10"
                    >
                      10%
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              <p>
                <strong>$ Deductible</strong>
                <br />${formatNumber(resultRates.pd_ded || 0)}
              </p>
              <br />
              <label style={{ color: "#000000" }}>
                <strong>Theft</strong>
              </label>

              {data?.selectedVehicle?.vehicle_type_id == 4 && (
                <FormControl
                  fullWidth
                  className={classesExtForm.selectFormControl}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classesExtForm.selectLabel}
                  >
                    % Deductible
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    value={coverages.theftded_customer}
                    disabled={validValueCoverage(coverages.fixeddeductible)}
                    inputProps={{
                      name: "theftded_customer",
                      id: "theftded_customer",
                      onChange: (e) => handleCoverageTab(e),
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classesExtForm.selectMenuItem,
                      }}
                    >
                      % Deductible:
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value="5"
                    >
                      5%
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value="10"
                    >
                      10%
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              <p>
                <strong>$ Deductible</strong>
                <br />${formatNumber(resultRates.theft_ded || 0)}
              </p>
            </CardBody>
          </Card>
        </div>

        <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
          <strong>Premium Rates</strong>
        </h4>
        <Card style={{ marginTop: "0px" }}>
          <CardBody style={{ textAlign: "left" }}>
            {showErrorRates && (
              <SnackbarContent
                message={
                  "Select dates, agency and coverage info in order to calculate premium rates"
                }
                color="danger"
              />
            )}
            {!fastquote && (
              <p>
                <strong>Net Premium:</strong>
                <br />${formatNumber(resultRates?.premium || 0)}
                <br />
                <strong>Policy Fee:</strong>
                <br />${formatNumber(resultRates?.policy_fee || 0)}
                <br />
                <strong>IVA Tax:</strong>
                <br />${formatNumber(resultRates?.iva || 0)}
                <br />
                <strong>Total:</strong>
                <br />${formatNumber(resultRates?.total || 0)}
                <br />
              </p>
            )}
            <div style={{ textAlign: "center" }}>
              <Button color="info" onClick={() => getQuoteRates()}>
                Calculate Premiums
              </Button>
            </div>
            {fastquote && (
              <GridItem xs={12}>
                <GridContainer
                  style={{ display: showRates ? "block" : "none" }}
                >
                  <GridItem xs={12} sm={12} lg={6}>
                    <Card pricing>
                      <CardBody pricing>
                        <h3
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          <strong>
                            ${formatNumber(resultRates?.total1 || 0)}
                          </strong>
                        </h3>
                        <p className={classes.cardDescription}>
                          <h5 style={{ color: "#f44336" }}>
                            <strong>{resultRates?.days1} Days</strong>
                          </h5>
                        </p>
                        <Button
                          id="shortPlanGuest"
                          round
                          color="success"
                          onClick={() => alertCheckCustomer(resultRates?.days1)}
                        >
                          Select plan
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Card pricing>
                      <CardBody pricing>
                        <h3
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          <strong>
                            ${formatNumber(resultRates?.total4 || 0)}
                          </strong>
                        </h3>
                        <p className={classes.cardDescription}>
                          <h5 style={{ color: "#f44336" }}>
                            <strong>90 Days</strong>
                          </h5>
                        </p>
                        <Button
                          id="midPlanGuest"
                          round
                          color="success"
                          onClick={() => alertCheckCustomer(90)}
                        >
                          Select plan
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Card pricing>
                      <CardBody pricing>
                        <h3
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          <strong>
                            ${formatNumber(resultRates?.total2 || 0)}
                          </strong>
                        </h3>
                        <p className={classes.cardDescription}>
                          <h5 style={{ color: "#f44336" }}>
                            <strong>182 Days</strong>
                          </h5>
                        </p>
                        <Button
                          id="midPlanGuest"
                          round
                          color="success"
                          onClick={() => alertCheckCustomer(182)}
                        >
                          Select plan
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Card pricing>
                      <CardBody pricing>
                        <h3
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          <strong>
                            ${formatNumber(resultRates?.total3 || 0)}
                          </strong>
                        </h3>
                        <p className={classes.cardDescription}>
                          <h5 style={{ color: "#f44336" }}>
                            <strong>365 Days</strong>
                          </h5>
                        </p>
                        <Button
                          id="longPlanGuest"
                          round
                          color="success"
                          onClick={() => alertCheckCustomer(365)}
                        >
                          Select plan
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        classes={{
          root: classesDialogs.center + " " + classesDialogs.modalRoot,
          paper: classesDialogs.modal,
        }}
        open={alertCustomer}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertCustomer(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        style={{ top: "15%" }}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialogs.modalHeader}
        >
          <Button
            justIcon
            className={classesDialogs.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertCustomer(false)}
          >
            <Close className={classesDialogs.modalClose} />
          </Button>
          <h4 className={classesDialogs.modalTitle}>Account Check</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classesDialogs.modalBody}
        >
          {showErrorCustomer && (
            <SnackbarContent message={messageAlertCustomer} color="success" />
          )}
          {successCustomer && (
            <SnackbarContent message={messageAlertCustomer} color="success" />
          )}
          <CustomInput
            success={
              emailState === "success" || customerEmail !== null
                ? "success"
                : ""
            }
            error={
              emailState === "error" || customerEmail === null ? "error" : ""
            }
            labelText={<span>Email</span>}
            id="customerEmail"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "customerEmail",
              value: customerEmail,
              // onChange: (e) => handlerAlertCustomer(e),
              onChange: (event) => {
                if (!verifyEmail(event.target.value)) {
                  setemailState("error");
                } else {
                  setemailState("success");
                }
                // setCustomerEmail(event.target.value);
                handlerAlertCustomer(event);
              },
            }}
          />
          <CustomInput
            success={
              driverLicenceState === "success" || customerDl !== null
                ? "success"
                : ""
            }
            error={
              driverLicenceState === "error" || customerDl === null
                ? "error"
                : ""
            }
            labelText={<span>Driver License</span>}
            id="customerDl"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "customerDl",
              value: customerDl,
              // onChange: (e) => handlerAlertCustomer(e),
              onChange: (event) => {
                if (!verifyLength(event.target.value, 1)) {
                  setDriverLicenceState("error");
                } else {
                  setDriverLicenceState("success");
                }
                handlerAlertCustomer(event);
              },
            }}
          />
        </DialogContent>
        <DialogActions className={classesDialogs.modalFooter}>
          <Button
            id="closeCheckMobileGuest"
            onClick={() => setAlertCustomer(false)}
            color="danger"
            simple
          >
            Close
          </Button>
          <Button
            id="checkMobileGuest"
            onClick={() => checkCustomer()}
            color="info"
            simple
          >
            Check
          </Button>
          {showErrorCustomer && (
            <>
              <Button
                id="loginCheckMobileGuest"
                onClick={() => {
                  let urlLocation = "";
                  let loginData =
                    typeExistUser == "both_found" ||
                    typeExistUser == "license_found"
                      ? customerDl
                      : customerEmail;
                  if (type == "kiosko") {
                    urlLocation = "/auth/login-page/kiosko";
                    localStorage.setItem("login", loginData);
                  } else {
                    urlLocation = "/auth/login-page";
                  }
                  window.location = urlLocation;
                }}
                color="success"
                simple
              >
                Login
              </Button>
              <Button
                id="continueWithoutLoginMobileGuest"
                onClick={() => fullQuoteView()}
                color="success"
                simple
              >
                Continue as Guest
              </Button>
            </>
          )}
          {successCustomer && (
            <Button
              id="continueMobileGuest"
              onClick={() => fullQuoteView()}
              color="success"
              simple
            >
              Continue
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
});

Step3.displayName = "Step3";

export default Step3;
