import React from "react";
export default function Icon(props) {
    return (
        <svg width="25" height="25" style={{ marginRight: "20px" }} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1448_1198)">
                <g mask="url(#mask0_1448_1198)">
                    <path d="M44.5898 13.0371V13.9336H3.75V11.5312C3.75 10.8867 4.27734 10.3594 4.92187 10.3594H41.9121C43.3945 10.3594 44.5898 11.5605 44.5898 13.0371Z" fill="#7FB4A2" />
                    <path d="M3.75 34.0312V40.3594H2.40234C1.75781 40.3594 1.23047 39.832 1.23047 39.1875V17.1562C1.23047 16.5117 1.75781 15.9844 2.40234 15.9844H3.75V34.0312Z" fill="#DAE1EA" />
                    <path d="M58.1836 42.9961C58.1836 42.9961 58.4941 45.1289 56.0508 45.1289H50.8594V45.0645C50.8594 44.0449 50.5312 43.0547 49.916 42.2402H58.1836V43.002V42.9961Z" fill="#DAE1EA" />
                    <path d="M37.5586 42.2344H42.3984C41.7832 43.0488 41.4551 44.0391 41.4551 45.0586H36.3867V45.0293C37.0312 45.0293 37.5586 44.502 37.5586 43.8574V42.2344Z" fill="#DAE1EA" />
                    <path d="M37.5586 42.2344V43.8574C37.5586 44.502 37.0312 45.0293 36.3867 45.0293H18.7793C18.7793 44.0215 18.4453 43.0371 17.8359 42.2344H37.5586Z" fill="#DAE1EA" />
                    <path d="M3.75 42.2344H10.3242C9.71484 43.0371 9.38086 44.0215 9.375 45.0293H3.10547C2.46094 45.0293 1.93359 44.502 1.93359 43.8574V42.2344H3.75Z" fill="#DAE1EA" />
                    <path d="M48.3633 22.6289C49.1543 24.9902 50.4258 29.1094 50.4258 29.1094H40.3418V22.6816H48.2051L48.3691 22.6289H48.3633Z" fill="#B9DDFC" />
                    <path d="M58.1836 32.584V32.8594H53.4785C52.834 32.8594 52.3066 33.3867 52.3066 34.0313H37.5586V29.1094H50.4258L55.377 29.9473C55.377 29.9473 58.1777 30.416 58.1777 32.584H58.1836Z" fill="#EB999B" />
                    <path d="M37.5586 29.1094H31.9336V34.0313H37.5586V29.1094Z" fill="#EB999B" />
                    <path d="M20.6836 29.1094H3.75V34.0313H20.6836V29.1094Z" fill="#EB999B" />
                    <path d="M31.9336 34.0312V39.1875C31.9336 39.832 31.4062 40.3594 30.7617 40.3594H21.8555C21.2109 40.3594 20.6836 39.832 20.6836 39.1875V34.0312H31.9336Z" fill="#F5F6F7" />
                    <path d="M31.9336 29.1094H20.6836V34.0313H31.9336V29.1094Z" fill="#EB999B" />
                    <path d="M31.9336 17.1562V29.1094H20.6836V17.1562C20.6836 16.5117 21.2109 15.9844 21.8555 15.9844H30.7617C31.4062 15.9844 31.9336 16.5117 31.9336 17.1562ZM29.6309 25.6758V18.7148H23.2031V25.6758H29.6309Z" fill="#F5F6F7" />
                    <path d="M48.3633 22.6289L48.1992 22.6816H40.3359V29.1094H37.5527V19.7344H45.3867C45.3867 19.7344 47.3438 19.7344 48.0117 21.6094C48.1055 21.8789 48.2285 22.2305 48.3574 22.6289H48.3633Z" fill="#F5F6F7" />
                    <path d="M58.1836 36.7266V42.2344H49.9102C48.3516 40.1602 45.4043 39.7441 43.3301 41.3027C42.9785 41.5664 42.6621 41.8828 42.3984 42.2344H37.5586V34.0312H52.3066V35.5547C52.3066 36.1992 52.834 36.7266 53.4785 36.7266H58.1836Z" fill="#DC5B69" />
                    <path d="M37.5586 34.0312V42.2344H17.8359C16.2773 40.1602 13.3301 39.7441 11.2559 41.3027C10.9043 41.5664 10.5879 41.8828 10.3242 42.2344H3.75V34.0312H20.6836V39.1875C20.6836 39.832 21.2109 40.3594 21.8555 40.3594H30.7617C31.4062 40.3594 31.9336 39.832 31.9336 39.1875V34.0312H37.5586Z" fill="#DC5B69" />
                    <g opacity="0.2">
                        <path d="M57.041 45.3398H2.10938V43.582H58.1953L57.041 45.3398Z" fill="#384169" />
                    </g>
                    <g opacity="0.2">
                        <path d="M58.1836 40.3594H3.69141V42.1172H58.1836V40.3594Z" fill="#494456" />
                    </g>
                    <g opacity="0.2">
                        <path d="M3.69141 10.3594H42.7207L44.0039 12.1172H3.69141V10.3594Z" fill="#494456" />
                    </g>
                    <g opacity="0.7">
                        <path d="M47.8711 21.4922H37.5938V19.7344H46.1133L47.8711 21.4922Z" fill="white" />
                    </g>
                    <path d="M18.7793 45.0586C18.7793 47.6543 16.6758 49.7578 14.0801 49.7637C11.4844 49.7637 9.38086 47.6602 9.375 45.0645C9.375 42.4688 11.4785 40.3652 14.0742 40.3594C16.6699 40.3594 18.7734 42.4629 18.7734 45.0586H18.7793Z" fill="#767C93" />
                    <path d="M15.5508 45.0586C15.5508 45.873 14.8887 46.5352 14.0742 46.5352C13.2598 46.5352 12.5977 45.873 12.5977 45.0586C12.5977 44.2441 13.2598 43.582 14.0742 43.582C14.8887 43.582 15.5508 44.2441 15.5508 45.0586Z" fill="#DAE1EA" />
                    <g opacity="0.25">
                        <path d="M9.85547 45.1172C9.85547 45.1172 10.207 48.3398 14.0625 48.3398C17.918 48.3398 18.3574 45.2109 18.3574 45.2109C18.3574 45.2109 18.5391 49.6934 14.0684 49.6934C9.59766 49.6934 9.86133 45.123 9.86133 45.123L9.85547 45.1172Z" fill="#494456" />
                    </g>
                    <g opacity="0.2">
                        <path d="M18.3516 45.0293C18.3516 45.0293 17.9941 41.8066 14.1445 41.8066C10.2949 41.8066 9.84961 44.9355 9.84961 44.9355C9.84961 44.9355 9.66797 40.459 14.1445 40.459C18.6211 40.459 18.3516 45.0234 18.3516 45.0234V45.0293Z" fill="white" />
                    </g>
                    <path d="M50.8594 45.0586C50.8594 47.6543 48.7559 49.7578 46.1602 49.7637C43.5645 49.7637 41.4609 47.6602 41.4551 45.0645C41.4551 42.4688 43.5586 40.3652 46.1543 40.3594C48.75 40.3594 50.8535 42.4629 50.8535 45.0586H50.8594Z" fill="#767C93" />
                    <path d="M47.6309 45.0586C47.6309 45.873 46.9687 46.5352 46.1543 46.5352C45.3398 46.5352 44.6777 45.873 44.6777 45.0586C44.6777 44.2441 45.3398 43.582 46.1543 43.582C46.9687 43.582 47.6309 44.2441 47.6309 45.0586Z" fill="#DAE1EA" />
                    <g opacity="0.25">
                        <path d="M41.9297 45.1172C41.9297 45.1172 42.2871 48.3398 46.1367 48.3398C49.9863 48.3398 50.4316 45.2109 50.4316 45.2109C50.4316 45.2109 50.6074 49.6934 46.1367 49.6934C41.666 49.6934 41.9297 45.123 41.9297 45.123V45.1172Z" fill="#494456" />
                    </g>
                    <g opacity="0.2">
                        <path d="M50.4316 45.0293C50.4316 45.0293 50.0742 41.8066 46.2246 41.8066C42.375 41.8066 41.9297 44.9355 41.9297 44.9355C41.9297 44.9355 41.7539 40.459 46.2246 40.459C50.6953 40.459 50.4316 45.0234 50.4316 45.0234V45.0293Z" fill="white" />
                    </g>
                    <path d="M58.1836 32.8594H53.8184C52.9863 32.8594 52.3066 33.3867 52.3066 34.0312V35.5547C52.3066 36.1992 52.9863 36.7266 53.8184 36.7266H58.1484C58.1602 35.4375 58.0254 34.1484 58.1836 32.8594Z" fill="#DAE1EA" />
                    <g opacity="0.2">
                        <path d="M58.1836 32.8594H52.6055V34.2656H58.1836V32.8594Z" fill="white" />
                    </g>
                    <g opacity="0.1">
                        <path d="M58.1836 35.5781H52.6055V36.9844H58.1836V35.5781Z" fill="#494456" />
                    </g>
                    <g opacity="0.5">
                        <path d="M57.9844 36.7969C56.918 36.7969 56.0508 35.9297 56.0508 34.8633C56.0508 33.7969 56.918 32.9297 57.9844 32.9297" fill="white" />
                    </g>
                    <path d="M17.0684 17.25V23.1504C17.0684 23.666 16.8457 24.0879 16.5762 24.0879H7.16602C6.89648 24.0879 6.67383 23.666 6.67383 23.1504V17.25C6.67383 16.7344 6.89648 16.3125 7.16602 16.3125H16.5762C16.8457 16.3125 17.0684 16.7344 17.0684 17.25Z" fill="#DAE1EA" />
                    <g opacity="0.2">
                        <path d="M32.0098 15.9844H20.8184V17.502H32.0098V15.9844Z" fill="#384169" />
                    </g>
                    <g opacity="0.3">
                        <path d="M48.8027 22.6699H40.3184V24.1875H48.8027V22.6699Z" fill="white" />
                    </g>
                    <g opacity="0.3">
                        <path d="M46.7402 22.5469V22.8164L40.3184 29.2441V22.5469H46.7402Z" fill="white" />
                    </g>
                    <g opacity="0.5">
                        <path d="M34.7461 38.7188C34.4238 38.7188 34.1602 38.4551 34.1602 38.1328V23.4844C34.1602 23.1621 34.4238 22.8984 34.7461 22.8984C35.0684 22.8984 35.332 23.1621 35.332 23.4844V38.1328C35.332 38.4551 35.0684 38.7188 34.7461 38.7188Z" fill="#494456" />
                    </g>
                    <path d="M52.8223 22.6816H52.4004C51.7532 22.6816 51.2285 23.2063 51.2285 23.8535V25.418C51.2285 26.0652 51.7532 26.5898 52.4004 26.5898H52.8223C53.4695 26.5898 53.9941 26.0652 53.9941 25.418V23.8535C53.9941 23.2063 53.4695 22.6816 52.8223 22.6816Z" fill="#F5F6F7" />
                    <g opacity="0.2">
                        <path d="M53.4258 22.6816C53.7422 22.6816 54 22.9395 54 23.2559V25.9629C54 26.2793 53.7422 26.5371 53.4258 26.5371C53.1094 26.5371 52.8516 26.2793 52.8516 25.9629V23.2559C52.8516 22.9395 53.1094 22.6816 53.4258 22.6816Z" fill="#494456" />
                    </g>
                    <path d="M58.1836 37.3125H53.4785C52.5059 37.3125 51.7207 36.5273 51.7207 35.5547V34.0312C51.7207 33.0586 52.5059 32.2734 53.4785 32.2734H58.1836C58.5059 32.2734 58.7695 32.5371 58.7695 32.8594C58.7695 33.1816 58.5059 33.4453 58.1836 33.4453H53.4785C53.1562 33.4453 52.8926 33.709 52.8926 34.0312V35.5547C52.8926 35.877 53.1562 36.1406 53.4785 36.1406H58.1836C58.5059 36.1406 58.7695 36.4043 58.7695 36.7266C58.7695 37.0488 58.5059 37.3125 58.1836 37.3125Z" fill="#494456" />
                    <path d="M14.0801 50.3438C11.1621 50.3438 8.79492 47.9766 8.79492 45.0586C8.79492 42.1406 11.1621 39.7734 14.0801 39.7734C16.998 39.7734 19.3652 42.1406 19.3652 45.0586C19.3652 47.9766 16.998 50.3438 14.0801 50.3438ZM14.0801 40.9453C11.8066 40.9453 9.9668 42.791 9.9668 45.0586C9.9668 47.332 11.8125 49.1719 14.0801 49.1719C16.3535 49.1719 18.1934 47.332 18.1934 45.0586C18.1934 42.7852 16.3477 40.9453 14.0801 40.9453Z" fill="#494456" />
                    <path d="M14.0801 47.1211C12.9434 47.1211 12.0176 46.1953 12.0176 45.0586C12.0176 43.9219 12.9434 42.9961 14.0801 42.9961C15.2168 42.9961 16.1426 43.9219 16.1426 45.0586C16.1426 46.1953 15.2168 47.1211 14.0801 47.1211ZM14.0801 44.168C13.5879 44.168 13.1895 44.5664 13.1895 45.0586C13.1895 45.5508 13.5879 45.9492 14.0801 45.9492C14.5723 45.9492 14.9707 45.5508 14.9707 45.0586C14.9707 44.5664 14.5723 44.168 14.0801 44.168Z" fill="#494456" />
                    <path d="M46.1543 50.3437C43.2363 50.3437 40.8691 47.9766 40.8691 45.0527C40.8691 42.1348 43.2363 39.7676 46.1602 39.7676C49.0781 39.7676 51.4453 42.1348 51.4453 45.0527C51.4453 47.9707 49.0781 50.3379 46.1543 50.3379V50.3437ZM46.1543 40.9453C43.8809 40.9453 42.041 42.791 42.041 45.0645C42.041 47.3379 43.8867 49.1777 46.1602 49.1777C48.4336 49.1777 50.2734 47.332 50.2734 45.0645C50.2734 42.791 48.4277 40.9512 46.1543 40.9512V40.9453Z" fill="#494456" />
                    <path d="M46.1543 47.1211C45.0176 47.1211 44.0918 46.1953 44.0918 45.0586C44.0918 43.9219 45.0176 42.9961 46.1543 42.9961C47.291 42.9961 48.2168 43.9219 48.2168 45.0586C48.2168 46.1953 47.291 47.1211 46.1543 47.1211ZM46.1543 44.168C45.6621 44.168 45.2637 44.5664 45.2637 45.0586C45.2637 45.5508 45.6621 45.9492 46.1543 45.9492C46.6465 45.9492 47.0449 45.5508 47.0449 45.0586C47.0449 44.5664 46.6465 44.168 46.1543 44.168Z" fill="#494456" />
                    <path d="M36.9727 9.77344H4.92187C3.94922 9.77344 3.16406 10.5586 3.16406 11.5312V42.2344C3.16406 42.5566 3.42773 42.8203 3.75 42.8203C4.07227 42.8203 4.33594 42.5566 4.33594 42.2344V11.5312C4.33594 11.209 4.59961 10.9453 4.92187 10.9453H36.9727V9.77344Z" fill="#494456" />
                    <path d="M36.9727 29.1094V43.8574C36.9727 44.1797 36.709 44.4434 36.3867 44.4434H18.9141C18.5918 44.4434 18.3281 44.707 18.3281 45.0293C18.3281 45.3516 18.5918 45.6152 18.9141 45.6152H36.3867C37.3594 45.6152 38.1445 44.8301 38.1445 43.8574V29.1094H36.9727Z" fill="#494456" />
                    <path d="M9.375 45.6152H3.10547C2.13281 45.6152 1.34766 44.8301 1.34766 43.8574V42.2344C1.34766 41.9121 1.61133 41.6484 1.93359 41.6484C2.25586 41.6484 2.51953 41.9121 2.51953 42.2344V43.8574C2.51953 44.1797 2.7832 44.4434 3.10547 44.4434H9.375C9.69727 44.4434 9.96094 44.707 9.96094 45.0293C9.96094 45.3516 9.69727 45.6152 9.375 45.6152Z" fill="#494456" />
                    <path d="M56.0508 45.7091H50.8594C50.5371 45.7091 50.2734 45.4454 50.2734 45.1231C50.2734 44.8009 50.5371 44.5372 50.8594 44.5372H56.0566C56.6426 44.5372 57.0703 44.3966 57.3223 44.1095C57.6856 43.6993 57.6094 43.0782 57.6094 43.0724C57.6094 43.0431 57.6094 43.0196 57.6094 42.9903V32.5782C57.6094 31.9044 57.2578 31.3829 56.5313 30.9727C56.1445 30.7618 55.7227 30.6036 55.2891 30.5157L50.3379 29.6778C50.0215 29.6251 49.8047 29.3204 49.8574 29.004C49.9102 28.6876 50.2148 28.4708 50.5313 28.5235L55.4824 29.3614C56.6191 29.5489 58.7754 30.4688 58.7754 32.5782V42.9552C58.7988 43.1954 58.8516 44.127 58.2129 44.8712C57.7266 45.4278 57.0059 45.7091 56.0566 45.7091H56.0508Z" fill="#494456" />
                    <path d="M50.4258 29.6953C50.168 29.6953 49.9453 29.5313 49.8691 29.2852C48.5039 24.9609 47.6953 22.4414 47.4609 21.8027C46.9453 20.3555 45.4512 20.3203 45.3867 20.3145V19.1426C45.4922 19.1426 47.7715 19.166 48.5684 21.4043C48.9199 22.3828 50.4727 27.3047 50.9883 28.9219C51.0879 29.2324 50.918 29.5605 50.6074 29.6602C50.5488 29.6777 50.4902 29.6895 50.4316 29.6895L50.4258 29.6953Z" fill="#494456" />
                    <path d="M50.4258 29.6953H40.3418C40.0195 29.6953 39.7559 29.4316 39.7559 29.1094V22.6816C39.7559 22.3594 40.0195 22.0957 40.3418 22.0957H48.2051C48.5273 22.0957 48.791 22.3594 48.791 22.6816C48.791 23.0039 48.5273 23.2676 48.2051 23.2676H40.9277V28.5234H50.4258C50.748 28.5234 51.0117 28.7871 51.0117 29.1094C51.0117 29.4316 50.748 29.6953 50.4258 29.6953Z" fill="#494456" />
                    <path d="M30.7617 40.9453H21.8555C20.8828 40.9453 20.0977 40.1602 20.0977 39.1875V17.1562C20.0977 16.1836 20.8828 15.3984 21.8555 15.3984H30.7617C31.7344 15.3984 32.5195 16.1836 32.5195 17.1562V39.1875C32.5195 40.1602 31.7344 40.9453 30.7617 40.9453ZM21.8555 16.5703C21.5332 16.5703 21.2695 16.834 21.2695 17.1562V39.1875C21.2695 39.5098 21.5332 39.7734 21.8555 39.7734H30.7617C31.084 39.7734 31.3477 39.5098 31.3477 39.1875V17.1562C31.3477 16.834 31.084 16.5703 30.7617 16.5703H21.8555Z" fill="#494456" />
                    <path d="M44.5898 14.5195H3.75C3.42773 14.5195 3.16406 14.2559 3.16406 13.9336C3.16406 13.6113 3.42773 13.3477 3.75 13.3477H44.5898C44.9121 13.3477 45.1758 13.6113 45.1758 13.9336C45.1758 14.2559 44.9121 14.5195 44.5898 14.5195Z" fill="#494456" />
                    <path d="M40.3418 29.6953H31.9336C31.6113 29.6953 31.3477 29.4316 31.3477 29.1094C31.3477 28.7871 31.6113 28.5234 31.9336 28.5234H40.3418C40.6641 28.5234 40.9277 28.7871 40.9277 29.1094C40.9277 29.4316 40.6641 29.6953 40.3418 29.6953Z" fill="#494456" />
                    <path d="M31.9336 29.6953H3.75C3.42773 29.6953 3.16406 29.4316 3.16406 29.1094C3.16406 28.7871 3.42773 28.5234 3.75 28.5234H31.9336C32.2559 28.5234 32.5195 28.7871 32.5195 29.1094C32.5195 29.4316 32.2559 29.6953 31.9336 29.6953Z" fill="#494456" />
                    <path d="M52.3066 34.6172H31.9336C31.6113 34.6172 31.3477 34.3535 31.3477 34.0312C31.3477 33.709 31.6113 33.4453 31.9336 33.4453H52.3066C52.6289 33.4453 52.8926 33.709 52.8926 34.0312C52.8926 34.3535 52.6289 34.6172 52.3066 34.6172Z" fill="#494456" />
                    <path d="M31.9336 34.6172H3.75C3.42773 34.6172 3.16406 34.3535 3.16406 34.0312C3.16406 33.709 3.42773 33.4453 3.75 33.4453H31.9336C32.2559 33.4453 32.5195 33.709 32.5195 34.0312C32.5195 34.3535 32.2559 34.6172 31.9336 34.6172Z" fill="#494456" />
                    <path d="M23.2031 32.332C22.8809 32.332 22.6172 32.0684 22.6172 31.7461V31.3945C22.6172 31.0723 22.8809 30.8086 23.2031 30.8086C23.5254 30.8086 23.7891 31.0723 23.7891 31.3945V31.7461C23.7891 32.0684 23.5254 32.332 23.2031 32.332Z" fill="#494456" />
                    <path d="M40.3418 32.332C40.0195 32.332 39.7559 32.0684 39.7559 31.7461V31.3945C39.7559 31.0723 40.0195 30.8086 40.3418 30.8086C40.6641 30.8086 40.9277 31.0723 40.9277 31.3945V31.7461C40.9277 32.0684 40.6641 32.332 40.3418 32.332Z" fill="#494456" />
                    <path d="M41.4551 45.6445H35.4199C35.0977 45.6445 34.834 45.3809 34.834 45.0586C34.834 44.7363 35.0977 44.4727 35.4199 44.4727H41.4551C41.7773 44.4727 42.041 44.7363 42.041 45.0586C42.041 45.3809 41.7773 45.6445 41.4551 45.6445Z" fill="#494456" />
                    <path d="M10.3242 42.8203H1.93359C1.61133 42.8203 1.34766 42.5566 1.34766 42.2344C1.34766 41.9121 1.61133 41.6484 1.93359 41.6484H10.3242C10.6465 41.6484 10.9102 41.9121 10.9102 42.2344C10.9102 42.5566 10.6465 42.8203 10.3242 42.8203Z" fill="#494456" />
                    <path d="M42.3984 42.8203H17.8359C17.5137 42.8203 17.25 42.5566 17.25 42.2344C17.25 41.9121 17.5137 41.6484 17.8359 41.6484H42.3984C42.7207 41.6484 42.9844 41.9121 42.9844 42.2344C42.9844 42.5566 42.7207 42.8203 42.3984 42.8203Z" fill="#494456" />
                    <path d="M57.9844 42.8203H49.9102C49.5879 42.8203 49.3242 42.5566 49.3242 42.2344C49.3242 41.9121 49.5879 41.6484 49.9102 41.6484H57.9844C58.3066 41.6484 58.5703 41.9121 58.5703 42.2344C58.5703 42.5566 58.3066 42.8203 57.9844 42.8203Z" fill="#494456" />
                    <path d="M3.69141 40.9453H2.40234C1.42969 40.9453 0.644531 40.1602 0.644531 39.1875V17.1562C0.644531 16.1836 1.42969 15.3984 2.40234 15.3984H3.69141C4.01367 15.3984 4.27734 15.6621 4.27734 15.9844C4.27734 16.3066 4.01367 16.5703 3.69141 16.5703H2.40234C2.08008 16.5703 1.81641 16.834 1.81641 17.1562V39.1875C1.81641 39.5098 2.08008 39.7734 2.40234 39.7734H3.69141C4.01367 39.7734 4.27734 40.0371 4.27734 40.3594C4.27734 40.6816 4.01367 40.9453 3.69141 40.9453Z" fill="#494456" />
                    <path d="M15.75 24.668H7.83984C6.86719 24.668 6.08203 23.8828 6.08203 22.9102V17.4902C6.08203 16.5176 6.86719 15.7324 7.83984 15.7324H15.75C16.7227 15.7324 17.5078 16.5176 17.5078 17.4902V22.9102C17.5078 23.8828 16.7227 24.668 15.75 24.668ZM7.83984 16.9043C7.51758 16.9043 7.25391 17.168 7.25391 17.4902V22.9102C7.25391 23.2324 7.51758 23.4961 7.83984 23.4961H15.75C16.0723 23.4961 16.3359 23.2324 16.3359 22.9102V17.4902C16.3359 17.168 16.0723 16.9043 15.75 16.9043H7.83984Z" fill="#494456" />
                    <path d="M52.8281 27.1816H52.4062C51.4336 27.1816 50.6484 26.3965 50.6484 25.4238V23.8594C50.6484 22.8867 51.4336 22.1016 52.4062 22.1016H52.8281C53.8008 22.1016 54.5859 22.8867 54.5859 23.8594V25.4238C54.5859 26.3965 53.8008 27.1816 52.8281 27.1816ZM52.4004 23.2676C52.0781 23.2676 51.8145 23.5312 51.8145 23.8535V25.418C51.8145 25.7402 52.0781 26.0039 52.4004 26.0039H52.8223C53.1445 26.0039 53.4082 25.7402 53.4082 25.418V23.8535C53.4082 23.5312 53.1445 23.2676 52.8223 23.2676H52.4004Z" fill="#494456" />
                    <path d="M52.4648 23.2676H48.0879C47.7656 23.2676 47.502 23.0039 47.502 22.6816C47.502 22.3594 47.7656 22.0957 48.0879 22.0957H52.4648C52.7871 22.0957 53.0508 22.3594 53.0508 22.6816C53.0508 23.0039 52.7871 23.2676 52.4648 23.2676Z" fill="#494456" />
                    <path d="M38.1445 29.1094H36.9727V21.4922C36.9727 20.1973 38.0215 19.1484 39.3164 19.1484H45.3926V20.3203H39.3164C38.6719 20.3203 38.1445 20.8477 38.1445 21.4922V29.1094Z" fill="#494456" />
                    <path d="M41.0742 9.77344H33.8496V10.9453H41.0742C42.6914 10.9453 44.0039 12.2578 44.0039 13.875V19.1484H45.1758V13.875C45.1758 11.6133 43.3359 9.77344 41.0742 9.77344Z" fill="#494456" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1448_1198">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}
