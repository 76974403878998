import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import logo from "assets/img/logo.png";

// @material-ui/icons
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import Speed from "@material-ui/icons/Speed";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import StepperComponent from "components/Stepper/StepperComponent";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomAlerts from "components/Alerts/CustomAlerts";
import LabelIcon from "@material-ui/icons/Label";

import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {
  login,
  oneClickLoginAPI,
  oneClickLoginValidation,
} from "../Services/LoginService";

import { checkExistPolicyNumber } from "../Services/OrdersService";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);
const useStylesDialog = makeStyles(stylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function getSteps() {
  return ["Select campaign settings", "Create an ad group", "Create an ad"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Select campaign settings...";
    case 1:
      return "What is an ad group anyways?";
    case 2:
      return "This is the bit I really care about!";
    default:
      return "Unknown step";
  }
}
export default function Formatos() {
  const routeParams = useParams();
  const { userId, token, type } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (type == "oneClickLogin") {
      setOneClickLogin(true);
    }
    localStorage.clear();
    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClicValidate(true);
      validateToken();
    }
  }, [dispatch]);
  const [toastMessage, setToastMessage] = useState(false);
  const [oneClicValidate, setOneClicValidate] = useState(false);
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [messageError, setMessageError] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [typeToast, setTypeToast] = useState("");
  const [loginCredentials, setLoginCredentials] = useState({
    login: "",
    password: "",
  });
  const [sended, setSended] = useState(false);
  const handleFormChange = (e) => {
    const valor = e.target.value;
    setLoginCredentials({ ...loginCredentials, [e.target.name]: valor });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const value = loginCredentials;
    if (loginCredentials.login && loginCredentials.password) {
      const resultSubmit = await dispatch(login(value));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast("Success login.");
        localStorage.setItem("userToken", resultSubmit.payload.data);
        window.location.replace("/admin/home");
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += " " + resultSubmit.payload.message[key] + "\n";
        }
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(mensaje);
        //handleError(resultSubmit.payload.detail + mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const oneClickLoginFunction = async () => {
    const value = loginCredentials;
    if (loginCredentials.login) {
      const data = {
        login: loginCredentials.login,
        domain: window.location.origin,
      };
      const resultSubmit = await dispatch(oneClickLoginAPI(data));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast(
          "Please check your email or SMS messages in order to log in to the tool."
        );
      } else {
        let mensaje = resultSubmit.payload.message;
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(mensaje);
        //handleError(resultSubmit.payload.detail + mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const validateToken = async () => {
    const data = {
      userId: userId,
      token: token,
    };
    const resultSubmit = await dispatch(oneClickLoginValidation(data));

    if (resultSubmit.payload.result) {
      setToastMessage(true);
      setTypeToast("success");
      setMessageToast("Success login.");
      localStorage.setItem("userToken", resultSubmit.payload.data);
      window.location.replace("/admin/home");
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.messageCode);
    }
  };

  const fastBuy = () => {
    setOpenModal(true);
  };

  const closeDialogError = () => {
    setOpenModal(false);
  };
  const steps = [{ title: "Prueba1" }, { title: "Prueba 2" }];

  const classes = useStyles();
  const classesDialogs = useStylesDialog();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <StepperComponent steps={steps} />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center}>
                    <h4 className={classes.socialTitle}>Components</h4>
                  </div>

                  <CustomInput
                    labelText="Normal Input"
                    helperText={
                      !loginCredentials.login && sended
                        ? "The Login field is required."
                        : null
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Email or driver license...",
                      name: "login",
                      onChange: handleFormChange,
                    }}
                  />
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      id="loginMobile"
                      type="submit"
                      variant="green"
                      block
                    >
                      <Icon>add_card_outlined</Icon> Green button
                    </Button>
                  </div>
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      onClick={() => setOneClickLogin(true)}
                      variant="yellow"
                      block
                      id="oneClickLogin"
                    >
                      <Icon>add_card_outlined</Icon>
                      Yellow button
                    </Button>
                  </div>
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      onClick={() => setOneClickLogin(true)}
                      variant="white"
                      block
                      id="oneClickLogin"
                    >
                      <Icon>add_card_outlined</Icon>
                      White button
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
              <GridItem style={{ marginTop: "30px" }} xs={12} sm={12} md={12}>
                <CustomAlerts

                  text={
                    "Vehicle verified: Auto 2018 Volkswagen Golf  Reset vehicle information"
                  }
                  variant="default"
                  icon="check"
                  severity="success"
                />
              </GridItem>
              <GridItem style={{ marginTop: "30px" }} xs={12} sm={12} md={12}>
                <CustomAlerts
                  title="VIN not found"
                  text={
                    "Please check the VIN  and try again, or manually enter the vehicle information."
                  }
                  variant="dangerOutlined"
                  icon="warning_amber"
                  severity="error"
                />
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
