import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import PolicySummary from "components/Policy/PolicySummary";

import LoginBase from "components/LoginBase/LoginBase";

import CardBody from "components/Card/CardBody";

import { getPolicyData } from "views/Services/PoliciesService";

export default function ViewPolicy() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const isLogged =
    localStorage.getItem("userToken") != undefined ? true : false;
  const { id, type } = routeParams;

  if (type == "kiosko") {
    let classLeftNav = document.getElementsByClassName("left-nav-logged");
    let classHeader = document.getElementsByClassName(
      "MuiAppBar-positionFixed"
    );
    for (let i = 0; i < classLeftNav.length; i++) {
      classLeftNav[i].style.display = "none";
    }
    for (let i = 0; i < classHeader.length; i++) {
      classHeader[i].style.display = "none";
    }
  }

  const [data, setData] = useState(null);
  const [email, setEmail] = useState(false);
  useEffect(() => {
    getData();
  }, [dispatch]);
  const getData = async () => {
    let dataSend = {
      id: id,
    };
    if (type == "kiosko") {
      dataSend.kiosko = true;
    }
    const resultSubmit = await dispatch(getPolicyData(dataSend));
    if (resultSubmit.payload.result) {
      setData(resultSubmit.payload.data);
      setEmail(resultSubmit.payload.data.email);
    }
  };
  return type == "kiosko" ? (
    <LoginBase>
      <CardBody>
        <PolicySummary
          isLogged={false}
          id={id}
          data={data}
          email={email}
          setEmail={setEmail}
        />
      </CardBody>
    </LoginBase>
  ) : (
    <PolicySummary
      isLogged={isLogged}
      id={id}
      data={data}
      email={email}
      setEmail={setEmail}
    />
  );
}
