import React from "react";
export default function Icon(props) {
  return (
    <svg width="35" height="36" style={props.estilo} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2913 7.52783C18.5721 7.52783 19.6452 8.41685 19.9273 9.61145L25.6247 9.61117C25.713 9.61117 25.797 9.62947 25.8731 9.66248C26.1643 9.69893 26.4364 9.85801 26.6087 10.1146L26.6685 10.2153L29.9997 16.6108V16.7638C29.9997 19.2518 28.0461 21.2778 25.6247 21.2778C23.2638 21.2778 21.3476 19.3519 21.2533 16.9495L21.2497 16.7638V16.6009L24.458 10.8612L19.9271 10.8617C19.6922 11.8551 18.9103 12.637 17.9169 12.8719L17.9163 22.8445L23.0891 26.8241C23.5384 27.1698 23.3287 27.8698 22.7949 27.939L22.708 27.9445H11.8747C11.3077 27.9445 11.0472 27.2617 11.4281 26.8815L11.4936 26.8241L16.6663 22.8445L16.6666 12.8721C15.6728 12.6375 14.8905 11.8554 14.6556 10.8617L10.338 10.8612L13.333 16.6108V16.7638C13.333 19.2518 11.3794 21.2778 8.95801 21.2778C6.5971 21.2778 4.68098 19.3519 4.58665 16.9495L4.58301 16.7638V16.6009L8.16878 10.1881C8.24118 10.0586 8.34006 9.9467 8.4583 9.85919C8.55636 9.73046 8.70403 9.63982 8.8732 9.61687L8.95801 9.61117L14.6554 9.61145C14.9375 8.41685 16.0106 7.52783 17.2913 7.52783ZM17.2913 23.9412L13.7113 26.6945H20.8705L17.2913 23.9412ZM11.9969 17.5282H5.9191C6.22423 18.8571 7.30704 19.866 8.63095 20.0101L8.79765 20.0236L8.95801 20.0278C10.428 20.0278 11.667 18.965 11.9969 17.5282ZM28.6636 17.5282H22.5858C22.8909 18.8571 23.9737 19.866 25.2976 20.0101L25.4643 20.0236L25.6247 20.0278C27.0402 20.0278 28.2416 19.0423 28.6232 17.6863L28.6636 17.5282ZM25.738 11.1345L22.8622 16.2778H28.4172L25.738 11.1345ZM9.07134 11.1345L6.19467 16.2778H11.7497L9.07134 11.1345ZM17.2913 8.77783C16.4859 8.77783 15.833 9.43075 15.833 10.2362C15.833 10.4375 15.8738 10.6293 15.9476 10.8038L15.9737 10.8619C16.1899 11.3163 16.6342 11.6412 17.1587 11.6885L17.2913 11.6945L17.4109 11.6897C18.1604 11.6288 18.7497 11.0013 18.7497 10.2362C18.7497 9.43075 18.0968 8.77783 17.2913 8.77783Z" fill="#008670" />
    </svg>


  );
}
