import React, { useEffect, useState } from "react";
// @material-ui/core components
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Vehicle from "components/Vehicle/Vehicle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import cardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { formatNumber } from "components/Utils/utils.js";

const useCardStyles = makeStyles(cardStyles);

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  iconAligth: {
    position: "absolute",
    top: 70,
    right: 20,
    color: "red",
    with: 30,
    heigth: 30,
  },
  iconEdit: {
    position: "absolute",
    top: 70,
    right: 50,
    color: "black",
    with: 30,
    heigth: 30,
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const Vehicles = ({ data, vehicles }) => {
  const cardClasses = useCardStyles();
  const closeCreateVehicle = () => {
    setshowEditVehicle(false);
    setshowAddVehicle(false);
    setshowVehicle(true);
  };

  const [showVehicle, setshowVehicle] = React.useState(true);
  const [showEditVehicle, setshowEditVehicle] = React.useState(false);
  const [showAddVehicle, setshowAddVehicle] = React.useState(false);
  const [vehicleDataEdit, setVehicleDataEdit] = React.useState(null);
  const sendState = () => {
    return {};
  };
  const isValidated = () => {
    return true;
  };
  const editVehicle = (data) => {
    const vehicleTemp = data;
    vehicleTemp.vehtype = comprobarProps(
      vehicleTemp,
      "vehtype",
      "vehicle_type_id"
    );
    vehicleTemp.insurancecompany = comprobarProps(
      vehicleTemp,
      "insurancecompany",
      "insurance_company"
    );
    vehicleTemp.insurancepolnumber = comprobarProps(
      vehicleTemp,
      "insurancepolnumber",
      "insurance_policy_number"
    );
    vehicleTemp.year_vehicle = comprobarProps(
      vehicleTemp,
      "year_vehicle",
      "year_model",
      true
    );
    vehicleTemp.make_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "makeId"
        : "make_vehicle",
      "make"
    );
    vehicleTemp.model_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "modelId"
        : "model_vehicle",
      "model"
    );
    vehicleTemp.value = comprobarProps(
      vehicleTemp,
      "value",
      "total_value",
      true
    );
    vehicleTemp.vin_vehicle = comprobarProps(vehicleTemp, "vin_vehicle", "vin");
    vehicleTemp.sentri = comprobarProps(vehicleTemp, "sentri", "sentri_number");
    vehicleTemp.plates_vehicle = comprobarProps(
      vehicleTemp,
      "plates_vehicle",
      "plates"
    );
    vehicleTemp.lienholder_vehicle = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle",
      "lienholder"
    );
    vehicleTemp.haveLienholder = vehicleTemp.lienholder_vehicle ? "Yes" : "No";
    vehicleTemp.lienholder_vehicle_address = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle_address",
      "lienholder_vehicle_address"
    );
    setVehicleDataEdit(vehicleTemp);
    setshowEditVehicle(false);
    setshowVehicle(false);
    setshowAddVehicle(true);
  };
  const comprobarProps = (
    objeto,
    propObjeto,
    propDatabase,
    parseToInt = false
  ) => {
    const valor = objeto[propObjeto]
      ? objeto[propObjeto]
      : objeto[propDatabase];
    if (parseToInt) {
      return parseInt(valor);
    } else {
      return valor;
    }
  };
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} style={{ display: showVehicle ? "block" : "none" }}>
          <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
            <CardBody style={{ textAlign: "left" }}>
              <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                <strong>Vehicle</strong>
              </h3>
              <div style={{ textAlign: "right", paddingBottom: "20px" }}>
                <Button
                  color="danger"
                  onClick={() => {
                    setVehicleDataEdit({});
                    setshowEditVehicle(false);
                    setshowVehicle(false);
                    setshowAddVehicle(true);
                  }}
                >
                  Add New Vehicle
                </Button>
              </div>
              {vehicles &&
                vehicles.map((item, index) => (
                  <GridContainer
                    style={{
                      background:
                        index % 2 === 0
                          ? "rgb(170 170 170 / 15%)"
                          : "rgb(255, 255, 255)",
                      paddingBottom: "15px",
                      paddingTop: "15px",
                    }}
                  >
                    <GridItem xs={12}>
                      <strong>Type:</strong>{" "}
                      {item.type_name ? item.type_name : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Year:</strong>{" "}
                      {item.year_model ? item.year_model : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Make:</strong>{" "}
                      {[1, 5].includes(parseInt(item.vehicle_type_id))
                        ? item.make_vehicle
                          ? item.make_vehicle
                          : "-"
                        : item.make
                        ? item.make
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Model:</strong>{" "}
                      {[1, 5].includes(parseInt(item.vehicle_type_id))
                        ? item.model_vehicle
                          ? item.model_vehicle
                          : "-"
                        : item.model
                        ? item.model
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Value:</strong> $
                      {formatNumber(item.total_value)
                        ? formatNumber(item.total_value)
                        : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Plates:</strong> {item.plates ? item.plates : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>VIN:</strong> {item.vin ? item.vin : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <strong>Trusted Traveler #:</strong>{" "}
                      {item.vehicle_id ? item.vehicle_id : "-"}
                    </GridItem>
                    <GridItem xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          color="info"
                          size="sm"
                          onClick={() => {
                            editVehicle(item);
                          }}
                        >
                          Edit Vehicle
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                ))}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          style={{ display: showAddVehicle ? "block" : "none" }}
        >
          <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
            <CardBody style={{ textAlign: "left" }}>
              <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                <strong>Add/Edit Vehicle</strong>
              </h3>
              <Vehicle
                vehicleDataEdit={vehicleDataEdit}
                showAddVehicle={showAddVehicle}
                sendToAPI={true}
                setSelectedVehicle={() => console.log()}
                closeCreateVehicle={closeCreateVehicle}
                data={data}
              />
              <div style={{ textAlign: "right", paddingBottom: "20px" }}></div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Vehicles;
