import React, { useEffect } from "react";
import cx from "classnames";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import AdjustIcon from "@material-ui/icons/Adjust";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
const useStylesPrincipal = makeStyles(stylePrincipal);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,

    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#008670",
      //borderTopWidth: 3,
    },
  },
  completed: {
    "& $line": {
      borderColor: "#FFF",
      //borderTopWidth: "3px !important",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 0,
    borderRadius: 1,
  },
})(StepConnector);

const QontoConnectorHorizontal = withStyles({
  alternativeLabel: {
    top: 10,

    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#008670",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#008670",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 0,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    color: "#D1D5DB",
  },
  active: {
    color: "#008670",
  },
  circle: {
    width: 22,
    height: 22,
  },
  completed: {
    color: "#008670",
    zIndex: 1,
    width: 22,
    height: 22,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();

  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckCircleIcon className={classes.completed} />
      ) : (
          <AdjustIcon className={classes.circle} />
        )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
export default function StepperComponent(props) {
  const classesPrincipal = useStylesPrincipal();
  const { steps, simple, orientation, activeStep } = props;
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log("Cambie los steps", steps);
  }, [props]);

  return (
    <div style={{ width: "100%" }} className={orientation && orientation !== "horizontal" ? "customStepper" : ""}
    >
      {orientation && orientation == "horizontal" ? (
        <div
          className={`${classesPrincipal.stepTitle} ${classesPrincipal.black}`}
        >
          {t("common.step")} {activeStep + 1}/{steps.length}:{" "}
          <span className={`${classesPrincipal.green}`}>
            {steps[activeStep].title}
          </span>
        </div>
      ) : null}
      <Stepper
        orientation={orientation ? orientation : "vertical"}
        activeStep={activeStep}
        connector={orientation && orientation != "horizontal" ? <QontoConnector /> : <QontoConnectorHorizontal />}
        style={{ marginBottom: "20px", position: "relative" }}
      >
        {steps &&
          steps.map((step, key) => {
            return (
              <Step key={step.title}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {orientation && orientation != "horizontal"
                    ? step.title
                    : null}
                </StepLabel>
                {orientation && orientation != "horizontal"
                  ? step.descriptions
                  : null}
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
}

StepperComponent.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};
