import React from "react";
export default function Icon(props) {
  return (
    <svg width="25" height="25" style={{ marginRight: "20px" }} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">

    <g mask="url(#mask0_1380_1259)">
    <path d="M41.1593 43.4478C41.1593 48.108 44.9373 51.8861 49.5975 51.8861C54.2577 51.8861 58.0358 48.108 58.0358 43.4478C58.0358 38.7876 54.2577 35.0095 49.5975 35.0095C44.9373 35.0095 41.1593 38.7876 41.1593 43.4478Z" fill="#767C93"/>
    <path d="M44.9043 43.4479C44.9043 46.0359 47.0014 48.133 49.5895 48.133C52.1775 48.133 54.2746 46.0359 54.2746 43.4479C54.2746 40.8598 52.1775 38.7627 49.5895 38.7627C47.0014 38.7627 44.9043 40.8598 44.9043 43.4479Z" fill="#F0F3F7"/>
    <path d="M47.4005 43.4477C47.4005 44.6627 48.3824 45.6446 49.5974 45.6446C50.8124 45.6446 51.7944 44.6627 51.7944 43.4477C51.7944 42.2327 50.8124 41.2507 49.5974 41.2507C48.3824 41.2507 47.4005 42.2327 47.4005 43.4477Z" fill="#BAC2D3"/>
    <g opacity="0.3">
    <path d="M57.3291 46.2273C57.3291 46.2273 55.282 50.5962 49.5982 50.5962C43.9144 50.5962 41.8673 46.2939 41.8673 46.2939C41.8673 46.2939 42.8659 51.8861 49.5982 51.8861C56.3305 51.8861 57.3291 46.2356 57.3291 46.2356V46.2273Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M41.8585 40.66C41.8585 40.66 43.9057 36.2911 49.5895 36.2911C55.2732 36.2911 57.3204 40.5935 57.3204 40.5935C57.3204 40.5935 56.3218 35.0012 49.5895 35.0012C42.8571 35.0012 41.8585 40.6517 41.8585 40.6517V40.66Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M45.4619 42.2828C45.4619 42.2828 46.5603 39.9943 49.5978 39.9943C52.6352 39.9943 53.7337 42.2495 53.7337 42.2495C53.7337 42.2495 53.2011 38.7544 49.5978 38.7544C45.9945 38.7544 45.4619 42.2828 45.4619 42.2828Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M47.1679 51.9615C47.1096 51.9615 47.0514 51.9532 47.0015 51.9365C46.6936 51.845 46.5188 51.5121 46.6103 51.2042L46.9931 49.9393C47.0764 49.6314 47.4009 49.44 47.7171 49.5315C48.025 49.6147 48.2164 49.9393 48.1249 50.2555C48.1249 50.2638 48.1249 50.2722 48.1166 50.2805L47.7338 51.5454C47.6589 51.795 47.4259 51.9615 47.1679 51.9615Z" fill="#494456"/>
    <path d="M42.6824 41.9082C42.6242 41.9082 42.5659 41.8999 42.516 41.8832L41.3343 41.5337C41.0264 41.4422 40.8433 41.1176 40.9348 40.8014C41.0264 40.4852 41.3509 40.3104 41.6672 40.402L42.8489 40.7515C43.1568 40.843 43.3315 41.1676 43.24 41.4838C43.1651 41.7334 42.9404 41.8999 42.6824 41.8999V41.9082Z" fill="#494456"/>
    <path d="M57.6609 46.3437C57.6027 46.3437 57.5444 46.3437 57.4945 46.3188L56.3128 45.9693C56.0049 45.8777 55.8218 45.5532 55.9134 45.237C56.0049 44.9207 56.3294 44.746 56.6457 44.8375L57.8274 45.187C58.1353 45.2786 58.31 45.6031 58.2185 45.9193C58.1436 46.169 57.9189 46.3354 57.6609 46.3354V46.3437Z" fill="#494456"/>
    <path d="M53.5916 51.304C53.3752 51.304 53.1755 51.1875 53.0756 50.9961L52.4598 49.856C52.3017 49.5731 52.4099 49.2153 52.6928 49.0571C52.9758 48.899 53.3336 49.0072 53.4917 49.2901L54.1158 50.4302C54.274 50.7132 54.1658 51.071 53.8828 51.2208C53.7996 51.2707 53.6998 51.2957 53.5999 51.2957L53.5916 51.304Z" fill="#494456"/>
    <path d="M42.1577 47.9167C41.8332 47.9167 41.5752 47.6587 41.5752 47.3342C41.5752 47.1178 41.6917 46.9181 41.8831 46.8182L43.0315 46.2024C43.3144 46.0526 43.6723 46.1608 43.8221 46.4438C43.9719 46.7267 43.8637 47.0845 43.5807 47.2343L42.4323 47.8501C42.3491 47.8918 42.2492 47.9167 42.1577 47.9167Z" fill="#494456"/>
    <path d="M55.9802 40.5271C55.6556 40.5271 55.3977 40.2692 55.3977 39.9446C55.3977 39.7283 55.5142 39.5285 55.7056 39.4287L56.7291 38.8794C57.0121 38.7213 57.3699 38.8212 57.528 39.1041C57.6861 39.3871 57.5863 39.7449 57.3033 39.903C57.3033 39.903 57.295 39.903 57.2867 39.9113L56.2631 40.4606C56.1799 40.5022 56.08 40.5271 55.9885 40.5271H55.9802Z" fill="#494456"/>
    <path d="M44.2545 50.4301C43.9299 50.4301 43.672 50.1638 43.672 49.8476C43.672 49.7144 43.7219 49.5813 43.8051 49.4731L44.6456 48.4578C44.8536 48.2082 45.2198 48.1749 45.4695 48.3746C45.7191 48.5743 45.7524 48.9488 45.5527 49.1985L44.7122 50.2137C44.604 50.3469 44.4376 50.4301 44.2628 50.4301H44.2545Z" fill="#494456"/>
    <path d="M43.964 39.4202C43.8308 39.4202 43.6977 39.3703 43.5978 39.2871L42.6741 38.5465C42.4244 38.3467 42.3828 37.9723 42.5826 37.7226C42.7823 37.473 43.1568 37.4313 43.4064 37.6311L44.3301 38.38C44.5798 38.5798 44.6214 38.9542 44.4217 39.2039C44.3135 39.3454 44.1387 39.4202 43.964 39.4202Z" fill="#494456"/>
    <path d="M56.1295 49.223C55.9964 49.223 55.8632 49.1814 55.7634 49.0898L54.7814 48.2993C54.5234 48.0995 54.4818 47.7334 54.6815 47.4754C54.8813 47.2174 55.2474 47.1758 55.5054 47.3756C55.5054 47.3756 55.5137 47.3839 55.522 47.3922L56.504 48.1828C56.7537 48.3825 56.7953 48.757 56.5955 49.0066C56.4874 49.1481 56.3209 49.223 56.1378 49.223H56.1295Z" fill="#494456"/>
    <path d="M50.4379 52.2608C50.1383 52.2608 49.8803 52.0361 49.8553 51.7365L49.7222 50.43C49.6889 50.1055 49.9219 49.8142 50.2465 49.7892C50.571 49.7643 50.8623 49.989 50.8873 50.3135L51.0204 51.62C51.0537 51.9446 50.8207 52.2275 50.4961 52.2608H50.4296H50.4379Z" fill="#494456"/>
    <path d="M41.1841 44.8045C40.8595 44.8212 40.5849 44.5715 40.5682 44.247C40.5516 43.9224 40.8013 43.6478 41.1258 43.6312L42.3907 43.5063C42.7153 43.4731 43.0065 43.7061 43.0315 44.0306C43.0565 44.3552 42.8318 44.6464 42.5072 44.6714L41.2423 44.7962H41.1841V44.8045Z" fill="#494456"/>
    <path d="M56.7874 43.2232C56.4628 43.2399 56.1882 42.9902 56.1716 42.6657C56.1549 42.3411 56.4046 42.0665 56.7291 42.0498L57.9191 41.9333C58.2437 41.9001 58.5266 42.1331 58.5599 42.4576C58.5932 42.7822 58.3602 43.0651 58.0357 43.0984L56.8456 43.2149H56.7874V43.2232Z" fill="#494456"/>
    </g>
    <path d="M1.98933 43.4478C1.98933 48.108 5.76742 51.8861 10.4276 51.8861C15.0878 51.8861 18.8659 48.108 18.8659 43.4478C18.8659 38.7876 15.0878 35.0095 10.4276 35.0095C5.76742 35.0095 1.98933 38.7876 1.98933 43.4478Z" fill="#767C93"/>
    <path d="M5.74203 43.4479C5.74203 46.0359 7.83911 48.133 10.4272 48.133C13.0153 48.133 15.1123 46.0359 15.1123 43.4479C15.1123 40.8598 13.0153 38.7627 10.4272 38.7627C7.83911 38.7627 5.74203 40.8598 5.74203 43.4479Z" fill="#F0F3F7"/>
    <g opacity="0.3">
    <path d="M18.166 46.2273C18.166 46.2273 16.1189 50.5962 10.4351 50.5962C4.75133 50.5962 2.70418 46.2939 2.70418 46.2939C2.70418 46.2939 3.70279 51.8861 10.4351 51.8861C17.1674 51.8861 18.166 46.2356 18.166 46.2356V46.2273Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M2.69626 40.66C2.69626 40.66 4.74342 36.2911 10.4272 36.2911C16.111 36.2911 18.1581 40.5935 18.1581 40.5935C18.1581 40.5935 17.1595 35.0012 10.4272 35.0012C3.69487 35.0012 2.69626 40.6517 2.69626 40.6517V40.66Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M6.28328 42.2831C6.28328 42.2831 7.38175 39.9946 10.4275 39.9946C13.4733 39.9946 14.5634 42.2498 14.5634 42.2498C14.5634 42.2498 14.0225 38.7546 10.4275 38.7546C6.83252 38.7546 6.28328 42.2831 6.28328 42.2831Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M8.84605 52.2191C8.84605 52.2191 8.77116 52.2191 8.72955 52.2108C8.41332 52.1526 8.20528 51.8447 8.26353 51.5284L8.5215 50.2136C8.57976 49.8974 8.88766 49.6893 9.21221 49.7476C9.52844 49.8058 9.73648 50.1137 9.67823 50.4383L9.4369 51.7531C9.38697 52.0277 9.14564 52.2275 8.8627 52.2275L8.84605 52.2191Z" fill="#494456"/>
    <path d="M3.37864 42.5658C3.37864 42.5658 3.30375 42.5658 3.26214 42.5575L2.04716 42.3245C1.73093 42.2662 1.52289 41.9583 1.58114 41.6338C1.63939 41.3175 1.9473 41.1095 2.27185 41.1677L3.48683 41.4007C3.80305 41.459 4.0111 41.7669 3.95285 42.0831C3.90292 42.3577 3.66158 42.5575 3.37864 42.5575V42.5658Z" fill="#494456"/>
    <path d="M15.1709 50.9208C14.9795 50.9208 14.7965 50.8209 14.6883 50.6628L13.956 49.581C13.7646 49.3147 13.8311 48.9485 14.0891 48.7654C14.3554 48.574 14.7216 48.6406 14.9046 48.8986C14.9046 48.9069 14.9213 48.9152 14.9213 48.9236L15.6536 50.0054C15.8367 50.2717 15.7618 50.6378 15.4955 50.8209C15.3956 50.8875 15.2874 50.9208 15.1709 50.9208Z" fill="#494456"/>
    <path d="M3.46186 48.6574C3.13731 48.6574 2.87934 48.3995 2.87102 48.0749C2.87102 47.8752 2.97088 47.7004 3.12899 47.5839L4.21915 46.8516C4.48544 46.6685 4.8516 46.7434 5.03468 47.0097C5.21776 47.276 5.14286 47.6422 4.87657 47.8253L3.79473 48.5576C3.69487 48.6242 3.57837 48.6574 3.46186 48.6574Z" fill="#494456"/>
    <path d="M16.4771 39.8613C16.1526 39.8613 15.8946 39.6033 15.8946 39.2788C15.8946 39.0874 15.9945 38.9043 16.1526 38.7961L17.1179 38.147C17.3842 37.964 17.7503 38.0388 17.9334 38.3051C18.1165 38.5714 18.0416 38.9376 17.7753 39.1207L16.81 39.7698C16.7101 39.8364 16.6019 39.8696 16.4854 39.8696L16.4771 39.8613Z" fill="#494456"/>
    <path d="M5.80028 50.971C5.47573 50.971 5.21776 50.7047 5.21776 50.3884C5.21776 50.2719 5.25105 50.1637 5.31762 50.0639L6.04994 48.9571C6.21637 48.6825 6.58253 48.5993 6.85715 48.7657C7.13177 48.9321 7.21498 49.2983 7.04855 49.5729C7.04855 49.5812 7.04023 49.5895 7.03191 49.5979L6.29959 50.7047C6.19141 50.8711 6.00833 50.971 5.8086 50.971H5.80028Z" fill="#494456"/>
    <path d="M4.40222 39.9362C4.28572 39.9362 4.17754 39.9029 4.07768 39.8363L3.07906 39.1789C2.81277 39.0042 2.73787 38.638 2.91263 38.3634C3.08738 38.0971 3.45354 38.0222 3.72816 38.197L4.72677 38.8544C4.99307 39.0291 5.06797 39.3953 4.89321 39.6699C4.78503 39.8363 4.60195 39.9362 4.40222 39.9362Z" fill="#494456"/>
    <path d="M17.4845 48.5824C17.368 48.5824 17.2598 48.5492 17.1599 48.4826L16.1031 47.7836C15.8368 47.6005 15.7785 47.2343 15.9616 46.968C16.1364 46.71 16.4859 46.6435 16.7522 46.8099L17.809 47.5089C18.0753 47.6837 18.1502 48.0498 17.9755 48.3245C17.8673 48.4909 17.6842 48.5908 17.4845 48.5908V48.5824Z" fill="#494456"/>
    <path d="M12.1411 52.1939C11.8665 52.1939 11.6251 51.9942 11.5669 51.7279L11.3006 50.4297C11.234 50.1135 11.4421 49.8056 11.7583 49.739C12.0745 49.6724 12.3824 49.8805 12.449 50.1967L12.7153 51.4949C12.7819 51.8111 12.5738 52.119 12.2576 52.1856C12.216 52.1856 12.1827 52.1939 12.1411 52.1939Z" fill="#494456"/>
    <path d="M2.18031 45.62C1.85576 45.62 1.59779 45.362 1.59779 45.0375C1.59779 44.7545 1.79751 44.5215 2.06381 44.4633L3.31207 44.2053C3.6283 44.1387 3.9362 44.3468 4.00278 44.663C4.06935 44.9792 3.86131 45.2871 3.54508 45.3537L2.29682 45.6117C2.29682 45.6117 2.22192 45.62 2.18031 45.62Z" fill="#494456"/>
    </g>
    <path d="M37.3814 13.7224H33.7448C33.1381 13.7224 32.6463 14.2142 32.6463 14.8209V15.5615C32.6463 16.1682 33.1381 16.66 33.7448 16.66H37.3814C37.9881 16.66 38.4799 16.1682 38.4799 15.5615V14.8209C38.4799 14.2142 37.9881 13.7224 37.3814 13.7224Z" fill="#767C93"/>
    <path d="M7.98058 43.4479C7.98058 44.6629 8.96255 45.6449 10.1775 45.6449C11.3925 45.6449 12.3745 44.6629 12.3745 43.4479C12.3745 42.2329 11.3925 41.251 10.1775 41.251C8.96255 41.251 7.98058 42.2329 7.98058 43.4479Z" fill="#BAC2D3"/>
    <path d="M48.2663 13.2649V19.2899L45.4369 19.2815V13.2649H48.2663Z" fill="#DAE1EA"/>
    <path d="M46.3111 34.0525L47.0435 37.3396H47.0185C46.5525 37.5393 46.1114 37.7973 45.7036 38.0969L44.5636 36.6073L43.6731 35.4422C44.4554 34.8597 45.3125 34.402 46.2279 34.0691L46.3111 34.0525Z" fill="#C63037"/>
    <path d="M56.58 36.3827L55.5398 37.4146L54.2166 38.7378C52.9434 37.4646 51.2041 36.7655 49.3983 36.8155L49.3234 36.8321L48.5911 33.5533L48.7991 33.5117C51.6868 33.2787 54.5328 34.3273 56.58 36.3827Z" fill="#C63037"/>
    <path d="M53.4345 25.3231C53.4345 28.9764 50.68 30.0083 50.68 30.0083V20.6379C50.68 20.6379 53.4345 21.6782 53.4345 25.3231Z" fill="#DAE1EA"/>
    <path d="M50.7209 43.1318C50.8624 43.7643 50.463 44.3884 49.8305 44.5299C49.7473 44.5465 49.6641 44.5548 49.5725 44.5548C49.0233 44.5548 48.549 44.172 48.4324 43.6394L47.4505 39.2122C48.1079 38.8794 48.8402 38.6963 49.5809 38.7046H49.739L50.7209 43.1401V43.1318Z" fill="#DAE1EA"/>
    <path d="M49.3315 36.8405L49.7476 38.6962H49.5895C48.8488 38.6962 48.1165 38.871 47.4591 39.2038L47.043 37.3314L46.3107 34.0443L44.6963 26.7794L43.3565 20.7877L43.0069 19.2233V19.1816C43.2566 19.2482 43.5146 19.2732 43.7642 19.2732H45.4203L45.9945 21.8446L47.7087 29.5589L48.5992 33.5617L49.3398 36.8488L49.3315 36.8405Z" fill="#DAE1EA"/>
    <path d="M40.9686 15.1955C41.4096 14.0388 42.5247 13.2732 43.773 13.2732H45.4374V19.2815H43.773C42.117 19.2815 40.7688 17.9334 40.7688 16.2774C40.7688 15.9112 40.8354 15.545 40.9686 15.1955Z" fill="#C63037"/>
    <path d="M50.6796 20.6379V30.0082C50.6796 30.0082 49.3897 30.1081 47.7087 29.5588L45.9945 21.8445C48.4078 20.4631 50.6796 20.6296 50.6796 20.6296V20.6379Z" fill="#C63037"/>
    <path d="M43.3565 20.7878L44.6879 26.7795H44.6463C44.6463 26.7795 43.4314 29.6339 42.0083 32.9543C42.0499 32.8544 42.7906 30.9737 40.7434 30.3579C39.2205 29.9002 38.3967 30.8239 38.0638 31.3648C38.921 29.767 35.1429 28.6602 35.1429 28.6602H40.2192C42.233 28.6602 42.233 26.4716 42.233 26.4716V23.2594C42.233 22.3856 42.0832 21.6949 41.8419 21.1623L43.3481 20.7878H43.3565Z" fill="#767C93"/>
    <path d="M42.2413 23.2594V26.4716C42.2413 26.4716 42.2413 28.6602 40.2275 28.6602H26.7961C26.7961 28.6602 25.5312 28.7435 25.3897 27.4286C25.3814 27.3371 25.3731 27.2539 25.3731 27.1623C25.3731 26.3218 26.1221 25.448 26.1221 25.448C30.8488 19.8974 38.2219 19.3149 38.2219 19.3149C38.2219 19.3149 40.8516 18.9071 41.8669 21.1623C42.0999 21.6949 42.2497 22.3773 42.2497 23.2594H42.2413Z" fill="#C63037"/>
    <path d="M40.7521 30.366C42.7993 30.9818 42.067 32.8542 42.0171 32.9624C40.4442 36.6323 38.6218 40.868 37.9061 42.4575C36.2417 46.1524 32.0642 46.0608 32.0642 46.0608H9.04618L11.2182 41.8001H31.1155C32.2889 41.8417 33.3957 41.2342 33.9865 40.2189C34.1447 39.9443 34.3527 39.5532 34.594 39.0705C34.852 38.5546 35.1349 37.9388 35.4345 37.2897C35.7008 36.7072 35.9838 36.0913 36.2501 35.5005C36.533 34.8764 36.7993 34.2689 37.0323 33.7363C37.5399 32.5796 37.8978 31.7474 37.8978 31.7474C37.9394 31.6475 37.981 31.556 38.0392 31.4644C38.0392 31.4644 38.0642 31.4228 38.0809 31.3979C38.422 30.8403 39.2376 29.9249 40.7605 30.3826L40.7521 30.366Z" fill="#DAE1EA"/>
    <path d="M35.1512 28.6602C35.1512 28.6602 38.9293 29.767 38.0721 31.3648L38.0305 31.4314C37.9806 31.5229 37.9307 31.6144 37.889 31.7143C37.889 31.7143 37.5312 32.5465 37.0236 33.7032L36.8988 33.6033L30.6741 30.9404L30.5659 30.8988L31.4646 28.7018V28.6436H35.1512V28.6602Z" fill="#767C93"/>
    <path d="M36.8904 33.6282L37.0153 33.728C36.7823 34.269 36.516 34.8681 36.233 35.4923L36.1165 35.434L29.9001 32.771L29.8169 32.7378L29.8918 32.5547C29.9917 32.3799 30.0666 32.1968 30.1165 32.0054L30.5659 30.9153L30.6741 30.9569L36.8904 33.6199V33.6282Z" fill="#DAE1EA"/>
    <path d="M35.3422 37.2482L35.4254 37.2898C35.1258 37.9389 34.8429 38.5464 34.5849 39.0707H34.5683L28.3519 36.3994H28.3352L29.0759 34.5686L29.1258 34.5852L35.3422 37.2482Z" fill="#DAE1EA"/>
    <path d="M34.5687 39.0541H34.5853C34.3523 39.5368 34.1443 39.9362 33.9778 40.2108C33.8363 40.4522 33.6616 40.6768 33.4619 40.8766L33.4203 40.835L30.0499 39.387L27.5284 38.3135L27.5534 38.2469L28.3107 36.3828H28.3273L34.552 39.0541H34.5687Z" fill="#DAE1EA"/>
    <path d="M30.5662 30.9151L30.1168 32.0053C30.1834 31.7806 30.2084 31.5476 30.2084 31.3146C30.2084 29.8999 29.0683 28.7515 27.6536 28.7515V28.6599H31.465V28.7182L30.5662 30.9151Z" fill="#767C93"/>
    <path d="M30.2168 31.3148C30.2168 31.5478 30.1835 31.7808 30.1252 32.0055L29.9006 32.5547C29.2182 33.7947 27.6537 34.244 26.4137 33.5617C25.1738 32.8793 24.7244 31.3148 25.4068 30.0748C26.0892 28.8349 27.6537 28.3855 28.8936 29.0679C29.7092 29.5173 30.2168 30.3827 30.2168 31.3148Z" fill="#767C93"/>
    <path d="M29.8998 32.7713L36.1162 35.4343L36.2327 35.4925C35.9664 36.0917 35.6918 36.6992 35.4172 37.2817L35.334 37.2401L29.1176 34.5771L29.0677 34.5605L29.8083 32.738L29.8915 32.7713H29.8998Z" fill="#DAE1EA"/>
    <path d="M27.6535 33.8778C28.5856 33.8778 29.451 33.3702 29.9004 32.5547L29.8255 32.7378L29.0849 34.5602L28.3442 36.3827L27.587 38.2468L27.562 38.3134L30.0752 39.3869L28.985 41.7919H24.6743L23.8089 39.1622L26.8214 38.1802L26.2638 36.2912L25.7062 34.4021L25.1487 32.5131L25.0322 32.122L25.2069 32.072C25.5398 33.1455 26.5384 33.8778 27.6619 33.8778H27.6535Z" fill="#767C93"/>
    <path d="M26.2635 36.2913L26.8211 38.1803L23.8003 39.1623L20.3884 40.2774L19.7725 38.405L26.2053 36.3079L26.2635 36.2913Z" fill="#DAE1EA"/>
    <path d="M25.7062 34.4021L26.2638 36.2911L26.2055 36.3078L19.7728 38.4049L19.1653 36.5408L25.5981 34.4354L25.7062 34.4021Z" fill="#DAE1EA"/>
    <path d="M25.7063 34.402L25.5981 34.4352L19.1654 36.5407L18.5579 34.6683L24.9906 32.5628L25.1404 32.5212L25.7063 34.402Z" fill="#DAE1EA"/>
    <path d="M26.7957 28.66H27.6529V28.7516C26.2382 28.7516 25.0898 29.9 25.0898 31.323C25.0898 31.581 25.1314 31.839 25.2063 32.0803L25.0315 32.1302L24.6487 30.8404C24.7818 29.2592 24.2825 28.4021 23.8831 27.9777H24.0246L25.3228 27.4451H25.3893C25.5308 28.7516 26.7957 28.6684 26.7957 28.6684V28.66Z" fill="#767C93"/>
    <path d="M23.8838 27.9777C24.2833 28.4021 24.7826 29.2676 24.6494 30.8404C24.6494 30.8404 20.3055 33.1705 13.182 31.4645C12.4913 31.2981 11.7673 31.0901 11.0184 30.8404C9.89493 30.4659 10.8852 29.2759 12.0336 27.6199C12.0336 27.6199 14.9795 28.7766 17.9171 28.7766C20.8547 28.7766 23.4345 27.6199 23.4345 27.6199C23.6009 27.7114 23.7507 27.8362 23.8838 27.9777Z" fill="#C63037"/>
    <path d="M18.3246 34.6685V36.7073H2.23856V34.4022C6.77393 30.2746 13.681 30.1747 18.3329 34.1775V34.6768L18.3246 34.6685Z" fill="#C63037"/>
    <path d="M18.3246 34.1693C16.8433 32.8877 15.0624 31.989 13.1567 31.5479L13.1817 31.4647C20.3051 33.179 24.6491 30.8406 24.6491 30.8406L25.0319 32.1305L25.1484 32.5216L24.9903 32.5715L18.5576 34.6686H18.3246V34.1693Z" fill="#767C93"/>
    <g opacity="0.3">
    <path d="M43.3565 20.7878L44.6879 26.7795H44.6463C44.6463 26.7795 43.4314 29.6339 42.0083 32.9543C42.0499 32.8544 42.7906 30.9737 40.7434 30.3579C39.2205 29.9002 38.3967 30.8239 38.0638 31.3648C38.921 29.767 35.1429 28.6602 35.1429 28.6602H40.2192C42.233 28.6602 42.233 26.4716 42.233 26.4716V23.2594C42.233 22.3856 42.0832 21.6949 41.8419 21.1623L43.3481 20.7878H43.3565Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M30.5662 30.9151L30.1168 32.0053C30.1834 31.7806 30.2084 31.5476 30.2084 31.3146C30.2084 29.8999 29.0683 28.7515 27.6536 28.7515V28.6599H31.465V28.7182L30.5662 30.9151Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M27.6535 33.8778C28.5856 33.8778 29.451 33.3702 29.9004 32.5547L29.8255 32.7378L29.0849 34.5602L28.3442 36.3827L27.587 38.2468L27.562 38.3134L30.0752 39.3869L28.985 41.7919H24.6743L23.8089 39.1622L26.8214 38.1802L26.2638 36.2912L25.7062 34.4021L25.1487 32.5131L25.0322 32.122L25.2069 32.072C25.5398 33.1455 26.5384 33.8778 27.6619 33.8778H27.6535Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M18.3246 34.1693C16.8433 32.8877 15.0624 31.989 13.1567 31.5479L13.1817 31.4647C20.3051 33.179 24.6491 30.8406 24.6491 30.8406L25.0319 32.1305L25.1484 32.5216L24.9903 32.5715L18.5576 34.6686H18.3246V34.1693Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M55.5062 37.5311C55.5062 37.5311 53.5922 35.2593 50.1387 35.1012C46.6852 34.9431 44.6879 36.5741 44.6879 36.5741L43.8058 35.2676C43.8058 35.2676 45.4452 33.3453 50.3051 33.5617C54.4411 33.7531 56.6047 36.4327 56.6047 36.4327L55.5146 37.5228L55.5062 37.5311Z" fill="white"/>
    </g>
    <g opacity="0.2">
    <path d="M18.3911 35.1011H2.23856V36.7238H18.3911V35.1011Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M19.9986 33.993L18.4427 34.4539L20.1799 40.3185L21.7358 39.8576L19.9986 33.993Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M17.8669 46.0525H9.04578V47.6752H17.8669V46.0525Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M17.1016 38.2797H3.74519L4.50247 36.657H16.1696L17.1016 38.2797Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M23.3195 33.0071L21.7635 33.468L23.5007 39.3326L25.0566 38.8718L23.3195 33.0071Z" fill="white"/>
    </g>
    <g opacity="0.3">
    <path d="M37.1719 29.596L32.3377 40.2876L33.8164 40.9562L38.6506 30.2645L37.1719 29.596Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M25.794 28.4256L25.7657 30.0481L37.4976 30.2529L37.5259 28.6304L25.794 28.4256Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M43.2538 19.293L43.2255 20.9155L45.7383 20.9594L45.7666 19.3369L43.2538 19.293Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M46.0195 34.0504L43.9805 34.668L45.0106 38.0688L47.0495 37.4513L46.0195 34.0504Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M28.8428 39.1121L30.3241 39.7778L33.7361 32.2133C33.2284 31.9887 32.7458 31.7473 32.2548 31.5476L28.8428 39.1038V39.1121Z" fill="white"/>
    </g>
    <g opacity="0.2">
    <path d="M33.678 28.4187L32.255 31.556C32.7376 31.7557 33.2286 31.9971 33.7362 32.2218L35.1509 29.0844L33.6697 28.4187H33.678Z" fill="white"/>
    </g>
    <g opacity="0.2">
    <path d="M2.23856 34.7765C2.23856 34.7765 4.5853 32.9041 10.2857 32.9041C15.9861 32.9041 18.1664 34.7765 18.1664 34.7765C18.1664 34.7765 16.3773 31.2314 10.4189 31.2314C4.46048 31.2314 2.24689 34.7765 2.24689 34.7765H2.23856Z" fill="white"/>
    </g>
    <g opacity="0.3">
    <path d="M10.4854 30.8738C10.4854 30.8738 12.3911 32.122 17.8502 32.122C23.3093 32.122 25.19 30.5076 25.19 30.5076L24.1332 29.2344C24.1332 29.2344 21.2788 30.5242 17.9418 30.5242C14.6047 30.5242 11.3343 29.2344 11.3343 29.2344L10.4854 30.8654V30.8738Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M25.0821 31.3147C25.0821 31.3147 25.8227 32.5463 27.6452 32.5463C29.4677 32.5463 30.2083 31.3147 30.2083 31.3147C30.2083 31.3147 30.1001 33.8778 27.6452 33.8778C25.1903 33.8778 25.0821 31.3147 25.0821 31.3147Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M26.114 25.448C26.114 25.448 25.3234 27.1706 27.5454 27.1706H40.078C40.078 27.1706 42.1501 27.3038 42.1834 25.448C42.1834 25.448 43.0904 28.7517 39.5787 28.7517H26.7964C26.7964 28.7517 24.225 28.6852 26.114 25.448Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M50.6796 30.0083L47.7087 29.5589L47.1678 27.0957C48.3329 27.2954 49.5062 27.3786 50.6796 27.337C50.6796 27.337 52.8766 27.3037 53.3759 25.7559C53.3759 25.7559 53.6422 29.1844 50.6796 30.0083Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M40.7686 16.2693C40.7686 16.2693 41.5592 17.6091 44.2971 17.6091H48.1417V19.2235H44.0058C44.0058 19.2235 40.727 19.0737 40.777 16.2693H40.7686Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M31.0985 43.8058H10.4854V42.6324H31.0735C31.1983 42.6324 34.1692 42.6657 35.3509 39.828C36.7989 36.3412 39.7531 30.0832 39.7864 30.0166L40.8433 30.5159C40.81 30.5742 37.8641 36.8155 36.4327 40.2774C35.018 43.681 31.5895 43.8058 31.0985 43.8058Z" fill="white"/>
    </g>
    <g opacity="0.5">
    <path d="M17.9418 41.7917L15.7698 46.0525H21.1123L23.0763 41.7917H17.9418Z" fill="white"/>
    </g>
    <g opacity="0.25">
    <path d="M9.04578 46.0526H32.0721C32.0721 46.0526 36.3911 45.7613 37.6976 42.8653C39.0042 39.9694 42.1248 32.6129 42.1248 32.6129C42.1248 32.6129 42.466 31.14 41.3759 30.6323C41.3759 30.6323 41.0763 31.3646 40.7268 32.1219C40.3773 32.8792 36.4827 41.7835 36.4827 41.7835C36.4827 41.7835 35.509 44.2551 31.889 44.3633C27.9778 44.4714 10.1859 44.2634 10.1859 44.2634L9.04578 46.0526Z" fill="#494456"/>
    </g>
    <g opacity="0.5">
    <path d="M37.6974 24.8152H37.5808C37.2563 24.8152 36.9983 24.5489 36.9983 24.2327C36.9983 23.9164 37.2646 23.6501 37.5808 23.6501H37.6974C38.0219 23.6501 38.2799 23.9164 38.2799 24.2327C38.2799 24.5489 38.0136 24.8152 37.6974 24.8152ZM35.2341 24.8152H35.1176C34.7931 24.8152 34.5351 24.5489 34.5351 24.2327C34.5351 23.9164 34.8014 23.6501 35.1176 23.6501H35.2341C35.5587 23.6501 35.8166 23.9164 35.8166 24.2327C35.8166 24.5489 35.5503 24.8152 35.2341 24.8152ZM32.7792 24.8152H32.6627C32.3381 24.8152 32.0802 24.5489 32.0802 24.2327C32.0802 23.9164 32.3465 23.6501 32.6627 23.6501H32.7792C33.1037 23.6501 33.3617 23.9164 33.3617 24.2327C33.3617 24.5489 33.0954 24.8152 32.7792 24.8152Z" fill="#494456"/>
    </g>
    <g opacity="0.3">
    <path d="M40.7686 16.269C40.7686 16.269 41.451 14.8376 43.8227 14.8376H48.1417V16.2773H44.089C44.089 16.2773 42.0502 16.2357 40.7686 16.2773" fill="white"/>
    </g>
    <g opacity="0.3">
    <path d="M46.5274 23.301C46.5274 23.301 48.2167 22.4605 50.68 22.3689C53.1432 22.2774 53.4345 24.7656 53.4345 24.7656C53.4345 24.7656 51.8201 24.5908 50.68 24.5908H46.5274V24.233" fill="white"/>
    </g>
    <g opacity="0.2">
    <path d="M25.3731 27.1706C25.3731 27.1706 28.6103 23.2011 37.0319 21.2455C37.0319 21.2455 42.2497 20.0554 42.2497 23.2676C42.2497 23.2676 42.699 18.1081 36.8405 19.631C30.982 21.1539 27.0707 23.0596 25.3731 27.1789V27.1706Z" fill="white"/>
    </g>
    <path d="M39.1036 10.6351C39.1036 10.6351 41.8581 9.94442 41.8581 7.51446C41.8581 5.08451 39.1036 4.3938 39.1036 4.3938V10.6351Z" fill="#DAE1EA"/>
    <g opacity="0.3">
    <path d="M41.8585 7.98047C41.8585 7.98047 40.9182 8.82097 39.1456 8.82097V10.6434C39.1456 10.6434 41.4924 10.2939 41.8585 7.98879V7.98047Z" fill="#494456"/>
    </g>
    <g opacity="0.7">
    <path d="M41.8585 7.98039C41.8585 7.98039 40.9182 7.32297 39.1456 7.32297V5.8833C39.1456 5.8833 41.4924 6.15792 41.8585 7.98039Z" fill="white"/>
    </g>
    <path d="M37.3814 17.2508H33.7448C32.8128 17.2508 32.0638 16.4935 32.0638 15.5698V14.8292C32.0638 13.8972 32.8211 13.1482 33.7448 13.1482H37.3814C38.3135 13.1482 39.0624 13.9055 39.0624 14.8292V15.5698C39.0624 16.5019 38.3051 17.2508 37.3814 17.2508ZM33.7448 14.3132C33.4619 14.3132 33.2289 14.5463 33.2289 14.8292V15.5698C33.2289 15.8528 33.4619 16.0858 33.7448 16.0858H37.3814C37.6644 16.0858 37.8974 15.8528 37.8974 15.5698V14.8292C37.8974 14.5463 37.6644 14.3132 37.3814 14.3132H33.7448Z" fill="#494456"/>
    <path d="M49.5814 52.4102C44.5967 52.4102 40.5606 48.3742 40.5606 43.3894C40.5606 40.5933 41.8588 37.9553 44.0641 36.2493C44.3221 36.0496 44.6882 36.0996 44.888 36.3575C45.0877 36.6155 45.0378 36.9817 44.7798 37.1814C41.3512 39.8277 40.7188 44.7625 43.3651 48.1911C46.0114 51.6197 50.9462 52.2521 54.3748 49.6058C57.8034 46.9595 58.4358 42.0247 55.7895 38.5961C55.5898 38.3381 55.3734 38.0968 55.1487 37.8638C54.8991 37.6557 54.8741 37.2812 55.0821 37.0399C55.2902 36.7903 55.6647 36.7653 55.906 36.9733C55.931 36.9983 55.9559 37.015 55.9726 37.0482C59.4844 40.585 59.4594 46.2937 55.9226 49.8055C54.2333 51.4865 51.9448 52.4269 49.5648 52.4269L49.5814 52.4102Z" fill="#494456"/>
    <path d="M49.5808 48.6575C46.6682 48.6575 44.3048 46.3025 44.3048 43.3899C44.3048 41.4342 45.3866 39.6284 47.1175 38.7213C47.4005 38.5632 47.7583 38.6631 47.9164 38.946C48.0745 39.229 47.9747 39.5868 47.6917 39.7449C47.6917 39.7449 47.6751 39.7449 47.6668 39.7532C45.6612 40.8101 44.9039 43.2983 45.9608 45.2955C47.0177 47.3011 49.5059 48.0584 51.5031 47.0015C53.5087 45.9446 54.2659 43.4564 53.2091 41.4592C52.5433 40.1943 51.2618 39.3704 49.8388 39.2872C49.5142 39.2706 49.2729 38.9876 49.2895 38.6631C49.3062 38.3385 49.5891 38.0972 49.9137 38.1138C52.818 38.2886 55.0399 40.7851 54.8651 43.6894C54.6987 46.4772 52.3852 48.6575 49.5808 48.6492V48.6575Z" fill="#494456"/>
    <path d="M18.6912 42.2996C18.4082 42.2996 18.1669 42.0998 18.117 41.8252C17.7675 39.9944 16.8021 38.3467 15.3708 37.1567C15.1211 36.9487 15.0962 36.5742 15.3042 36.3329C15.5123 36.0832 15.8867 36.0582 16.1281 36.2663C17.7591 37.6311 18.8659 39.5118 19.2654 41.6005C19.3236 41.9168 19.1156 42.2247 18.7994 42.2829C18.7661 42.2913 18.7328 42.2996 18.6912 42.2996Z" fill="#494456"/>
    <path d="M10.4105 52.4105C5.4258 52.4105 1.38974 48.3744 1.38974 43.3897C1.38974 40.5936 2.68794 37.9556 4.89321 36.2496C5.15118 36.0499 5.51734 36.0998 5.71706 36.3578C5.91679 36.6157 5.86685 36.9819 5.60888 37.1816C2.18031 39.8363 1.54785 44.7628 4.2025 48.1913C6.85715 51.6199 11.7836 52.2524 15.2122 49.5977C16.4189 48.6657 17.3343 47.3924 17.8336 45.9445C17.9418 45.6366 18.2746 45.4784 18.5742 45.5783C18.8821 45.6865 19.0402 46.0194 18.9404 46.3189C17.6838 49.9639 14.2552 52.4022 10.4105 52.4022V52.4105Z" fill="#494456"/>
    <path d="M10.4109 48.6572C7.49824 48.6572 5.13486 46.3022 5.13486 43.3895C5.13486 40.4769 7.48992 38.1135 10.4025 38.1135C12.6078 38.1135 14.5801 39.4866 15.3457 41.5504C15.4622 41.85 15.3041 42.1912 14.9962 42.3077C14.6966 42.4242 14.3554 42.2661 14.2389 41.9582C13.4483 39.8361 11.0849 38.7543 8.96287 39.5449C6.84082 40.3354 5.75899 42.6988 6.54956 44.8209C7.34013 46.9429 9.70351 48.0248 11.8256 47.2342C12.6161 46.9429 13.2902 46.4103 13.7729 45.7196C13.9476 45.445 14.3138 45.3701 14.5801 45.5449C14.8547 45.7196 14.9296 46.0858 14.7548 46.3521C14.7548 46.3604 14.7382 46.3771 14.7299 46.3854C13.7479 47.8084 12.1251 48.6572 10.3942 48.6572H10.4109Z" fill="#494456"/>
    <path d="M18.3249 37.2897H2.23887C1.91432 37.2897 1.65634 37.0234 1.65634 36.7072V34.402C1.65634 34.2356 1.72292 34.0775 1.84774 33.9693C6.6078 29.642 13.8477 29.5421 18.7243 33.7363C18.8575 33.8445 18.924 34.0109 18.9323 34.1773V36.7155C18.9323 37.04 18.6661 37.298 18.3415 37.298L18.3249 37.2897ZM2.82139 36.1246H17.7423V34.4436C13.3817 30.8653 7.07382 30.9568 2.82139 34.66V36.1163V36.1246Z" fill="#494456"/>
    <path d="M45.7032 38.6794C45.5201 38.6794 45.3454 38.5962 45.2372 38.4464L43.2067 35.8001C43.1068 35.6752 43.0652 35.5171 43.0902 35.359C43.1151 35.2009 43.1983 35.0594 43.3232 34.9679C44.147 34.3521 45.0624 33.8611 46.0277 33.5116C46.3357 33.4034 46.6685 33.5698 46.7684 33.8777C46.8682 34.1773 46.7185 34.5019 46.4189 34.6184C45.7448 34.8597 45.104 35.1759 44.5049 35.5671L45.828 37.298C46.1359 37.1066 46.4522 36.9485 46.7767 36.807C47.0763 36.6822 47.4175 36.8236 47.5423 37.1149C47.6671 37.4062 47.5257 37.7557 47.2344 37.8805C46.81 38.0636 46.4105 38.2883 46.0444 38.5629C45.9445 38.6378 45.8197 38.6794 45.6949 38.6794H45.7032Z" fill="#494456"/>
    <path d="M54.225 39.3286C54.0669 39.3286 53.9171 39.2704 53.8089 39.1539C52.6522 37.9888 51.0627 37.3564 49.4233 37.4063C49.0988 37.4146 48.8325 37.1566 48.8242 36.8321C48.8158 36.5075 49.0738 36.2413 49.3984 36.2329C51.1543 36.1747 52.8769 36.7822 54.2167 37.9223L55.7562 36.3827C53.8672 34.7184 51.3789 33.8862 48.8658 34.0859C48.5412 34.1109 48.2583 33.8695 48.2333 33.545C48.2083 33.2205 48.4497 32.9375 48.7742 32.9125C51.8366 32.6712 54.8408 33.7863 57.0128 35.9583C57.2375 36.183 57.2375 36.5575 57.0128 36.7905L54.6494 39.1539C54.5412 39.2621 54.3914 39.3203 54.2333 39.3203L54.225 39.3286Z" fill="#494456"/>
    <path d="M49.5807 45.1453C48.7568 45.1453 48.0495 44.5711 47.8664 43.7722L42.4406 19.3561C42.374 19.0399 42.5738 18.7237 42.89 18.6571C43.2062 18.5905 43.5224 18.7902 43.589 19.1065L49.0148 43.5225C49.0897 43.8388 49.3976 44.0385 49.7139 43.9719C50.0301 43.9053 50.2298 43.5891 50.1549 43.2729L44.8456 19.4227C44.7707 19.1065 44.9621 18.7902 45.2783 18.7153C45.5946 18.6404 45.9108 18.8318 45.9857 19.1481C45.9857 19.1481 45.9857 19.1647 45.9857 19.173L51.2867 43.0232C51.4947 43.9719 50.9039 44.9123 49.9552 45.1203C49.8304 45.1453 49.6972 45.1619 49.5724 45.1619L49.5807 45.1453Z" fill="#494456"/>
    <path d="M26.7541 29.2509C26.2464 29.2509 25.7638 29.0678 25.3893 28.7349C25.1147 28.477 24.7901 27.9943 24.7901 27.1704C24.7901 26.1469 25.5807 25.1815 25.6722 25.0734C30.424 19.4894 37.639 18.7821 38.1466 18.7405C38.3796 18.7072 40.1272 18.5158 41.442 19.6476C42.3657 20.4381 42.8317 21.6531 42.8317 23.2675V26.4797C42.8234 26.9458 42.7152 27.3951 42.5238 27.8195C42.0994 28.7433 41.2839 29.2509 40.227 29.2509H26.7541ZM26.5627 25.8306C26.3962 26.0304 25.9552 26.6628 25.9552 27.1704C25.9552 27.5033 26.0301 27.7363 26.1882 27.8861C26.3463 28.0193 26.5543 28.0858 26.7624 28.0858H40.2353C41.6251 28.0858 41.6667 26.6462 41.6667 26.4797V23.2675C41.6667 22.0109 41.3338 21.0956 40.6847 20.538C39.7277 19.7141 38.3213 19.9055 38.3047 19.9055H38.2631C38.1882 19.9055 31.0731 20.5463 26.571 25.8306H26.5627Z" fill="#494456"/>
    <path d="M18.2991 32.688C16.2187 32.688 13.7055 32.3634 10.8262 31.3981C10.6015 31.3232 10.4517 31.1235 10.4267 30.8905C10.4267 30.8156 10.2853 29.093 11.5419 27.2788C11.7 27.0541 11.9829 26.9709 12.2409 27.0708C12.2825 27.0874 15.1285 28.1859 17.9163 28.1859C20.7041 28.1859 23.1757 27.0958 23.1923 27.0874C23.3505 27.0209 23.5252 27.0209 23.6833 27.0874C23.7582 27.1207 25.4725 27.9779 25.2229 30.8905C25.2062 31.0902 25.0897 31.265 24.915 31.3565C24.7652 31.4314 22.3685 32.6797 18.2908 32.6797L18.2991 32.688ZM11.6084 30.4161C15.6195 31.6811 18.8234 31.6145 20.829 31.3232C21.9441 31.1734 23.0342 30.8822 24.0745 30.4578C24.1077 29.1596 23.6334 28.5354 23.3588 28.2858C22.5682 28.5937 20.3796 29.3593 17.9163 29.3593C15.4531 29.3593 13.2312 28.6603 12.2742 28.3274C11.8997 28.9682 11.675 29.6838 11.6084 30.4161Z" fill="#494456"/>
    <path d="M32.1051 46.6349H9.04551C8.72096 46.6349 8.46298 46.377 8.46298 46.0524C8.46298 45.9609 8.48795 45.8693 8.52956 45.7861L10.7015 41.5254C10.8014 41.3257 11.0011 41.2092 11.2258 41.2092H31.1648C32.1135 41.2258 33.0039 40.7348 33.4866 39.911C34.4685 38.2466 37.3312 31.5725 37.3562 31.506C37.4644 31.248 38.463 29.0677 40.9262 29.8083C41.5587 29.9997 42.033 30.3076 42.3493 30.732C43.3562 28.3853 44.1051 26.6128 44.1301 26.5711C44.2549 26.2716 44.6045 26.1384 44.8957 26.2632C45.1953 26.3881 45.3284 26.7376 45.2036 27.0288C45.1787 27.0871 43.972 29.9082 42.5739 33.187C42.5739 33.187 42.5739 33.1953 42.5739 33.2036C41.0011 36.8901 39.1787 41.1259 38.463 42.7071C36.7404 46.5268 32.6377 46.6516 32.1218 46.6516L32.1051 46.6349ZM10.0025 45.4616H32.1135C32.4879 45.4616 35.9498 45.37 37.3812 42.2078C38.0802 40.6349 39.911 36.3992 41.4838 32.7209C41.5004 32.671 41.7334 32.0635 41.5004 31.5725C41.359 31.2729 41.0511 31.0566 40.5934 30.9151C39.0289 30.4491 38.4713 31.8638 38.4463 31.9304C38.4463 31.9304 38.4463 31.947 38.438 31.9553C38.3215 32.23 35.5254 38.7709 34.4935 40.5018C33.3617 42.4158 31.3728 42.3825 31.0982 42.3742H11.5753L9.99419 45.4616H10.0025Z" fill="#494456"/>
    <path d="M27.6533 34.4688C25.914 34.4688 24.4993 33.0624 24.4993 31.3148C24.4993 29.5673 25.9057 28.1609 27.6533 28.1609C29.4008 28.1609 30.8072 29.5673 30.8072 31.3148C30.8072 33.0541 29.3925 34.4605 27.6533 34.4688ZM27.6533 29.3426C26.5631 29.3426 25.6727 30.2247 25.6727 31.3232C25.6727 32.4216 26.5548 33.3037 27.6533 33.3037C28.7517 33.3037 29.6338 32.4216 29.6338 31.3232C29.6338 30.233 28.7517 29.3426 27.6533 29.3426Z" fill="#494456"/>
    <path d="M27.6529 31.9052C27.3284 31.9052 27.0704 31.639 27.0704 31.3227V31.2395C27.0704 30.915 27.3367 30.657 27.6529 30.657C27.9692 30.657 28.2355 30.9233 28.2355 31.2395V31.3227C28.2355 31.6473 27.9692 31.9052 27.6529 31.9052Z" fill="#494456"/>
    <path d="M24.0245 28.5606C23.7 28.5606 23.442 28.3026 23.4337 27.978C23.4337 27.7367 23.5752 27.5203 23.7998 27.4371L25.098 26.9045C25.3976 26.7797 25.7388 26.9212 25.8636 27.2207C25.9885 27.5203 25.847 27.8615 25.5474 27.9863L24.2492 28.5189C24.1743 28.5522 24.0994 28.5606 24.0245 28.5606Z" fill="#494456"/>
    <path d="M41.8502 21.753C41.5257 21.753 41.2677 21.4867 41.2677 21.1622C41.2677 20.8959 41.4508 20.6629 41.7087 20.5963L43.215 20.2218C43.5312 20.1469 43.8474 20.3383 43.9223 20.6462C43.9972 20.9624 43.8058 21.2787 43.4979 21.3536L41.9917 21.7281C41.9418 21.7364 41.9001 21.7447 41.8502 21.7447V21.753Z" fill="#494456"/>
    <path d="M48.2667 19.8724L43.773 19.8557C41.7924 19.8225 40.2112 18.1831 40.2528 16.2025C40.2861 14.2718 41.8423 12.7157 43.773 12.6824H48.2667C48.5913 12.6824 48.8492 12.9487 48.8492 13.2649V19.2899C48.8492 19.6144 48.5829 19.8724 48.2667 19.8724ZM43.773 13.8557C42.4415 13.889 41.3763 14.9875 41.4096 16.3273C41.4345 17.6172 42.4831 18.6657 43.773 18.6907L47.6842 18.7073V13.8557H43.773Z" fill="#494456"/>
    <path d="M45.4369 19.8641C45.1124 19.8641 44.8544 19.5978 44.8544 19.2815V13.2649C44.8544 12.9403 45.1207 12.6824 45.4369 12.6824C45.7531 12.6824 46.0194 12.9487 46.0194 13.2649V19.2815C46.0194 19.6061 45.7531 19.8641 45.4369 19.8641Z" fill="#494456"/>
    <path d="M50.6793 30.5991C50.3547 30.5991 50.0968 30.3328 50.0968 30.0166V20.6462C50.0968 20.3217 50.3631 20.0637 50.6793 20.0637C50.7459 20.0637 50.8208 20.0804 50.8873 20.1053C51.0122 20.1553 54.0246 21.3286 54.0246 25.3397C54.0246 29.3508 51.0205 30.5242 50.8873 30.5741C50.8208 30.5991 50.7542 30.6074 50.6793 30.6157V30.5991ZM51.2701 21.6781V28.9763C51.9608 28.4188 52.8513 27.312 52.8513 25.3231C52.8513 23.3342 51.9608 22.2357 51.2701 21.6781Z" fill="#494456"/>
    <path d="M39.104 11.2177C38.7795 11.2177 38.5215 10.9514 38.5215 10.6351V4.3938C38.5215 4.06925 38.7878 3.81128 39.104 3.81128C39.154 3.81128 39.1956 3.81128 39.2455 3.82792C40.3523 4.10254 42.4411 5.2343 42.4411 7.51447C42.4411 9.79464 40.3523 10.9264 39.2455 11.201C39.1956 11.2093 39.154 11.2177 39.104 11.2177ZM39.6865 5.25095V9.77799C40.3606 9.4368 41.2677 8.74609 41.2677 7.51447C41.2677 6.7239 40.9015 6.05816 40.1692 5.54221C40.0111 5.43403 39.853 5.33416 39.6865 5.25095Z" fill="#494456"/>
    <path d="M45.9947 22.4355C45.6702 22.4355 45.4122 22.1692 45.4122 21.853C45.4122 21.645 45.5287 21.4452 45.7035 21.3454C48.2499 19.8891 50.6216 20.0472 50.7215 20.0555C51.046 20.0805 51.2874 20.3634 51.2624 20.688C51.2374 21.0125 50.9545 21.2538 50.63 21.2289C49.1071 21.2122 47.6008 21.6034 46.2777 22.3606C46.1861 22.4106 46.0946 22.4355 45.9864 22.4355H45.9947Z" fill="#494456"/>
    <path d="M50.4303 30.607C49.44 30.5904 48.4663 30.4323 47.526 30.1161C47.2181 30.0079 47.0599 29.675 47.1598 29.3754C47.2597 29.0758 47.5842 28.9094 47.8921 29.0093C48.7742 29.3088 49.7063 29.4503 50.6383 29.4337C50.9629 29.4087 51.2458 29.65 51.2708 29.9746C51.2957 30.2991 51.0544 30.5821 50.7298 30.607C50.7132 30.607 50.6133 30.607 50.4386 30.607H50.4303Z" fill="#494456"/>
    <path d="M18.5492 35.251C18.2247 35.251 17.9667 34.9847 17.9667 34.6601C17.9667 34.4105 18.1332 34.1858 18.3662 34.1026L24.7989 32.0055C25.0985 31.8973 25.4397 32.0471 25.5479 32.355C25.656 32.6629 25.5062 32.9958 25.1983 33.1039C25.1817 33.1039 25.1734 33.1123 25.1567 33.1206L18.724 35.2177C18.6657 35.2343 18.5992 35.251 18.5409 35.251H18.5492Z" fill="#494456"/>
    <path d="M19.1646 37.123C18.8401 37.123 18.5821 36.8567 18.5821 36.5322C18.5821 36.2825 18.7486 36.0578 18.9816 35.9746L25.4143 33.8775C25.7222 33.7693 26.0551 33.9274 26.1633 34.2354C26.2714 34.5433 26.1133 34.8761 25.8054 34.9843C25.7971 34.9843 25.7888 34.9843 25.7721 34.9926L19.3394 37.0897C19.2812 37.1064 19.2229 37.123 19.1563 37.123H19.1646Z" fill="#494456"/>
    <path d="M19.7721 38.9959C19.4475 38.9959 19.1895 38.7296 19.1895 38.4051C19.1895 38.1554 19.356 37.9307 19.589 37.8475L26.0217 35.7504C26.3296 35.6505 26.6625 35.817 26.7624 36.1249C26.8622 36.4328 26.6958 36.7657 26.3879 36.8655L19.9551 38.9626C19.8969 38.9793 19.8386 38.9959 19.7721 38.9959Z" fill="#494456"/>
    <path d="M20.3884 40.8599C20.0638 40.8599 19.8058 40.5936 19.8058 40.2691C19.8058 40.0194 19.9723 39.7947 20.2053 39.7115L26.638 37.6061C26.9459 37.5063 27.2788 37.6727 27.3786 37.9806C27.4785 38.2885 27.3121 38.6214 27.0042 38.7212L20.5714 40.8183C20.5132 40.835 20.4549 40.8516 20.3967 40.8516L20.3884 40.8599Z" fill="#494456"/>
    <path d="M36.8904 34.2191C36.8072 34.2191 36.7323 34.2025 36.6574 34.1692L30.4327 31.5062C30.1332 31.3814 30 31.0319 30.1248 30.7323C30.2497 30.4327 30.5992 30.2995 30.8988 30.4244L37.1234 33.0873C37.423 33.2122 37.5562 33.5617 37.4314 33.8613C37.3398 34.0776 37.1235 34.2191 36.8904 34.2108V34.2191Z" fill="#494456"/>
    <path d="M36.117 36.0249C36.0337 36.0249 35.9588 36.0082 35.8839 35.975L29.6593 33.312C29.3597 33.1788 29.2348 32.8376 29.3597 32.5381C29.4928 32.2468 29.8257 32.1136 30.1169 32.2385L36.3333 34.9014C36.6329 35.0263 36.766 35.3758 36.6412 35.667C36.5497 35.8834 36.3333 36.0249 36.1003 36.0249H36.117Z" fill="#494456"/>
    <path d="M35.3423 37.8306C35.2591 37.8306 35.1842 37.814 35.1093 37.7807L28.893 35.1177C28.5934 34.9929 28.4519 34.6517 28.5767 34.3521C28.7016 34.0525 29.0427 33.911 29.3423 34.0359C29.3423 34.0359 29.3507 34.0359 29.359 34.0359L35.5753 36.6988C35.8749 36.8237 36.0081 37.1732 35.8832 37.4644C35.7917 37.6808 35.5753 37.8223 35.3423 37.8223V37.8306Z" fill="#494456"/>
    <path d="M34.5684 39.6367C34.4852 39.6367 34.4103 39.6201 34.3354 39.5868L28.1273 36.9238C27.8277 36.799 27.6863 36.4578 27.8111 36.1582C27.9359 35.8587 28.2771 35.7172 28.5767 35.842C28.5767 35.842 28.585 35.842 28.5934 35.842L34.8097 38.505C35.1093 38.6298 35.2425 38.9793 35.1176 39.2789C35.0261 39.4953 34.8097 39.6367 34.5767 39.6284L34.5684 39.6367Z" fill="#494456"/>
    <path d="M33.4282 41.4173C33.345 41.4173 33.2701 41.4006 33.1952 41.3673L27.1869 38.7959C26.8873 38.6711 26.7458 38.3299 26.8707 38.0303C26.9955 37.7307 27.3367 37.5893 27.6363 37.7141C27.6363 37.7141 27.6446 37.7141 27.6529 37.7141L33.6612 40.2855C33.9608 40.4103 34.094 40.7599 33.9691 41.0511C33.8776 41.2675 33.6612 41.409 33.4282 41.409V41.4173Z" fill="#494456"/>
    <path d="M26.8208 38.7628C26.5628 38.7628 26.3298 38.5964 26.2549 38.3467L24.083 31.0153C23.9914 30.7073 24.1662 30.3828 24.4824 30.2829C24.7986 30.1831 25.1149 30.3662 25.2147 30.6824L27.3867 38.0139C27.4782 38.3218 27.3035 38.6463 26.9873 38.7462C26.9373 38.7628 26.8791 38.7711 26.8208 38.7711V38.7628Z" fill="#494456"/>
    <path d="M27.5705 38.8294C27.4956 38.8294 27.4207 38.8127 27.3458 38.7878C27.0462 38.6629 26.9047 38.3217 27.0213 38.0222L30.9075 28.5021C31.024 28.2025 31.3652 28.0527 31.6648 28.1692C31.9644 28.2857 32.1142 28.6269 31.9977 28.9265C31.9977 28.9265 31.9977 28.9431 31.9894 28.9514L28.1031 38.4715C28.0115 38.6962 27.7952 38.8377 27.5622 38.8377L27.5705 38.8294Z" fill="#494456"/>
    <path d="M20.3884 40.8597C20.1387 40.8597 19.914 40.6933 19.8308 40.452L18 34.8431C17.9001 34.5352 18.0666 34.2023 18.3745 34.1025C18.6824 34.0026 19.0153 34.169 19.1151 34.4769L20.9459 40.0858C21.0458 40.3937 20.8793 40.7266 20.5714 40.8265C20.5132 40.8431 20.4549 40.8597 20.3884 40.8597Z" fill="#494456"/>
    <path d="M38.0226 32.0387C37.6981 32.0387 37.4401 31.7724 37.4401 31.4561C37.4401 31.348 37.4734 31.2398 37.5316 31.1482C37.6148 31.0484 37.6398 30.9069 37.5899 30.7821C37.3569 30.1746 35.9338 29.5172 34.9935 29.2342C34.6856 29.1427 34.5025 28.8181 34.594 28.5102C34.6856 28.2023 35.0101 28.0192 35.318 28.1108C35.784 28.2523 38.1724 29.0095 38.68 30.366C38.8631 30.832 38.7965 31.3563 38.5219 31.7724C38.4137 31.9471 38.2306 32.047 38.0226 32.047V32.0387Z" fill="#494456"/>
    <path d="M24.6661 42.3745C24.4165 42.3745 24.1918 42.208 24.1086 41.975L23.2431 39.3454C23.1432 39.0374 23.318 38.7046 23.6259 38.613C23.9255 38.5215 24.25 38.6796 24.3582 38.9792L25.2237 41.6089C25.3235 41.9168 25.1571 42.2497 24.8492 42.3495C24.7909 42.3662 24.7244 42.3828 24.6661 42.3828V42.3745Z" fill="#494456"/>
    <path d="M28.9681 42.3744C28.8849 42.3744 28.8017 42.3577 28.7268 42.3245C28.4355 42.1913 28.3024 41.8418 28.4355 41.5505L29.5257 39.1372C29.6588 38.846 30.0083 38.7128 30.2996 38.846C30.5908 38.9791 30.724 39.3286 30.5908 39.6199L29.5007 42.0249C29.4092 42.2329 29.2011 42.3744 28.9681 42.3744Z" fill="#494456"/>
    <path d="M40.8766 15.7781H38.4799C38.1553 15.7781 37.8974 15.5118 37.8974 15.1956C37.8974 14.8793 38.1637 14.613 38.4799 14.613H40.8766C41.2011 14.613 41.4591 14.8793 41.4591 15.1956C41.4591 15.5118 41.1928 15.7781 40.8766 15.7781Z" fill="#494456"/>
    <path d="M9.32085 46.0524C9.24596 46.0524 9.16274 46.0358 9.09617 46.0025C7.68147 45.4033 7.01572 43.7723 7.61489 42.3576C8.21406 40.9428 9.84513 40.2771 11.2598 40.8763C11.4845 40.9678 11.7009 41.0926 11.8923 41.2508C12.1503 41.4505 12.1919 41.8166 11.9921 42.0746C11.7924 42.3326 11.4263 42.3742 11.1683 42.1745C10.8853 41.9498 10.5358 41.8333 10.178 41.8416C9.28757 41.8416 8.56357 42.5656 8.56357 43.456C8.56357 44.1051 8.9547 44.6876 9.55386 44.9456C9.85345 45.0704 9.99492 45.4116 9.87009 45.7112C9.77855 45.9276 9.56219 46.0774 9.32085 46.0691V46.0524Z" fill="#494456"/>
    <path d="M43.7725 13.8557C43.5229 13.8557 43.2982 13.6976 43.215 13.4563L41.2927 7.69759C41.1928 7.38968 41.3592 7.05681 41.6671 6.95695C41.975 6.85709 42.3079 7.02352 42.4078 7.33143L44.3301 13.0901C44.43 13.398 44.2635 13.7309 43.9556 13.8307C43.8974 13.8474 43.8308 13.864 43.7725 13.864V13.8557Z" fill="#494456"/>
    <path d="M26.2633 46.635C25.9387 46.635 25.6808 46.3687 25.6808 46.0525V41.7918C25.6808 41.4672 25.9471 41.2092 26.2633 41.2092C26.5795 41.2092 26.8458 41.4755 26.8458 41.7918V46.0525C26.8458 46.3771 26.5795 46.635 26.2633 46.635Z" fill="#494456"/>
    </g>
    </svg>
       );
}
