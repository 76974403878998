import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import CustomInput from "components/CustomInput/CustomInput.js";
import AllInbox from "@material-ui/icons/AllInbox";
import Download from "@material-ui/icons/CloudDownload";
import PolicyCard from "@material-ui/icons/CardMembership";
import EmailIcon from "@material-ui/icons/Email";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import SmsIcon from "@material-ui/icons/Sms";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { sendEmailAPI } from "views/Services/PoliciesService";
const useStyles = makeStyles(styles);
import environment from "../../environment/environment";

import { checkIfIsKiosko } from "components/Utils/utils";

const server = `${environment.SERVER}`;

export default function PolicySummary(props) {
  const history = useHistory();
  const reactRedesign = process.env.REACT_APP_REDESIGN;
  const isRedesign = reactRedesign === "true" ? true : false;
  const dispatch = useDispatch();
  const isKiosko = checkIfIsKiosko();
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const classes = useStyles();

  const { id, data, email, setEmail, isLogged } = props;

  const [sendEmail, setSendEmail] = useState(false);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const sendEmailProcess = async () => {
    const resultSubmit = await dispatch(
      sendEmailAPI({
        orderid: data.encryptId,
        to_name: "test test",
        uri: "/admin/look_up/" + id,
        to_email: email,
        csr_email: "test",
      })
    );
    if (resultSubmit.payload.result) {
      setSendEmail(false);
    }
  };
  return (
    <div>
      {!sendEmail ? (
        <GridContainer>
          <GridItem xs={12} style={{ textAlign: "right", marginTop: "15px" }}>
            <Button
              color="twitter"
              onClick={() => {
                if (isLogged) {
                  history.push("/admin/home");
                } else {
                  let producerCode = localStorage.getItem("producerCode");
                  let sale = localStorage.getItem("sale");
                  let urlHome = "/admin/home";
                  if (isKiosko) {
                    localStorage.clear();
                    urlHome = "/guest/kiosko/" + producerCode;
                  }
                  window.location.href = urlHome;
                }
              }}
            >
              <ArrowBack className={classes.inputAdornmentIcon} />
              <label style={{ paddingLeft: "5px", color: "#FFF" }}>
                Back to home
              </label>
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AllInbox />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Summary</h4>
              </CardHeader>
              <CardBody>
                {data ? (
                  <GridContainer>
                    <GridItem xs={12}>
                      <span>
                        <span style={{ fontSize: "1em" }}>Policy Number:</span>
                        <br />
                        <span style={{ fontSize: "1.4em" }}>
                          <strong>{data.policy_nbr}</strong>
                        </span>
                        <br />
                        {isLogged && (
                          <Button
                            color="success"
                            size="sm"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            onClick={() =>
                              history.push(
                                isRedesign
                                  ? `/guestRedesing/en/${data.order_id}`
                                  : `/admin/wizard/${data.order_id}` +
                                      (isKiosko ? "/kiosko" : "")
                              )
                            }
                          >
                            Clone Policy
                          </Button>
                        )}
                        <br />
                        {data.make_vehicle}
                        <br />
                        {data.model_vehicle}
                        <br />
                        <span style={{ color: "#00acc1" }}>Plates:</span>{" "}
                        {data.plates}
                        <br />
                        <span style={{ color: "#00acc1" }}>VIN:</span> *********
                        {data.vin.substr(data.vin.length - 5)}
                        <br />
                        <span style={{ color: "#00acc1" }}>
                          {" "}
                          Effective Date:
                        </span>
                        <br />
                        {data.effective_date}
                        <br />
                        <span style={{ color: "#00acc1" }}>
                          {" "}
                          Expiration Date:
                        </span>
                        <br />
                        {data.expiration_date}
                        <br />
                        <span style={{ color: "#00acc1" }}>Days:</span>
                        <br />
                        {data.num_of_days}
                        <br />
                        <span style={{ color: "#00acc1" }}>Total:</span>
                        <br />
                        {USDollar.format(data.total)}
                        <br />
                        <br />
                      </span>
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      <span>
                        <Button
                          onClick={() => {
                            window.open(
                              `${server}quotes_mobile/policy/${data.order_id}`
                            );
                          }}
                          round
                          color="twitter"
                        >
                          <Download /> Download Policy
                        </Button>
                        <Button
                          onClick={() => {
                            window.open(
                              `${server}quotes_mobile/policy_card/${data.order_id}`
                            );
                          }}
                          round
                          color="twitter"
                        >
                          <PolicyCard /> Download Policy Card
                        </Button>
                        {isLogged && (
                          <Button
                            onClick={() => setSendEmail(true)}
                            round
                            color="rose"
                          >
                            <EmailIcon /> Send Via Email
                          </Button>
                        )}
                      </span>
                    </GridItem>
                  </GridContainer>
                ) : null}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          {data ? (
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Policy Information</h4>
                </CardHeader>
                <CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                      <strong>Summary</strong>
                    </h3>
                    <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
                      <strong>Client Info</strong>
                    </h4>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Client Number:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.client_id}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Valid driver license?</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.valid_license == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>D.L. Country:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.dl_country_name}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>D.L. State:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.dl_state_name}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>First Name:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.first_name}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Middle Name:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.middle_name}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Last Name:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.last_name}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Address:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.address}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Zip Code:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.zipcode}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>City:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.city}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>State:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.state_name}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Country:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.country_name}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Phone #:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.phone}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Email:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.email}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Additional Insured:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.additional_insured}</GridItem>
                    </GridContainer>
                    <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
                      <strong>Vehicle Info</strong>
                    </h4>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Type:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.type_name}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Year:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.year_model}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Make:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {" "}
                        {[1, 5].includes(parseInt(data.vehicle_type_id))
                          ? data.make_vehicle == null
                            ? data.make
                            : data.make_vehicle
                          : data.make}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Model:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {" "}
                        {[1, 5].includes(parseInt(data.vehicle_type_id))
                          ? data.model_vehicle == null
                            ? data.model
                            : data.model_vehicle
                          : data.model}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Value:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {USDollar.format(data.total_value)}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Plates:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.plates}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>VIN:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.vin}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Auto Insurance Company:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.insurance_company}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Full Coverage in the US / Canada?</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.insurance_policy_number}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Do you have a lienholder?</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.lienholder_vehicle_address != "" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Trusted Traveler #:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.sentri_number}</GridItem>
                    </GridContainer>
                    {data.make_trailer && data.make_trailer != "" ? (
                      <>
                        <GridContainer>
                          <GridItem xs={12}>
                            <center>
                              <strong>Other vehicle (1)</strong>
                            </center>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Type</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {trailerTypes.find(
                              (o) => o.id == parseInt(data.vehtype_trailer)
                            )
                              ? trailerTypes.find(
                                  (o) => o.id == parseInt(data.vehtype_trailer)
                                ).name
                              : ""}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Insurance Company (If Different)</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {data.other_policy_company_trailer}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Ins. Policy #</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {data.other_policy_number_trailer}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Year</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.year_model_trailer}</GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Make</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.make_trailer}</GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Model</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.model_trailer}</GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>VIN</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.vin_trailer}</GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Value</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {USDollar.format(data.total_value_trailer)}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Plates</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.plates_trailer}</GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Description</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.description_trailer}</GridItem>
                        </GridContainer>
                      </>
                    ) : null}
                    {data.make_other && data.make_other != "" ? (
                      <>
                        <GridContainer>
                          <GridItem
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                            xs={12}
                          >
                            <center>
                              <strong>Other vehicle (2)</strong>
                            </center>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Type</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {trailerTypes.find(
                              (o) => o.id == parseInt(data.vehtype_other)
                            )
                              ? trailerTypes.find(
                                  (o) => o.id == parseInt(data.vehtype_other)
                                ).name
                              : ""}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Insurance Company (If Different)</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {data.other_policy_company_other}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Ins. Policy #</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {data.other_policy_number_other}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Year</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.year_model_other}</GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Make</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.make_other}</GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Model</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.model_other}</GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>VIN</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.vin_other}</GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Value</strong>
                          </GridItem>
                          <GridItem xs={7}>
                            {USDollar.format(data.total_value_other)}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={5}>
                            <strong>Plates</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.plates_other}</GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{ background: "rgb(170 170 170 / 15%)" }}
                        >
                          <GridItem xs={5}>
                            <strong>Description</strong>
                          </GridItem>
                          <GridItem xs={7}>{data.description_other}</GridItem>
                        </GridContainer>
                      </>
                    ) : null}

                    <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
                      <strong>Coverage Info</strong>
                    </h4>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Physical Damage:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.physical_damage == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Theft:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.theft == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Liability limit:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {" "}
                        {USDollar.format(data.csllimit)}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Excess limit:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {USDollar.format(data.excessliab)}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Medical limit:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {USDollar.format(data.medicallimit)}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Legal Aid:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.legalaid == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Roadside:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.roadside == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Gap Coverage:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.gapcoverage == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Premiere package:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {data.premierepackage == "1" ? "Yes" : "No"}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Fixed Deductible:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.theft ? "Yes" : "No"}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Physical Damage %:</strong>
                      </GridItem>
                      <GridItem xs={7}>2%</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>PD Deductible:</strong>
                      </GridItem>
                      <GridItem xs={7}>{USDollar.format(data.pd_ded)}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Theft %:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.theftded}%</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Theft Deductible:</strong>
                      </GridItem>
                      <GridItem xs={7}>
                        {" "}
                        {USDollar.format(data.theft_ded)}
                      </GridItem>
                    </GridContainer>
                    <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
                      <strong>Policy Information</strong>
                    </h4>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Number of days:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.num_of_days}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Effective date:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.effective_date}</GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ background: "rgb(170 170 170 / 15%)" }}
                    >
                      <GridItem xs={5}>
                        <strong>Effective time:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.effective_time}</GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Expiration date:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.expiration_date}</GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={5}>
                        <strong>Border Crossing:</strong>
                      </GridItem>
                      <GridItem xs={7}>{data.border_crossing_name}</GridItem>
                    </GridContainer>
                    {isLogged && (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          color="success"
                          size="sm"
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          onClick={() =>
                            history.push(
                              isRedesign
                                ? `/guestRedesing/en/${data.order_id}`
                                : `/admin/wizard/${data.order_id}` +
                                    (isKiosko ? "/kiosko" : "")
                            )
                          }
                        >
                          Clone Policy
                        </Button>
                      </div>
                    )}
                  </CardBody>

                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          ) : null}
        </GridContainer>
      ) : sendEmail ? (
        <GridContainer>
          <GridItem xs={12} style={{ textAlign: "right", marginTop: "15px" }}>
            <Button
              color="twitter"
              onClick={() => {
                setSendEmail(false);
              }}
            >
              <ArrowBack className={classes.inputAdornmentIcon} />
              <label style={{ paddingLeft: "5px", color: "#FFF" }}>
                Cancel
              </label>
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AllInbox />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Send Email</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText={<span>Email</span>}
                  id="clientnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: email,
                    onChange: (event) => {
                      setEmail(event.target.value);
                    },
                  }}
                />
                <Button
                  color="info"
                  onClick={() => {
                    sendEmailProcess();
                  }}
                >
                  Continue
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : null}
    </div>
  );
}
