import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import environment from '../../environment/environment';
const BASE_URL = `${environment.API}`;

export const login = createAsyncThunk(
	'loginView/login',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/login`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const oneClickLoginAPI = createAsyncThunk(
	'loginView/login',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/oneClickLogin`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
export const oneClickLoginValidation = createAsyncThunk(
	'loginView/login',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/checkTokenOneClick`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const recoverPassword = createAsyncThunk(
	'loginView/recoverPassword',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/recoverPass`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const resetPassword = createAsyncThunk(
	'loginView/resetPassword',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/resetPassword`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const checkToken = createAsyncThunk(
	'loginView/resetPassword',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/checkToken`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const checkDataLogin = createAsyncThunk(
	'loginView/checkDataLogin',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/getMaskDataByEmailOrDl`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const sendTypeCode = createAsyncThunk(
	'loginView/sendTypeCode',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/sendCode`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const checkCode = createAsyncThunk(
	'loginView/checkCode',
	async (params, { rejectWithValue }) => {
		try {
            const response = await axios.post(`${BASE_URL}/Auth/checkCode`, params);
			const data = await response.data;
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);