import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;

export const addCard = createAsyncThunk(
  "card/addCard",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/Cards`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "card/deleteCard",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/Cards`, {
        data: params,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCardsByClient = createAsyncThunk(
  "card/getCardsByClient",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${BASE_URL}/Cards/getClientsCards`, params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
