import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";

// @material-ui/icons

// core components
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import Button from "components/CustomButtons/CustomButton.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import PasswordLogin from "components/CustomIcons/PasswordLogin.js";
import ProducerCodeLogin from "components/CustomIcons/ProducerCodeLogin.js";

const useStyles = makeStyles(styles);
const useStylesRadio = makeStyles(stylesRadio);

import "react-credit-cards/es/styles-compiled.css";

import { useTranslation } from "react-i18next";

export default function CustomOneClickFields({ children, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  const [seePassword, setSeePassword] = useState(false);
  const {
    loginCredentials,
    handleFormChange,
    sended,
    changeLogin,
    typeCode,
    setTypeCode,
    sendCode,
    typeLogin,
    isValidUser,
  } = props;

  const handleChange = (event) => {
    setTypeCode(event.target.value);
  };

  return (
    <div>
      {(typeLogin === "customer" || (typeLogin == "agent" && isValidUser)) && (
        <>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              {loginCredentials.login}
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                id="changeLoginData"
                type="button"
                style={{ backgroundColor: "#008670" }}
                block
                onClick={changeLogin}
              >
                {t("login.change")}
              </Button>
            </GridItem>
          </GridContainer>
          <br />
          <div
            className={
              classesRadio.checkboxAndRadio +
              " " +
              classesRadio.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={typeCode === "sms"}
                  onChange={handleChange}
                  value={"sms"}
                  name="testing"
                  aria-label="B"
                  icon={
                    <FiberManualRecord
                      className={classesRadio.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classesRadio.radioChecked} />
                  }
                  classes={{
                    checked: classesRadio.radio,
                    root: classesRadio.radioRoot,
                  }}
                />
              }
              classes={{
                label: classesRadio.label,
                root: classesRadio.labelRoot,
              }}
              label={`${t("login.textMeCode")} ${loginCredentials.maskPhone}`}
            />
          </div>
          <br />
          <div
            className={
              classesRadio.checkboxAndRadio +
              " " +
              classesRadio.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={typeCode === "email"}
                  onChange={handleChange}
                  value={"email"}
                  name="testing"
                  aria-label="B"
                  icon={
                    <FiberManualRecord
                      className={classesRadio.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classesRadio.radioChecked} />
                  }
                  classes={{
                    checked: classesRadio.radio,
                    root: classesRadio.radioRoot,
                  }}
                />
              }
              classes={{
                label: classesRadio.label,
                root: classesRadio.labelRoot,
              }}
              label={`${t("login.emailMeCode")} ${loginCredentials.maskEmail}`}
            />
          </div>
          <br />
          {typeLogin == "customer" && (
            <div
              className={
                classesRadio.checkboxAndRadio +
                " " +
                classesRadio.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={typeCode === "password"}
                    onChange={handleChange}
                    value={"password"}
                    name="testing"
                    aria-label="B"
                    icon={
                      <FiberManualRecord
                        className={classesRadio.radioUnchecked}
                      />
                    }
                    checkedIcon={
                      <FiberManualRecord
                        className={classesRadio.radioChecked}
                      />
                    }
                    classes={{
                      checked: classesRadio.radio,
                      root: classesRadio.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classesRadio.label,
                  root: classesRadio.labelRoot,
                }}
                label={t("login.password")}
              />
            </div>
          )}
          {typeCode !== "password" && typeCode !== "" && (
            <div>
              <div style={{ marginTop: "10px" }} className={classes.center}>
                <Button
                  id="sendCodeMobile"
                  type="button"
                  style={{ backgroundColor: "#008670" }}
                  block
                  onClick={sendCode}
                >
                  {t("login.sendCode")}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {(typeCode === "password" || (typeLogin == "agent" && !isValidUser)) && (
        <div>
          <CustomInput
            labelText={t("login.password")}
            helperText={
              !loginCredentials.password && sended
                ? t("login.passwordRequired")
                : null
            }
            error={!loginCredentials.password && sended ? true : null}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                  onClick={() => setSeePassword(!seePassword)}
                >
                  <Icon
                    style={{
                      color: seePassword ? "#13816F" : "black",
                    }}
                    className={classes.inputAdornmentIcon}
                  >
                    remove_red_eye
                  </Icon>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <PasswordLogin className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              name: "password",
              type: seePassword ? "text" : "password",
              onChange: handleFormChange,
            }}
          />
          {typeLogin === "agent" && (
            <CustomInput
              helperText={
                !loginCredentials.producerCode && sended
                  ? t("login.producerCodeRequired")
                  : null
              }
              error={!loginCredentials.producerCode && sended ? true : null}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              name="producerCode"
              labelText={t("login.producerCode")}
              inputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <ProducerCodeLogin className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                name: "producerCode",
                onChange: handleFormChange,
                value: loginCredentials.producerCode,
              }}
            />
          )}
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <Button
              id="loginMobile"
              color="success"
              type="submit"
              variant={typeLogin == "customer" ? "" : "green"}
              className={
                typeLogin == "customer" ? classesRadio.customerLoginStyle : ""
              }
            >
              <span style={{ fontStyle: "bold" }}>{t("login.logMeIn")}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
