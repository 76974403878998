import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import ReactInputVerificationCode from "react-input-verification-code";

import OneClickFields from "components/OneClickFields/OneClickFields";

import { checkIfIsKiosko } from "components/Utils/utils";

import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {
  login,
  oneClickLoginAPI,
  oneClickLoginValidation,
  checkDataLogin,
  sendTypeCode,
  checkCode,
} from "../Services/LoginService";
import LoginBase from "components/LoginBase/LoginBase";

const useStyles = makeStyles(styles);

const emptyLogin = {
  login: "",
  password: "",
  maskEmail: "",
  maskPassword: "",
  userId: "",
};

export default function LoginPage() {
  const [loginCredentials, setLoginCredentials] = useState(emptyLogin);
  const routeParams = useParams();
  const classes = useStyles();
  const { userId, token, type, producerCode } = routeParams;
  const isKiosko = checkIfIsKiosko();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (type == "oneClickLogin") {
      setOneClickLogin(true);
    }
    let pCode = localStorage.getItem("producerCode");
    if (localStorage.getItem("kiosko") === "true") {
      localStorage.clear();
      window.location = "/guest/kiosko/" + pCode;
    }
    if (localStorage.getItem("login") !== null) {
      let loginData = { ...loginCredentials };
      loginData.login = localStorage.getItem("login");
      setLoginCredentials(loginData);
    }
    localStorage.clear();
    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClickValidate(true);
      validateToken();
    }
  }, [dispatch]);
  const [toastMessage, setToastMessage] = useState(false);
  const [oneClickValidate, setOneClickValidate] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);
  const [isCodeSended, setIsCodeSended] = useState(false);
  const [typeCode, setTypeCode] = useState("");
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [sended, setSended] = useState(false);
  const handleFormChange = (e) => {
    setLoginCredentials({ ...loginCredentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const value = loginCredentials;
    if (loginCredentials.login && loginCredentials.password) {
      const resultSubmit = await dispatch(login(value));
      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast("Success login.");
        setUserLoginData(type, resultSubmit);
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += " " + resultSubmit.payload.message[key] + "\n";
        }
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const setUserLoginData = (type, resultSubmit) => {
    let userToken =
      typeof resultSubmit.payload.dataLogin != "undefined"
        ? resultSubmit.payload.dataLogin.data
        : resultSubmit.payload.data;
    localStorage.setItem("userToken", userToken);
    if (isKiosko) {
      localStorage.setItem("kiosko", true);
      localStorage.setItem("producerCode", producerCode);
    }
    window.location.replace(
      "/admin/home" + (isKiosko ? "/kiosko/" + producerCode : "")
    );
  };
  const oneClickLoginFunction = async () => {
    const value = loginCredentials;
    if (loginCredentials.login) {
      const data = {
        login: loginCredentials.login,
        domain: window.location.origin,
      };
      const resultSubmit = await dispatch(oneClickLoginAPI(data));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast(
          "Please check your email or SMS messages in order to log in to the tool."
        );
      } else {
        let mensaje = resultSubmit.payload.message;
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const sendCode = async () => {
    if (loginCredentials.login) {
      const data = {
        id: loginCredentials.userId,
        type: typeCode,
      };
      const resultSubmit = await dispatch(sendTypeCode(data));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast(
          "Please check your email or SMS messages in order to log in to the tool."
        );
        setIsCodeSended(true);
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    } else {
      setSended(true);
    }
  };
  const validateToken = async () => {
    const data = {
      userId: userId,
      token: token,
    };
    const resultSubmit = await dispatch(oneClickLoginValidation(data));

    if (resultSubmit.payload.result) {
      setToastMessage(true);
      setTypeToast("success");
      setMessageToast("Success login.");
      localStorage.setItem("userToken", resultSubmit.payload.data);
      window.location.replace("/admin/home");
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.messageCode);
    }
  };

  const checkLogin = async () => {
    const data = {
      data: loginCredentials.login,
    };
    const resultSubmit = await dispatch(checkDataLogin(data));

    if (resultSubmit.payload.result) {
      let loginData = { ...loginCredentials };
      loginData.maskEmail = resultSubmit.payload.data.maskEmail;
      loginData.maskPhone = resultSubmit.payload.data.maskPhone;
      loginData.userId = resultSubmit.payload.data.id;
      setLoginCredentials(loginData);
      setIsValidUser(true);
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.message);
    }
  };

  const changeLogin = () => {
    setIsValidUser(false);
    setLoginCredentials(emptyLogin);
    setIsCodeSended(false);
  };

  const sendCodeToValidate = async (value) => {
    if (validFormatCode(value)) {
      const data = {
        code: value,
        id: loginCredentials.userId,
      };
      const resultSubmit = await dispatch(checkCode(data));

      if (resultSubmit.payload.result) {
        setUserLoginData(type, resultSubmit);
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    }
  };

  const validFormatCode = (code) => {
    let lengthCode = code.length;
    let isValid = true;
    for (let i = 0; i < lengthCode; i++) {
      isValid = code[i] != "·" ? true : false;
    }
    return isValid;
  };

  const loginField = () => {
    return (
      <div>
        <CustomInput
          helperText={
            !loginCredentials.login && sended
              ? "The Login field is required."
              : null
          }
          error={!loginCredentials.login && sended ? true : null}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          name="login"
          onChange={(e) => handleFormChange(e)}
          inputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: "Email or driver license...",
            name: "login",
            onChange: handleFormChange,
            value: loginCredentials.login,
          }}
        />
        {!oneClickLogin && (
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <Button
              id="continueMobile"
              onClick={() => checkLogin()}
              style={{ backgroundColor: "#008670" }}
              block
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  };

  const formNoOneClickLogin = () => {
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        {!isValidUser && <div>{loginField()}</div>}
        {isValidUser && !isCodeSended && (
          <OneClickFields
            loginCredentials={loginCredentials}
            handleFormChange={handleFormChange}
            sended={sended}
            changeLogin={changeLogin}
            typeCode={typeCode}
            setTypeCode={setTypeCode}
            sendCode={sendCode}
          />
        )}
        {isCodeSended && (
          <div>
            <p>Enter code:</p>
            <ReactInputVerificationCode
              length={6}
              onCompleted={sendCodeToValidate}
            />
          </div>
        )}
        <div style={{ marginTop: "10px" }} className={classes.center}>
          <p style={{ textAlign: "center" }}>
            Forgot{" "}
            <a
              id="forgotPassword"
              href={
                "/auth/recovery-password-page" +
                (isKiosko ? "/kiosko/" + producerCode : "")
              }
              style={{ color: "#008670" }}
            >
              Password
            </a>
          </p>
        </div>
      </form>
    );
  };

  const formOneClickLogin = () => {
    return (
      <form className={classes.form}>
        {loginField()}
        <div style={{ marginTop: "10px" }} className={classes.center}>
          <Button
            onClick={() => oneClickLoginFunction()}
            style={{ backgroundColor: "#008670" }}
            block
            id="oneClickLogin"
          >
            One click login
          </Button>
        </div>
      </form>
    );
  };

  const setPageLogin = () => {
    setOneClickLogin(false);
    setIsValidUser(false);
    setIsCodeSended(false);
    setTypeCode("");
    setLoginCredentials(emptyLogin);
  };

  const noValidationClick = () => {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5}>
          <div className={classes.center}>
            <h4 className={classes.socialTitle}>Login</h4>
          </div>
          {!oneClickLogin ? formNoOneClickLogin() : formOneClickLogin()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <p style={{ textAlign: "center" }}>Don't have an account?</p>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ marginTop: "10px" }} className={classes.center}>
            <Button
              id="guestQuote"
              onClick={() => {
                isKiosko
                  ? history.push(`/guest/kiosko/${producerCode}`)
                  : history.push(`/auth/guest`);
              }}
              color="success"
              style={{
                backgroundColor: "#FFF",
                borderColor: "#008670",
                color: "#008670",
                border: "2px solid",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              Guest Quote
            </Button>
            {(oneClickLogin || isValidUser) && (
              <Button
                onClick={() => setPageLogin()}
                color="success"
                style={{
                  backgroundColor: "#FFF",
                  borderColor: "#008670",
                  color: "#008670",
                  border: "2px solid",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                Back to login
              </Button>
            )}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <LoginBase>
      <CardBody>
        {!oneClickValidate ? (
          noValidationClick()
        ) : (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={5}>
              <div className={classes.center}>
                <h4 className={classes.socialTitle}>
                  One click login validation
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        )}
        <GridItem xs={12} sm={12} md={3}>
          <Snackbar
            style={{
              position: "absolute",
              top: "50", // Asegúrate de que el Snackbar esté en la parte superior
            }}
            place="bc"
            color={typeToast}
            message={messageToast}
            open={toastMessage}
            closeNotification={() => setToastMessage(false)}
            close
          />
        </GridItem>
      </CardBody>
    </LoginBase>
  );
}
