import React from "react";
export default function Icon(props) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.48047 7.95119H4.73861V7.55798C4.73861 7.15354 4.44651 6.8165 4.0533 6.76033L2.48047 6.54688" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M11.8952 9.7487V10.2767C11.8952 10.8047 12.3221 11.2204 12.8389 11.2204H13.3669C13.8949 11.2204 14.3106 10.7935 14.3106 10.2767V6.266C14.3106 6.03007 14.4342 5.80538 14.6476 5.69303L14.9397 5.52452C15.0296 5.49081 15.0858 5.40094 15.0858 5.31106V4.53588C15.0858 4.4123 14.9847 4.29995 14.8498 4.29995H14.0971C13.7826 4.29995 13.5017 4.13143 13.3444 3.85057L12.2547 1.83959C12.0412 1.44638 11.6255 1.19922 11.1762 1.19922H7.9069H4.61518C4.1658 1.19922 3.76136 1.44638 3.5479 1.83959L2.44692 3.8618C2.30087 4.13143 2.00877 4.31119 1.6942 4.31119H0.952722C0.829142 4.31119 0.716797 4.4123 0.716797 4.54711V5.32229C0.716797 5.42341 0.77297 5.50205 0.862846 5.53575L1.15494 5.70427C1.35717 5.81661 1.49198 6.0413 1.49198 6.27723V10.288C1.49198 10.816 1.91889 11.2317 2.43568 11.2317H2.9637C3.49173 11.2317 3.90741 10.8047 3.90741 10.288V9.75994H11.8952V9.7487Z" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M6.53531 4.33326H12.1975L11.1752 2.4571C11.119 2.34475 11.0067 2.27734 10.8831 2.27734H7.80481H4.72655C4.60297 2.27734 4.49063 2.34475 4.43445 2.4571L3.41211 4.33326H6.53531Z" fill="#C2C2C2" />
      <path d="M13.221 6.53516L11.6482 6.74861C11.255 6.80479 10.9629 7.14182 10.9629 7.54626V7.93947H13.221" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M3.86169 9.75H2.87305" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M11.8398 9.75H12.8397" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M9.03022 8.00781H6.46875" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M9.59115 7.23242H5.99609" stroke="#C2C2C2" stroke-miterlimit="10" stroke-linecap="round" />
    </svg>
  );
}
