import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Card from "react-credit-cards";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/CustomButton.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CreditCardItem from "components/CreditCardItem/CustomCreditCardItem";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CardHeader from "components/Card/CardHeader.js";

import { styled } from "@mui/material/styles";

const useStyles = makeStyles(styles);

import "react-credit-cards/es/styles-compiled.css";
import "../../assets/css/stylesPage.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(stylesDialogs);

export default function CreditCardList(props) {
  const classes = useStyles();
  const classesDialogs = useStylesDialog();
  const {
    creditCardListModal,
    onClose,
    inputHandler,
    callBackCard,
    handlerSaveCard,
    saveCard,
    clientCards,
    handlerCardData,
    handlerDeleteCard,
    handleChangeRadio,
    selectedValue
  } = props;

  console.log("props en componente credit card list", props);

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={creditCardListModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      id={"paymentDialogItems"}
      style={{
        zIndex: "5000"
      }}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h3
          className={
            classesDialogs.modalTitle + " " + classesDialogs.modalTitlePayment
          }
        >
          Customer Cards
        </h3>
        <Button
          justIcon
          className={
            classesDialogs.modalCloseButton +
            " " +
            classesDialogs.buttonPaymentClose
          }
          key="close"
          aria-label="Close"
          onClick={onClose}
        >
          <Close className={classesDialogs.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        <GridContainer>
          {clientCards.length > 0 &&
            clientCards.map((item) => (
              <CreditCardItem
                idCard={item.id}
                numberCard={item.number}
                monthCard={item.expiration_month}
                yearCard={item.expiration_year}
                nameCard={item.first_name}
                lastNameCard={item.last_name}
                typeCard={item.card_type}
                selectedValue={selectedValue}
                handleChangeRadio={handleChangeRadio}
                inputHandler={inputHandler}
                handlerCardData={handlerCardData}
                handlerDeleteCard={handlerDeleteCard}
                onlyShowData={false}
              />
            ))}
        </GridContainer>
      </DialogContent>
      <DialogActions
        className={classesDialogs.modalFooter}
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <GridItem xs={12} className={classesDialogs.center}>
          <a href="#" className={classesDialogs.linkPayment}>
            Privacy Policy
          </a>{" "}
          | Disclaimer
        </GridItem>
      </DialogActions>
    </Dialog>
  );
}
