/*eslint-disable*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";

// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function FixedPlugin(props) {
  const [classes, setClasses] = React.useState("dropdown show");
  const [bg_checked, setBg_checked] = React.useState(true);
  const [bgImage, setBgImage] = React.useState(props.bgImage);
  const [showImage, setShowImage] = React.useState(true);
  const handleClick = () => {
    props.handleFixedClick();
  };
  const handleChange = (name) => (event) => {
    switch (name) {
      case "miniActive":
        props.sidebarMinimize();
        break;
      case "image":
        if (event.target.checked) {
          props.handleImageClick(bgImage);
        } else {
          props.handleImageClick();
        }
        setShowImage(event.target.checked);
        break;
      default:
        break;
    }
  };
  const classesObj = useStyles();
  return (
    <div
      className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
      style={{ background: 'rgb(255 0 0 / 30%)', top: '90px'}}
    >
      <div id="fixedPluginClasses" className={props.fixedClasses}>
        <div onClick={handleClick}>
          <i className="fa fa-rss" style={{ paddingTop: '10px', paddingBottom: '10px'}} /> News
        </div>
        <ul className="dropdown-menu">
          <li className="header-title">News</li>
          <li className="adjustments-line" style={{ color: '#000000', height: 'auto'}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={priceImage1} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Memorial Day - Headquarters office will be closed
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion} style={{ color: '#000000', textAlign: 'left'}}>
                      In observance of Memorial Day, our headquarters office will be closed on Monday, May 29. We will resume regular business hours on Tuesday, May 30th, from 8 am to 6 pm CT.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={priceImage2} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Welcome 2023!!
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion} style={{ color: '#000000', textAlign: 'left'}}>
                      Best wishes for a Healthy and Prosperous New Year! 
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              
            </GridContainer>
          </li>
        </ul>
      </div>
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  miniActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  handleBgColorClick: PropTypes.func,
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  rtlActive: PropTypes.bool,
};
