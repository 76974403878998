import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";
import Apps from "@material-ui/icons/Apps";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";

import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Phone from "@material-ui/icons/Phone";
import Download from "@material-ui/icons/CloudDownload";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PolicyCard from "@material-ui/icons/CardMembership";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { checkIfIsKiosko } from "components/Utils/utils";

import {
  getOrdersByClient,
  getAgencyDataByClient,
} from "views/Services/OrdersService";
import environment from "../../environment/environment";

import { buttonStyleStatusPolicy } from "components/Utils/utils.js";

const server = `${environment.SERVER}`;

const useStyles = makeStyles(styles);

export default function Dashboard() {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const history = useHistory();
  const isKiosko = checkIfIsKiosko();
  const redesign = process.env.REACT_APP_REDESIGN;
  const isRedesign = redesign === "true" ? true : false;
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [agencyData, setAgencyData] = useState(null);
  useEffect(() => {
    getOrders();
    getAgencyData();
  }, [dispatch]);
  const getOrders = async () => {
    const resultSubmit = await dispatch(getOrdersByClient());
    setOrders(resultSubmit.payload.data);
    const ordenesT = [];
    resultSubmit.payload.data.forEach((order) => {
      ordenesT.push([
        <span>
          <br />
          <span style={{ fontSize: "1.4em", marginTop: "10px" }}>
            <strong>{order.policy_nbr}</strong>
          </span>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              className="clone-policy"
              color="success"
              size="sm"
              center
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onClick={() =>
                history.push(
                  isRedesign
                    ? `/guestRedesing/en/${order.order_id}`
                    : `/admin/wizard/${order.order_id}` +
                        (isKiosko ? "/kiosko" : "")
                )
              }
            >
              Clone Policy
            </Button>
          </div>
          <br />
          <span style={{ color: "#00acc1" }}>Status:</span>
          <br />
          {order.cancellation == "Cancelled" ? (
            <button style={buttonStyleStatusPolicy.cardStyleWarning}>
              Cancelled
            </button>
          ) : order.cancellation == "Expired" ? (
            <button style={buttonStyleStatusPolicy.cardStyleExpire}>
              Expired
            </button>
          ) : (
            <button style={buttonStyleStatusPolicy.cardStyleSuccess}>
              Active
            </button>
          )}
          <br />
          <span style={{ color: "#00acc1" }}>Year vehicle:</span>
          <br />
          {order.year_model}
          <br />
          <span style={{ color: "#00acc1" }}>Make:</span>
          <br />
          {order.make}
          <br />
          <span style={{ color: "#00acc1" }}>Model:</span>
          <br />
          {order.model}
          <br />
          <span style={{ color: "#00acc1" }}>Plates:</span>
          <br />
          {order.plates}
          <br />
          <span style={{ color: "#00acc1" }}>VIN:</span> *********
          <br />
          {order.vin.substr(order.vin.length - 5)}
          <br />
          <span style={{ color: "#00acc1" }}> Effective Date:</span>
          <br />
          {order.effective_date}
          <br />
          <span style={{ color: "#00acc1" }}> Expiration Date:</span>
          <br />
          {order.expiration_date}
          <br />
          <span style={{ color: "#00acc1" }}>Days:</span>
          <br />
          {order.num_of_days}
          <br />
          <span style={{ color: "#00acc1" }}>Total:</span>
          <br />
          {order.total}
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              justIcon
              round
              color="twitter"
              style={{ marginRight: "20px" }}
              onClick={() => {
                window.open(
                  `${server}quotes_mobile/policy_card/${order.order_id}`
                );
              }}
            >
              <PolicyCard />
            </Button>
            <Button
              justIcon
              round
              color="twitter"
              style={{ marginRight: "20px" }}
              onClick={() => {
                window.open(`${server}quotes_mobile/policy/${order.order_id}`);
              }}
            >
              <Download />
            </Button>
            <Button
              onClick={() => history.push(`/admin/view/${order.order_id}`)}
              round
              color="twitter"
              style={{ marginRight: "7px" }}
            >
              View
            </Button>
          </div>
        </span>,
      ]);
    });
    setOrdenes(ordenesT);
  };
  const calculateExpiration = (orderExpirationDate) => {
    const fechaActual = new Date();
    const fechaExpiracion = new Date(orderExpirationDate);
    const diferenciaMilisegundos = fechaExpiracion - fechaActual;
    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    if (diferenciaDias <= 5) {
      return cardStyleExpire;
    } else if (diferenciaDias >= 6 && diferenciaDias <= 15) {
      return cardStyleWarning;
    } else if (diferenciaDias >= 16) {
      return cardStyleSuccess;
    }
  };
  const handleWhatsApp = () => {
    const url = `https://wa.me/9566863601`;
    window.open(url);
  };
  const handleOpenSMSApp = () => {
    window.location.href = `sms:9566863601`;
  };
  const handleMakePhoneCall = () => {
    window.location.href = `tel:9566863601`;
  };
  const goToGoogleMaps = () => {
    window.open("https://maps.app.goo.gl/LWdzksnYxUcc2ZYBA");
  };
  const getAgencyData = async () => {
    const resultSubmit = await dispatch(getAgencyDataByClient());
    setAgencyData(resultSubmit.payload.data);
    console.log(agencyData);
  };
  const classes = useStyles();

  // Hooks
  const [showActivePolicies, setActivePolicies] = React.useState(false);
  const [showAllPolicies, setAllPolicies] = React.useState(false);

  return (
    <div>
      <div></div>
      <GridContainer
        style={{
          marginTop: "20px",
        }}
      >
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <h4>
                  Need a New Policy? Choose to issue a new one from a previous
                  policy below.{" "}
                </h4>
              </CardIcon>
            </CardHeader>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <h3
                  className={classes.cardIconTitle}
                  style={{ marginLeft: "25px" }}
                >
                  My Policies
                </h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <CardBody>
                  <Table tableData={[ordenes]} />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
