import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";
import Apps from "@material-ui/icons/Apps";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";

import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Phone from "@material-ui/icons/Phone";
import Download from "@material-ui/icons/CloudDownload";

import { useInactivity } from "components/Inactivity/Inactivity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PolicyCard from "@material-ui/icons/CardMembership";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import {
  getOrdersByClient,
  getActiveOrdersByClient,
  getAgencyDataByClient,
} from "views/Services/OrdersService";

import {
  buttonStyleStatusPolicy,
  checkIfIsKiosko,
} from "components/Utils/utils.js";
import environment from "../../environment/environment";
import { Assignment } from "@material-ui/icons";
const server = `${environment.SERVER}`;

const useStyles = makeStyles(styles);

export default function Dashboard() {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const isKiosko = checkIfIsKiosko();
  const history = useHistory();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [agencyData, setAgencyData] = useState(null);
  const isInactive = useInactivity();
  const cardStyleExpire = {
    border: "2px solid #F44336",
  };
  const cardStyleWarning = {
    border: "2px solid #FF9802",
  };
  const cardStyleSuccess = {
    border: "2px solid #4CAF50",
  };
  useEffect(() => {
    getOrders();
    getActiveOrders();
    getAgencyData();
  }, [dispatch]);
  const getOrders = async () => {
    const resultSubmit = await dispatch(getOrdersByClient());
    //setOrders(resultSubmit.payload.data);
    const ordenesT = [];
    resultSubmit.payload.data.forEach((order) => {
      ordenesT.push([
        <span>
          <br />
          <span style={{ fontSize: "1.4em", marginTop: "10px" }}>
            <strong>{order.policy_nbr}</strong>
          </span>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              color="success"
              size="sm"
              center
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onClick={() =>
                history.push(
                  isRedesign
                    ? `/guestRedesing/en/${order.order_id}`
                    : `/admin/wizard/${order.order_id}` +
                        (isKiosko ? "/kiosko" : "")
                )
              }
            >
              Clone Policy
            </Button>
          </div>
          <br />
          <span style={{ color: "#00acc1" }}>Status:</span>
          <br />
          {order.cancellation == "Cancelled" ? (
            <button style={buttonStyleStatusPolicy.cardStyleWarning}>
              Cancelled
            </button>
          ) : order.cancellation == "Expired" ? (
            <button style={buttonStyleStatusPolicy.cardStyleExpire}>
              Expired
            </button>
          ) : (
            <button style={buttonStyleStatusPolicy.cardStyleSuccess}>
              Active
            </button>
          )}
          <br />
          <span style={{ color: "#00acc1" }}>Year vehicle:</span> <br />
          {order.year_model}
          <br />
          <span style={{ color: "#00acc1" }}>Make:</span>
          <br />
          {order.make}
          <br />
          <span style={{ color: "#00acc1" }}>Model:</span>
          <br />
          {order.model}
          <br />
          <span style={{ color: "#00acc1" }}>Plates:</span>
          <br />
          {order.plates}
          <br />
          <span style={{ color: "#00acc1" }}>VIN:</span>
          <br />
          *********{order.vin.substr(order.vin.length - 5)}
          <br />
          <span style={{ color: "#00acc1" }}> Effective Date:</span>
          <br />
          {order.effective_date}
          <br />
          <span style={{ color: "#00acc1" }}> Expiration Date:</span>
          <br />
          {order.expiration_date}
          <br />
          <span style={{ color: "#00acc1" }}>Days:</span>
          <br />
          {order.num_of_days}
          <br />
          <span style={{ color: "#00acc1" }}>Total:</span>
          <br />
          {order.total}
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              justIcon
              round
              color="twitter"
              style={{ marginRight: "20px" }}
              onClick={() => {
                window.open(`${server}quotes_mobile/policy/${order.order_id}`);
              }}
            >
              <Download />
            </Button>
            <Button
              onClick={() => history.push(`/admin/view/${order.order_id}`)}
              round
              color="twitter"
              style={{ marginRight: "7px" }}
            >
              View
            </Button>
          </div>
        </span>,
      ]);
    });
    setOrdenes(ordenesT);
  };
  const getActiveOrders = async () => {
    const resultSubmit = await dispatch(getActiveOrdersByClient());
    setOrders(resultSubmit.payload.data);
  };
  const calculateExpiration = (orderExpirationDate) => {
    if (orderExpirationDate != null && orderExpirationDate != "") {
      const fechaActual = new Date();
      const fechaExpiracion = new Date(orderExpirationDate);
      const diferenciaMilisegundos = fechaExpiracion - fechaActual;
      const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
      if (diferenciaDias <= 5) {
        return cardStyleExpire;
      } else if (diferenciaDias >= 6 && diferenciaDias <= 15) {
        return cardStyleWarning;
      } else if (diferenciaDias >= 16) {
        return cardStyleSuccess;
      }
    }
    return cardStyleExpire;
  };
  const calculateExpirationColor = (orderExpirationDate) => {
    if (orderExpirationDate != null && orderExpirationDate != "") {
      const fechaActual = new Date();
      const fechaExpiracion = new Date(orderExpirationDate);
      const diferenciaMilisegundos = fechaExpiracion - fechaActual;
      const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
      if (diferenciaDias <= 5) {
        return "rose";
      } else if (diferenciaDias >= 6 && diferenciaDias <= 15) {
        return "warning";
      } else if (diferenciaDias >= 16) {
        return "success";
      }
    }
    return cardStyleExpire;
  };
  const handleWhatsApp = () => {
    const url = `https://wa.me/9566863601`;
    window.open(url);
  };
  const handleOpenSMSApp = () => {
    window.location.href = `sms:9566863601`;
  };
  const handleMakePhoneCall = () => {
    window.location.href = `tel:9566863601`;
  };
  const goToGoogleMaps = () => {
    window.open("https://maps.app.goo.gl/LWdzksnYxUcc2ZYBA");
  };
  const getAgencyData = async () => {
    const resultSubmit = await dispatch(getAgencyDataByClient());
    setAgencyData(resultSubmit.payload.data);
    console.log(agencyData);
  };
  const classes = useStyles();

  // Hooks
  const [showActivePolicies, setActivePolicies] = React.useState(false);
  const [showAllPolicies, setAllPolicies] = React.useState(false);
  const redesign = process.env.REACT_APP_REDESIGN;
  const isRedesign = redesign === "true" ? true : false;
  const url = redesign === "true" ? "/guestRedesing" : "/admin/wizard";

  return (
    <div>
      <div>
        <GridContainer
          style={{
            display: showActivePolicies
              ? "none"
              : showAllPolicies
              ? "none"
              : "block",
            marginTop: "20px",
          }}
        >
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <Button
              style={{ marginRight: "10px", backgroundColor: "#008670" }}
              onClick={() => {
                setActivePolicies(true);
              }}
            >
              <span>
                <AllInbox className={classes.inputAdornmentIcon} />
                <br />
                Active
                <br />
                Policies
              </span>
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              color="twitter"
              onClick={() => {
                setAllPolicies(true);
              }}
            >
              <span>
                <Apps className={classes.inputAdornmentIcon} />
                <br />
                ALL
                <br />
                Policies
              </span>
            </Button>
            <Button
              id="newQuote"
              color="danger"
              onClick={() =>
                history.push(isKiosko ? "/admin/wizard/kiosko" : url)
              }
            >
              <span>
                <Assignment className={classes.inputAdornmentIcon} />
                <br />
                NEW
                <br />
                QUOTE
              </span>
            </Button>
          </GridItem>
        </GridContainer>

        <GridContainer
          style={{
            display: showActivePolicies ? "block" : "none",
            marginTop: "20px",
          }}
        >
          <GridItem xs={12} style={{ textAlign: "right" }}>
            <Button
              color="twitter"
              onClick={() => {
                setActivePolicies(false);
              }}
            >
              <ArrowBack className={classes.inputAdornmentIcon} />
              <label style={{ paddingLeft: "5px", color: "#FFF" }}>
                Back to home
              </label>
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <h2>Your active policies:</h2>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ display: showActivePolicies ? "block" : "none" }}
        >
          {orders.map((order) => (
            <GridItem xs={12}>
              <Card style={calculateExpiration(order.expiration_date)}>
                <CardHeader
                  color={calculateExpirationColor(order.expiration_date)}
                  stats
                  icon
                >
                  <CardIcon
                    color={calculateExpirationColor(order.expiration_date)}
                  >
                    <AllInbox />
                  </CardIcon>
                  <p
                    className={classes.cardCategory}
                    style={{ marginTop: "45px" }}
                  >
                    Policy Number
                  </p>
                  <h3
                    className={classes.cardTitle}
                    style={{ fontSize: "1.4em" }}
                  >
                    <strong>{order.policy_nbr}</strong>
                  </h3>
                  <h3 className={classes.cardTitle}>
                    <small>
                      <p>
                        <span style={{ color: "#00acc1" }}>Year vehicle:</span>
                        <br />
                        {order.year_model}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>Make:</span>
                        <br />
                        {[1, 5].includes(parseInt(order.vehicle_type_id))
                          ? order.make_vehicle
                          : order.make}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>Model:</span>
                        <br />
                        {[1, 5].includes(parseInt(order.vehicle_type_id))
                          ? order.model_vehicle
                          : order.model}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>Plates:</span>
                        <br />
                        {order.plates}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>VIN:</span>
                        <br />
                        *********{order.vin.substr(order.vin.length - 5)}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>
                          Effective Date:
                        </span>
                        <br />
                        {order.effective_date}
                      </p>
                      <p>
                        <span style={{ color: "#00acc1" }}>
                          Expiration Date:
                        </span>
                        <br />
                        {order.expiration_date}
                      </p>
                    </small>
                  </h3>
                </CardHeader>
                <CardFooter style={{ textAlign: "right", display: "block" }}>
                  <div>
                    <Button
                      justIcon
                      round
                      color="twitter"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        window.open(
                          `${server}quotes_mobile/policy_card/${order.order_id}`
                        );
                      }}
                    >
                      <PolicyCard />
                    </Button>
                    <Button
                      justIcon
                      round
                      color="twitter"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        window.open(
                          `${server}quotes_mobile/policy/${order.order_id}`
                        );
                      }}
                    >
                      <Download />
                    </Button>
                    <Button
                      onClick={() =>
                        history.push(`/admin/view/${order.order_id}`)
                      }
                      round
                      color="twitter"
                      style={{ marginRight: "7px" }}
                    >
                      View
                    </Button>
                  </div>
                </CardFooter>
                <CardFooter stats>
                  <div className={classes.stats}>
                    {calculateExpiration(order.expiration_date).border ===
                    "2px solid #F44336" ? (
                      <>
                        <Danger>
                          <Warning />
                        </Danger>
                        <Danger>
                          <p>Expiration Date: {order.expiration_date} </p>
                          {/*- 10 days left*/}
                        </Danger>
                      </>
                    ) : null}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
      <GridContainer
        style={{
          display: showActivePolicies
            ? "none"
            : showAllPolicies
            ? "none"
            : "block",
        }}
      >
        <GridItem xs={12} sm={12} md={4} lg={4}>
          {agencyData ? (
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="greenS">
                  <Store />
                </CardIcon>
                <h4
                  className={classes.cardTitle}
                  style={{ paddingTop: "15px", textAlign: "left" }}
                >
                  My Agent
                </h4>
                <h4
                  className={classes.cardTitle}
                  style={{ fontWeight: "bolder", textAlign: "left" }}
                >
                  {agencyData.agency_name}
                </h4>
                <p
                  className={classes.cardCategory}
                  style={{ color: "#000000", textAlign: "left" }}
                >
                  {agencyData.address +
                    " " +
                    agencyData.city +
                    ", " +
                    agencyData.state_name +
                    " " +
                    agencyData.zipcode}
                  <br />
                  Phone: {agencyData.phone}
                  <br />
                  E-mail: {agencyData.email}
                </p>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.price}>
                  <Button
                    justIcon
                    round
                    color="success"
                    style={{ marginRight: "7px" }}
                    onClick={handleWhatsApp}
                  >
                    <WhatsApp style={{ marginBottom: "10px" }} />
                  </Button>
                  <Button
                    justIcon
                    round
                    color="twitter"
                    style={{ marginRight: "7px" }}
                    onClick={handleOpenSMSApp}
                  >
                    <Email style={{ marginBottom: "10px" }} />
                  </Button>
                  <Button
                    justIcon
                    round
                    color="danger"
                    style={{ marginRight: "7px" }}
                    onClick={handleMakePhoneCall}
                  >
                    <Phone style={{ marginBottom: "10px" }} />
                  </Button>
                  <Button
                    justIcon
                    round
                    color="info"
                    style={{ marginRight: "7px" }}
                    onClick={goToGoogleMaps}
                  >
                    <Place style={{ marginBottom: "10px" }} />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          ) : null}
          <Card style={{ marginTop: "50px" }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>info_outline</Icon>
              </CardIcon>
              <h3
                className={classes.cardTitle}
                style={{ paddingTop: "20px", textAlign: "left" }}
              >
                Claims
              </h3>
              <p
                className={classes.cardCategory}
                style={{
                  paddingTop: "40px",
                  paddingBottom: "35px",
                  color: "#000000",
                  textAlign: "left",
                }}
              >
                <strong>
                  You MUST report your claim BEFORE leaving Mexico.
                </strong>
                <br className={"brClass"} />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="http://www.ICclaims.com"
                  target="_blank"
                >
                  HDI Seguros
                </a>
                <br className={"brClass"} />
                Know How to Call Within <strong>Mexico direct dial</strong>
                <br className={"brClass"} />
                <strong></strong> options
                <br className={"brClass"} />
                <strong>
                  <Phone
                    style={{
                      color: "#008670",
                      width: "20px",
                      marginBottom: "-10px",
                    }}
                  />{" "}
                  477-740-2818
                  <br className={"brClass"} />
                  <Phone
                    style={{
                      color: "#008670",
                      width: "20px",
                      marginBottom: "-10px",
                    }}
                  />
                  011-52-477-740-2818
                  <br className={"brClass"} />
                  <Phone
                    style={{
                      color: "#008670",
                      width: "20px",
                      marginBottom: "-10px",
                    }}
                  />
                  +52-477-740-2818
                </strong>
                <br className={"brClass"} />
                presiona 1 para español
                <br className={"brClass"} /> <br className={"brClass"} />
                To have your vehicle repaired in the <strong>U.S.</strong>,
                contact:
                <br className={"brClass"} />
                <b>
                  <a
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                    href="http://www.ICclaims.com"
                    target="_blank"
                  >
                    International Claims Service
                  </a>
                </b>
                <br className={"brClass"} />
                <a
                  className="link-claims"
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="http://www.ICclaims.com"
                  target="_blank"
                >
                  Servicio Internacional de Reclamos
                </a>
                <br className={"brClass"} />
                <Phone
                  style={{
                    color: "#008670",
                    width: "20px",
                    marginBottom: "-10px",
                  }}
                />
                <strong> 800-284-9783</strong>
                <br className={"brClass"} />
                <Phone
                  style={{
                    color: "#008670",
                    width: "20px",
                    marginBottom: "-10px",
                  }}
                />
                <strong> 956-682-1353</strong>
                <br className={"brClass"} />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="mailto:claim@ICclaims.com"
                >
                  claim@ICclaims.com
                </a>
                <br className={"brClass"} />
                PO Box 310
                <br className={"brClass"} />
                McAllen TX 78505-0310
                <br className={"brClass"} /> <br className={"brClass"} />
                <strong>Documents</strong>
                <br className={"brClass"} />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="http://www.ICclaims.com"
                  target="_blank"
                >
                  Download Claims Forms
                </a>
                <br />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="https://agent.sanborns.com/assets/documentation/Documentation/In case of an accident.pdf"
                  target="_blank"
                >
                  In case of accident
                </a>
                <br />
                <br />
                <strong>Links</strong>
                <br />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="https://producer.imglobal.com/international-insurance-plans.aspx?IMGAC=20849"
                  target="_blank"
                >
                  Travel Medical Ins
                </a>
                <br />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="http://www.sanborns.com/"
                  target="_blank"
                >
                  Sanborn’s webpage
                </a>
                <br />
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                  href="https://www.jdpower.com/cars"
                  target="_blank"
                >
                  Vehicle Values
                </a>
                <br />
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        {/*<GridItem xs={12} sm={12} md={4} lg={4}>
          <a
            target="_blank"
            href="https://producer.imglobal.com/international-insurance-plans.aspx?imgac=20849"
          >
            <img
              style={{ width: "100%", marginBottom: "6%" }}
              src="https://producer.imglobal.com/IMG_Affiliate_Graphics/Banners/img_468x60.sflb.ashx"
              alt="Travel Medical Insurance - International Medical Group"
            />
          </a>
        </GridItem>
                */}
      </GridContainer>
      <GridContainer
        style={{
          display: showActivePolicies
            ? "none"
            : showAllPolicies
            ? "block"
            : "none",
          marginTop: "20px",
        }}
      >
        <GridItem xs={12} style={{ textAlign: "right" }}>
          <Button
            color="twitter"
            onClick={() => {
              setAllPolicies(false);
            }}
          >
            <ArrowBack className={classes.inputAdornmentIcon} />
            <label style={{ paddingLeft: "5px", color: "#FFF" }}>
              Back to home
            </label>
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <h4>
                  Need a New Policy? Choose to issue a new one from a previous
                  policy below.{" "}
                </h4>
              </CardIcon>
            </CardHeader>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <h3
                  className={classes.cardIconTitle}
                  style={{ marginLeft: "25px" }}
                >
                  My Policies
                </h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <CardBody>
                  <Table tableData={[ordenes]} />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
