import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Language from "@material-ui/icons/Language";
import Phone from "@material-ui/icons/Phone";
import Person from "@material-ui/icons/Person";
import Menu from "@material-ui/icons/Menu";
import LockOpen from "@material-ui/icons/LockOpen";
import MonetizationOn from "@material-ui/icons/MonetizationOn";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/guestNavbarStyle.js";
import logo from "assets/img/logo.png";
import logo4 from "assets/img/logo_4.png";
import { useTranslation } from "react-i18next";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import { ClassNames } from "@emotion/react";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const userType = localStorage.getItem("userType");

  const changeLanguage = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    const urlSegments = window.location.pathname.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (lastSegment === "es") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const phpSystem = process.env.REACT_APP_PHP_SYSTEM;
  var list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <div>
          <Language className={classes.listItemIcon} />
          <CustomSelect
            menuItems={[
              { value: "en", label: "English" },
              { value: "es", label: "Español" },
            ]}
            formControlProps={{
              fullWidth: false,
              className: "languageStyle",
            }}
            inputProps={{
              value: i18n.language,
              onChange: handleLanguageChange,
            }}
          />
        </div>
        {/*<NavLink to={"/admin/dashboard"} className={classes.navLink}>
          <Language className={classes.listItemIcon} />
          <ListItemText
            primary={"Español"}
            disableTypography={true}
            className={classes.listItemText}
          />
          
        </NavLink>*/}
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to={"/admin/dashboard"} className={classes.navLink}>
          <Phone className={classes.listItemIcon} />

          <ListItemText
            primary={t("navbar.call_us")}
            disableTypography={true}
            className={classes.listItemText}
          />
          <span> 800-222-0158</span>
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/auth/login-customer"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/auth/login-customer"),
          })}
        >
          <Person className={classes.listItemIcon} />
          <ListItemText
            primary={
              localStorage.getItem("userToken") === null
                ? t("navbar.log_in")
                : t("navbar.log_out")
            }
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
      {[1, 2, "1", "2"].includes(userType) && (
        <ListItem className={classes.listItem}>
          <a
            href={phpSystem}
            target="_blank"
            className={cx(classes.navLink, {
              [classes.navLinkActive]: false,
            })}
            rel="noopener noreferrer"
          >
            <ListItemText
              primary={t("navbar.home")}
              disableTypography={true}
              className={classes.listItemText}
            />
          </a>
        </ListItem>
      )}
    </List>
  );
  var listSmall = (
    <List className={`${classes.list} ${classes.listFlex}`} dense>
      <ListItem className={`${classes.listItem} ${classes.listItemIconBorder}`}>
        <div
          onClick={() => changeLanguage(i18n.language === "es" ? "en" : "es")}
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Language className={classes.listItemIcon} />
          {i18n.language.toUpperCase()}
        </div>
      </ListItem>
      <ListItem className={`${classes.listItemFlex} ${classes.listItem}`}>
        <NavLink to={"/admin/dashboard"} className={classes.navLink}>
          <Phone
            className={`${classes.listItemIcon} ${classes.listItemIconBorder}`}
          />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/auth/login-customer"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/auth/login-customer"),
          })}
        >
          <Person
            className={`${classes.listItemIcon} ${classes.listItemIconBorder}`}
          />
        </NavLink>
      </ListItem>
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <img
            src={logo}
            alt="Sanborn's Mexico Auto Insurance"
            style={{ boxShadow: "none", width: "100px", cursor: "pointer" }}
            onClick={() => {
              let locationRedirect =
                userType == null ? "/auth/login-customer" : "/admin/home";
              window.location.href = locationRedirect;
            }}
          />
        </div>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>{listSmall}</Hidden>
        {/*<Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
            */}
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
