import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/CustomButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import { getUsersList } from "views/Services/UsersService";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { formatNumber } from "components/Utils/utils.js";
import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import stylesInput from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useTranslation } from "react-i18next";
import TablePagination from "@mui/material/TablePagination";

import CustomInput from "components/CustomInput/CustomOutlinedInput.js";

import axios from "axios";

const useStyles = makeStyles(styles);
const useStylesInput = makeStyles(stylesInput);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
function selectCustomerModal({ open, setOpen, selectCustomer }) {
  const { t } = useTranslation();
  const activeRequestRef = useRef(null);
  const cancelTokenSourceRef = useRef(null);
  const classes = useStyles();
  const classesInput = useStylesInput();
  const classesPrincipal = useStylesPrincipal();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);

  const [search, setSearch] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paginationCounts, setPaginationCounts] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setAssignCustomer = (customerId) => {
    setOpen(false);
    selectCustomer(customerId);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, search]);

  const handleFormChange = (event) => {
    setSearch(event.target.value);
  };

  const getUsers = async () => {
    let dataGrid = {
      draw: "1",
      option: "all",
      columns: {
        0: {
          data: "client_number",
          name: "",
          searchable: "true",
          orderable: "true",
          search: {
            value: "",
            regex: "false",
          },
        },
      },
      order: {
        0: {
          column: "0",
          dir: "asc",
        },
      },
      start: page * rowsPerPage,
      length: rowsPerPage,
      search: {
        value: search,
        regex: "false",
      },
      token: localStorage.getItem("userToken"),
    };

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Solicitud cancelada por una nueva búsqueda."
      );
      console.log("solicitud cancelada");
      activeRequestRef.current = null;
    }

    const newCancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = newCancelTokenSource;

    dataGrid.cancelToken = newCancelTokenSource.token;

    activeRequestRef.current = await dispatch(
      getUsersList(dataGrid)
    ).catch((err) => {
      if (axios.isCancel(err)) {
        console.log("Solicitud cancelada:", err.message);
      } else {
        console.error("Error en la petición:", err);
      }
    });

    if (activeRequestRef.current.payload.result) {
      if(activeRequestRef.current.payload.data !== "undefined"){

      }
      setUsers(activeRequestRef.current.payload.data.data);
      setPaginationCounts(
        Math.ceil(activeRequestRef.current.payload.data.recordsTotal / 10)
      );
    }
  };

  return (
    <BootstrapDialog
      onClose={() => setOpen(false)}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{t("selectCustomerModal.title")}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={8}></GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText={t("step2.search")}
              formControlProps={{
                fullWidth: true,
                className: classesInput.customFormControlClasses,
              }}
              inputProps={{
                name: "search",
                value: search,
                onChange: handleFormChange,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {users.map((item, index) => (
            <GridItem xs={12} md={4} key={index}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  boxShadow: "lg",
                  marginBottom: "20px",
                }}
              >
                <CardContent>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("selectCustomerModal.customerInfo")}
                    </div>
                    <div
                      className={`${classesPrincipal.black} ${classesPrincipal.text}`}
                      style={{
                        width: "100%",
                        textTransform: "none !important",
                      }}
                    >
                      {item.type_name || "-"}
                    </div>

                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black} ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                      style={{
                        width: "100%",
                        textTransform: "none !important",
                      }}
                    >
                      {item.customer_name || "-"} {item.address || "-"}{" "}
                      {item.city || item.state || "-"}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectCustomerModal.phoneLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        {item.phone || "-"}
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectCustomerModal.emailLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        {item.email || "-"}
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectCustomerModal.driverLicenseLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        {item.dl_number || "-"}
                      </span>
                    </div>
                  </GridItem>
                  <GridContainer>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      <Button
                        variant={"green"}
                        size="sm"
                        onClick={() => setAssignCustomer(item.client_number)}
                      >
                        {t("selectCustomerModal.selectVehicleButton")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
        <GridContainer>
          <GridItem xs={3}></GridItem>
          <GridItem xs={7} style={{ textAlign: "right !important" }}>
            <TablePagination
              component="div"
              count={paginationCounts}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6}>
            <Button variant="white" block onClick={() => setOpen(false)}>
              {t("selectCustomerModal.cancelButton")}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default selectCustomerModal;
