import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/CustomButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import { getVehiclesList } from "views/Services/VehiclesService";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { formatNumber } from "components/Utils/utils.js";
import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
function SelectVehicleModal({ open, setOpen, selectVehicle, vehicles }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesPrincipal = useStylesPrincipal();

  return (
    <BootstrapDialog
      onClose={() => setOpen(false)}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{t("selectVehicleModal.title")}</DialogTitle>
      <DialogContent>
        <GridContainer>
          {vehicles.map((item, index) => (
            <GridItem xs={12} md={4} key={index}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  boxShadow: "lg",
                  marginBottom: "20px",
                }}
              >
                <CardContent>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.gray}`}
                    >
                      {t("selectVehicleModal.vehicleInfo")}
                    </div>
                    <div
                      className={`${classesPrincipal.black} ${classesPrincipal.text}`}
                      style={{
                        width: "100%",
                        textTransform: "none !important",
                      }}
                    >
                      {item.type_name || "-"}
                    </div>

                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black} ${classesPrincipal.font700} ${classesPrincipal.fontSize14}`}
                      style={{
                        width: "100%",
                        textTransform: "none !important",
                      }}
                    >
                      {item.year_model || "-"}{" "}
                      {item.make_vehicle || item.make || "-"}{" "}
                      {item.model_vehicle || item.model || "-"}
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectVehicleModal.valueLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        ${formatNumber(item.total_value) || "-"}
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectVehicleModal.platesLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        {item.plates || "-"}
                      </span>
                    </div>
                    <div
                      className={`${classesPrincipal.textNormal} ${classesPrincipal.black}`}
                    >
                      {t("selectVehicleModal.vinLabel")}:{" "}
                      <span className={`${classesPrincipal.font700}`}>
                        {item.vin || "-"}
                      </span>
                    </div>
                  </GridItem>
                  <GridContainer>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      <Button
                        variant={"green"}
                        size="sm"
                        onClick={() => selectVehicle(item)}
                      >
                        {t("selectVehicleModal.selectVehicleButton")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
        <GridContainer>
          <GridItem xs={6}>
            <Button variant="white" block onClick={() => setOpen(false)}>
              {t("selectVehicleModal.cancelButton")}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default SelectVehicleModal;
