import zIndex from "@material-ui/core/styles/zIndex";
import {
  defaultFont,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const notificationsStyle = (theme) => ({
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px",
  },
  cardHeader: {
    zIndex: "3",
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: "14px",
    margin: "0 0 10px",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  marginRight: {
    marginRight: "5px",
  },
  modalSectionTitle: {
    marginTop: "30px",
  },
  modalTitlePayment: {
    fontWeight: "bold !important"
  },
  priceTitle: {
    fontWeight: "bold !important",
    fontSize: "23px",
    marginBottom: "1em",
    color: "#13816F"
  },
  buttonPaymentClose: {
    [theme.breakpoints.only("xs")]: {
      top: "-1%",
      left: "92%",
    },
    position: "absolute",
    top: "-2%",
    left: "96%",
    backgroundColor: "#FFC14B !important",
    color: "#000 !important",
    opacity: "1 !important",
    width: "20px",
    height: "40px",
    zIndex: "4000"
  },
  linkPayment: {
    color: "#FFF !important",
    textDecoration: "underline !important"
  },
  ...modalStyle(theme),
});

export default notificationsStyle;
