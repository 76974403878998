import {
  grayColor,
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  blackColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  hexToRgb,
  greenColor,
  yellowColor,
  defaultFont400,
} from "assets/jss/material-dashboard-pro-react.js";

const alertStyle = {
  alert: {
    ...defaultFont400,
    borderRadius: "8px",
  },
  default: {
    color: blackColor,
    backgroundColor: "rgba(23, 133, 115, 0.07)",
  },
  dangerOutlined: {
    color: blackColor,
    backgroundColor: "rgba(198, 48, 55, 0.08)",
    border: "1px solid #C63037",
  },
  successOutlined: {
    color: blackColor,
    backgroundColor: whiteColor+ " !important",
    border: "1px solid " + greenColor,
  },
};

export default alertStyle;
