import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import logo from "assets/img/logo.png";
import logo4 from "assets/img/logo_4.png";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Step1 from "./GuestSteps/Step1.js";
import Step2 from "./GuestSteps/Step2.js";
import Step3 from "./GuestSteps/Step3.js";
import CreditCard from "components/CreditCard/CreditCard.js";
import CreditCardItem from "components/CreditCardItem/CreditCardItem.js";
import SweetAlert from "react-bootstrap-sweetalert";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { RotatingLines } from "react-loader-spinner";
import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { IconButton, Tooltip } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CloseIcon from "../../../src/assets/img/error.gif";
import { getTokenClient } from "components/Utils/applePay.js";
import { AccountCircle, Phone, Email } from "@material-ui/icons";

import "../../assets/css/freewayStyle.css";
import "../../assets/css/stylesPage.css";
import "../../assets/css/stylesInputs.css";

import {
  formatCreditCardNumber,
  formatExpirationDate,
  setDataPolicy,
  formatNumber,
  getMaskNumberCard,
  currentDateEffective,
  addDays,
  sanatizeMessageHtml,
  getEffectiveTime,
  verifyLength,
} from "components/Utils/utils.js";

import { Catalogs } from "views/Services/CatalogsService.js";
import {
  getAgencyData,
  getAgencyIdByCode,
} from "views/Services/AgencyService.js";

import { issue, checkQuote } from "views/Services/OrdersService";

const monthList = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

const timeZoneList = {
  1: 12,
  2: 8,
  3: 17,
  4: 33,
  5: 12,
  6: 33,
};
const trailerTypes = [
  { id: 1, name: "Trailer" },
  { id: 2, name: "Boat" },
  { id: 3, name: "Other" },
  { id: 4, name: "ATV/UTV" },
];
let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const currentDate = currentDateEffective();
const expirationData = addDays(3, "effectivedate", {
  effectivedate: currentDate,
});
const coverages = {
  physical_damage: false,
  theft: false,
  legalaid: true,
  roadside: true,
  gapcoverage: false,
  premierepackage: true,
  fixeddeductible: true,
  effectivedate: currentDate,
  expirationdate: "",
  timeset: getEffectiveTime(),
  bordercross: "",
  csllimit: "500000",
  excessliab: "150000",
  medicallimit: "5000",
  policy_type: "1",
  policynotes: "",
  damageded_customer: 2,
  theftded_customer: 5,
  pd_ded: 500,
  theft_ded: 1000,
  days: 0,
  premium: 0,
  policy_fee: 0,
  iva: 0,
  total: 0,
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);
const useExtFormStyles = makeStyles(extendedFormstyles);
const useFormStyles = makeStyles(formStyles);
const useStylesDialog = makeStyles(stylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function WizardView() {
  const routeParams = useParams();
  const { id, type, producerCode, kiosko } = routeParams;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const getVehicleType = urlParams.get("ChooseVehicle");
  const getDays = urlParams.get("txtDays");
  const getValue = urlParams.get("txtValue");

  const dispatch = useDispatch();
  const [showButtonCheckout, setShowButtonCheckout] = React.useState(false);
  const [data, setData] = useState({
    data_customer: {
      id: "New Client",
      dl_number: "",
      dl_country_id: 0,
      valid_license: "",
      dl_state_id: 0,
      first_name: "",
      middle_name: "",
      last_name: "",
      address: "",
      zipcode: "",
      city: "",
      country_id: 0,
      state_id: 0,
      phone: "",
      email: "",
      additional_insured: "",
      agency_id: "",
    },
    coverages: {},
    selectedVehicle: {},
  });

  const classesExtForm = useExtFormStyles();
  const [agencyData, setAgencyData] = useState(null);
  const [quoteId, setQuoteId] = useState(0);
  const [fastQuote, setFastQuote] = useState(true);
  const [fullQuoteVehicle, setFullQuoteVehicle] = useState(false);
  const [fullQuoteRates, setFullQuoteRates] = useState(false);
  const [calculateRates, setCalculateRates] = useState(false);
  const [addVehicle, setAddVehicle] = useState(false);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [openModalWelcome, setOpenModalWelcome] = useState(true);
  const [saveCard, setSaveCard] = useState(false);
  const [vehicleDataFromSite, setVehicleDataFromSite] = useState(false);
  const [paymentTypeState, setPaymentTypeState] = React.useState("success");
  const [paymentType, setPaymentType] = React.useState(2);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const [openPhone, setOpenPhone] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openLogIn, setOpenLogIn] = React.useState(false);
  const setAlertAgencyData = async (agencyId) => {
    const resultSubmit = await dispatch(getAgencyData(`?id=${agencyId}`));
    setAgencyData(resultSubmit.payload.data);
  };

  const getAgencyId = async (agencyCode) => {
    agencyCode = agencyCode.toString();
    const resultSubmit = await dispatch(
      getAgencyIdByCode(`?agencyCode=${agencyCode.padStart(5, "0")}`)
    );
    if (typeof resultSubmit.payload.data.id != "undefined") {
      setAgency(resultSubmit.payload.data.id);
      let dataCoverages = {...data};
      dataCoverages.coverages.timeset = getEffectiveTime();
      dataCoverages.coverages.bordercross = resultSubmit.payload.data.default_border_crossing;
      dataCoverages.coverages.timezone = timeZoneList[resultSubmit.payload.data.default_border_crossing];
      setData(dataCoverages);
    } else {
      window.location = "/auth/login";
    }
  };

  React.useEffect(() => {
    if (Number(id)) {
      window.location.href = "/auth/login";
    } else {
      getCatalogs();
    }

    if (window.ApplePaySession) {
      var merchantIdentifier = "merchant.sanborns.applepay";
      var promise = ApplePaySession.canMakePaymentsWithActiveCard(
        merchantIdentifier
      );
      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          setIsAppleDevice(true);
        }
      });
    }
  }, [dispatch]);

  const classes = useStyles();
  const classesAlerts = useStylesAlert();
  const classesForm = useFormStyles();
  const classesDialogs = useStylesDialog();
  const cardDataInitial = {
    "cnumber-card_string": "",
    "fname-card": "",
    "lname-card": "",
    "month-card": "",
    "year-card": "",
    expirationCard: "",
    focused: "",
    "id-card": "none",
  };
  const [card, setCard] = React.useState(cardDataInitial);
  const [showWizard, setshowWizard] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [clientCards, setClientCards] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [alertLoading, setAlertLoading] = React.useState(null);
  const [alertAgency, setAlertAgency] = React.useState(false);
  const [openModalError, setOpenModalError] = React.useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const setCardData = (cards, cardId) => {
    let cardData = {};
    cards.forEach((element) => {
      if (element.id == cardId) {
        cardData["id-card"] = element.id;
        cardData["fname-card"] = element.first_name;
        cardData["lname-card"] = element.last_name;
        cardData["cnumber-card_string"] = element.number;
        cardData["type-ccard"] = element.card_type;
        let monthExpDate =
          typeof monthList[element.expiration_month] == "undefined"
            ? ""
            : monthList[element.expiration_month];
        cardData[
          "expirationCard"
        ] = `${monthExpDate}/${element.expiration_year}`;
        cardData["month-card"] = monthList[element.expiration_month];
        cardData["year-card"] = element.expiration_year;
        cardData["street-card"] = element.street;
        cardData["co-card"] = element.co;
        cardData["city-card"] = element.last_name;
        cardData["state-card"] = element.state;
        cardData["code-card"] = element.zipcode;
      }
    });
    return cardData;
  };

  const handleChangeRadio = (event) => {
    let dataPolicy = { ...data };
    let cardData = setCardData(clientCards, event.target.value);
    dataPolicy.card = cardData;
    setSelectedValue(event.target.value);
    setCard(cardData);
    setData(dataPolicy);
  };

  const updateDataStepToData = (dataStep) => {
    setData(dataStep);
  };

  const setSummary = () => {
    setshowWizard(!showWizard);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleTooltipClose = (typeTool) => {
    switch (typeTool) {
      case "phone":
        setOpenPhone(false);
        break;
      case "email":
        setOpenEmail(false);
        break;
      case "login":
        setOpenLogIn(false);
        break;
    }
  };

  const handleTooltipOpen = (typeTool) => {
    switch (typeTool) {
      case "phone":
        setOpenPhone(true);
        break;
      case "email":
        setOpenEmail(true);
        break;
      case "login":
        setOpenLogIn(true);
        break;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCard(cardDataInitial);
  };

  const closeDialogWelcome = () => {
    setOpenModalWelcome(false);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const setEmailDLFastquote = (value, type) => {
    let dataPolicy = { ...data };
    if (type == "customerEmail") {
      dataPolicy.data_customer.email = value;
    } else {
      dataPolicy.data_customer.dl_number = value;
    }
    setData(dataPolicy);
  };

  const successPolicy = (orderData) => {
    setAlert(null);
    localStorage.setItem("userToken", orderData.data.dataLogin.data);
    if (type == "kiosko") {
      localStorage.setItem("producerCode", producerCode);
      localStorage.setItem("kiosko", true);
      localStorage.setItem("sale", true);
    }
    window.location.href =
      `/admin/view/${orderData.data.orderId}` +
      (type == "kiosko" ? "/kiosko" : "");
  };

  const callBackCard = ({ issuer }, isValid) => {};

  const handlerCardData = (cardId) => {
    if (typeof cardId != "undefined") {
      let cardData = setCardData(clientCards, cardId);
      setCard(cardData);
      setIsModalOpen(true);
    }
  };

  const inputHandler = ({ target }) => {
    let cardData = { ...card };
    if (target.id === "cnumber-card_string") {
      let formatCreditCard = formatCreditCardNumber(target.value);
      target.value = formatCreditCard.value;
      cardData["type-ccard"] = formatCreditCard.cardTypeId;
    }
    if (target.id === "expirationCard") {
      target.value = formatExpirationDate(target.value);
      let value = target.value.split("/");
      cardData["month-card"] = value[0] ?? "";
      cardData["year-card"] = value[1] ?? "";
    }
    cardData[target.id] = target.value;
    setCard(cardData);
  };

  const inputHandlerCoverage = ({ target }) => {
    let dataHandler = { ...data };
    if (typeof dataHandler.coverages == "undefined") {
      dataHandler.coverages = {};
    }
    dataHandler.coverages[target.name] = target.value;
    setData(dataHandler);
  };

  const inputHandlerCcv = ({ target }) => {
    let cardData = { ...card };
    let dataPolicy = { ...data };
    cardData["creditcardverificator_string"] = target.value;
    dataPolicy.card = cardData;
    setData(dataPolicy);
  };

  const setAgency = (value) => {
    let dataPolicy = { ...data };
    dataPolicy.data_customer.agency_id = value;
    setAlertAgencyData(value);
    setData(dataPolicy);
  };

  const successAlert = (orderData) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <h5 style={{ color: "black" }}>
            {"   Policy issued ok.   " +
              (type == "kiosko"
                ? "We have emailed a copy of your policy."
                : "")}
          </h5>
        }
        onConfirm={() => successPolicy(orderData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          "issuePolicyGuest " +
          classesAlerts.button +
          " " +
          classesAlerts.success
        }
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };

  const loadingAlert = () => {
    setAlertLoading(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{"Loading"}</h5>}
        allowEscape={false}
        closeOnClickOutside={false}
        showConfirm={false}
        showCancel={false}
      >
        <RotatingLines
          strokeColor="gray"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </SweetAlert>
    );
  };

  const handlerSaveCard = () => {
    let cardDataSave = { ...card };
    setSelectedValue(null);
    setSaveCard(true);
    let validCard = checkDataCard(cardDataSave);
    if (validCard) {
      let monthList = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      };
      let cardData = {
        "client-id-card": data?.data_customer.id,
        id: "none",
        first_name: cardDataSave["fname-card"],
        last_name: cardDataSave["lname-card"],
        number: cardDataSave["cnumber-card_string"].replaceAll(" ", ""),
        card_type: cardDataSave["type-ccard"],
        expiration_month: monthList[cardDataSave["month-card"]],
        expiration_year: cardDataSave["year-card"],
        street: cardDataSave["street-card"],
        co: cardDataSave["co-card"],
        city: cardDataSave["city-card"],
        state: cardDataSave["state-card"],
        zipcode: cardDataSave["code-card"],
        "cnumber-card_string": cardDataSave["cnumber-card_string"],
        mask_number: getMaskNumberCard(cardDataSave["cnumber-card_string"]),
      };
      let cardList = [];
      cardList[0] = cardData;
      setClientCards(cardList);
      setIsModalOpen(false);
    }
  };

  const checkDataCard = (cardDataSave) => {
    let valid = true;
    cardDataSave["month-card"] =
      typeof cardDataSave["month-card"] == "undefined"
        ? ""
        : parseInt(cardDataSave["month-card"]);
    if (
      cardDataSave["cnumber-card_string"].replaceAll(" ", "") < 16 ||
      cardDataSave["cnumber-card_string"] == "" ||
      cardDataSave["cnumber-card_string"] == null ||
      typeof cardDataSave["month-card"] == "undefined" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["fname-card"] == "" ||
      cardDataSave["fname-card"] == null ||
      typeof cardDataSave["fname-card"] == "undefined" ||
      cardDataSave["lname-card"] == "" ||
      cardDataSave["lname-card"] == null ||
      typeof cardDataSave["lname-card"] == "undefined" ||
      cardDataSave["type-ccard"] == "" ||
      cardDataSave["type-ccard"] == null ||
      typeof cardDataSave["type-ccard"] == "undefined" ||
      cardDataSave["month-card"] == "" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["month-card"] > 12 ||
      cardDataSave["month-card"] == 0 ||
      cardDataSave["year-card"] == "" ||
      cardDataSave["year-card"] == null ||
      typeof cardDataSave["year-card"] == "undefined" ||
      cardDataSave["street-card"] == "" ||
      cardDataSave["street-card"] == null ||
      typeof cardDataSave["street-card"] == "undefined" ||
      cardDataSave["city-card"] == "" ||
      cardDataSave["city-card"] == null ||
      typeof cardDataSave["city-card"] == "undefined" ||
      cardDataSave["state-card"] == "" ||
      cardDataSave["state-card"] == null ||
      typeof cardDataSave["state-card"] == "undefined" ||
      cardDataSave["state-card"] == "State" ||
      cardDataSave["code-card"] == "" ||
      cardDataSave["code-card"] == null ||
      typeof cardDataSave["code-card"] == "undefined"
    ) {
      let messageToast = "";
      messageToast +=
        cardDataSave["month-card"] > 12 ||
        cardDataSave["month-card"] == 0 ||
        cardDataSave["month-card"] == ""
          ? "<li>Please enter a valid month expiration date</li>"
          : "";
      messageToast +=
        cardDataSave["year-card"] == ""
          ? "<li>Please enter a valid year expiration date</li>"
          : "";
      messageToast =
        messageToast == ""
          ? "<li>Please check the information in your credit card</li>"
          : messageToast;
      setMessageToast(messageToast);
      setOpenModalError(true);
      valid = false;
    }
    return valid;
  };

  const onApplePayButtonClicked = async () => {
    tokenClient = getTokenClient(data, issuePolicy);
  };

  const processResponseIssue = (resultSubmit) => {
    if (resultSubmit.payload) {
      if (resultSubmit.payload.message.errors) {
        let messageToast = "<ul>";
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `<li key=${index}>${error.Message}</li>`)
        );
        messageToast += `</ul>`;
        setMessageToast(messageToast);
        setOpenModalError(true);
      } else {
        if (typeof resultSubmit.payload.approvalUrl != "undefined") {
          window.location.href = resultSubmit.payload.approvalUrl;
        } else {
          window.dataLayer.push({
            event: "gtm.formSubmit",
            hostname: window.location.origin,
            customer: "new",
            eventLabel: "Sale",
            raterVersion: "LegacyMOB"
          });
          successAlert(resultSubmit.payload);
        }
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const issuePolicy = async (appleData) => {
    let ccvValid =
      typeof data.card?.creditcardverificator_string == "undefined"
        ? false
        : data.card.creditcardverificator_string == ""
        ? false
        : true;
    let validCard = selectedValue != null ? checkDataCard(data.card) : true;

    let paymentValid = true;

    if (paymentType == 2) {
      paymentValid =
        ccvValid && validCard && selectedValue != null ? true : false;
    }
    if (checked.indexOf(1) !== -1 && paymentValid) {
      setToastMessage(false);
      let dataQuote = { ...data };
      dataQuote.paymentType = paymentType;
      let dataPolicy = setDataPolicy(dataQuote);
      dataPolicy.view = "guest";
      if (type == "kiosko") {
        dataPolicy.kiosko = true;
      }
      if (typeof appleData != "undefined") {
        dataPolicy.nonce = appleData.nonce;
        dataPolicy.deviceData = appleData.deviceData;
      }
      loadingAlert();
      const resultSubmit = await dispatch(issue(dataPolicy));
      setAlertLoading(null);
      processResponseIssue(resultSubmit);
    } else {
      if (validCard) {
        let errorMessageIssue =
          checked.indexOf(1) === -1
            ? "<li>Please accept the Terms & Conditions</li>"
            : "";
        errorMessageIssue +=
          selectedValue === null
            ? "<li>Please select a valid credit card</li>"
            : "";
        errorMessageIssue += !ccvValid
          ? "<li>Please enter a ccv valid</li>"
          : "";
        setMessageToast(errorMessageIssue);
        setOpenModalError(true);
      }
    }
  };

  const setAgencyContinue = () => {
    if (type == "kiosko") {
      setAgency(data.data_customer.agency_id);
    }
  };

  const checkInfoQuote = async (event) => {
    let dataPolicy = setDataPolicy(data);
    if (type == "kiosko") {
      dataPolicy.kiosko = true;
    }
    loadingAlert();
    const resultSubmit = await dispatch(checkQuote(dataPolicy));
    setAlertLoading(null);
    if (resultSubmit.payload) {
      if (!resultSubmit.payload.result) {
        let messageToast = "<ul>";
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `<li key=${index}>${error.Message}</li>`)
        );
        messageToast += `</ul>`;
        setMessageToast(messageToast);
        setOpenModalError(true);
      } else {
        setSummary(event);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const getCatalogs = async () => {
    let typeCatalogs = typeof type == "undefined" ? "api" : type;
    const resultSubmit = await dispatch(Catalogs({ type: typeCatalogs }));

    if (resultSubmit.payload.result) {
      let dataPolicy = data;
      dataPolicy["catalogs"] = resultSubmit.payload.catalogs;
      dataPolicy.coverages = coverages;
      const AgencyCode = parseInt(producerCode);
      if (Number(AgencyCode)) {
        getAgencyId(AgencyCode);
      }
      if (type == "kiosko" && typeof producerCode == "undefined") {
        getAgencyId("80000");
      }
      if (getVehicleType != null) {
        dataPolicy.selectedVehicle = {
          client_have_lienholder: undefined,
          description_other: "",
          description_trailer: "",
          insurance_company: undefined,
          insurance_policy_number: undefined,
          lienholder: "",
          lienholder_vehicle_address: "",
          makeId: "",
          make_name: "",
          make_other: "",
          make_trailer: "",
          make_vehicle: "",
          mileage: "",
          modelId: "",
          model_name: "",
          model_other: "",
          model_trailer: "",
          model_vehicle: "",
          other: "0",
          other_policy_company_other: "",
          other_policy_company_trailer: "",
          other_policy_number_other: "",
          other_policy_number_trailer: "",
          plates: "",
          plates_other: "",
          plates_trailer: "",
          sentri_number: "",
          total_value: getValue,
          total_value_other: "",
          total_value_trailer: "",
          trailer: "0",
          type_name: dataPolicy["catalogs"]["vehicle_types"].find(
            (e) => e.id == getVehicleType
          ).name,
          value: getValue,
          vehicle_type_id: getVehicleType,
          vehtype: getVehicleType,
          vehtype_other: "",
          vehtype_trailer: "",
          vin: undefined,
          vin_other: "",
          vin_trailer: "",
          year_model: "",
          year_model_other: "",
          year_model_trailer: "",
          year_vehicle: "",
        };
        dataPolicy.coverages.days = getDays;
        let effectiveData = addDays(getDays, "effectivedate", {
          effectivedate: currentDate,
        });
        dataPolicy.coverages.expirationdate = effectiveData.expirationDate.date;
        setVehicleDataFromSite(true);
      }
      setData(dataPolicy);
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const closeDialogError = () => {
    setOpenModalError(false);
  };

  const handlerDeleteCard = async (cardId) => {
    setClientCards([]);
    setCard(cardDataInitial);
  };

  const setStepData = (type, dataStep) => {
    let dataGuest = { ...data };
    let insertStep = true;
    if (type == "fastquote") {
      setFastQuote(dataStep);
      insertStep = false;
    }
    if (type == "fullQuoteVehicle") {
      setFullQuoteVehicle(dataStep);
      insertStep = false;
    }
    if (type == "fullQuoteRates") {
      setFullQuoteRates(dataStep);
      insertStep = false;
    }
    if (type == "addVehicle") {
      setAddVehicle(dataStep);
      insertStep = false;
    }
    if (type == "vehicleDataFromSite") {
      setVehicleDataFromSite(dataStep);
      insertStep = false;
    }
    if (type == "showAddVehicle") {
      setShowAddVehicle(dataStep);
      insertStep = false;
    }
    if (type == "delete selectedVehicle") {
      let dataPolicy = { ...data };
      delete dataPolicy["selectedVehicle"];
      setData(dataPolicy);
      insertStep = false;
    }

    if (type == "calculateRates") {
      setCalculateRates(dataStep);
      insertStep = false;
    }
    if (type == "coveragesRates") {
      dataGuest.coverages.premium = dataStep.premium;
      dataGuest.coverages.policy_fee = dataStep.policy_fee;
      dataGuest.coverages.iva = dataStep.iva;
      dataGuest.coverages.total = dataStep.total;
      dataGuest.coverages.additional_charges = dataStep.additional_charges;
      dataGuest.coverages.pd_ded = dataStep.pd_ded;
      dataGuest.coverages.theft_ded = dataStep.theft_ded;
    }
    if (insertStep) {
      dataGuest[type] = dataStep;
      setData(dataGuest);
    }
  };

  const handleViewButtonCheckout = (valor) => {
    setShowButtonCheckout(valor);
  };

  return (
    <div className={classes.container}>
      {alert}
      {alertLoading}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardHeader
              image
              className={`${classes.cardHeader} ${classes.textCenter} ${
                producerCode == "77001" ? "freeway-style" : ""
              }`}
              color="white"
              style={{
                margin: producerCode == "77001" ? "0" : "",
                paddingBottom: producerCode == "77001" ? "10%" : "0%",
                borderRadius: producerCode == "77001" ? "0px" : "",
              }}
            >
              <div
                className={classes.socialLine + " textRight"}
                style={{
                  textAlign: "right",
                  paddingTop: "3rem",
                }}
              >
                {type != "kiosko" ? (
                  <>
                    <a
                      href="tel:8002220158"
                      id="phoneButton"
                      color="info"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <Phone></Phone>
                    </a>
                    <a
                      href="mailto:service@sanborns.com"
                      id="emailButton"
                      color="info"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <Email
                        style={{
                          color: "#008A9A",
                        }}
                      ></Email>
                    </a>
                    <a
                      href={
                        `/auth/login-page` +
                        (type == "kiosko" ? "/kiosko/" + producerCode : "")
                      }
                      id="loginButton"
                      color="success"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <AccountCircle></AccountCircle>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href="javascrip:void();"
                      id="phoneButton"
                      color="info"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={() => handleTooltipClose("phone")}
                      >
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("phone")}
                            open={openPhone}
                            arrow
                            placement="bottom-start"
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="8002220158"
                          >
                            <IconButton
                              onClick={() => handleTooltipOpen("phone")}
                              style={{ paddingTop: "0px" }}
                            >
                              <Phone style={{ color: "#008A9A" }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </a>
                    <a
                      href="javascrip:void();"
                      id="emailButton"
                      color="info"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={() => handleTooltipClose("email")}
                      >
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("email")}
                            open={openEmail}
                            arrow
                            placement="bottom-start"
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="service@sanborns.com"
                          >
                            <IconButton
                              onClick={() => handleTooltipOpen("email")}
                              style={{ paddingTop: "0px" }}
                            >
                              <Email
                                style={{
                                  color: "#008A9A",
                                }}
                              ></Email>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </a>
                    <a
                      href="javascrip:void();"
                      id="emailButton"
                      color="info"
                      style={{
                        width: "10%",
                        display: "inline-block",
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={() => handleTooltipClose("login")}
                      >
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true
                            }}
                            onClose={() => handleTooltipClose("login")}
                            open={openLogIn}
                            arrow
                            placement="bottom-start"
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            interactive
                            title={
                              <a
                                href={
                                  "/auth/login-page" +
                                  (type == "kiosko"
                                    ? "/kiosko/" + producerCode
                                    : "")
                                }
                                style={{
                                  color: "#FFF",
                                }}
                              >
                                Log In
                              </a>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                handleTooltipOpen("login");
                              }}
                              style={{ paddingTop: "0px" }}
                            >
                              <AccountCircle
                                style={{
                                  color: "#008A9A",
                                }}
                              ></AccountCircle>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </a>
                  </>
                )}
              </div>
              <img
                src={producerCode == "77001" ? logo4 : logo}
                alt="Sanborn's Mexico Auto Insurance"
                style={{
                  boxShadow: "none",
                  width: producerCode == "77001" ? "40%" : "50%",
                  marginLeft: producerCode == "77001" ? "5%" : "0%",
                }}
              />
              {producerCode == "77001" && (
                <img
                  src={logo}
                  alt="Sanborn's Mexico Auto Insurance"
                  style={{
                    boxShadow: "none",
                    width: "30%",
                    marginLeft: "10%",
                    position: "absolute",
                    marginTop: "-2%",
                  }}
                />
              )}
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
                    <CardBody style={{ textAlign: "left" }}>
                      {fastQuote && producerCode == null && (
                        <FormControl
                          fullWidth
                          className={
                            data.data_customer.agency_id !== ""
                              ? classesExtForm.selectFormControl
                              : classesExtForm.selectFormControlDanger
                          }
                          style={{ marginTop: "10px" }}
                          error={
                            data.data_customer.agency_id === "" ? true : false
                          }
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={
                              data.data_customer.agency_id !== ""
                                ? classesExtForm.selectLabel
                                : classesExtForm.selectLabelDanger
                            }
                            id="agency_id"
                          >
                            Agency: <small>(required)</small>
                          </InputLabel>
                          <Select
                            id="agencyId"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            classes={{
                              select: classesExtForm.select,
                            }}
                            value={data.data_customer.agency_id || ""}
                            inputProps={{
                              name: "agency_id",
                              id: "agency_id",
                              onChange: (e) => setAgency(e.target.value),
                            }}
                          >
                            {data?.catalogs &&
                              data.catalogs.agencies.map((item) => (
                                <MenuItem
                                  classes={{
                                    root: classesExtForm.selectMenuItem,
                                    selected:
                                      classesExtForm.selectMenuItemSelected,
                                  }}
                                  value={item.id}
                                >
                                  {item.agency_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}

                      {(!fastQuote || producerCode != null) && (
                        <a
                          href="javascript:;"
                          onClick={(e) => setAlertAgency(true)}
                        >
                          {(agencyData?.state_name || "") +
                            " - " +
                            (agencyData?.agency_name || "") +
                            " - " +
                            (agencyData?.city || "")}
                        </a>
                      )}
                    </CardBody>
                  </Card>
                </GridItem>

                {fastQuote && (
                  <>
                    {data ? (
                      <GridItem
                        xs={12}
                        style={{ display: showWizard ? "block" : "none" }}
                      >
                        <h3 style={{ textAlign: "center" }}>
                          Mexico Insurance Quote
                        </h3>
                        <Wizard
                          validate
                          steps={[
                            {
                              stepName: "Vehicle",
                              stepComponent: Step2,
                              stepId: "vehicle",
                            },
                            {
                              stepName: "Coverage",
                              stepComponent: Step3,
                              stepId: "coverage",
                            },
                          ]}
                          color="danger"
                          data={data}
                          setData={setData}
                          setStepData={setStepData}
                          inputHandlerCoverage={inputHandlerCoverage}
                          fastQuote={fastQuote}
                          title="Mexico Insurance Quote"
                          finishClasses="no-checkout"
                          previousButtonColor="info"
                          fullQuoteVehicle={fullQuoteVehicle}
                          fullQuoteRates={fullQuoteRates}
                          setAgencyContinue={setAgencyContinue}
                          addVehicle={addVehicle}
                          showAddVehicle={showAddVehicle}
                          vehicleDataFromSite={vehicleDataFromSite}
                          calculateRates={calculateRates}
                          quoteId={quoteId}
                          type={type}
                          setEmailDLFastquote={setEmailDLFastquote}
                          nextButtonId="nextButtonGuest"
                          previousButtonId="previousButtonGuest"
                          checkoutButtonId="checkoutGuest"
                          finishButtonClick={(e) => {
                            setSummary(e);
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </>
                )}

                {!fastQuote && (
                  <>
                    {data ? (
                      <GridItem
                        xs={12}
                        style={{ display: showWizard ? "block" : "none" }}
                      >
                        <h3 style={{ textAlign: "center" }}>
                          Mexico Insurance Quote
                        </h3>
                        <Wizard
                          validate
                          steps={[
                            {
                              stepName: "Client",
                              stepComponent: Step1,
                              stepId: "info",
                            },
                            {
                              stepName: "Vehicle",
                              stepComponent: Step2,
                              stepId: "vehicle",
                            },
                            {
                              stepName: "Coverage",
                              stepComponent: Step3,
                              stepId: "coverage",
                            },
                          ]}
                          color="danger"
                          data={data}
                          setData={setData}
                          setStepData={setStepData}
                          inputHandlerCoverage={inputHandlerCoverage}
                          fastQuote={fastQuote}
                          fullQuoteVehicle={fullQuoteVehicle}
                          fullQuoteRates={fullQuoteRates}
                          addVehicle={addVehicle}
                          showAddVehicle={showAddVehicle}
                          calculateRates={calculateRates}
                          quoteId={quoteId}
                          title="Mexico Insurance Quote"
                          previousButtonColor="info"
                          sendValueErrorRates={handleViewButtonCheckout}
                          nextButtonId="nextButtonContinueGuest"
                          previousButtonId="previousButtonContinueGuest"
                          checkoutButtonId="checkoutContinueGuest"
                          finishButtonClick={(e) => {
                            checkInfoQuote(e);
                          }}
                        />
                      </GridItem>
                    ) : null}
                    <GridItem
                      xs={12}
                      style={{ display: showWizard ? "none" : "block" }}
                    >
                      <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
                        <CardBody style={{ textAlign: "left" }}>
                          <h3
                            style={{ color: "#000000", paddingBottom: "10px" }}
                          >
                            <strong>Summary</strong>
                          </h3>
                          <h4
                            style={{ color: "#f44336", paddingBottom: "10px" }}
                          >
                            <strong>Client Info</strong>
                          </h4>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Client Number:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.id}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Valid driver license?</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.valid_license == "1"
                                ? "Yes"
                                : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>D.L. Country:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.dl_country_id
                                ? data?.catalogs?.countries?.find(
                                    (e) =>
                                      e.id == data?.data_customer?.dl_country_id
                                  ).name
                                : ""}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>D.L. State:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.dl_state_id
                                ? data?.catalogs?.states?.find(
                                    (e) =>
                                      e.id == data?.data_customer?.dl_state_id
                                  ).name
                                : ""}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>First Name:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.first_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Middle Name:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.middle_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Last Name:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.last_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Address:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.address}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Zip Code:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.zipcode}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>City:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.city}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Country:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.country_id
                                ? data?.catalogs?.countries?.find(
                                    (e) =>
                                      e.id == data?.data_customer?.country_id
                                  ).name
                                : ""}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>State:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.state_id
                                ? data?.catalogs?.states?.find(
                                    (e) => e.id == data?.data_customer?.state_id
                                  ).name
                                : ""}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Phone #:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.phone}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Email:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.email}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Additional Insured:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.data_customer?.additional_insured}
                            </GridItem>
                          </GridContainer>
                          <h4
                            style={{ color: "#f44336", paddingBottom: "10px" }}
                          >
                            <strong>Vehicle Info</strong>
                          </h4>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Type:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.type_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Year:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.year_model}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Make:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.make_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Model:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.model_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Value:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              $
                              {formatNumber(
                                data?.selectedVehicle?.total_value || 0
                              )}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Plates:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.plates}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>VIN:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.vin}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Auto Insurance Company:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.insurance_company}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Full Coverage in the US / Canada?</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.insurance_policy_number}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Do you have a lienholder?</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.client_have_lienholder}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Trusted Traveler #:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.selectedVehicle?.sentri_number}
                            </GridItem>
                          </GridContainer>

                          {data?.selectedVehicle?.make_trailer &&
                          data?.selectedVehicle?.make_trailer != "" ? (
                            <>
                              <GridContainer>
                                <GridItem xs={12}>
                                  <center>
                                    <strong>Other vehicle (1)</strong>
                                  </center>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Type</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {trailerTypes.find(
                                    (o) =>
                                      o.id ==
                                      parseInt(
                                        data?.selectedVehicle?.vehtype_trailer
                                      )
                                  )
                                    ? trailerTypes.find(
                                        (o) =>
                                          o.id ==
                                          parseInt(
                                            data?.selectedVehicle
                                              ?.vehtype_trailer
                                          )
                                      ).name
                                    : ""}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>
                                    Insurance Company (If Different)
                                  </strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {
                                    data?.selectedVehicle
                                      ?.other_policy_company_trailer
                                  }
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Ins. Policy #</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {
                                    data?.selectedVehicle
                                      ?.other_policy_number_trailer
                                  }
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Year</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.year_trailer}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Make</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.make_trailer}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Model</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.model_trailer}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>VIN</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.vin_trailer}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Value</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {USDollar.format(
                                    data?.selectedVehicle?.value_trailer
                                  )}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Plates</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.plates_trailer}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Description</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.description_trailer}
                                </GridItem>
                              </GridContainer>
                            </>
                          ) : null}
                          {data?.selectedVehicle?.make_other &&
                          data?.selectedVehicle?.make_other != "" ? (
                            <>
                              <GridContainer>
                                <GridItem
                                  style={{
                                    background: "rgb(170 170 170 / 15%)",
                                  }}
                                  xs={12}
                                >
                                  <center>
                                    <strong>Other vehicle (2)</strong>
                                  </center>
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Type</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {trailerTypes.find(
                                    (o) =>
                                      o.id ==
                                      parseInt(
                                        data?.selectedVehicle?.vehtype_other
                                      )
                                  )
                                    ? trailerTypes.find(
                                        (o) =>
                                          o.id ==
                                          parseInt(
                                            data?.selectedVehicle?.vehtype_other
                                          )
                                      ).name
                                    : ""}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>
                                    Insurance Company (If Different)
                                  </strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {
                                    data?.selectedVehicle
                                      ?.other_policy_company_other
                                  }
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Ins. Policy #</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {
                                    data?.selectedVehicle
                                      ?.other_policy_number_other
                                  }
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Year</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.year_other}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Make</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.make_other}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Model</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.model_other}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>VIN</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.vin_other}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Value</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {USDollar.format(
                                    data?.selectedVehicle?.value_other
                                  )}
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={5}>
                                  <strong>Plates</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.plates_other}
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{ background: "rgb(170 170 170 / 15%)" }}
                              >
                                <GridItem xs={5}>
                                  <strong>Description</strong>
                                </GridItem>
                                <GridItem xs={7}>
                                  {data?.selectedVehicle?.description_other}
                                </GridItem>
                              </GridContainer>
                            </>
                          ) : null}

                          <h4
                            style={{ color: "#f44336", paddingBottom: "10px" }}
                          >
                            <strong>Coverage Info</strong>
                          </h4>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Physical Damage and theft:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.physical_damage ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Liability limit:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.csllimit || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Excess limit:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.excessliab || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Medical limit:</strong>
                            </GridItem>
                            <GridItem xs={7}>{`${formatNumber(
                              data?.coverages?.medicallimit || 0
                            )}/${formatNumber(
                              (data?.coverages?.medicallimit || 0) * 5
                            )}`}</GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Legal Aid:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.legalaid ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Roadside:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.roadside ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Gap Coverage:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.gapcoverage ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Premiere package:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.premierepackage ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Fixed Deductible:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.theft ? "Yes" : "No"}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>PD Deductible:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.pd_ded || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Theft Deductible:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.theft_ded || 0)}
                            </GridItem>
                          </GridContainer>
                          <h4
                            style={{ color: "#f44336", paddingBottom: "10px" }}
                          >
                            <strong>Policy Information</strong>
                          </h4>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Number of days:</strong>
                            </GridItem>
                            <GridItem xs={7}>{data?.coverages?.days}</GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Effective date:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.effectivedate}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Effective time:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.timeset}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Expiration date:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.expirationdate}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Expiration time:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.timeset}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Border Crossing:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              {data?.coverages?.bordercross_name}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>Net Premium:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.premium || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Policy Fee:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.policy_fee || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            style={{ background: "rgb(170 170 170 / 15%)" }}
                          >
                            <GridItem xs={5}>
                              <strong>IVA Tax:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.iva || 0)}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={5}>
                              <strong>Total:</strong>
                            </GridItem>
                            <GridItem xs={7}>
                              ${formatNumber(data?.coverages?.total || 0)}
                            </GridItem>
                          </GridContainer>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              id="editQuoteGuest"
                              color="info"
                              onClick={() => {
                                setshowWizard(!showWizard);
                              }}
                            >
                              Edit Quote
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem
                      xs={12}
                      style={{ display: showWizard ? "none" : "block" }}
                    >
                      <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
                        <CardBody style={{ textAlign: "left" }}>
                          <div
                            className="payment-option"
                            style={{ marginBottom: "50px" }}
                          >
                            <FormControl
                              fullWidth
                              error={
                                paymentTypeState === "error" ? true : false
                              }
                              style={{ marginTop: "10px" }}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={
                                  paymentTypeState !== "error"
                                    ? classesExtForm.selectLabel
                                    : classesExtForm.selectLabelDanger
                                }
                                id="paymenttype"
                              >
                                Payment type: <small>(required)</small>
                              </InputLabel>
                              <Select
                                id="paymentTypeGuest"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                classes={{
                                  select: classesExtForm.select,
                                }}
                                value={paymentType || ""}
                                inputProps={{
                                  name: "paymenttype",
                                  id: "paymenttype",
                                  onChange: (event) => {
                                    event.target.value = event.target.value.toString();
                                    if (!verifyLength(event.target.value, 1)) {
                                      setPaymentTypeState("error");
                                    } else {
                                      setPaymentTypeState("success");
                                    }
                                    setPaymentType(event.target.value);
                                  },
                                }}
                              >
                                <MenuItem
                                  classes={{
                                    root: classesExtForm.selectMenuItem,
                                    selected:
                                      classesExtForm.selectMenuItemSelected,
                                  }}
                                  value={2}
                                >
                                  {"Credit Card"}
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classesExtForm.selectMenuItem,
                                    selected:
                                      classesExtForm.selectMenuItemSelected,
                                  }}
                                  value={5}
                                >
                                  {"PayPal"}
                                </MenuItem>
                                {isAppleDevice && (
                                  <MenuItem
                                    classes={{
                                      root: classesExtForm.selectMenuItem,
                                      selected:
                                        classesExtForm.selectMenuItemSelected,
                                    }}
                                    value={6}
                                  >
                                    {"Apple pay"}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          <div
                            style={{
                              display: paymentType != 2 ? "none" : "block",
                            }}
                          >
                            <Button
                              color="rose"
                              round
                              className={classes.marginRight}
                              onClick={() => setIsModalOpen(true)}
                            >
                              + Add new credit/debit card
                            </Button>

                            <GridContainer xs={12}>
                              {clientCards.length > 0 &&
                                clientCards.map((item) => (
                                  <CreditCardItem
                                    idCard={item.id}
                                    numberCard={item.mask_number}
                                    monthCard={item.expiration_month}
                                    yearCard={item.expiration_year}
                                    nameCard={item.first_name}
                                    lastNameCard={item.last_name}
                                    typeCard={item.card_type}
                                    selectedValue={selectedValue}
                                    handleChangeRadio={handleChangeRadio}
                                    inputHandler={inputHandlerCcv}
                                    handlerCardData={handlerCardData}
                                    handlerDeleteCard={handlerDeleteCard}
                                    guest={true}
                                  />
                                ))}
                            </GridContainer>

                            <CreditCard
                              isOpen={isModalOpen}
                              onClose={closeModal}
                              inputHandler={inputHandler}
                              callBackCard={callBackCard}
                              cardData={card}
                              handlerSaveCard={handlerSaveCard}
                              handlerCardData={handlerCardData}
                              saveCard={saveCard}
                            ></CreditCard>
                          </div>
                          <br />
                          <div
                            className={
                              classesForm.checkboxAndRadio +
                              " " +
                              classesForm.checkboxAndRadioHorizontal
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={(e) => handleToggle(e, 1)}
                                  checkedIcon={
                                    <Check
                                      className={classesForm.checkedIcon}
                                    />
                                  }
                                  icon={
                                    <Check
                                      className={classesForm.uncheckedIcon}
                                    />
                                  }
                                  classes={{
                                    checked: classesForm.checked,
                                    root: classesForm.checkRoot,
                                  }}
                                  inputProps={{
                                    name: "terms",
                                    id: "terms",
                                  }}
                                />
                              }
                              classes={{
                                label: classesForm.label,
                                root: classesForm.labelRoot,
                              }}
                              label="I agree to the Terms and Conditions for the purchase of this insurance policy"
                            />
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {!isAppleDevice && (
                              <Button
                                id="payPolicyGuest"
                                color="success"
                                onClick={issuePolicy}
                              >
                                Pay Policy
                              </Button>
                            )}

                            {isAppleDevice && (
                              <Button
                                color="success"
                                onClick={onApplePayButtonClicked}
                              >
                                Apple Pay
                              </Button>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <Dialog
          classes={{
            root: classesDialogs.center + " " + classesDialogs.modalRoot,
            paper: classesDialogs.modal,
          }}
          open={alertAgency}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAlertCustomer(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classesDialogs.modalHeader}
          >
            <Button
              justIcon
              className={classesDialogs.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setAlertCustomer(false)}
            >
              <Close className={classesDialogs.modalClose} />
            </Button>
            <h4 className={classesDialogs.modalTitle}>Account Check</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classesDialogs.modalBody}
          ></DialogContent>
          <DialogActions className={classesDialogs.modalFooter}>
            <Button
              onClick={() => setAlertCustomer(false)}
              color="danger"
              simple
            >
              Close
            </Button>
            <Button onClick={() => checkCustomer()} color="info" simple>
              Check
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classesDialogs.center + " " + classesDialogs.modalRoot,
            paper: classesDialogs.modal,
          }}
          open={alertAgency}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAlertAgency(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classesDialogs.modalHeader}
          >
            <Button
              justIcon
              className={classesDialogs.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setAlertAgency(false)}
            >
              <Close className={classesDialogs.modalClose} />
            </Button>
            <h4 className={classesDialogs.modalTitle}>Agency Information</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classesDialogs.modalBody}
          >
            <strong>{`Address: `}</strong>
            <br />
            {agencyData?.address} {agencyData?.city} {`,`}{" "}
            {agencyData?.state_name} {agencyData?.zipcode} <br />
            <strong>{`Phone: `}</strong>
            {agencyData?.phone}
            <br />
            <strong>{`Hours of Operation: `}</strong>
            <br />
            {agencyData?.hours_opp1} {agencyData?.hours_opp2}
            {agencyData?.hours_opp3}
            <br />
            <strong>{`Fax: `}</strong>
            {agencyData?.fax}
            <br />
            <strong>{`E-mail: `}</strong>
            {agencyData?.email}
          </DialogContent>
          <DialogActions className={classesDialogs.modalFooter}>
            <Button onClick={() => setAlertAgency(false)} color="danger" simple>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          style={{ zIndex: "3000" }}
          open={openModalError}
          onClose={closeDialogError}
          classes={{
            root: classesDialogs.center + " " + classesDialogs.modalRoot,
            paper: classesDialogs.modal,
          }}
          keepMounted
          TransitionComponent={Transition}
        >
          <DialogTitle
            disableTypography
            className={classesDialogs.modalHeaderError}
            style={{ color: "#e8403c" }}
          >
            <Button
              justIcon
              className={classesDialogs.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={closeDialogError}
            >
              <Close
                className={classesDialogs.modalClose}
                style={{ color: "white" }}
              />
            </Button>
            <h4
              className={classesDialogs.modalTitle}
              style={{ color: "white" }}
            >
              ERROR
            </h4>
          </DialogTitle>
          <DialogContent style={{ textAlign: "left" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanatizeMessageHtml(messageToast),
              }}
            ></div>
          </DialogContent>
        </Dialog>
      </GridContainer>
      <Snackbar
        style={{ zIndex: "3000" }}
        place="br"
        color={typeToast}
        message={messageToast}
        open={toastMessage}
        closeNotification={() => setToastMessage(false)}
        close
      />
    </div>
  );
}