import React from "react";
export default function Icon(props) {
  return (
    <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1266_1765)">
<path d="M0.0644629 1.84668C0.0229829 2.02344 0.000484141 2.20758 0.000244141 2.3973V3.69277H36.0001V2.40043C36.0001 2.20963 35.9773 2.0244 35.9356 1.84668H0.0644629Z" fill="white"/>
<path d="M2.40008 0C1.26128 0 0.313293 0.78549 0.0644531 1.84625H35.9355C35.6867 0.78549 34.7388 0 33.6001 0H2.40008Z" fill="#E53D3C"/>
<path d="M0 5.53906H36V7.38513H0V5.53906Z" fill="white"/>
<path d="M0 3.69238H36V5.53859H0V3.69238Z" fill="#E53D3C"/>
<path d="M0 9.23047H36V11.0765H0V9.23047Z" fill="white"/>
<path d="M0 7.38477H36V9.23098H0V7.38477Z" fill="#E53D3C"/>
<path d="M0 12.9229H36V14.7689H0V12.9229Z" fill="white"/>
<path d="M0 11.0771H36V12.9234H0V11.0771Z" fill="#E53D3C"/>
<path d="M0 14.7695H36V16.6157H0V14.7695Z" fill="#E53D3C"/>
<path d="M0 16.6152H36V18.4613H0V16.6152Z" fill="white"/>
<path d="M0 18.4619H36V20.3083H0V18.4619Z" fill="#E53D3C"/>
<path d="M2.40009 0C1.07153 0 0.00192414 1.06876 0.000244141 2.39687V12.927H16.4171V0H2.40009Z" fill="#2B4896"/>
<path d="M0 20.3076H36V22.1537H0V20.3076Z" fill="white"/>
<path d="M0.0644531 22.1543C0.313293 23.215 1.26132 24.0005 2.40008 24.0005H33.6001C34.7388 24.0005 35.6867 23.215 35.9355 22.1543H0.0644531Z" fill="#E53D3C"/>
<path d="M11.9323 1.2656L12.0975 0.825195L12.2627 1.2656H12.7031L12.3177 1.54085L12.4829 1.98126L12.0975 1.706L11.7121 1.98126L11.8773 1.54085L11.4919 1.2656H11.9323Z" fill="white"/>
<path d="M14.5193 1.2656L14.6844 0.825195L14.8496 1.2656H15.29L14.9046 1.54085L15.0698 1.98126L14.6844 1.706L14.2991 1.98126L14.4642 1.54085L14.0789 1.2656H14.5193Z" fill="white"/>
<path d="M9.34568 1.2656L9.51083 0.825195L9.67598 1.2656H10.1164L9.73103 1.54085L9.89618 1.98126L9.51083 1.706L9.12548 1.98126L9.29063 1.54085L8.90527 1.2656H9.34568Z" fill="white"/>
<path d="M6.75925 1.2656L6.9244 0.825195L7.08955 1.2656H7.52996L7.14461 1.54085L7.30976 1.98126L6.9244 1.706L6.53905 1.98126L6.7042 1.54085L6.31885 1.2656H6.75925Z" fill="white"/>
<path d="M4.17258 1.2656L4.33773 0.825195L4.50288 1.2656H4.94329L4.55794 1.54085L4.72309 1.98126L4.33773 1.706L3.95238 1.98126L4.11753 1.54085L3.73218 1.2656H4.17258Z" fill="white"/>
<path d="M1.36565 1.9812L1.53089 1.54092L1.14551 1.26561H1.58592L1.75103 0.825195L1.91627 1.26561H2.35669L1.9713 1.54092L2.13641 1.9812L1.75103 1.70589L1.36565 1.9812Z" fill="white"/>
<path d="M12.2627 3.76072H12.7031L12.3177 4.03597L12.4829 4.47637L12.0975 4.20112L11.7121 4.47637L11.8773 4.03597L11.4919 3.76072H11.9323L12.0975 3.32031L12.2627 3.76072Z" fill="white"/>
<path d="M14.8496 3.76072H15.29L14.9046 4.03597L15.0698 4.47637L14.6844 4.20112L14.2991 4.47637L14.4642 4.03597L14.0789 3.76072H14.5193L14.6844 3.32031L14.8496 3.76072Z" fill="white"/>
<path d="M9.67598 3.76072H10.1164L9.73103 4.03597L9.89618 4.47637L9.51083 4.20112L9.12548 4.47637L9.29063 4.03597L8.90527 3.76072H9.34568L9.51083 3.32031L9.67598 3.76072Z" fill="white"/>
<path d="M7.08955 3.76072H7.52996L7.14461 4.03597L7.30976 4.47637L6.9244 4.20112L6.53905 4.47637L6.7042 4.03597L6.31885 3.76072H6.75925L6.9244 3.32031L7.08955 3.76072Z" fill="white"/>
<path d="M4.50288 3.76072H4.94329L4.55794 4.03597L4.72309 4.47637L4.33773 4.20112L3.95238 4.47637L4.11753 4.03597L3.73218 3.76072H4.17258L4.33773 3.32031L4.50288 3.76072Z" fill="white"/>
<path d="M1.75106 3.32031L1.91622 3.76072H2.35662L1.97127 4.03597L2.13642 4.47637L1.75106 4.20112L1.36571 4.47637L1.53086 4.03597L1.14551 3.76072H1.58591L1.75106 3.32031Z" fill="white"/>
<path d="M12.2627 6.25486H12.7031L12.3177 6.53011L12.4829 6.97051L12.0975 6.69526L11.7121 6.97051L11.8773 6.53011L11.4919 6.25486H11.9323L12.0975 5.81445L12.2627 6.25486Z" fill="white"/>
<path d="M14.8496 6.25486H15.29L14.9046 6.53011L15.0698 6.97051L14.6844 6.69526L14.2991 6.97051L14.4642 6.53011L14.0789 6.25486H14.5193L14.6844 5.81445L14.8496 6.25486Z" fill="white"/>
<path d="M9.67598 6.25486H10.1164L9.73103 6.53011L9.89618 6.97051L9.51083 6.69526L9.12548 6.97051L9.29063 6.53011L8.90527 6.25486H9.34568L9.51083 5.81445L9.67598 6.25486Z" fill="white"/>
<path d="M7.08955 6.25486H7.52996L7.14461 6.53011L7.30976 6.97051L6.9244 6.69526L6.53905 6.97051L6.7042 6.53011L6.31885 6.25486H6.75925L6.9244 5.81445L7.08955 6.25486Z" fill="white"/>
<path d="M4.50288 6.25486H4.94329L4.55794 6.53011L4.72309 6.97051L4.33773 6.69526L3.95238 6.97051L4.11753 6.53011L3.73218 6.25486H4.17258L4.33773 5.81445L4.50288 6.25486Z" fill="white"/>
<path d="M1.75106 5.81445L1.91622 6.25486H2.35662L1.97127 6.53011L2.13642 6.97051L1.75106 6.69526L1.36571 6.97051L1.53086 6.53011L1.14551 6.25486H1.58591L1.75106 5.81445Z" fill="white"/>
<path d="M12.2627 8.74997H12.7031L12.3177 9.02523L12.4829 9.46563L12.0975 9.19038L11.7121 9.46563L11.8773 9.02523L11.4919 8.74997H11.9323L12.0975 8.30957L12.2627 8.74997Z" fill="white"/>
<path d="M14.8496 8.74997H15.29L14.9046 9.02523L15.0698 9.46563L14.6844 9.19038L14.2991 9.46563L14.4642 9.02523L14.0789 8.74997H14.5193L14.6844 8.30957L14.8496 8.74997Z" fill="white"/>
<path d="M9.67598 8.74997H10.1164L9.73103 9.02523L9.89618 9.46563L9.51083 9.19038L9.12548 9.46563L9.29063 9.02523L8.90527 8.74997H9.34568L9.51083 8.30957L9.67598 8.74997Z" fill="white"/>
<path d="M7.08955 8.74997H7.52996L7.14461 9.02523L7.30976 9.46563L6.9244 9.19038L6.53905 9.46563L6.7042 9.02523L6.31885 8.74997H6.75925L6.9244 8.30957L7.08955 8.74997Z" fill="white"/>
<path d="M4.50288 8.74997H4.94329L4.55794 9.02523L4.72309 9.46563L4.33773 9.19038L3.95238 9.46563L4.11753 9.02523L3.73218 8.74997H4.17258L4.33773 8.30957L4.50288 8.74997Z" fill="white"/>
<path d="M1.75106 8.30957L1.91622 8.74997H2.35662L1.97127 9.02523L2.13642 9.46563L1.75106 9.19038L1.36571 9.46563L1.53086 9.02523L1.14551 8.74997H1.58591L1.75106 8.30957Z" fill="white"/>
<path d="M11.9323 11.2441L12.0975 10.8037L12.2627 11.2441H12.7031L12.3177 11.5194L12.4829 11.9598L12.0975 11.6845L11.7121 11.9598L11.8773 11.5194L11.4919 11.2441H11.9323Z" fill="white"/>
<path d="M14.5193 11.2441L14.6844 10.8037L14.8496 11.2441H15.29L14.9046 11.5194L15.0698 11.9598L14.6844 11.6845L14.2991 11.9598L14.4642 11.5194L14.0789 11.2441H14.5193Z" fill="white"/>
<path d="M9.34568 11.2441L9.51083 10.8037L9.67598 11.2441H10.1164L9.73103 11.5194L9.89618 11.9598L9.51083 11.6845L9.12548 11.9598L9.29063 11.5194L8.90527 11.2441H9.34568Z" fill="white"/>
<path d="M6.75925 11.2441L6.9244 10.8037L7.08955 11.2441H7.52996L7.14461 11.5194L7.30976 11.9598L6.9244 11.6845L6.53905 11.9598L6.7042 11.5194L6.31885 11.2441H6.75925Z" fill="white"/>
<path d="M4.17258 11.2441L4.33773 10.8037L4.50288 11.2441H4.94329L4.55794 11.5194L4.72309 11.9598L4.33773 11.6845L3.95238 11.9598L4.11753 11.5194L3.73218 11.2441H4.17258Z" fill="white"/>
<path d="M1.14551 11.2441H1.58591L1.75106 10.8037L1.91622 11.2441H2.35662L1.97127 11.5194L2.13642 11.9598L1.75106 11.6845L1.36571 11.9598L1.53086 11.5194L1.14551 11.2441Z" fill="white"/>
<path d="M11.1806 3.22833L10.7953 2.95307L10.4099 3.22833L10.5751 2.78792L10.1897 2.51267H10.6301L10.7953 2.07227L10.9604 2.51267H11.4008L11.0155 2.78792L11.1806 3.22833Z" fill="white"/>
<path d="M13.7678 3.22833L13.3824 2.95307L12.9971 3.22833L13.1622 2.78792L12.7769 2.51267H13.2173L13.3824 2.07227L13.5476 2.51267H13.988L13.6026 2.78792L13.7678 3.22833Z" fill="white"/>
<path d="M8.59418 3.22833L8.20883 2.95307L7.82347 3.22833L7.98863 2.78792L7.60327 2.51267H8.04368L8.20883 2.07227L8.37398 2.51267H8.81438L8.42903 2.78792L8.59418 3.22833Z" fill="white"/>
<path d="M6.00751 3.22833L5.62216 2.95307L5.2368 3.22833L5.40196 2.78792L5.0166 2.51267H5.45701L5.62216 2.07227L5.78731 2.51267H6.22771L5.84236 2.78792L6.00751 3.22833Z" fill="white"/>
<path d="M3.42084 3.22833L3.03549 2.95307L2.65013 3.22833L2.81529 2.78792L2.42993 2.51267H2.87034L3.03549 2.07227L3.20064 2.51267H3.64104L3.25569 2.78792L3.42084 3.22833Z" fill="white"/>
<path d="M10.4099 5.72344L10.5751 5.28304L10.1897 5.00779H10.6301L10.7953 4.56738L10.9604 5.00779H11.4008L11.0155 5.28304L11.1806 5.72344L10.7953 5.44819L10.4099 5.72344Z" fill="white"/>
<path d="M12.9971 5.72344L13.1622 5.28304L12.7769 5.00779H13.2173L13.3824 4.56738L13.5476 5.00779H13.988L13.6026 5.28304L13.7678 5.72344L13.3824 5.44819L12.9971 5.72344Z" fill="white"/>
<path d="M7.82347 5.72344L7.98863 5.28304L7.60327 5.00779H8.04368L8.20883 4.56738L8.37398 5.00779H8.81438L8.42903 5.28304L8.59418 5.72344L8.20883 5.44819L7.82347 5.72344Z" fill="white"/>
<path d="M5.2368 5.72344L5.40196 5.28304L5.0166 5.00779H5.45701L5.62216 4.56738L5.78731 5.00779H6.22771L5.84236 5.28304L6.00751 5.72344L5.62216 5.44819L5.2368 5.72344Z" fill="white"/>
<path d="M2.65013 5.72344L2.81529 5.28304L2.42993 5.00779H2.87034L3.03549 4.56738L3.20064 5.00779H3.64104L3.25569 5.28304L3.42084 5.72344L3.03549 5.44819L2.65013 5.72344Z" fill="white"/>
<path d="M10.4099 8.21758L10.5751 7.77718L10.1897 7.50193H10.6301L10.7953 7.06152L10.9604 7.50193H11.4008L11.0155 7.77718L11.1806 8.21758L10.7953 7.94233L10.4099 8.21758Z" fill="white"/>
<path d="M12.9971 8.21758L13.1622 7.77718L12.7769 7.50193H13.2173L13.3824 7.06152L13.5476 7.50193H13.988L13.6026 7.77718L13.7678 8.21758L13.3824 7.94233L12.9971 8.21758Z" fill="white"/>
<path d="M7.82347 8.21758L7.98863 7.77718L7.60327 7.50193H8.04368L8.20883 7.06152L8.37398 7.50193H8.81438L8.42903 7.77718L8.59418 8.21758L8.20883 7.94233L7.82347 8.21758Z" fill="white"/>
<path d="M5.2368 8.21758L5.40196 7.77718L5.0166 7.50193H5.45701L5.62216 7.06152L5.78731 7.50193H6.22771L5.84236 7.77718L6.00751 8.21758L5.62216 7.94233L5.2368 8.21758Z" fill="white"/>
<path d="M2.65013 8.21758L2.81529 7.77718L2.42993 7.50193H2.87034L3.03549 7.06152L3.20064 7.50193H3.64104L3.25569 7.77718L3.42084 8.21758L3.03549 7.94233L2.65013 8.21758Z" fill="white"/>
<path d="M10.4099 10.7127L10.5751 10.2723L10.1897 9.99704H10.6301L10.7953 9.55664L10.9604 9.99704H11.4008L11.0155 10.2723L11.1806 10.7127L10.7953 10.4374L10.4099 10.7127Z" fill="white"/>
<path d="M12.9971 10.7127L13.1622 10.2723L12.7769 9.99704H13.2173L13.3824 9.55664L13.5476 9.99704H13.988L13.6026 10.2723L13.7678 10.7127L13.3824 10.4374L12.9971 10.7127Z" fill="white"/>
<path d="M7.82347 10.7127L7.98863 10.2723L7.60327 9.99704H8.04368L8.20883 9.55664L8.37398 9.99704H8.81438L8.42903 10.2723L8.59418 10.7127L8.20883 10.4374L7.82347 10.7127Z" fill="white"/>
<path d="M5.2368 10.7127L5.40196 10.2723L5.0166 9.99704H5.45701L5.62216 9.55664L5.78731 9.99704H6.22771L5.84236 10.2723L6.00751 10.7127L5.62216 10.4374L5.2368 10.7127Z" fill="white"/>
<path d="M2.65013 10.7127L2.81529 10.2723L2.42993 9.99704H2.87034L3.03549 9.55664L3.20064 9.99704H3.64104L3.25569 10.2723L3.42084 10.7127L3.03549 10.4374L2.65013 10.7127Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1266_1765">
<rect width="36" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


  );
}
