import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
const API_V1 = `${environment.SERVER}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const insertVehicle = createAsyncThunk(
  "vehiclesView/insertVehicle",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/VehicleClient`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const validateVin = createAsyncThunk(
  "vehiclesView/checkVin",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/VehicleClient/checkVin`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getMakes = createAsyncThunk(
  "vehiclesView/getMakes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/VehicleClient/getmakes`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getModels = createAsyncThunk(
  "vehiclesView/getModels",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/VehicleClient/makeModels`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehiclesList = createAsyncThunk(
  "vehiclesView/getVehiclesList",
  async (params, { rejectWithValue }) => {
    try {
      params.token = localStorage.getItem("userToken");
      const response = await axios.post(`${BASE_URL}/VehicleClient/getVehiclesByCustomer`, params);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
