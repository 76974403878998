import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import  GuestNavbar from "components/Navbars/GuestNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/quoteStyle.js";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import axios from "axios";


const useStyles = makeStyles(styles);

export default function Pages(props) {
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    
  });
  axios.defaults.headers.common.Authorization = `Bearer AOPTM6M72K48CTB5AI3JBBD1PTD0PJGGyd8XrqmDS2zf009UTp3gHnBbdtrv6bAeO0MChkXo`;
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/guestRedesing") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    return login;
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    
    <div>
      <div className={classes.wrapper} ref={wrapper}>
      <GuestNavbar></GuestNavbar>
        <div
          id="container-login"
          className={classes.fullPage}
          
        >
         
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/guest" to="/auth/login-page" />
          </Switch>
          <Footer color={"#000"}/>
        </div>
      </div>
    </div>
  );
}