import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import CreditCard from "components/CreditCard/CreditCard.js";
import CreditCardItem from "components/CreditCardItem/CreditCardItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Snackbar from "components/Snackbar/Snackbar.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { RotatingLines } from "react-loader-spinner";
import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import {
  addCard,
  getCardsByClient,
  deleteCard,
} from "../Services/CreditCardService";

import {
  formatCreditCardNumber,
  formatExpirationDate,
  setDataPolicy,
  currentDateEffective,
  formatNumber,
  addDays,
  getEffectiveTime,
  sanatizeMessageHtml,
  formatDateToEffectiveAndExpiration,
  verifyLength,
  checkIfIsKiosko,
} from "components/Utils/utils.js";
import {
  getCustomerByToken,
  getAgencyDataByClient,
  issue,
  checkQuote,
  getAppleTokenClient
} from "views/Services/OrdersService";
import { getPolicyData } from "views/Services/PoliciesService";

const monthList = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

const timeZoneList = {
  1: 12,
  2: 8,
  3: 17,
  4: 33,
  5: 12,
  6: 33,
};
const trailerTypes = [
  { id: 1, name: "Trailer" },
  { id: 2, name: "Boat" },
  { id: 3, name: "Other" },
  { id: 4, name: "ATV/UTV" },
];
let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currentDate = currentDateEffective();
const expirationData = addDays(3, "effectivedate", {
  effectivedate: currentDate,
});

const coverages = {
  physical_damage: false,
  theft: false,
  legalaid: true,
  roadside: false,
  gapcoverage: false,
  premierepackage: false,
  fixeddeductible: true,
  effectivedate: currentDate,
  expirationdate: "",
  timeset: getEffectiveTime(),
  bordercross: "",
  csllimit: "",
  excessliab: "",
  medicallimit: "",
  policy_type: "1",
  policynotes: "",
  damageded_customer: 2,
  theftded_customer: 5,
  pd_ded: 500,
  theft_ded: 1000,
  days: 0,
  premium: 0,
  policy_fee: 0,
  iva: 0,
  total: 0,
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);
const useFormStyles = makeStyles(formStyles);
const useStylesDialog = makeStyles(stylesDialogs);
const useExtFormStyles = makeStyles(extendedFormstyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function WizardView() {
  const routeParams = useParams();
  const { id, type } = routeParams;

  const isKiosko = checkIfIsKiosko();

  const classesForm = useFormStyles();
  const classesDialogs = useStylesDialog();
  const classesExtForm = useExtFormStyles();

  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [agencyData, setAgencyData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [calculateRates, setCalculateRates] = useState(true);
  const [addVehicle, setAddVehicle] = useState(false);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [openModalError, setOpenModalError] = React.useState(false);
  const [paymentTypeState, setPaymentTypeState] = React.useState("success");
  const [paymentType, setPaymentType] = React.useState(2);
  const [saveCard, setSaveCard] = useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  useEffect(() => {
    getCustomer();
    getAgencyData();
  }, [dispatch]);
  const getData = async (datosIniciales) => {
    const resultSubmit = await dispatch(getPolicyData({ id: id }));
    const orden = resultSubmit.payload.data;
    const selectedVehicle = {
      model_vehicle: "",
      make_vehicle: "",
      type_name: "",
      vehicle_type: "",
      id: "",
      lienholder: "",
      insurance_company: "",
      insurance_policy_number: "",
      mileage: "",
      plates: "",
      sp_equip_list: null,
      sp_equip_value: null,
      total_value: "",
      trailer: "",
      vin: "",
      year_model: "",
      client_id: "",
      model: " ",
      vehicle_type_id: "",
      sentri_number: "",
      other: "",
      year_model_trailer: "",
      model_trailer: "",
      vin_trailer: "",
      total_value_trailer: "",
      plates_trailer: "",
      lienholder_trailer: null,
      sp_equip_list_trailer: null,
      sp_equip_value_trailer: null,
      year_model_other: "",
      model_other: "",
      vin_other: "",
      total_value_other: "",
      plates_other: "",
      lienholder_other: null,
      sp_equip_list_other: null,
      sp_equip_value_other: null,
      make_trailer: "",
      make_other: "",
      make: "",
      lienholder_vehicle_address: "",
      lienholder_trailer_address: null,
      lienholder_other_address: null,
      vehtype_trailer: "",
      vehtype_other: "",
      description_trailer: "",
      description_other: "",
      other_policy_company_trailer: "",
      other_policy_number_trailer: "",
      other_policy_company_other: "",
      other_policy_number_other: "",
      commercial: "",
      active: "",
      client_have_lienholder: "",
      vehicle_type_model: "",
      makeId: "",
      name: "",
      modelId: "",
    };

    if (orden) {
      for (const key in datosIniciales.data_customer) {
        if (key !== "id" && key !== "agency_id") {
          datosIniciales.data_customer[key] = orden[key];
        }
      }
      for (const key in selectedVehicle) {
        if (key !== "id") {
          selectedVehicle[key] = orden[key];
        }
      }

      if ([1, 5].includes(parseInt(orden["vehicle_type_id"]))) {
        selectedVehicle["makeId"] = orden["make"];
        selectedVehicle["modelId"] = orden["model"];
      }

      for (const key in coverages) {
        if (!["id", "expirationdate", "effectivedate"].includes(key)) {
          coverages[key] = orden[key];
        }
      }

      coverages["timezone"] = timeZoneList[coverages["bordercross"]];
      coverages["damageded_customer"] = orden["damageded"];
      coverages["theftded_customer"] = orden["theftded"];
      coverages["policynotes"] = orden["notes"];
      coverages["csllimit"] = cslLimitCheck(orden["csllimit"]);

      if (orden.order_status_id == "3") {
        datosIniciales.orderData = {};
        datosIniciales.orderData.quote_id = orden.order_id;
        datosIniciales.orderData.vehicle_id = orden.vehicle_id;
        datosIniciales.orderData.vehicle_order_id = orden.vehicle_order_id;
        datosIniciales.orderData.client_id = orden.client_id;
        datosIniciales.orderData.client_order_id = orden.client_order_id;
        coverages["timeset"] = orden.effective_time;
        coverages["days"] = orden.num_of_days;
        coverages["policy_type"] = orden.policy_type;
        coverages["effectivedate"] = formatDateToEffectiveAndExpiration(
          orden.effective_date,
          "quote"
        ).date;
        coverages["expirationdate"] = formatDateToEffectiveAndExpiration(
          orden.expiration_date,
          "quote"
        ).date;
      }

      coverages.physical_damage =
        coverages.physical_damage == "1" ? "true" : "false";
      coverages.roadside = coverages.roadside == "1" ? "true" : "false";
      coverages.gapcoverage = coverages.gapcoverage == "1" ? "true" : "false";
      coverages.fixeddeductible =
        coverages.fixeddeductible == "1" ? "true" : "false";
      coverages.theft = coverages.theft == "1" ? "true" : "false";
      coverages.legalaid = coverages.legalaid == "1" ? "true" : "false";
      coverages.premierepackage =
        coverages.premierepackage == "0" || coverages.csllimit == "50000" ? "false" : "true";
      datosIniciales.selectedVehicle = selectedVehicle;
      datosIniciales.coverages = coverages;
      setData(datosIniciales);
    }
  };

  const validateCoverage = (coverage) => {
    return coverage == "true" || coverage == "1" || coverage === true
      ? true
      : false;
  };

  const getAgencyData = async () => {
    const resultSubmit = await dispatch(getAgencyDataByClient());
    setAgencyData(resultSubmit.payload.data);
    if (!Number.isInteger(parseInt(id))) {
      let dataPolicy = { ...data };
      const borderList = {
        1: "Arizona",
        2: "California",
        3: "New Mexico/West Texas",
        4: "Texas",
        5: "Douglas",
      };
      dataPolicy.coverages = coverages;
      dataPolicy.coverages.csllimit = resultSubmit.payload.data.csl_limit || "";
      dataPolicy.coverages.medicallimit =
        resultSubmit.payload.data.medical_limit || "";
      dataPolicy.coverages.bordercross =
        resultSubmit.payload.data.default_border_crossing || "";
      dataPolicy.coverages.bordercross_name =
        borderList[resultSubmit.payload.data.default_border_crossing] || "";
      dataPolicy.coverages.excessliab = "150000";
      dataPolicy.coverages.timezone =
        dataPolicy.coverages.bordercross != ""
          ? timeZoneList[parseInt(dataPolicy.coverages.bordercross)]
          : "";
      dataPolicy.coverages.premierepackage = dataPolicy.coverages.csllimit == "50000" ? false : dataPolicy.coverages.premierepackage;
    }
  };

  const cslLimitCheck = (value) => {
    let newValueCsl = value || "";
    newValueCsl = ["100000", "200000", "300000"].includes(newValueCsl) ? "" : newValueCsl;
    return newValueCsl;
  };

  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const getCustomer = async () => {
    const resultSubmit = await dispatch(getCustomerByToken({}));

    getClientCards(resultSubmit.payload.data?.data_customer?.id);
    if (!isNaN(id)) {
      getData(resultSubmit.payload.data);
    } else {
      resultSubmit.payload.data.coverages = coverages;
      setData(resultSubmit.payload.data);
    }
  };
  const classes = useStyles();
  const classesAlerts = useStylesAlert();
  const cardDataInitial = {
    "cnumber-card_string": "",
    "fname-card": "",
    "lname-card": "",
    "month-card": "",
    "year-card": "",
    expirationCard: "",
    focused: "",
  };
  const [card, setCard] = React.useState(cardDataInitial);
  const [showWizard, setshowWizard] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [clientCards, setClientCards] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [alertLoading, setAlertLoading] = React.useState(null);
  const [alertLoadingSaveQuote, setAlertLoadingSaveQuote] = React.useState(
    null
  );
  const [alertAgency, setAlertAgency] = React.useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [errors, setErrors] = useState([]);

  const setCardData = (cards, cardId) => {
    let cardData = {};
    cards.forEach((element) => {
      if (element.id == cardId) {
        cardData["id-card"] = element.id;
        cardData["fname-card"] = element.first_name;
        cardData["lname-card"] = element.last_name;
        cardData["cnumber-card_string"] = element.mask_number;
        cardData["type-ccard"] = element.card_type;
        let monthExpDate =
          typeof monthList[element.expiration_month] == "undefined"
            ? ""
            : monthList[element.expiration_month];
        cardData[
          "expirationCard"
        ] = `${monthExpDate}/${element.expiration_year[2]}${element.expiration_year[3]}`;
        cardData["month-card"] = monthList[element.expiration_month];
        cardData["year-card"] =
          element.expiration_year[2] + element.expiration_year[3];
        cardData["street-card"] = element.street;
        cardData["co-card"] = element.co;
        cardData["city-card"] = element.last_name;
        cardData["state-card"] = element.state;
        cardData["code-card"] = element.zipcode;
      }
    });
    return cardData;
  };

  const handleChangeRadio = (event) => {
    let dataPolicy = { ...data };
    let cardData = setCardData(clientCards, event.target.value);
    dataPolicy.card = cardData;
    setSelectedValue(event.target.value);
    setCard(cardData);
    setData(dataPolicy);
  };

  const setSummary = () => {
    setshowWizard(!showWizard);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCard(cardDataInitial);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successPolicy = (orderId, type) => {
    setAlert(null);
    localStorage.setItem("sale", true);
    window.location.href =
      type == "Quote"
        ? `/admin/wizard/${orderId}/quote`
        : `/admin/view/${orderId}` + (isKiosko ? "/kiosko" : "");
  };

  const callBackCard = ({ issuer }, isValid) => {};

  const handlerCardData = (cardId) => {
    if (typeof cardId != "undefined") {
      let cardData = setCardData(clientCards, cardId);
      setCard(cardData);
      setIsModalOpen(true);
    }
  };

  const inputHandler = ({ target }) => {
    let cardData = { ...card };
    if (target.id === "cnumber-card_string") {
      let formatCreditCard = formatCreditCardNumber(target.value);
      target.value = formatCreditCard.value;
      cardData["type-ccard"] = formatCreditCard.cardTypeId;
    }
    if (target.id === "expirationCard") {
      target.value = formatExpirationDate(target.value);
      let value = target.value.split("/");
      cardData["month-card"] = value[0] ?? "";
      cardData["year-card"] = value[1] ?? "";
    }
    cardData[target.id] = target.value;
    setCard(cardData);
  };

  const inputHandlerCoverage = ({ target }) => {
    let dataHandler = { ...data };
    if (typeof dataHandler.coverages == "undefined") {
      dataHandler.coverages = {};
    }
    dataHandler.coverages[target.name] = target.value;
    setData(dataHandler);
  };

  const inputHandlerCcv = ({ target }) => {
    let cardData = { ...card };
    let dataPolicy = { ...data };
    cardData["creditcardverificator_string"] = target.value;
    dataPolicy.card = cardData;
    setData(dataPolicy);
  };

  const successAlert = (orderId, message, type) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <h5 style={{ color: "black" }}>
            {"   " +
              message +
              (type != "Quote"
                ? " ok." +
                  (isKiosko ? "    We have emailed a copy of your policy." : "")
                : "")}
          </h5>
        }
        onConfirm={() => successPolicy(orderId, type)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          "issuePolicyCustomer " +
          classesAlerts.button +
          " " +
          classesAlerts.success
        }
        id="issuePolicyCustomer"
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };

  const showAlertCard = (cardId) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Do you want delete this card?"
        onConfirm={() => handlerDeleteCard(cardId)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classesAlerts.button + " " + classesAlerts.success}
        cancelBtnCssClass={classesAlerts.button + " " + classesAlerts.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const loadingAlert = () => {
    setAlertLoading(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{"Loading"}</h5>}
        allowEscape={false}
        closeOnClickOutside={false}
        showConfirm={false}
        onConfirm={() => {}}
        showCancel={false}
      >
        <RotatingLines
          strokeColor="gray"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </SweetAlert>
    );
  };

  const handlerSaveCard = async () => {
    let cardDataSave = { ...card };
    setSelectedValue(null);
    setSaveCard(true);
    let validCard = checkDataCard(cardDataSave);
    if (validCard) {
      let monthList = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      };
      let cardData = {
        "client-id-card": data?.data_customer.id,
        "id-card": cardDataSave["id-card"],
        "fname-card": cardDataSave["fname-card"],
        "lname-card": cardDataSave["lname-card"],
        "cnumber-card": cardDataSave["cnumber-card_string"].replaceAll(" ", ""),
        "type-ccard": cardDataSave["type-ccard"],
        "month-card": monthList[parseInt(cardDataSave["month-card"])],
        "year-card": "20" + cardDataSave["year-card"],
        "street-card": cardDataSave["street-card"],
        "co-card": cardDataSave["co-card"],
        "city-card": cardDataSave["city-card"],
        "state-card": cardDataSave["state-card"],
        "code-card": cardDataSave["code-card"],
      };
      const resultSubmit = await dispatch(addCard(cardData));

      if (resultSubmit.payload.result) {
        getClientCards(data.data_customer.id);
        if (cardData["id-card"] > 0) {
          setCard(cardDataInitial);
        }
        setIsModalOpen(false);
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    }
  };

  const checkDataCard = (cardDataSave) => {
    let valid = true;
    cardDataSave["month-card"] =
      typeof cardDataSave["month-card"] == "undefined"
        ? ""
        : parseInt(cardDataSave["month-card"]);
    if (
      cardDataSave["cnumber-card_string"].replaceAll(" ", "") < 16 ||
      cardDataSave["cnumber-card_string"] == "" ||
      cardDataSave["cnumber-card_string"] == null ||
      typeof cardDataSave["month-card"] == "undefined" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["fname-card"] == "" ||
      cardDataSave["fname-card"] == null ||
      typeof cardDataSave["fname-card"] == "undefined" ||
      cardDataSave["lname-card"] == "" ||
      cardDataSave["lname-card"] == null ||
      typeof cardDataSave["lname-card"] == "undefined" ||
      cardDataSave["type-ccard"] == "" ||
      cardDataSave["type-ccard"] == null ||
      typeof cardDataSave["type-ccard"] == "undefined" ||
      cardDataSave["month-card"] == "" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["month-card"] > 12 ||
      cardDataSave["month-card"] == 0 ||
      cardDataSave["year-card"] == "" ||
      cardDataSave["year-card"] == null ||
      typeof cardDataSave["year-card"] == "undefined" ||
      cardDataSave["street-card"] == "" ||
      cardDataSave["street-card"] == null ||
      typeof cardDataSave["street-card"] == "undefined" ||
      cardDataSave["city-card"] == "" ||
      cardDataSave["city-card"] == null ||
      typeof cardDataSave["city-card"] == "undefined" ||
      cardDataSave["state-card"] == "" ||
      cardDataSave["state-card"] == null ||
      typeof cardDataSave["state-card"] == "undefined" ||
      cardDataSave["state-card"] == "State" ||
      cardDataSave["code-card"] == "" ||
      cardDataSave["code-card"] == null ||
      typeof cardDataSave["code-card"] == "undefined"
    ) {
      let messageToast = "";
      messageToast +=
        cardDataSave["month-card"] > 12 ||
        cardDataSave["month-card"] == 0 ||
        cardDataSave["month-card"] == ""
          ? "<li>Please enter a valid month expiration date <br></li>"
          : "";
      messageToast +=
        cardDataSave["year-card"] == ""
          ? "<li>Please enter a valid year expiration date <br></li>"
          : "";
      messageToast =
        messageToast == ""
          ? "<li>Please check the information in your credit card</li>"
          : messageToast;
      setMessageToast(messageToast);
      setOpenModalError(true);
      valid = false;
    }
    return valid;
  };

  const getClientCards = async (clientId) => {
    setClientCards([]);
    setCard(cardDataInitial);
    const resultSubmit = await dispatch(
      getCardsByClient({ clientId: clientId })
    );

    if (resultSubmit.payload.result) {
      if (resultSubmit.payload.result) {
        resultSubmit.payload.data.length > 0
          ? setClientCards(resultSubmit.payload.data)
          : setClientCards([]);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const issuePolicy = async () => {
    let ccvValid =
      typeof data.card?.creditcardverificator_string == "undefined"
        ? false
        : data.card.creditcardverificator_string == ""
        ? false
        : true;
    let validCard = selectedValue != null ? checkDataCard(data.card) : true;

    let paymentValid = true;

    if (paymentType == 2) {
      paymentValid =
        ccvValid && validCard && selectedValue != null ? true : false;
    }

    if (checked.indexOf(1) !== -1 && paymentValid) {
      setToastMessage(false);
      let dataQuote = { ...data };
      dataQuote.paymentType = paymentType;
      let dataPolicy = setDataPolicy(dataQuote);
      if (isKiosko) {
        const agencyFilter = data.catalogs.agencies.filter(
          (agency) =>
            agency.producer_code === localStorage.getItem("producerCode")
        );
        dataPolicy.agency = agencyFilter[0].id;
      }
      if (typeof appleData != "undefined") {
        dataPolicy.nonce = appleData.nonce;
        dataPolicy.deviceData = appleData.deviceData;
      }
      loadingAlert();
      const resultSubmit = await dispatch(issue(dataPolicy));
      setAlertLoading(null);
      if (resultSubmit.payload) {
        if (resultSubmit.payload.message.errors) {
          let messageToast = "<ul>";
          resultSubmit.payload.message.errors.map(
            (error, index) =>
              (messageToast += `<li key=${index}>${error.Message}</li>`)
          );
          messageToast += `</ul>`;
          setMessageToast(messageToast);
          setOpenModalError(true);
        } else {
          if (typeof resultSubmit.payload.approvalUrl != "undefined") {
            window.location = resultSubmit.payload.approvalUrl;
          } else {
            window.dataLayer.push({
              event: "gtm.formSubmit",
              hostname: window.location.origin,
              customer: "existing",
              eventLabel: "Sale",
              raterVersion: "LegacyMOB"
            });
            successAlert(
              resultSubmit.payload.data.orderId,
              resultSubmit.payload.message,
              "Policy"
            );
          }
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    } else {
      if (validCard) {
        setTypeToast("danger");
        let errorMessageIssue =
          checked.indexOf(1) === -1
            ? "<li>Please accept the Terms & Conditions</li>"
            : "";
        errorMessageIssue +=
          selectedValue === null
            ? "<li>Please select a valid credit card</li>"
            : "";
        errorMessageIssue += !ccvValid
          ? "<li>Please enter a ccv valid</li>"
          : "";
        setMessageToast(errorMessageIssue);
        setOpenModalError(true);
      }
    }
  };

  const processResponseIssue = (resultSubmit) => {
    if (resultSubmit.payload) {
      if (resultSubmit.payload.message.errors) {
        let messageToast = "<ul>";
        console.log(resultSubmit.payload.message);
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `<li key=${index}>${error.Message}</li>`)
        );
        messageToast += `</ul>`;
        setMessageToast(messageToast);
        setOpenModalError(true);
      } else {
        if (typeof resultSubmit.payload.approvalUrl != "undefined") {
          window.location = resultSubmit.payload.approvalUrl;
        } else {
          window.dataLayer.push({
            event: "gtm.formSubmit",
            hostname: window.location.origin,
            customer: "existing",
            eventLabel: "Sale",
            raterVersion: "LegacyMOB"
          });
          successAlert(
            resultSubmit.payload.data.orderId,
            resultSubmit.payload.message,
            "Policy"
          );
        }
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const saveQuote = async () => {
    if (data.selectedVehicle != null) {
      setToastMessage(false);
      data.flag_draft = 1;
      let dataPolicy = setDataPolicy(data);
      loadingAlert();
      const resultSubmit = await dispatch(issue(dataPolicy));
      setAlertLoading(null);
      if (resultSubmit.payload) {
        if (resultSubmit.payload.message.errors) {
          let messageToast = "<ul>";
          resultSubmit.payload.message.errors.map(
            (error, index) =>
              (messageToast += `<li key=${index}>${error.Message}</li>`)
          );
          messageToast += `</ul>`;
          setMessageToast(messageToast);
          setOpenModalError(true);
        } else {
          successAlert(
            resultSubmit.payload.data.orderId,
            resultSubmit.payload.message,
            "Quote"
          );
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    } else {
      setTypeToast("danger");
      let errorMessageIssue = "Please select a vehicle valid";
      setMessageToast(errorMessageIssue);
      setToastMessage(true);
    }
  };

  const checkInfoQuote = async (event) => {
    let dataPolicy = setDataPolicy(data);
    loadingAlert();
    const resultSubmit = await dispatch(checkQuote(dataPolicy));
    setAlertLoading(null);
    if (resultSubmit.payload) {
      if (!resultSubmit.payload.result) {
        let messageToast = "<ul>";
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `<li key=${index}>${error.Message}</li>`)
        );
        messageToast += `</ul>`;
        setMessageToast(messageToast);
        setOpenModalError(true);
      } else {
        setSummary(event);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const closeDialogError = () => {
    setOpenModalError(false);
  };

  const handlerDeleteCard = async (cardId) => {
    const resultSubmit = await dispatch(deleteCard({ id: cardId }));
    setAlert(null);
    if (resultSubmit.payload.result) {
      if (resultSubmit.payload.result) {
        getClientCards(data.data_customer.id);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const setStepData = (type, dataStep) => {
    if (type == "calculateRates") {
      setCalculateRates(dataStep);
    }
    if (type == "addVehicle") {
      setAddVehicle(dataStep);
    }
    if (type == "showAddVehicle") {
      setShowAddVehicle(dataStep);
    }
    if (type == "delete selectedVehicle") {
      let dataPolicy = { ...data };
      delete dataPolicy["selectedVehicle"];
      setData(dataPolicy);
    }
    if (type == "coveragesRates") {
      let dataPolicy = { ...data };
      dataPolicy.coverages.premium = dataStep.premium;
      dataPolicy.coverages.policy_fee = dataStep.policy_fee;
      dataPolicy.coverages.iva = dataStep.iva;
      dataPolicy.coverages.total = dataStep.total;
      dataPolicy.coverages.additional_charges = dataStep.additional_charges;
      dataPolicy.coverages.pd_ded = dataStep.pd_ded;
      dataPolicy.coverages.theft_ded = dataStep.theft_ded;
      setData(dataPolicy);
    }
  };

  React.useEffect(() => {
    console.log("data", data); // This will log the updated value
  }, [data]);

  return (
    <GridContainer justify="center">
      {alert}
      {alertLoading}
      <GridItem xs={12}>
        <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
          <CardBody style={{ textAlign: "left" }}>
            <label>My agent:</label>
            <br />
            {agencyData ? (
              <GridContainer>
                <GridItem xs={8} sm={8} md={8}>
                  <a href="javascript:;" onClick={(e) => setAlertAgency(true)}>
                    {agencyData.state_name +
                      " - " +
                      agencyData.agency_name +
                      " - " +
                      agencyData.city}
                  </a>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={4}
                  style={{ textAlign: "right", marginTop: "-20px" }}
                >
                  <Button id="saveQuote" color="success" onClick={saveQuote}>
                    Save Quote
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} style={{ display: showWizard ? "block" : "none" }}>
        <h3 style={{ textAlign: "center" }}>Mexico Insurance Quote</h3>
        <Wizard
          validate
          steps={[
            { stepName: "Client", stepComponent: Step1, stepId: "info" },
            { stepName: "Vehicle", stepComponent: Step2, stepId: "vehicle" },
            { stepName: "Coverage", stepComponent: Step3, stepId: "coverage" },
          ]}
          color="danger"
          data={data}
          setData={setData}
          inputHandlerCoverage={inputHandlerCoverage}
          title="Mexico Insurance Quote"
          calculateRates={calculateRates}
          setStepData={setStepData}
          addVehicle={addVehicle}
          showAddVehicle={showAddVehicle}
          previousButtonColor="info"
          nextButtonId="nextButtonCustomer"
          previousButtonId="previousButtonCustomer"
          checkoutButtonId="checkoutCustomer"
          finishButtonClick={(e) => {
            checkInfoQuote(e);
          }}
        />
      </GridItem>
      <GridItem xs={12} style={{ display: showWizard ? "none" : "block" }}>
        <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
          <CardBody style={{ textAlign: "left" }}>
            <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
              <strong>Summary</strong>
            </h3>
            <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
              <strong>Client Info</strong>
            </h4>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Client Number:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.id}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Valid driver license?</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.data_customer?.valid_license == "1" ? "Yes" : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>D.L. Country:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data?.catalogs?.countries?.find(
                    (e) => e.id == data?.data_customer?.dl_country_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>D.L. State:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data?.catalogs?.states?.find(
                    (e) => e.id == data?.data_customer?.dl_state_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>First Name:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.first_name}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Middle Name:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.middle_name}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Last Name:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.last_name}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Address:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.address}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Zip Code:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.zipcode}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>City:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.city}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Country:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data?.catalogs?.countries?.find(
                    (e) => e.id == data?.data_customer?.country_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>State:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data?.catalogs?.states?.find(
                    (e) => e.id == data?.data_customer?.state_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Phone #:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.phone}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Email:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.data_customer?.email}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Additional Insured:</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.data_customer?.additional_insured}
              </GridItem>
            </GridContainer>
            <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
              <strong>Vehicle Info</strong>
            </h4>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Type:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.type_name}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Year:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.year_model}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Make:</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.selectedVehicle?.make_vehicle == null
                  ? data?.selectedVehicle?.make
                  : data?.selectedVehicle?.make_vehicle}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Model:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.model}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Value:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.selectedVehicle?.total_value || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Plates:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.plates}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>VIN:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.vin}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Auto Insurance Company:</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.selectedVehicle?.insurance_company}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Full Coverage in the US / Canada?</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.selectedVehicle?.insurance_policy_number}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Do you have a lienholder?</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.selectedVehicle?.client_have_lienholder}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Trusted Traveler #:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.selectedVehicle?.sentri_number}</GridItem>
            </GridContainer>

            {data?.selectedVehicle?.make_trailer &&
            data?.selectedVehicle?.make_trailer != "" ? (
              <>
                <GridContainer>
                  <GridItem xs={12}>
                    <center>
                      <strong>Other vehicle (1)</strong>
                    </center>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Type</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {trailerTypes.find(
                      (o) =>
                        o.id == parseInt(data?.selectedVehicle?.vehtype_trailer)
                    )
                      ? trailerTypes.find(
                          (o) =>
                            o.id ==
                            parseInt(data?.selectedVehicle?.vehtype_trailer)
                        ).name
                      : ""}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Insurance Company (If Different)</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.other_policy_company_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Ins. Policy #</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.other_policy_number_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Year</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.year_model_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Make</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.make_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Model</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.model_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>VIN</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.vin_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Value</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {USDollar.format(
                      data?.selectedVehicle?.total_value_trailer
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Plates</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.plates_trailer}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Description</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.description_trailer}
                  </GridItem>
                </GridContainer>
              </>
            ) : null}
            {data?.selectedVehicle?.make_other &&
            data?.selectedVehicle?.make_other != "" ? (
              <>
                <GridContainer>
                  <GridItem
                    style={{
                      background: "rgb(170 170 170 / 15%)",
                    }}
                    xs={12}
                  >
                    <center>
                      <strong>Other vehicle (2)</strong>
                    </center>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Type</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {trailerTypes.find(
                      (o) =>
                        o.id == parseInt(data?.selectedVehicle?.vehtype_other)
                    )
                      ? trailerTypes.find(
                          (o) =>
                            o.id ==
                            parseInt(data?.selectedVehicle?.vehtype_other)
                        ).name
                      : ""}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Insurance Company (If Different)</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.other_policy_company_other}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Ins. Policy #</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.other_policy_number_other}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Year</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.year_model_other}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Make</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.make_other}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Model</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.model_other}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>VIN</strong>
                  </GridItem>
                  <GridItem xs={7}>{data?.selectedVehicle?.vin_other}</GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Value</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {USDollar.format(data?.selectedVehicle?.total_value_other)}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <strong>Plates</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.plates_other}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
                  <GridItem xs={5}>
                    <strong>Description</strong>
                  </GridItem>
                  <GridItem xs={7}>
                    {data?.selectedVehicle?.description_other}
                  </GridItem>
                </GridContainer>
              </>
            ) : null}

            <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
              <strong>Coverage Info</strong>
            </h4>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Physical Damage and theft:</strong>
              </GridItem>
              <GridItem xs={7}>
                {validateCoverage(data?.coverages?.physical_damage)
                  ? "Yes"
                  : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Liability limit:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.csllimit || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Excess limit:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.excessliab || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Medical limit:</strong>
              </GridItem>
              <GridItem xs={7}>{`${formatNumber(
                data?.coverages?.medicallimit || 0
              )}/${formatNumber(
                (data?.coverages?.medicallimit || 0) * 5
              )}`}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Legal Aid:</strong>
              </GridItem>
              <GridItem xs={7}>
                {data?.coverages?.legalaid ? "Yes" : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Roadside:</strong>
              </GridItem>
              <GridItem xs={7}>
                {validateCoverage(data?.coverages?.roadside) ? "Yes" : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Gap Coverage:</strong>
              </GridItem>
              <GridItem xs={7}>
                {validateCoverage(data?.coverages?.gapcoverage) ? "Yes" : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Premiere package:</strong>
              </GridItem>
              <GridItem xs={7}>
                {validateCoverage(data?.coverages?.premierepackage)
                  ? "Yes"
                  : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Fixed Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                {validateCoverage(data?.coverages?.fixeddeductible)
                  ? "Yes"
                  : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>PD Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.pd_ded || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Theft Deductible:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.theft_ded || 0)}
              </GridItem>
            </GridContainer>
            <h4 style={{ color: "#f44336", paddingBottom: "10px" }}>
              <strong>Policy Information</strong>
            </h4>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Number of days:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.days}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Effective date:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.effectivedate}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Effective time:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.timeset}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Expiration date:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.expirationdate}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Expiration time:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.timeset}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Border Crossing:</strong>
              </GridItem>
              <GridItem xs={7}>{data?.coverages?.bordercross_name}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Net Premium:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.premium || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Policy Fee:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.policy_fee || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>IVA Tax:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.iva || 0)}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Total:</strong>
              </GridItem>
              <GridItem xs={7}>
                ${formatNumber(data?.coverages?.total || 0)}
              </GridItem>
            </GridContainer>
            <div style={{ textAlign: "center" }}>
              <Button
                id="editQuoteCustomer"
                color="info"
                onClick={() => {
                  setshowWizard(!showWizard);
                }}
              >
                Edit Quote
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} style={{ display: showWizard ? "none" : "block" }}>
        <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
          <CardBody style={{ textAlign: "left" }}>
            <div className="payment-option" style={{ marginBottom: "50px" }}>
              <FormControl
                fullWidth
                error={paymentTypeState === "error" ? true : false}
                style={{ marginTop: "10px" }}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={
                    paymentTypeState !== "error"
                      ? classesExtForm.selectLabel
                      : classesExtForm.selectLabelDanger
                  }
                  id="paymenttype"
                >
                  Payment type: <small>(required)</small>
                </InputLabel>
                <Select
                  id="paymentTypeCustomer"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  value={paymentType || ""}
                  inputProps={{
                    name: "paymenttype",
                    id: "paymenttype",
                    onChange: (event) => {
                      event.target.value = event.target.value.toString();
                      if (!verifyLength(event.target.value, 1)) {
                        setPaymentTypeState("error");
                      } else {
                        setPaymentTypeState("success");
                      }
                      setPaymentType(event.target.value);
                    },
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={2}
                  >
                    {"Credit Card"}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={5}
                  >
                    {"PayPal"}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: paymentType != 2 ? "none" : "block" }}>
              <Button
                color="rose"
                round
                className={classes.marginRight}
                onClick={() => setIsModalOpen(true)}
              >
                + Add new credit/debit card
              </Button>

              <GridContainer xs={12}>
                {clientCards.length > 0 &&
                  clientCards.map((item) => (
                    <CreditCardItem
                      idCard={item.id}
                      numberCard={item.number}
                      monthCard={item.expiration_month}
                      yearCard={item.expiration_year}
                      nameCard={item.first_name}
                      lastNameCard={item.last_name}
                      typeCard={item.card_type}
                      selectedValue={selectedValue}
                      handleChangeRadio={handleChangeRadio}
                      inputHandler={inputHandlerCcv}
                      handlerCardData={handlerCardData}
                      handlerDeleteCard={showAlertCard}
                    />
                  ))}
              </GridContainer>

              <CreditCard
                isOpen={isModalOpen}
                onClose={closeModal}
                inputHandler={inputHandler}
                callBackCard={callBackCard}
                cardData={card}
                handlerSaveCard={handlerSaveCard}
                handlerCardData={handlerCardData}
                saveCard={saveCard}
              ></CreditCard>
            </div>
            <br />
            <div
              className={
                classesForm.checkboxAndRadio +
                " " +
                classesForm.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={(e) => handleToggle(e, 1)}
                    checkedIcon={<Check className={classesForm.checkedIcon} />}
                    icon={<Check className={classesForm.uncheckedIcon} />}
                    classes={{
                      checked: classesForm.checked,
                      root: classesForm.checkRoot,
                    }}
                    inputProps={{
                      name: "terms",
                      id: "terms",
                    }}
                  />
                }
                classes={{
                  label: classesForm.label,
                  root: classesForm.labelRoot,
                }}
                label="I agree to the Terms and Conditions for the purchase of this insurance policy"
              />
            </div>
            <div style={{ textAlign: "center" }}>
              {paymentType != 6 && (
                <Button
                  id="payPolicyCustomer"
                  color="success"
                  onClick={issuePolicy}
                >
                  Pay Policy
                </Button>
              )}
              {isAppleDevice && paymentType == 6 && (
                <Button color="success" onClick={onApplePayButtonClicked}>
                  Apple Pay
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Snackbar
          place="br"
          color={typeToast}
          message={messageToast}
          open={toastMessage}
          closeNotification={() => setToastMessage(false)}
          close
        />
      </GridItem>
      <Dialog
        classes={{
          root: classesDialogs.center + " " + classesDialogs.modalRoot,
          paper: classesDialogs.modal,
        }}
        open={alertAgency}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertAgency(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialogs.modalHeader}
        >
          <Button
            justIcon
            className={classesDialogs.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertAgency(false)}
          >
            <Close className={classesDialogs.modalClose} />
          </Button>
          <h4 className={classesDialogs.modalTitle}>Agency Information</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classesDialogs.modalBody}
        >
          <strong>{`Address: `}</strong>
          <br />
          {agencyData?.address} {agencyData?.city} {`,`}{" "}
          {agencyData?.state_name} {agencyData?.zipcode} <br />
          <strong>{`Phone: `}</strong>
          {agencyData?.phone}
          <br />
          <strong>{`Hours of Operation: `}</strong>
          <br />
          {agencyData?.hours_opp1} {agencyData?.hours_opp2}
          {agencyData?.hours_opp3}
          <br />
          <strong>{`Fax: `}</strong>
          {agencyData?.fax}
          <br />
          <strong>{`E-mail: `}</strong>
          {agencyData?.email}
        </DialogContent>
        <DialogActions className={classesDialogs.modalFooter}>
          <Button onClick={() => setAlertAgency(false)} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        style={{ zIndex: "3000" }}
        open={openModalError}
        onClose={closeDialogError}
        classes={{
          root: classesDialogs.center + " " + classesDialogs.modalRoot,
          paper: classesDialogs.modal,
        }}
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle
          disableTypography
          className={classesDialogs.modalHeaderError}
          style={{ color: "#e8403c" }}
        >
          <Button
            justIcon
            className={classesDialogs.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={closeDialogError}
          >
            <Close
              className={classesDialogs.modalClose}
              style={{ color: "white" }}
            />
          </Button>
          <h4 className={classesDialogs.modalTitle} style={{ color: "white" }}>
            ERROR
          </h4>
        </DialogTitle>
        <DialogContent style={{ textAlign: "left" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanatizeMessageHtml(messageToast),
            }}
          ></div>
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
}
