import React from "react";
export default function Icon(props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.40002 21.0124C2.40002 17.2369 5.55431 14.1762 12 14.1762C18.4457 14.1762 21.6 17.2369 21.6 21.0124C21.6 21.6131 21.1618 22.1 20.6212 22.1H3.37885C2.83826 22.1 2.40002 21.6131 2.40002 21.0124Z" stroke="#13816F" stroke-width="2"/>
<path d="M15.6 6.5C15.6 8.48823 13.9882 10.1 12 10.1C10.0118 10.1 8.40002 8.48823 8.40002 6.5C8.40002 4.51178 10.0118 2.9 12 2.9C13.9882 2.9 15.6 4.51178 15.6 6.5Z" stroke="#13816F" stroke-width="2"/>
</svg>

  );
}
