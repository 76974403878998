import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import UserLogin from "components/CustomIcons/UserLogin.js";

// @material-ui/icons
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import Button from "components/CustomButtons/CustomButton.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomToogle from "components/CustomToogle/CustomToogle";

import ReactInputVerificationCode from "react-input-verification-code";

import OneClickFields from "components/OneClickFields/CustomOneClickFields.js";

import { checkIfIsKiosko } from "components/Utils/utils";

import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {
  login,
  oneClickLoginAPI,
  oneClickLoginValidation,
  checkDataLogin,
  sendTypeCode,
  checkCode,
} from "../Services/LoginService";
import LoginBaseRedesign from "components/LoginBaseRedesign/LoginBase";
import CardHeader from "components/Card/CardHeader";
import logo from "assets/img/logo.png";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const emptyLogin = {
  login: "",
  password: "",
  maskEmail: "",
  maskPassword: "",
  userId: "",
  producerCode: "",
};

export default function LoginPage() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const urlSegments = window.location.pathname.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (lastSegment === "es") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  const [loginCredentials, setLoginCredentials] = useState(emptyLogin);
  const routeParams = useParams();
  const classes = useStyles();
  const currentUrl = window.location.href;
  const typeLogin = currentUrl.includes("customer") ? "customer" : "agent";
  const { userId, token, type, producerCode } = routeParams;
  const isKiosko = checkIfIsKiosko();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (type == "oneClickLogin") {
      setOneClickLogin(true);
    }
    let pCode = localStorage.getItem("producerCode");
    if (localStorage.getItem("kiosko") === "true") {
      localStorage.clear();
      window.location = "/guest/kiosko/" + pCode;
    }
    if (localStorage.getItem("login") !== null) {
      let loginData = { ...loginCredentials };
      loginData.login = localStorage.getItem("login");
      setLoginCredentials(loginData);
    }
    localStorage.clear();
    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClickValidate(true);
      validateToken();
    }
  }, [dispatch]);

  const [toastMessage, setToastMessage] = useState(false);
  const [oneClickValidate, setOneClickValidate] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);
  const [isCodeSended, setIsCodeSended] = useState(false);
  const [typeCode, setTypeCode] = useState("");
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [sended, setSended] = useState(false);
  const [language, setLanguage] = useState("en");
  const handleFormChange = (e) => {
    const valor = setValueField(e);
    if (e.target.name == "language") {
      setLanguage(valor);
    } else {
      setLoginCredentials({ ...loginCredentials, [e.target.name]: valor });
    }
  };
  const setValueField = (e) => {
    return e.target.name != "login"
      ? e.target.value
      : e.target.value != ""
      ? e.target.value[0].toLowerCase() + e.target.value.slice(1)
      : "";
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const value = loginCredentials;
    if (loginCredentials.login && loginCredentials.password) {
      const resultSubmit = await dispatch(login(value));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast("Success login.");
        setUserLoginData(type, resultSubmit);
      } else {
        checkAgentCode(resultSubmit);
      }
    } else {
      setSended(true);
    }
  };

  const checkAgentCode = (resultSubmit) => {
    if (resultSubmit.payload.message.code) {
      checkLogin();
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        if (key != "code") {
          mensaje += " " + resultSubmit.payload.message[key] + "\n";
        }
      }
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(mensaje);
    }
  };

  const setUserLoginData = (type, resultSubmit) => {
    let userToken =
      typeof resultSubmit.payload.dataLogin != "undefined"
        ? resultSubmit.payload.dataLogin.data
        : resultSubmit.payload.data;
    localStorage.setItem("userToken", userToken);
    localStorage.setItem("userType", resultSubmit.payload.userType);
    if (isKiosko) {
      localStorage.setItem("kiosko", true);
      localStorage.setItem("producerCode", producerCode);
    }
    window.location.replace(
      "/admin/home" + (isKiosko ? "/kiosko/" + producerCode : "")
    );
  };
  const oneClickLoginFunction = async () => {
    const value = loginCredentials;
    if (loginCredentials.login) {
      const data = {
        login: loginCredentials.login,
        domain: window.location.origin,
      };
      const resultSubmit = await dispatch(oneClickLoginAPI(data));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast(
          "Please check your email or SMS messages in order to log in to the tool."
        );
      } else {
        let mensaje = resultSubmit.payload.message;
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(mensaje);
      }
    } else {
      setSended(true);
    }
  };
  const sendCode = async () => {
    if (loginCredentials.login) {
      const data = {
        id: loginCredentials.userId,
        type: typeCode,
      };
      const resultSubmit = await dispatch(sendTypeCode(data));

      if (resultSubmit.payload.result) {
        setToastMessage(true);
        setTypeToast("success");
        setMessageToast(
          "Please check your email or SMS messages in order to log in to the tool."
        );
        setIsCodeSended(true);
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    } else {
      setSended(true);
    }
  };
  const validateToken = async () => {
    const data = {
      userId: userId,
      token: token,
    };
    const resultSubmit = await dispatch(oneClickLoginValidation(data));

    if (resultSubmit.payload.result) {
      setToastMessage(true);
      setTypeToast("success");
      setMessageToast("Success login.");
      localStorage.setItem("userToken", resultSubmit.payload.data);
      window.location.replace("/admin/home");
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.messageCode);
    }
  };

  const checkLogin = async () => {
    const data = {
      data: loginCredentials.login,
    };
    const resultSubmit = await dispatch(checkDataLogin(data));

    if (resultSubmit.payload.result) {
      let loginData = { ...loginCredentials };
      loginData.maskEmail = resultSubmit.payload.data.maskEmail;
      loginData.maskPhone = resultSubmit.payload.data.maskPhone;
      loginData.userId = resultSubmit.payload.data.id;
      setLoginCredentials(loginData);
      setIsValidUser(true);
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast(resultSubmit.payload.message);
    }
  };

  const changeLogin = () => {
    setIsValidUser(false);
    setLoginCredentials(emptyLogin);
    setIsCodeSended(false);
  };

  const sendCodeToValidate = async (value) => {
    if (validFormatCode(value)) {
      const data = {
        code: value,
        id: loginCredentials.userId,
      };
      const resultSubmit = await dispatch(checkCode(data));

      if (resultSubmit.payload.result) {
        setUserLoginData(type, resultSubmit);
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast(resultSubmit.payload.message);
      }
    }
  };

  const validFormatCode = (code) => {
    let lengthCode = code.length;
    let isValid = true;
    for (let i = 0; i < lengthCode; i++) {
      isValid = code[i] != "·" ? true : false;
    }
    return isValid;
  };
  const formNoOneClickLogin = () => {
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        {!isValidUser && <div>{loginField()}</div>}
        {((isValidUser && !isCodeSended) ||
          typeLogin == "agent") && (
            <>
              <OneClickFields
                loginCredentials={loginCredentials}
                handleFormChange={handleFormChange}
                sended={sended}
                changeLogin={changeLogin}
                typeCode={typeCode}
                setTypeCode={setTypeCode}
                sendCode={sendCode}
                isValidUser={isValidUser}
                typeLogin={typeLogin}
              />
            </>
          )}
        {isCodeSended && (
          <div>
            <p>Enter code:</p>
            <ReactInputVerificationCode
              length={6}
              onCompleted={sendCodeToValidate}
            />
          </div>
        )}
      </form>
    );
  };
  const loginField = () => (
    <div>
      <CustomInput
        helperText={
          !loginCredentials.login && sended ? t("login.requiredMessage") : null
        }
        error={!loginCredentials.login && sended}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
        }}
        name="login"
        labelText={t("login.label")}
        onChange={handleFormChange}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <UserLogin className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          name: "login",
          onChange: handleFormChange,
          value: loginCredentials.login,
        }}
      />

      {!oneClickLogin && typeLogin === "customer" && (
        <div style={{ marginTop: "10px" }} className={classes.center}>
          <Button
            id="continueMobile"
            onClick={() => checkLogin()}
            color="success"
            style={{
              backgroundColor: "#FFF",
              borderColor: "#13816F",
              color: "#13816F",
              border: "1px solid",
              width: "100%",
            }}
          >
            {t("login.continueButton")}
          </Button>
        </div>
      )}
    </div>
  );

  const formOneClickLogin = () => (
    <form className={classes.form}>
      {loginField()}
      <div style={{ marginTop: "10px" }} className={classes.center}>
        <Button
          onClick={() => oneClickLoginFunction()}
          style={{ backgroundColor: "#008670" }}
          block
          id="oneClickLogin"
        >
          {t("login.oneClickLoginButton")}
        </Button>
      </div>
    </form>
  );

  const setPageLogin = () => {
    setOneClickLogin(false);
    setIsValidUser(false);
    setIsCodeSended(false);
    setTypeCode("");
    setLoginCredentials(emptyLogin);
  };

  const noValidationClick = () => {
    return (
      <GridContainer justify="left">
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.left}>
            <h3 className={classes.socialTitle} style={{ marginTop: "0" }}>
              {t("login.accessTitle")}
            </h3>
          </div>
          {!oneClickLogin ? formNoOneClickLogin() : formOneClickLogin()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ marginTop: "10px" }} className={classes.left}>
            <a
              id="forgotPassword"
              href={
                "/auth/recovery-password-page" +
                (isKiosko ? "/kiosko/" + producerCode : "")
              }
              style={{ color: "#008670" }}
            >
              {t("login.resetPassword")}
            </a>
            <br />
            <a
              id="guestQuote"
              href={"javascript:;"}
              onClick={() => {
                const redirectPage =
                  typeLogin === "agent"
                    ? `/auth/login-customer`
                    : `/guestRedesing`;
                history.push(redirectPage);
              }}
              style={{
                color: "#13816F",
              }}
            >
              {typeLogin === "agent"
                ? t("login.guestQuoteAgent")
                : t("login.guestQuoteCustomer")}
            </a>

            {(oneClickLogin || isValidUser) && (
              <Button
                onClick={() => setPageLogin()}
                variant="white"
                style={{
                  backgroundColor: "#FFF",
                  width: "100%",
                }}
              >
                {t("login.backToLogin")}
              </Button>
            )}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <LoginBaseRedesign type={typeLogin}>
      <CardBody className={"main-Font login-container"}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={8} sm={8} md={8} lg={8}>
                <CardHeader
                  image
                  className={`${classes.cardHeader} ${classes.textLeft}`}
                  color="white"
                >
                  <div className={classes.socialLine}>&nbsp;</div>
                  <img
                    src={logo}
                    alt="Sanborn's Mexico Auto Insurance"
                    style={{ boxShadow: "none", width: "60%" }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <CustomToogle
                  helperText={null}
                  items={[
                    { value: "en", label: "En" },
                    { value: "es", label: "Es" },
                  ]}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  name="language"
                  onChange={(e) => handleFormChange(e)}
                  defaultValue={i18n.language}
                  inputProps={{
                    name: "language",
                    value: i18n.language,
                    onChange: handleLanguageChange,
                  }}
                />
              </GridItem>
            </GridContainer>
            {!oneClickValidate ? (
              noValidationClick()
            ) : (
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center}>
                    <h4 className={classes.socialTitle}>
                      {t("login.oneClickValidation")}
                    </h4>
                  </div>
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Snackbar
              style={{
                position: "absolute",
                top: "50", // Asegúrate de que el Snackbar esté en la parte superior
              }}
              place="bc"
              color={typeToast}
              message={messageToast}
              open={toastMessage}
              closeNotification={() => setToastMessage(false)}
              close
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </LoginBaseRedesign>
  );
}
