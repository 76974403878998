import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// Images
import imageVisa from "assets/img/visa.png";
import imageMaster from "assets/img/mastercard.png";
import imageDiscover from "assets/img/discover.png";
import imageAmerican from "assets/img/americanexpress.png";

const cardTypeList = {
  3: imageVisa,
  2: imageMaster,
  4: imageDiscover,
  5: imageAmerican,
};

const useStyles = makeStyles(styles);

export default function CreditCardItem(props) {
  const classes = useStyles();

  const {
    idCard,
    numberCard,
    monthCard,
    yearCard,
    nameCard,
    lastNameCard,
    typeCard,
    selectedValue,
    handleChangeRadio,
    inputHandler,
    handlerCardData,
    handlerDeleteCard,
  } = props;

  return (
    <GridItem xs={12} sm={12} md={3}>
      <Card pricing>
        <Tooltip
          id="tooltip-top"
          title="Edit Card"
          placement="top"
          style={{ position: "absolute", left: "75%", zIndex: "2" }}
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={(e) => handlerCardData(idCard)}>
            <Edit
              className={classes.tableActionButtonIcon + " " + classes.edit}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top-start"
          title="Remove"
          placement="top"
          style={{ position: "absolute", left: "85%", zIndex: "2" }}
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton aria-label="Close" className={classes.tableActionButton} onClick={(e) => handlerDeleteCard(idCard)}>
            <Close
              className={classes.tableActionButtonIcon + " " + classes.close}
            />
          </IconButton>
        </Tooltip>
        <CardBody pricing>
          <p style={{ textAlign: "left" }}>
            <GridContainer>
              <GridItem xs={12} sm={10}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedValue === idCard}
                        onChange={handleChangeRadio}
                        value={idCard}
                        name="testing"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="Select"
                  />
                </div>
              </GridItem>
            </GridContainer>
            Ending in {numberCard} <br />
            Expiring in {monthCard}/{yearCard} <br />
            {nameCard} {lastNameCard} <br />
            {selectedValue === idCard &&
              "Please enter you card's security code (required)"}
            {selectedValue === idCard && (
              <CustomInput
                labelText={<span>CCV</span>}
                id={`ccv${idCard}`}
                className="ccv-test"
                formControlProps={{
                  fullWidth: false,
                  type: "password",
                  onChange: (e) => inputHandler(e),
                }}
              />
            )}
          </p>
        </CardBody>
        <CardFooter testimonial>
          <CardAvatar testimonial testimonialFooter>
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={cardTypeList[typeCard]} alt="..." />
            </a>
          </CardAvatar>
        </CardFooter>
      </Card>
    </GridItem>
  );
}
