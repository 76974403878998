import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import logo from "assets/img/logo.png";

// @material-ui/icons
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import Speed from "@material-ui/icons/Speed";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { RotatingLines } from "react-loader-spinner";
import { checkIfIsKiosko } from "components/Utils/utils";

import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { resetPassword, checkToken } from "../Services/LoginService";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);

export default function RecoveryPasswordPage() {
  const routeParams = useParams();
  const isKiosko = checkIfIsKiosko();
  const { userId, newPassKey, type, producerCode } = routeParams;

  const dispatch = useDispatch();
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [sended, setSended] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [sendedMail, setSendedMail] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [alert, setAlert] = useState(null);
  const [alertLoading, setAlertLoading] = useState(null);

  const handleFormChange = (e) => {
    const valor = e.target.value;
    setPasswordData({ ...passwordData, [e.target.name]: valor });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    checkTokenUser();
  }, [dispatch]);

  const successAlert = (message, type) => {
    if (type == "success") {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={<h5 style={{ color: "black" }}>{message}</h5>}
          onConfirm={() =>
            (window.location.href =
              "/auth/login-page" +
              (isKiosko
                ? "/kiosko" +
                  (typeof producerCode != "undefined" ? producerCode : "")
                : ""))
          }
          confirmBtnCssClass={
            classesAlerts.button + " " + classesAlerts.success
          }
          allowEscape={false}
          closeOnClickOutside={false}
        ></SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={<h5 style={{ color: "black" }}>{message}</h5>}
          onConfirm={() =>
            type == "danger"
              ? hideAlert()
              : (window.location.href =
                  "/auth/login-page" + (isKiosko ? "/kiosko" : ""))
          }
          confirmBtnCssClass={
            classesAlerts.button + " " + classesAlerts.success
          }
          allowEscape={false}
          closeOnClickOutside={false}
        ></SweetAlert>
      );
    }
  };

  const loadingAlert = () => {
    setAlertLoading(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Loading"
        allowEscape={false}
        closeOnClickOutside={false}
        showConfirm={false}
        showCancel={false}
      >
        <RotatingLines
          strokeColor="gray"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </SweetAlert>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSended(true);
    const value = passwordData;
    value.userId = userId;
    value.newPassKey = newPassKey;
    value.url = location.protocol + "//" + location.hostname;
    if (passwordData.password != "") {
      if (passwordData.password === passwordData.confirmPassword) {
        loadingAlert();
        const resultSubmit = await dispatch(resetPassword(value));
        setAlertLoading(null);
        if (resultSubmit.payload.result) {
          successAlert(resultSubmit.payload.message, "success");
        } else {
          successAlert(resultSubmit.payload.message, "danger");
        }
      } else {
        setToastMessage(true);
        setTypeToast("danger");
        setMessageToast("Passwords don't match.");
      }
    }
  };

  const checkTokenUser = async () => {
    const value = passwordData;
    value.userId = userId;
    value.newPassKey = newPassKey;
    value.url = location.protocol + "//" + location.hostname;
    if (passwordData.userId != "" && passwordData.newPassKey != "") {
      loadingAlert();
      const resultSubmit = await dispatch(checkToken(value));
      setAlertLoading(null);
      if (resultSubmit.payload.result) {
        setValidToken(true);
      } else {
        successAlert(resultSubmit.payload.message, "tokenNoValid");
      }
    }
  };
  const classes = useStyles();
  const classesAlerts = useStylesAlert();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Snackbar
            style={{
              position: "absolute",
              top: "50",
            }}
            place="bc"
            color={typeToast}
            message={messageToast}
            open={toastMessage}
            closeNotification={() => setToastMessage(false)}
            close
          />
          {alert}
          {alertLoading}
          <Card className={classes.cardSignup}>
            <CardHeader
              image
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="white"
            >
              <div className={classes.socialLine}>&nbsp;</div>
              <img
                src={logo}
                alt="Sanborn's Mexico Auto Insurance"
                style={{ boxShadow: "none", width: "50%" }}
              />
            </CardHeader>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.center}>
                    <h4 className={classes.socialTitle}>Reset Password</h4>
                  </div>
                  {validToken && (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <CustomInput
                        helperText={
                          !passwordData.password && sended
                            ? "The password field is required."
                            : null
                        }
                        error={!passwordData.password && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="password"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          placeholder: "Password",
                          name: "password",
                          onChange: handleFormChange,
                        }}
                      />
                      <CustomInput
                        helperText={
                          !passwordData.confirmPassword && sended
                            ? "The confirm password field is required."
                            : null
                        }
                        error={
                          !passwordData.confirmPassword && sended ? true : null
                        }
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="confirmPassword"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          placeholder: "Confirm Password",
                          name: "confirmPassword",
                          onChange: handleFormChange,
                        }}
                      />
                      <div className={classes.center}>
                        <Button type="submit" color="info" block>
                          Change Password
                        </Button>
                        <a
                          href={
                            "/auth/login-page" + (isKiosko ? "/kiosko" : "")
                          }
                        >
                          Back to home
                        </a>
                        <a href="#pablo"> </a>
                      </div>
                    </form>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
