import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowBack from "@material-ui/icons/ArrowBack";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";
import Apps from "@material-ui/icons/Apps";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";

import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Phone from "@material-ui/icons/Phone";
import Download from "@material-ui/icons/CloudDownload";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PolicyCard from "@material-ui/icons/CardMembership";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import Vehicle from "components/Vehicle/Vehicle.js";

import {
  insertVehicle,
  getMakes,
  getModels,
  getVehiclesList,
} from "views/Services/VehiclesService";

import { formatNumber } from "components/Utils/utils.js";

import environment from "../../environment/environment";
const server = `${environment.SERVER}`;

const useStyles = makeStyles(styles);

export default function Dashboard() {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [agencyData, setAgencyData] = useState(null);

  const [showVehicle, setshowVehicle] = React.useState(true);
  const [showEditVehicle, setshowEditVehicle] = React.useState(false);
  const [showAddVehicle, setshowAddVehicle] = React.useState(false);
  const [vehicleDataEdit, setVehicleDataEdit] = React.useState(null);
  const [dataCustomer, setDataCustomer] = React.useState({});

  const cardStyleExpire = {
    border: "2px solid #F44336",
    borderRadius: "5px",
    background: "#F44336",
  };
  const cardStyleWarning = {
    border: "2px solid #FF9802",
    borderRadius: "5px",
    background: "#FF9802",
  };
  const cardStyleSuccess = {
    border: "2px solid #4CAF50",
    borderRadius: "5px",
    background: "#4CAF50",
  };
  useEffect(() => {
    getVehicles();
  }, [dispatch]);
  const getVehicles = async () => {
    const resultSubmit = await dispatch(getVehiclesList({}));
    let customerData = {
      data_customer: { id: resultSubmit.payload.customerId },
      catalogs: resultSubmit.payload.catalogs,
    };
    setDataCustomer(customerData);
    if (resultSubmit.payload.result) {
      setVehicles(resultSubmit.payload.data);
    }
  };
  const calculateExpiration = (orderExpirationDate) => {
    const fechaActual = new Date();
    const fechaExpiracion = new Date(orderExpirationDate);
    const diferenciaMilisegundos = fechaExpiracion - fechaActual;
    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    if (diferenciaDias <= 5) {
      return cardStyleExpire;
    } else if (diferenciaDias >= 6 && diferenciaDias <= 15) {
      return cardStyleWarning;
    } else if (diferenciaDias >= 16) {
      return cardStyleSuccess;
    }
  };

  const closeCreateVehicle = () => {
    setshowAddVehicle(false);
    setshowVehicle(true);
  };

  const editVehicle = (data) => {
    const vehicleTemp = data;
    vehicleTemp.vehtype = comprobarProps(
      vehicleTemp,
      "vehtype",
      "vehicle_type_id"
    );
    vehicleTemp.insurancecompany = comprobarProps(
      vehicleTemp,
      "insurancecompany",
      "insurance_company"
    );
    vehicleTemp.insurancepolnumber = comprobarProps(
      vehicleTemp,
      "insurancepolnumber",
      "insurance_policy_number"
    );
    vehicleTemp.year_vehicle = comprobarProps(
      vehicleTemp,
      "year_vehicle",
      "year_model",
      true
    );
    vehicleTemp.make_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "makeId"
        : "make_vehicle",
      "make"
    );
    vehicleTemp.model_vehicle = comprobarProps(
      vehicleTemp,
      [1, 5].includes(parseInt(vehicleTemp.vehtype))
        ? "modelId"
        : "model_vehicle",
      "model"
    );
    vehicleTemp.value = comprobarProps(
      vehicleTemp,
      "value",
      "total_value",
      true
    );
    vehicleTemp.vin_vehicle = comprobarProps(vehicleTemp, "vin_vehicle", "vin");
    vehicleTemp.sentri = comprobarProps(vehicleTemp, "sentri", "sentri_number");
    vehicleTemp.plates_vehicle = comprobarProps(
      vehicleTemp,
      "plates_vehicle",
      "plates"
    );
    vehicleTemp.lienholder_vehicle = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle",
      "lienholder"
    );
    vehicleTemp.haveLienholder = vehicleTemp.lienholder_vehicle ? "Yes" : "No";
    vehicleTemp.lienholder_vehicle_address = comprobarProps(
      vehicleTemp,
      "lienholder_vehicle_address",
      "lienholder_vehicle_address"
    );
    setVehicleDataEdit(vehicleTemp);
    setshowEditVehicle(false);
    setshowVehicle(false);
    setshowAddVehicle(true);
  };
  const comprobarProps = (
    objeto,
    propObjeto,
    propDatabase,
    parseToInt = false
  ) => {
    const valor = objeto[propObjeto]
      ? objeto[propObjeto]
      : objeto[propDatabase];
    if (parseToInt) {
      return parseInt(valor);
    } else {
      return valor;
    }
  };

  const handleWhatsApp = () => {
    const url = `https://wa.me/9566863601`;
    window.open(url);
  };
  const handleOpenSMSApp = () => {
    window.location.href = `sms:9566863601`;
  };
  const handleMakePhoneCall = () => {
    window.location.href = `tel:9566863601`;
  };
  const goToGoogleMaps = () => {
    window.open("https://maps.app.goo.gl/LWdzksnYxUcc2ZYBA");
  };
  const getAgencyData = async () => {
    const resultSubmit = await dispatch(getAgencyDataByClient());
    setAgencyData(resultSubmit.payload.data);
    console.log(agencyData);
  };
  const classes = useStyles();

  // Hooks
  const [showActivePolicies, setActivePolicies] = React.useState(false);
  const [showAllPolicies, setAllPolicies] = React.useState(false);

  return (
    <div>
      <div></div>
      <GridContainer
        style={{
          marginTop: "20px",
        }}
      >
        <GridItem xs={12}>
          <Card>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <h3
                  className={classes.cardIconTitle}
                  style={{ marginLeft: "25px" }}
                >
                  My Vehicles
                </h3>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ display: showAddVehicle ? "none" : "block" }}
              >
                <CardBody>
                  <div style={{ textAlign: "right", paddingBottom: "20px" }}>
                    <Button
                      color="danger"
                      onClick={() => {
                        setVehicleDataEdit({});
                        setshowAddVehicle(true);
                      }}
                    >
                      Add New Vehicle
                    </Button>
                  </div>
                  {vehicles &&
                    vehicles.map((item, index) => (
                      <GridContainer
                        style={{
                          background:
                            index % 2 === 0
                              ? "rgb(170 170 170 / 15%)"
                              : "rgb(255, 255, 255)",
                          paddingBottom: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        <GridItem xs={12}>
                          <strong>Type:</strong>{" "}
                          {item.type_name ? item.type_name : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Year:</strong>{" "}
                          {item.year_model ? item.year_model : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Make:</strong>{" "}
                          {[1, 5].includes(parseInt(item.vehicle_type_id))
                            ? item.make_vehicle
                              ? item.make_vehicle
                              : "-"
                            : item.make
                            ? item.make
                            : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Model:</strong>{" "}
                          {[1, 5].includes(parseInt(item.vehicle_type_id))
                            ? item.model_vehicle
                              ? item.model_vehicle
                              : "-"
                            : item.model
                            ? item.model
                            : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Value:</strong> $
                          {formatNumber(item.total_value)
                            ? formatNumber(item.total_value)
                            : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Plates:</strong>{" "}
                          {item.plates ? item.plates : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>VIN:</strong> {item.vin ? item.vin : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>Trusted Traveler #:</strong>{" "}
                          {item.vehicle_id ? item.vehicle_id : "-"}
                        </GridItem>
                        <GridItem xs={12}>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              color="info"
                              size="sm"
                              onClick={() => {
                                editVehicle(item);
                                setshowAddVehicle(true);
                              }}
                            >
                              Edit Vehicle
                            </Button>
                          </div>
                        </GridItem>
                      </GridContainer>
                    ))}
                </CardBody>
              </GridItem>
              <GridItem
                xs={12}
                style={{ display: showAddVehicle ? "block" : "none" }}
              >
                <Card style={{ marginBottom: "0px", marginTop: "20px" }}>
                  <CardBody style={{ textAlign: "left" }}>
                    <h3 style={{ color: "#000000", paddingBottom: "10px" }}>
                      <strong>Add/Edit Vehicle</strong>
                    </h3>
                    <Vehicle
                      vehicleDataEdit={vehicleDataEdit}
                      showAddVehicle={showAddVehicle}
                      sendToAPI={true}
                      closeCreateVehicle={closeCreateVehicle}
                      setSelectedVehicle={() => {
                        getVehicles();
                      }}
                      data={dataCustomer}
                    />
                    <div
                      style={{ textAlign: "right", paddingBottom: "20px" }}
                    ></div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
