import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const getUsersList = createAsyncThunk(
  "policView/geUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Customer/getUsers`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
