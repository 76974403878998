import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Material-UI Components
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon";
import Face from "@material-ui/icons/Face";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

// Custom Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDialog from "components/CustomDialog/CustomDialog.js";
import CustomToogle from "components/CustomToogle/CustomToogle.js";
import CustomAlerts from "components/Alerts/CustomAlerts";
import StepperComponent from "components/Stepper/StepperComponent";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";
import Button from "components/CustomButtons/CustomButton";
import PriceCard from "components/CardComponents/PriceCard";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SelectVehicleModal from "components/Vehicle/SelectVehicleModal";

// Custom Icons
import AccidentIcon from "components/CustomIcons/Accident";
import LegalIcon from "components/CustomIcons/Legal";
import TheftIcon from "components/CustomIcons/Theft";

// Utility Functions
import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  validValueCoverage,
  getUTCDate,
} from "components/Utils/utils.js";

// Styles
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import { useTranslation } from "react-i18next";
// Services
import {
  getMakes,
  getModels,
  validateVin,
} from "views/Services/VehiclesService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formStyles);
let current_date = new Date();

const Step1 = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classesForm = useFormStyles();
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const {
    isSmall,
    submitAction,
    previewAction,
    vehicles,
    vinPolicy,
    setVinPolicy,
  } = props;
  const routeParams = useParams();
  const { userId, token, type } = routeParams;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openVehicleSelectModal, setOpenVehicleSelectModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const classesPrincipal = useStylesPrincipal();
  const [checked, setChecked] = React.useState([]);
  const [showSelectMake, setShowSelectMake] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [refreshQuote, setRefreshQuote] = useState(false);
  const [oneClicValidate, setOneClicValidate] = useState(false);
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [vinResponse, setVinResponse] = useState(null);
  const [seePassword, setSeePassword] = useState(false);
  const [years, setYears] = useState([]);
  const [makesCatalog, setMakesCatalog] = useState([]);
  const [modelsCatalog, setModelsCatalog] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({
    login: "",
    password: "",
  });
  const [sended, setSended] = useState(false);
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState({
    vehtype: 1,
    agent: 441,
  });
  const [valores, setValores] = useState([]);

  const [secondTowedUnits, setSecondTowedUnits] = useState(false);
  const [gettingQuote, setGettingQuote] = useState(false);

  const [vehTypeState, setVehTypeState] = useState("error");
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const daysOptions = [
    { value: 1, label: "1 day" },
    { value: 2, label: "2 days" },
    { value: 3, label: "3 days" },
    { value: 4, label: "4 days" },
    { value: 5, label: "5 days" },
    { value: 6, label: "6 days" },
    { value: 7, label: "7 days" },
    { value: 8, label: "8 days" },
    { value: 9, label: "9 days" },
    { value: 10, label: "10 days" },
    { value: 11, label: "11 days" },
    { value: 12, label: "12 days" },
    { value: 13, label: "13 days" },
    { value: 14, label: "14 days" },
    { value: 15, label: "15 days" },
    { value: 16, label: "16 days" },
    { value: 17, label: "17 days" },
    { value: 18, label: "18 days" },
    { value: 19, label: "19 days" },
    { value: 20, label: "20 days" },
    { value: 21, label: "21 days" },
    { value: 22, label: "22 days" },
    { value: 23, label: "23 days" },
    { value: 24, label: "24 days" },
    { value: 25, label: "25 days" },
    { value: 26, label: "26 days" },
    { value: 27, label: "27 days" },
    { value: 28, label: "28 days" },
    { value: 29, label: "29 days" },
    { value: 30, label: "30 days" },
    { value: 90, label: "90 days" },
    { value: 182, label: "6 months" },
    { value: 365, label: "1 year" },
  ];
  const [coverages, setCoverages] = useState({
    csllimit: "500000",
    excessliab: "150000",
    country: 0,
    policy_type: 1,
    medicallimit: "5000",
    damageded: 2,
    theftded: 5,
    vehtype: 1,
    year_vehicle: 2025,
    specialdiscount: 1,
    effectivedate: "10-23-2024",
    bordercross: 2,
    gapcoverage: "no",
    premierepackage: true,
    fixeddeductible: true,
    policycaravan: false,
    roadside: true,
    physical_damage: true,
    theft: true,
    legalaid: true,
    hdiautoguard: false,
    accessories_and_safety: false,
    ter_ext: false,
  });
  const [resultRates, setResultRates] = useState({});

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setValuesForTypeVehicle(1, {});
    }
    if (props.vehicleData) {
      setVehicleData(props.vehicleData);
      if (vinPolicy && vehicleData.vin) {
        getVinVehicle();
        handleToggle({}, 1);
        if ([1, 5].includes(parseInt(vehicleData.vehtype))) {
          getMakesData(props.vehicleData);
          getModelsData(props.vehicleData);
        }
        setVinPolicy(false);
        validateComplete(props.vehicleData);
      }
    }
    if (!years.length) {
      const currentYear = new Date().getFullYear() + 1;
      const arreglo = [];
      for (let index = currentYear; index >= currentYear - 59; index--) {
        arreglo.push(index);
      }
      setYears(arreglo);
    }
  }, [props]);

  useEffect(() => {
    if (type === "oneClickLogin") {
      setOneClickLogin(true);
    }
    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClicValidate(true);
      validateToken();
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    getQuoteRates(coverages, vehicleData);
  };
  const handlePreviewAction = async (event) => {
    previewAction();
  };
  const handleSubmitStep = async (event) => {
    const makeData = makesCatalog.find((e) => e.id === vehicleData.makeVehicle);
    const modelData = modelsCatalog.find(
      (e) => e.id === vehicleData.vehicleModel
    );
    vehicleData.vehText = `${vehicleData.vehicleYear} ${
      makeData ? makeData.name : ""
    } ${modelData ? modelData.name : ""}`;
    submitAction(vehicleData);
  };
  const getVinVehicle = async () => {
    if (vehicleData.vin && /^[a-zA-Z0-9]{17}$/.test(vehicleData.vin.trim())) {
      const resultSubmit = await dispatch(
        validateVin({
          vin: vehicleData.vin.trim(),
          vehicleTypeId: vehicleData.vehtype,
        })
      );
      setVinResponse(resultSubmit.payload);
      if (resultSubmit.payload.result) {
        const tempVehicleData = setVehicleDataFromList(
          resultSubmit.payload.vehicleData
        );

        setVehicleData(tempVehicleData);
        getMakesData(tempVehicleData);
        getModelsData(tempVehicleData);
        validateComplete(tempVehicleData);
      } else {
        if (resultSubmit.payload.vehicleData) {
          const tempVehicleData = setVehicleDataFromList(
            resultSubmit.payload.vehicleData
          );

          setVehicleData(tempVehicleData);
          getMakesData(tempVehicleData);
          getModelsData(tempVehicleData);
          validateComplete(tempVehicleData);
        }
        if (
          resultSubmit.payload.message.includes(
            "The vehicle value has been set to liability only"
          )
        ) {
          setVehicleData({
            ...vehicleData,
            total_value_vehicle: 0,
            fullCoverage: "no",
          });
        } else if (
          resultSubmit.payload.message.includes(
            "This vehicle may be ineligible for coverage under this program"
          )
        ) {
          setIsCompleted(false);
        } else if (
          resultSubmit.payload.message.includes(
            "Please verify that the vehicle information is correct"
          )
        ) {
          const currentIndex = checked.indexOf(1);
          const newChecked = [...checked];

          if (currentIndex === -1) {
            newChecked.push(1);
          }
          //setChecked(newChecked);
          const tempVehicleData = setVehicleDataFromList(
            resultSubmit.payload.vehicleData
          );

          setVehicleData(tempVehicleData);
          getMakesData(tempVehicleData);
          getModelsData(tempVehicleData);
          validateComplete(tempVehicleData);
        }
      }
    } else {
      setVinResponse({
        result: false,
        message:
          "The VIN isn't valid; please enter a valid 17-digit VIN and try again",
      });
    }
  };

  const setVehicleDataFromList = (vehicleDataResult) => {
    let tempVehicleData = { ...vehicleData };
    tempVehicleData.vehicleYear = vehicleDataResult.vehicleYear;
    tempVehicleData.makeVehicle = ["1", "5"].includes(vehicleDataResult.vehicleTypeId) ? vehicleDataResult.vehicleMakeId : vehicleDataResult.vehicleMakeName;
    tempVehicleData.vehicleModel = ["1", "5"].includes(vehicleDataResult.vehicleTypeId) ? vehicleDataResult.vehicleModelId : vehicleDataResult.vehicleModelName;
    tempVehicleData.vehicle_type_id = vehicleDataResult.vehicleTypeId;
    tempVehicleData.year_model = vehicleDataResult.vehicleYear;
    tempVehicleData.makeId = vehicleDataResult.vehicleMakeId;
    tempVehicleData.make_vehicle = vehicleDataResult.vehicleMakeName;
    tempVehicleData.modelId = vehicleDataResult.vehicleModelId;
    tempVehicleData.model = vehicleDataResult.vehicleModelName;
    return tempVehicleData;
  };

  const selectVehicle = async (vehicle) => {
    setVinResponse({
      result: true,
      message: "Validated",
      vehicleData: {
        vehicleType: vehicle.type_name,
        vehicleTypeId: vehicle.vehicle_type_id,
        vehicleYear: vehicle.year_model,
        vehicleMakeId: vehicle.makeId,
        vehicleMakeName: vehicle.make_vehicle,
        vehicleModelId: vehicle.modelId,
        vehicleModelName: vehicle.model,
      },
    });
    let tempVehicleData = { ...vehicleData };
    Object.assign(tempVehicleData, vehicle);
    tempVehicleData.vehicleYear = vehicle.year_model;
    tempVehicleData.makeVehicle = vehicle.makeId;
    tempVehicleData.vehicleModel = vehicle.modelId;
    tempVehicleData.licensePlate = vehicle.plates;
    tempVehicleData.total_value_vehicle = vehicle.total_value;
    tempVehicleData.insuranceCompany = vehicle.insurance_company;
    setVehicleData(tempVehicleData);
    getMakesData(tempVehicleData);
    getModelsData(tempVehicleData);
    setOpenVehicleSelectModal(false);
  };

  const handleFormChange = (e) => {
    const valor = e.target.value;
    let vehData = { ...vehicleData };
    vehData[e.target.name] = valor;
    if (e.target.name === "days") {
      vehData.selectedDays = valor;
    }
    if (e.target.name === "vehtype" || e.target.name === "vehicleYear") {
      if (e.target.name === "vehtype") {
        vehData.vehicle_type_id = valor;
        vehData.year_vehicle = vehicleData.year_vehicle;
        vehData.year_model = vehicleData.year_vehicle;
        vehData.type_name = e.nativeEvent.target.textContent;
      } else {
        vehData.vehtype = vehicleData.vehtype;
        vehData.vehicle_type_id = vehicleData.vehtype;
        vehData.year_vehicle = valor;
      }
      setValuesForTypeVehicle(valor, vehData);
      getMakesData(vehData);
    } else if (e.target.name === "makeVehicle" && showSelectMake) {
      vehData.vehtype = vehicleData.vehtype;
      vehData.make_vehicle = valor;
      vehData.makeId = valor;
      getModelsData(vehData);
    }
    if (e.target.name === "vehicleYear") {
      vehData.year_model = e.nativeEvent.target.textContent;
    }
    if (e.target.name === "make_vehicle") {
      vehData.make_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.makeId = valor;
    }
    if (e.target.name === "vehicleModel") {
      vehData.model_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.modelId = valor;
      vehData.model = valor;
    }
    if (e.target.name === "total_value_vehicle") {
      vehData.total_value = valor;
    }
    if (e.target.name === "insurancepolnumber") {
      setFullCoverageState("success");
      vehData.insurancepolnumber = valor;
      vehData.insurance_policy_number = valor;
      if (valor == "No") {
        vehData.value = "0";
        formatValueField(0);
      } else {
        setValuesForTypeVehicle(1, vehData);
      }
    }
    if (e.target.name === "insuranceCompany") {
      vehData.insurance_company = valor;
    }
    if (vehData.fullCoverage === "no") {
      vehData.total_value_vehicle = 0;
      vehData.total_value = 0;
    }
    if (vehData.initDate && vehData.endDate) {
      const initDate = new Date(vehData.initDate);
      const endDate = new Date(vehData.endDate);
      const diffTime = Math.abs(endDate - initDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      vehData.days = diffDays;
    }
    setVehicleData(vehData);
    validateComplete(vehData);
  };

  const handleCoveragesChange = (e) => {
    const valor = e.target.value;
    let coveragesData = { ...coverages };
    coveragesData[e.target.name] = valor;
    setCoverages(coveragesData);
    getQuoteRates(coveragesData, vehicleData);
  };
  const validateComplete = (data) => {
    const reqProperties = [
      "vehtype",
      "licensePlate",
      "total_value_vehicle",
      "fullCoverage",
      "insuranceCompany",
    ];
    if (data.vehicle_type_id !== "11") {
      reqProperties.push("makeVehicle");
    }
    if (data.hasLienholder === "yes") {
      reqProperties.push("lienholderName", "lienholderAddress");
    }
    if (checked.indexOf(1) === 0) {
      reqProperties.push("vehicleYear", "makeVehicle", "vehicleModel");
    }
    if (data.towingUnits === "yes") {
      reqProperties.push(
        "towed_total_value_vehicle",
        "towed_vin",
        "towed_vehicleYear",
        "towed_make",
        "towed_model"
      );
      if (secondTowedUnits) {
        reqProperties.push(
          "secondary_total_value_vehicle",
          "secondary_vin",
          "secondary_vehicleYear",
          "secondary_make",
          "secondary_model"
        );
      }
    }
    const allPropertiesValid = reqProperties.every((prop) => {
      return (
        data[prop] !== undefined && data[prop] !== null && data[prop] !== ""
      );
    });
    let validVehicle =
      !vinResponse ||
      !vinResponse.message.includes(
        "This vehicle may be ineligible for coverage under this program"
      );
    setIsCompleted(allPropertiesValid && validVehicle);
  };
  const getMakesData = async (sendedData) => {
    //sendedData.vehtype = sendedData.vehtype === null ? "1" : sendedData.vehtype;
    if (sendedData.vehtype && sendedData.vehicleYear) {
      const sendedVehicle =
        sendedData.vehtype === "1"
          ? "P"
          : sendedData.vehtype === "5"
          ? "M"
          : null;
      if (sendedVehicle) {
        setShowSelectMake(true);
        const resultSubmit = await dispatch(
          getMakes({
            year: sendedData.vehicleYear,
            type_vehicle: sendedVehicle,
          })
        );
        let makeList = resultSubmit.payload.makes;
        if (!makeList) {
          makeList = [{ id: "Other", name: "Other" }];
        } else {
          makeList.unshift({ id: "Other", name: "Other" });
        }
        setMakesCatalog(makeList);
      } else {
        setShowSelectMake(false);
      }
    }
  };
  const getModelsData = async (sendedData) => {
    //sendedData.vehtype = sendedData.vehtype === null ? "1" : sendedData.vehtype;
    const sendedVehicle =
      sendedData.vehtype === "1"
        ? "P"
        : sendedData.vehtype === "5"
        ? "M"
        : null;
    const resultSubmit = await dispatch(
      getModels({
        name: sendedData.makeVehicle,
        type_vehicle: sendedVehicle,
      })
    );
    let modelList = resultSubmit.payload.makes;

    if (parseInt(sendedData.makeVehicle) > 0) {
      if (!modelList) {
        modelList = [{ id: "Other", name: "Other" }];
      } else {
        modelList.unshift({ id: "Other", name: "Other" });
      }
      setModelsCatalog(modelList);
    }
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    formatValueField(limitValue);
  };

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...vehicleData };
      vehicleDataToEdit.value = 0;
      setVehicleData(vehicleDataToEdit);
    }
    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 &&
          arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };
  const vehicleValueDisabled = () => {
    let disable = false;
    if (
      vehicleData.fullCoverage === "no" ||
      (vinResponse &&
        vinResponse.message.includes(
          "The vehicle value has been set to liability only"
        ))
    ) {
      disable = true;
    }
    return disable;
  };
  const getQuoteRates = async (coveragesData, vehicle) => {
    let valueVehicle =
      typeof vehicle.total_value_vehicle != "undefined"
        ? parseInt(vehicle.total_value_vehicle || 0)
        : 0;
    let valueTrailer =
      typeof vehicle.total_value_trailer != "undefined"
        ? parseInt(vehicle.total_value_trailer || 0)
        : 0;
    let valueOther =
      typeof vehicle.total_value_other != "undefined"
        ? parseInt(vehicle.total_value_other || 0)
        : 0;
    let totalValue = valueVehicle + valueTrailer + valueOther;
    if (data) {
      let stringRates = `csl=${coveragesData.csllimit}&excessliab=${
        coveragesData.excessliab
      }&value=${totalValue}&days=${vehicle.days}&
      country=${data.data_customer?.country_id}&policy_type=${
        coveragesData.policy_type
      }&
      medicallimit=${
        coveragesData.medicallimit
      }&damageded=2&theftded=5&vehtype=${vehicle.vehtype}&year_vehicle=${
        data.selectedVehicle?.year_model
      }&
      specialdiscount=1&effectivedate=${
        coveragesData.effectivedate
      }&vehicle_type_model=&bordercross=${vehicle.borderCrossing}&agency=${
        vehicle.agent
      }&
      gapcoverage=${
        coveragesData.gapcoverage == "no" ? false : true
      }&premierepackage=${coveragesData.premierepackage}&fixeddeductible=${
        coveragesData.fixeddeductible
      }&policycaravan=false&
      roadside=${coveragesData.roadside}&physical_damage=${
        coveragesData.physical_damage
      }&theft=${coveragesData.theft}&legalaid=${coveragesData.legalaid}&
      hdiautoguard=false&accessories_and_safety=false&ter_ext=false`;
      if (typeof data?.selectedVehicle?.trailer != "undefined") {
        stringRates +=
          data?.selectedVehicle?.trailer != "0" ? "&withtrailer=true" : "";
      }
      const resultSubmit = await dispatch(getRates(stringRates));
      if (resultSubmit.payload.result) {
        setResultRates(resultSubmit.payload.data);
        if (!gettingQuote) {
          setGettingQuote(true);
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    }
  };

  const checkSelectedQuote = (days) => {
    return vehicleData.days === parseInt(days) ? "yellow" : "gray";
  };

  const setDays = (days) => {
    if (
      (vehicleData.days < 90 && parseInt(days) >= 90) ||
      (vehicleData.days < 365 && parseInt(days) >= 365)
    ) {
      setOpen(true);
    }
    let vehicle = { ...vehicleData };
    vehicle.days = parseInt(days);
    setVehicleData(vehicle);
    getQuoteRates(coverages, vehicle);
  };
  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setIsCompleted(false);
    } else {
      newChecked.splice(currentIndex, 1);
      validateComplete(vehicleData);
    }
    setChecked(newChecked);
  };

  return (
    <div>
      {vehicleData.towingUnits ? (
        <GridItem xs={12} sm={12} md={12}>
          {open ? (
            <CustomDialog open={true} title={"Edit Quote"}></CustomDialog>
          ) : null}
          <div
            className={`${classesPrincipal.title} ${classesPrincipal.black}`}
          >
            {t("step3.title")}
          </div>
          <GridContainer style={{ marginTop: "20px" }}>
            <GridItem xs={12} sm={12} md={8}>
              {!vinResponse ||
              (!vinResponse.result &&
                !vinResponse.message.includes(
                  "This vehicle is only eligible for liability coverage in Mexico"
                ) &&
                !vinResponse.message.includes(
                  "Click override vehicle information if you need to make any corrections"
                ) &&
                !vinResponse.message.includes(
                  "Click Manually enter vehicle information if you need to make any corrections"
                )) ? (
                <>
                  <GridContainer>
                    {["1", "2", "3"].includes(
                      localStorage.getItem("userType")
                    ) && (
                      <GridItem xs={12} sm={12} md={4}>
                        <Button
                          id="loginMobile"
                          variant="green"
                          onClick={() => setOpenVehicleSelectModal(true)}
                          style={{
                            width: "100%",
                            marginTop: "25px",
                            marginBottom: "25px",
                          }}
                        >
                          {t("step3.buttons.selectVehicle")}
                        </Button>
                      </GridItem>
                    )}

                    <GridItem xs={12} sm={12} md={12}>
                      <CustomSelect
                        labelText={t("step3.labels.vehicleType")}
                        helperText={
                          !loginCredentials.login && sended
                            ? t("step3.errors.vehicleTypeRequired")
                            : null
                        }
                        menuItems={
                          data?.catalogs
                            ? data.catalogs.vehicle_types.map((item) => ({
                                value: item.id,
                                label: item.name,
                                icon: item.icon,
                              }))
                            : []
                        }
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="vehtype"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          name: "vehtype",
                          required: true,
                          value: vehicleData.vehtype,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText={t("step3.labels.vin")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          required: true,
                          name: "vin",
                          value: vehicleData.vin,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Button
                        id="loginMobile"
                        variant="green"
                        onClick={getVinVehicle}
                        style={{ width: "100%", marginTop: "25px" }}
                      >
                        {t("step3.buttons.verifyVin")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </>
              ) : (
                <>
                  {(vinResponse.message.includes(
                    "Click override vehicle information if you need to make any corrections"
                  ) ||
                    vinResponse.message.includes(
                      "Click Manually enter vehicle information if you need to make any corrections"
                    )) && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomSelect
                          labelText={t("step3.labels.vehicleType")}
                          helperText={
                            !loginCredentials.login && sended
                              ? t("step3.errors.vehicleTypeRequired")
                              : null
                          }
                          menuItems={
                            data?.catalogs
                              ? data.catalogs.vehicle_types.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                  icon: item.icon,
                                }))
                              : []
                          }
                          error={
                            !loginCredentials.login && sended ? true : null
                          }
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          name="vehtype"
                          onChange={(e) => handleFormChange(e)}
                          inputProps={{
                            name: "vehtype",
                            value: vehicleData.vehtype,
                            onChange: handleFormChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <p className={`${classesPrincipal.labelRoot}`}>
                        {t("step3.labels.vin")}
                      </p>
                      <p className={`${classesPrincipal.labelRoot}`}>
                        {vehicleData.vin}
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem
                      style={{ marginTop: "30px", marginBottom: "20px" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <CustomAlerts
                        text={
                          <>
                            {t("step3.alerts.vehicleVerified")}{" "}
                            {vinResponse.vehicleData.vehicleType}{" "}
                            {vinResponse.vehicleData.vehicleYear}{" "}
                            {vinResponse.vehicleData.vehicleMakeName}{" "}
                            {vinResponse.vehicleData.vehicleModelName === null
                              ? t("step3.alerts.vehicleOther")
                              : vinResponse.vehicleData.vehicleModelName}
                            &nbsp;
                            <span
                              className={classesPrincipal.red}
                              onClick={() => {
                                setVinResponse(null);
                              }}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              {t("step3.buttons.resetVehicleInformation")}
                            </span>
                          </>
                        }
                        variant="default"
                        icon="check"
                        severity="success"
                      />
                    </GridItem>
                  </GridContainer>
                </>
              )}

              {vinResponse && !vinResponse.result ? (
                <>
                  <GridContainer>
                    <GridItem
                      style={{ marginTop: "30px", marginBottom: "20px" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <CustomAlerts
                        title={
                          vinResponse.message.includes("VIN not found")
                            ? t("step3.alerts.vinNotFoundTitle")
                            : ""
                        }
                        text={
                          vinResponse.message.includes("VIN not found")
                            ? t("step3.alerts.vinNotFoundText")
                            : vinResponse.message
                        }
                        variant="dangerOutlined"
                        icon="warning_amber"
                        severity="error"
                      />
                    </GridItem>
                  </GridContainer>
                  {vinResponse &&
                  !vinResponse.message.includes("The VIN isn't valid") &&
                  !vinResponse.message.includes(
                    "This vehicle may be ineligible for coverage under this program"
                  ) &&
                  !vinResponse.message.includes(
                    "The vehicle value has been set to liability only"
                  ) ? (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={9}>
                        <div
                          className={
                            classesForm.checkboxAndRadio +
                            " " +
                            classesForm.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  checked.indexOf(1) === -1 ? false : true
                                }
                                tabIndex={-1}
                                onClick={(e) => handleToggle(e, 1)}
                                checkedIcon={
                                  <CloseIcon
                                    className={classesForm.checkedIcon}
                                  />
                                }
                                icon={
                                  <CloseIcon
                                    className={classesForm.uncheckedIcon}
                                  />
                                }
                                classes={{
                                  checked: classesForm.greenCheck,
                                  root: classesForm.checkRoot,
                                }}
                                inputProps={{
                                  name: "terms",
                                  id: "terms",
                                  value: 1,
                                }}
                              />
                            }
                            classes={{
                              label: classesForm.label,
                              root: classesForm.labelRoot,
                            }}
                            label={t("step3.labels.manuallyEnterVehicle")}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                </>
              ) : null}
              {vinResponse &&
              !vinResponse.result &&
              checked.indexOf(1) === 0 ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText={t("step3.labels.vehicleYear")}
                      helperText={
                        !loginCredentials.login && sended
                          ? t("step3.errors.vehicleValueRequired")
                          : null
                      }
                      menuItems={
                        years
                          ? years.map((item) => ({
                              value: item,
                              label: item,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="vehicleYear"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "vehicleYear",
                        value: vehicleData.vehicleYear,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText={t("step3.labels.vehicleMake")}
                      helperText={
                        !loginCredentials.login && sended
                          ? t("step3.errors.vehicleValueRequired")
                          : null
                      }
                      menuItems={
                        makesCatalog
                          ? makesCatalog.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="makeVehicle"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "makeVehicle",
                        value: vehicleData.makeVehicle,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText={t("step3.labels.vehicleModel")}
                      helperText={
                        !loginCredentials.login && sended
                          ? t("step3.errors.vehicleValueRequired")
                          : null
                      }
                      menuItems={
                        modelsCatalog
                          ? modelsCatalog.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="vehicleModel"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "vehicleModel",
                        value: vehicleData.vehicleModel,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              ) : null}

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t("step3.labels.licensePlate")}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      required: true,
                      name: "licensePlate",
                      value: vehicleData.licensePlate,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomSelect
                    labelText={t("step3.labels.vehicleValue")}
                    secondaryText={
                      <>
                        {t("step3.descriptions.vehicleValueIntro")}&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {t("step3.descriptions.vehicleValueBold")}
                        </span>
                        . {t("step3.descriptions.vehicleValueRecommendation")}
                        &nbsp;
                        <span
                          className={classesPrincipal.green}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {t("step3.links.kelleyBlueBook")}
                        </span>
                        &nbsp;{t("step3.descriptions.or")}&nbsp;
                        <span
                          className={classesPrincipal.green}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {t("step3.links.nada")}
                        </span>
                      </>
                    }
                    placeholder={t("step3.placeholders.actualCashValue")}
                    helperText={
                      !loginCredentials.login && sended
                        ? t("step3.errors.vehicleValueRequired")
                        : null
                    }
                    menuItems={
                      valores
                        ? valores.map((item) => ({
                            value: item.value,
                            label: item.traduccion,
                          }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="total_value_vehicle"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      disabled: vehicleValueDisabled(),
                      name: "total_value_vehicle",
                      value: vehicleData.total_value_vehicle,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomToogle
                    labelText={t("step3.labels.fullCoverage")}
                    secondaryText={
                      vehicleData.fullCoverage === "no"
                        ? t("step3.descriptions.fullCoverageNote")
                        : ""
                    }
                    items={[
                      { value: "yes", label: t("step3.options.yes") },
                      { value: "no", label: t("step3.options.no") },
                    ]}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="fullCoverage"
                    defaultValue={vehicleData.fullCoverage || ""}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "fullCoverage",
                      value: vehicleData.fullCoverage,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t("step3.labels.insuranceCompany")}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      required: true,
                      name: "insuranceCompany",
                      value: vehicleData.insuranceCompany,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomToogle
                    labelText={t("step3.labels.hasLienholder")}
                    items={[
                      { value: "yes", label: t("step3.options.yes") },
                      { value: "no", label: t("step3.options.no") },
                    ]}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="hasLienholder"
                    defaultValue={vehicleData.hasLienholder || "no"}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "hasLienholder",
                      value: vehicleData.hasLienholder,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>

              {vehicleData.hasLienholder === "yes" ? (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText={t("step3.labels.lienholderName")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "lienholderName",
                          value: vehicleData.lienholderName,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText={t("step3.labels.lienholderAddress")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "lienholderAddress",
                          value: vehicleData.lienholderAddress,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </>
              ) : null}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomToogle
                    information="Tooltip Text"
                    labelText={t("step3.labels.willTowing")}
                    helperText={null}
                    items={[
                      { value: "yes", label: t("step3.options.yes") },
                      { value: "no", label: t("step3.options.no") },
                    ]}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="towingUnits"
                    defaultValue={
                      vehicleData.towingUnits ? vehicleData.towingUnits : ""
                    }
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "towingUnits",
                      value: vehicleData.towingUnits,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>

              {vehicleData.towingUnits === "yes" && (
                <>
                  <span
                    className={`${classesPrincipal.subTitle} ${classesPrincipal.green}`}
                  >
                    {t("step3.titleTowed")}
                  </span>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomSelect
                        labelText={t("step3.labels.towedUnitValue")}
                        secondaryText={t("step3.helperTexts.towedUnitValue")}
                        placeholder={t("step3.placeholders.actualCashValue")}
                        helperText={
                          !loginCredentials.login && sended
                            ? t("step3.errors.vehicleValueRequired")
                            : null
                        }
                        menuItems={
                          valores
                            ? valores.map((item) => ({
                                value: item.value,
                                label: item.traduccion,
                              }))
                            : []
                        }
                        error={!loginCredentials.login && sended}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="towed_total_value_vehicle"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          name: "towed_total_value_vehicle",
                          value:
                            vehicleData.towed_total_value_vehicle != null
                              ? vehicleData.towed_total_value_vehicle
                              : "placeholder",

                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText={t("step3.labels.towedVin")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "towed_vin",
                          value: vehicleData.towed_vin,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomSelect
                        labelText={t("step3.labels.towedUnitYear")}
                        helperText={
                          !loginCredentials.login && sended
                            ? t("step3.errors.vehicleYearRequired")
                            : null
                        }
                        menuItems={
                          years
                            ? years.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : []
                        }
                        error={!loginCredentials.login && sended}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="towed_vehicleYear"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          name: "towed_vehicleYear",
                          value: vehicleData.towed_vehicleYear,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText={t("step3.labels.towedUnitMake")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "towed_make",
                          value: vehicleData.towed_make,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText={t("step3.labels.towedUnitModel")}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "towed_model",
                          value: vehicleData.towed_model,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  {!secondTowedUnits && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <Button
                          id="loginMobile"
                          type="submit"
                          onClick={() => setSecondTowedUnits(true)}
                          variant="green"
                        >
                          {t("step3.buttons.addSecondTowedUnit")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  )}

                  {secondTowedUnits && (
                    <>
                      <div style={{ marginTop: "25px" }}>
                        <span
                          className={`${classesPrincipal.subTitle} ${classesPrincipal.green}`}
                        >
                          {t("step3.titlesTows")}
                        </span>
                      </div>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomSelect
                            labelText={t("step3.labels.towedUnitValue")}
                            placeholder={t(
                              "step3.placeholders.actualCashValue"
                            )}
                            helperText={
                              !loginCredentials.login && sended
                                ? t("step3.errors.vehicleValueRequired")
                                : null
                            }
                            menuItems={
                              valores
                                ? valores.map((item) => ({
                                    value: item.value,
                                    label: item.traduccion,
                                  }))
                                : []
                            }
                            error={!loginCredentials.login && sended}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            name="secondary_total_value_vehicle"
                            onChange={(e) => handleFormChange(e)}
                            inputProps={{
                              name: "secondary_total_value_vehicle",
                              value:
                                vehicleData.secondary_total_value_vehicle !=
                                null
                                  ? vehicleData.secondary_total_value_vehicle
                                  : "placeholder",

                              onChange: handleFormChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <CustomInput
                            labelText={t("step3.labels.towedVin")}
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              name: "secondary_vin",
                              value: vehicleData.secondary_vin,
                              onChange: handleFormChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomSelect
                            labelText={t("step3.labels.towedUnitYear")}
                            helperText={
                              !loginCredentials.login && sended
                                ? t("step3.errors.vehicleYearRequired")
                                : null
                            }
                            menuItems={
                              years
                                ? years.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))
                                : []
                            }
                            error={!loginCredentials.login && sended}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            name="secondary_vehicleYear"
                            onChange={(e) => handleFormChange(e)}
                            inputProps={{
                              name: "secondary_vehicleYear",
                              value: vehicleData.secondary_vehicleYear,
                              onChange: handleFormChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText={t("step3.labels.towedUnitMake")}
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              name: "secondary_make",
                              value: vehicleData.secondary_make,
                              onChange: handleFormChange,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText={t("step3.labels.towedUnitModel")}
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              name: "secondary_model",
                              value: vehicleData.secondary_model,
                              onChange: handleFormChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <Button
                            id="loginMobile"
                            type="submit"
                            onClick={() => setSecondTowedUnits(false)}
                            variant="green"
                          >
                            {t("step3.buttons.removeSecondTowedUnit")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                </>
              )}

              <GridContainer
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ minHeight: "100px", marginTop: "20px" }}
              >
                <GridItem xs={4}>
                  <Button
                    id="backButton"
                    onClick={handlePreviewAction}
                    variant="white"
                    style={{ width: "100%" }}
                  >
                    {t("common.previous")}
                  </Button>
                </GridItem>

                <GridItem xs={4} />
                <GridItem xs={4}>
                  <Button
                    id="loginMobile"
                    type="submit"
                    onClick={handleSubmitStep}
                    disabled={!isCompleted}
                    variant="green"
                    style={{ width: "100%" }}
                  >
                    {t("common.continue")}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      ) : null}
      <SelectVehicleModal
        open={openVehicleSelectModal}
        setOpen={setOpenVehicleSelectModal}
        selectVehicle={selectVehicle}
        vehicles={vehicles}
      ></SelectVehicleModal>
    </div>
  );
});

Step1.displayName = "Step1";

export default Step1;
