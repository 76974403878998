import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDialog from "components/CustomDialog/CustomDialog.js";
import PriceCard from "components/CardComponents/PriceCard";
import CustomToogle from "components/CustomToogle/CustomToogle.js";
import Button from "components/CustomButtons/CustomButton";
import AccidentIcon from "components/CustomIcons/Accident";
import LegalIcon from "components/CustomIcons/Legal";
import TheftIcon from "components/CustomIcons/Theft";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomAlerts from "components/Alerts/CustomAlerts";
import StepperComponent from "components/Stepper/StepperComponent";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getRates } from "views/Services/OrdersService";
import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  validValueCoverage,
  getUTCDate,
} from "components/Utils/utils.js";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
const useStyles = makeStyles(styles);
let current_date = new Date();

const Step1 = React.forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const { isSmall, submitAction } = props;
  const routeParams = useParams();
  const { userId, token, type } = routeParams;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const classesPrincipal = useStylesPrincipal();
  const [toastMessage, setToastMessage] = useState(false);
  const [refreshQuote, setRefreshQuote] = useState(false);
  const [oneClicValidate, setOneClicValidate] = useState(false);
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    login: "",
    password: "",
  });
  const [sended, setSended] = useState(false);
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState({ vehtype: 1, agent: 441 });
  const [valores, setValores] = useState([]);
  const [gettingQuote, setGettingQuote] = useState(false);
  const [vehTypeState, setVehTypeState] = useState("error");
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const daysOptions = [
    { value: 1, label: t("daysOptions.1day") },
    { value: 2, label: t("daysOptions.2days") },
    { value: 3, label: t("daysOptions.3days") },
    { value: 4, label: t("daysOptions.4days") },
    { value: 5, label: t("daysOptions.5days") },
    { value: 6, label: t("daysOptions.6days") },
    { value: 7, label: t("daysOptions.7days") },
    { value: 8, label: t("daysOptions.8days") },
    { value: 9, label: t("daysOptions.9days") },
    { value: 10, label: t("daysOptions.10days") },
    { value: 11, label: t("daysOptions.11days") },
    { value: 12, label: t("daysOptions.12days") },
    { value: 13, label: t("daysOptions.13days") },
    { value: 14, label: t("daysOptions.14days") },
    { value: 15, label: t("daysOptions.15days") },
    { value: 16, label: t("daysOptions.16days") },
    { value: 17, label: t("daysOptions.17days") },
    { value: 18, label: t("daysOptions.18days") },
    { value: 19, label: t("daysOptions.19days") },
    { value: 20, label: t("daysOptions.20days") },
    { value: 21, label: t("daysOptions.21days") },
    { value: 22, label: t("daysOptions.22days") },
    { value: 23, label: t("daysOptions.23days") },
    { value: 24, label: t("daysOptions.24days") },
    { value: 25, label: t("daysOptions.25days") },
    { value: 26, label: t("daysOptions.26days") },
    { value: 27, label: t("daysOptions.27days") },
    { value: 28, label: t("daysOptions.28days") },
    { value: 29, label: t("daysOptions.29days") },
    { value: 30, label: t("daysOptions.30days") },
    { value: 90, label: t("daysOptions.90days") },
    { value: 182, label: t("daysOptions.6months") },
    { value: 365, label: t("daysOptions.1year") },
  ];
  const [coverages, setCoverages] = useState({
    csllimit: "500000",
    excessliab: "150000",
    country: 0,
    policy_type: 1,
    medicallimit: "5000",
    damageded: 2,
    theftded: 5,
    vehtype: 1,
    year_vehicle: 2025,
    specialdiscount: 1,
    effectivedate: "10-23-2024",
    bordercross: 2,
    gapcoverage: "no",
    premierepackage: true,
    fixeddeductible: true,
    policycaravan: false,
    roadside: true,
    physical_damage: true,
    theft: true,
    legalaid: true,
    hdiautoguard: false,
    accessories_and_safety: false,
    ter_ext: false,
  });
  const [resultRates, setResultRates] = useState({});

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setValuesForTypeVehicle(1, {});
    }
    if (props.vehicleData) {
      setVehicleData(props.vehicleData);
      validateComplete(props.vehicleData);
    }
    if (props.coverages) {
      setCoverages(props.coverages);
    }
  }, [props]);

  useEffect(() => {
    if (type === "oneClickLogin") {
      setOneClickLogin(true);
    }

    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClicValidate(true);
      validateToken();
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    getQuoteRates(coverages, vehicleData);
  };
  const handleSubmitStep = async (event) => {
    submitAction(vehicleData, coverages, resultRates);
  };

  const handleFormChange = (e) => {
    const valor = e.target.value;
    let vehData = { ...vehicleData };
    vehData[e.target.name] = valor;
    const sendedData = {};
    if (e.target.name === "days") {
      vehData.selectedDays = valor;
    }
    if (e.target.name === "vehtype" || e.target.name === "year_vehicle") {
      if (e.target.name === "vehtype") {
        sendedData.vehtype = valor;
        setVehTypeState("success");
        vehData.vehicle_type_id = valor;
        vehData.year_vehicle = vehicleData.year_vehicle;
        vehData.year_model = vehicleData.year_vehicle;
        vehData.type_name = e.nativeEvent.target.textContent;
      } else {
        vehData.vehtype = vehicleData.vehtype;
        vehData.vehicle_type_id = vehicleData.vehtype;
        vehData.year_vehicle = valor;
        setYearState("success");
      }
      setValuesForTypeVehicle(valor, vehData);
    } else if (e.target.name === "make_vehicle" && showSelectMake) {
      vehData.vehtype = vehicleData.vehtype;
      vehData.make_vehicle = valor;
      vehData.makeId = valor;
      setMakeState("success");
      getModelsData(sendedData);
    }
    if (e.target.name === "total_value_vehicle") {
      vehData.total_value = valor;
    }
    if (e.target.name === "total_value_trailer") {
      vehData.towingUnits = valor == -1 || valor == 0 ? "no" : "yes";
      vehData.towed_total_value_vehicle = valor;
    }
    if (e.target.name === "year_vehicle") {
      vehData.year_model = e.nativeEvent.target.textContent;
    }
    if (e.target.name === "borderCrossing") {
      let coveragesData = { ...coverages };
      let timeZoneList = {
        1: 12,
        2: 8,
        3: 17,
        4: 33,
        5: 12,
        6: 33,
      };
      coveragesData.timezone = timeZoneList[e.target.value];
      setCoverages(coveragesData);
    }
    if (e.target.name === "make_vehicle") {
      vehData.make_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.makeId = valor;
    }
    if (e.target.name === "model_vehicle") {
      vehData.model_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.modelId = valor;
    }
    if (e.target.name === "value") {
      setValueState("success");
      vehData.value = valor;
    }
    if (e.target.name === "insurancepolnumber") {
      setFullCoverageState("success");
      vehData.insurancepolnumber = valor;
      if (valor == "No") {
        vehData.value = "0";
        formatValueField(0);
      } else {
        setValuesForTypeVehicle(1, vehData);
      }
    }
    if (vehData.initDate && vehData.endDate) {
      const initDate = new Date(vehData.initDate);
      const endDate = new Date(vehData.endDate);
      const diffTime = Math.abs(endDate - initDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      vehData.days = diffDays;
    }
    setVehicleData(vehData);
    validateComplete(vehData);
  };

  const handleCoveragesChange = (e) => {
    const valor = e.target.value;
    let coveragesData = { ...coverages };
    coveragesData[e.target.name] = valor;
    setCoverages(coveragesData);
    getQuoteRates(coveragesData, vehicleData);
  };
  const validateComplete = (data) => {
    const reqProperties = [
      "borderCrossing",
      "total_value_vehicle",
      "vehtype",
      "days",
      "agent",
    ];
    const allPropertiesValid = reqProperties.every((prop) => {
      return (
        data[prop] !== undefined && data[prop] !== null && data[prop] !== ""
      );
    });
    setIsCompleted(allPropertiesValid);
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    formatValueField(limitValue);
  };

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...vehicleData };
      vehicleDataToEdit.value = 0;
      setVehicleData(vehicleDataToEdit);
    }
    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 &&
          arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };

  const getQuoteRates = async (coveragesData, vehicle) => {
    let valueVehicle =
      typeof vehicle.total_value_vehicle != "undefined"
        ? parseInt(vehicle.total_value_vehicle || 0)
        : 0;
    let valueTrailer =
      typeof vehicle.total_value_trailer != "undefined"
        ? parseInt(vehicle.total_value_trailer || 0)
        : 0;
    let valueOther =
      typeof vehicle.total_value_other != "undefined"
        ? parseInt(vehicle.total_value_other || 0)
        : 0;
    let totalValue = valueVehicle + valueTrailer + valueOther;
    if (data) {
      let stringRates = `csl=${coveragesData.csllimit}&excessliab=${
        coveragesData.excessliab
        }&value=${totalValue}&days=${vehicle.days}&
      country=${data.data_customer?.country_id}&policy_type=${
        coveragesData.policy_type
        }&
      medicallimit=${
        coveragesData.medicallimit
        }&damageded=2&theftded=5&vehtype=${vehicle.vehtype}&year_vehicle=${
        data.selectedVehicle?.year_model
        }&
      specialdiscount=1&effectivedate=${
        coveragesData.effectivedate
        }&vehicle_type_model=&bordercross=${vehicle.borderCrossing}&agency=${
        vehicle.agent
        }&
      gapcoverage=${
        coveragesData.gapcoverage == "no" ? false : true
        }&premierepackage=${coveragesData.premierepackage}&fixeddeductible=${
        coveragesData.fixeddeductible
        }&policycaravan=false&
      roadside=${coveragesData.roadside}&physical_damage=${
        coveragesData.physical_damage
        }&theft=${coveragesData.theft}&legalaid=${coveragesData.legalaid}&
      hdiautoguard=false&accessories_and_safety=false&ter_ext=false`;
      if (typeof data?.selectedVehicle?.trailer != "undefined") {
        stringRates +=
          data?.selectedVehicle?.trailer != "0" ? "&withtrailer=true" : "";
      }
      const resultSubmit = await dispatch(getRates(stringRates));
      if (resultSubmit.payload.result) {
        setResultRates(resultSubmit.payload.data);
        if (!gettingQuote) {
          setGettingQuote(true);
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    }
  };

  const checkSelectedQuote = (days) => {
    return vehicleData.days === parseInt(days) ? "yellow" : "gray";
  };

  const setDays = (days) => {
    if (
      (vehicleData.days < 90 && parseInt(days) >= 90) ||
      (vehicleData.days < 365 && parseInt(days) >= 365)
    ) {
      setOpen(true);
    }
    let vehicle = { ...vehicleData };
    vehicle.days = parseInt(days);
    setVehicleData(vehicle);
    getQuoteRates(coverages, vehicle);
  };

  return (
    <div>
      {!gettingQuote ? (
        <GridItem xs={12} sm={12} md={12}>
          {open ? (
            <CustomDialog open={true} title={"Edit Quote"}></CustomDialog>
          ) : null}
          <div
            className={`${classesPrincipal.title} ${classesPrincipal.black}`}
          >
            {t("step1.quote.title")}{" "}
            <span
              className={`${classesPrincipal.title} ${classesPrincipal.red}`}
            >
              {t("step1.quote.quote")}{" "}
            </span>
          </div>
          <div
            className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
          >
            {t("step1.quote.subTitle")}{" "}
            <span
              className={`${classesPrincipal.subTitle} ${classesPrincipal.green}`}
            >
              {" "}
              {t("step1.quote.greenSubtitle")}{" "}
            </span>
          </div>

          <GridContainer style={{ marginTop: "20px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.daysOfCoverage")}
                    menuItems={daysOptions}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    placeholder={t("step1.quote.daysPlaceholder")}
                    name="days"
                    information={"Tooltip Text"}
                    value={vehicleData.days}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "days",
                      value: vehicleData.days
                        ? vehicleData.days
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={8} sm={8} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.borderCrossing")}
                    placeholder={t("step1.quote.borderCrossing")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.borderCrossing.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="borderCrossing"
                    value={vehicleData.borderCrossing}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "borderCrossing",
                      value: vehicleData.borderCrossing
                        ? vehicleData.borderCrossing
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleType")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.vehicle_types.map((item) => ({
                          value: item.id,
                          label: item.name,
                          icon: item.icon,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="vehtype"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "vehtype",
                      value: vehicleData.vehtype,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleValue")}
                    placeholder={t("step1.quote.actualCash")}
                    menuItems={
                      valores
                        ? valores.map((item) => ({
                          value: item.value,
                          label: item.traduccion,
                        }))
                        : []
                    }
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.valueVehicleInfo"),
                        }}
                      />
                    }
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "total_value_vehicle",
                      value:
                        vehicleData.total_value_vehicle != null
                          ? vehicleData.total_value_vehicle
                          : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.towedUnitsValue")}
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.towedUnitsInfo"),
                        }}
                      />
                    }
                    menuItems={
                      valores
                        ? [
                          { value: -1, label: "No towed units" },
                          ...valores.map((item) => ({
                            value: item.value,
                            label: item.traduccion,
                          })),
                        ]
                        : [{ value: -1, label: "No towed units" }]
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "total_value_trailer",
                      value: vehicleData.total_value_trailer
                        ? vehicleData.total_value_trailer
                        : -1,

                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days >= 90 ? (
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomToogle
                      labelText={t("step1.quote.vehicleCoverageSonoraBC")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "mexcoverage",
                        value: vehicleData.mexcoverage,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days > 360 ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomToogle
                      labelText={t("step1.quote.sentriPass")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      defaultValue="no"
                      inputProps={{
                        required: true,
                        name: "sentripass",
                        value: vehicleData.sentripass,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>

              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.selectAgent")}
                    information={t("step1.quote.selectAgentInfo")}
                    helperText={
                      !loginCredentials.login && sended
                        ? "Vehicle type field is required."
                        : null
                    }
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.agencies.map((item) => ({
                          value: item.id,
                          label: `${item.city} ${item.agency_name}`,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      required: true,
                      name: "agent",
                      value: vehicleData.agent,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                style={{ padding: "40px 0px 40px 0px" }}
                justify={isSmall ? "center" : "left"}
              >
                <GridItem xs={12} sm={12} md={4}>
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      disabled={!isCompleted}
                      id="loginMobile"
                      type="submit"
                      onClick={() => handleSubmit()}
                      variant="green"
                      block
                    >
                      {t("step1.quote.quoteButton")}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      ) : (
          <GridItem xs={12} sm={12} md={12}>
            <div
              className={`${classesPrincipal.title} ${classesPrincipal.black}`}
            >
              {t("step1.quotation.title")}
            </div>
            <div
              className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
            >
              {t("step1.quotation.subTitle")}
            </div>
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <PriceCard
                      size="medium"
                      edit={true}
                      variant={checkSelectedQuote(resultRates.days1)}
                      text={
                        daysOptions.find((e) => e.value == resultRates.days1)
                          .label
                      }
                      onEdit={() => setOpen(true)}
                      clicked={() => setDays(resultRates.days1)}
                      cost={formatNumber(resultRates.total1)}
                      buy={true}
                      buyEvent={handleSubmitStep}
                    />
                  </GridItem>
                </GridContainer>
                {vehicleData.days < 365 ? (
                  <>
                    <div
                      className={`${classesPrincipal.subTitle} ${classesPrincipal.black} ${classesPrincipal.font700}`}
                    >
                      {t("step1.quotation.savings")}
                    </div>
                    <div
                      className={`${classesPrincipal.text} ${classesPrincipal.black}`}
                    >
                      {t("step1.quotation.greater")}
                    </div>
                  </>
                ) : null}
                <GridContainer style={{ marginTop: "20px" }}>
                  {vehicleData.days < 90 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days4)}
                        clicked={() => setDays(resultRates.days4)}
                        text={t("daysOptions.90days")}
                        cost={formatNumber(resultRates.total4)}
                      />
                    </GridItem>
                  ) : null}
                  {vehicleData.days < 182 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days2)}
                        clicked={() => setDays(resultRates.days2)}
                        text={t("daysOptions.6months")}
                        cost={formatNumber(resultRates.total2)}
                      />
                    </GridItem>
                  ) : null}
                  {vehicleData.days < 365 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days3)}
                        clicked={() => setDays(resultRates.days3)}
                        text={t("daysOptions.1year")}
                        cost={formatNumber(resultRates.total3)}
                      />
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <Accordion
                      defaultExpanded
                      sx={{
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        classes={{ content: classesPrincipal.titleAccordion }}
                        className={`${classesPrincipal.subTitle} ${classesPrincipal.white} ${classesPrincipal.greenBackground}`}
                      >
                        {t("step1.quotation.coverages")} <ExpandMoreIcon />
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AccidentIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.liabilityLimit")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t("step1.coverages.liabilityLimitTooltip")}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomSelect
                                menuItems={
                                  data?.catalogs
                                    ? data.catalogs.CSL_limits.map((item) => ({
                                      value: item.name,
                                      label:
                                        item.name == 0
                                          ? t("step1.coverages.none")
                                          : "$" + formatNumber(item.name),
                                    }))
                                    : []
                                }
                                error={
                                  !loginCredentials.login && sended ? true : null
                                }
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                name="login"
                                onChange={(e) => handleFormChange(e)}
                                inputProps={{
                                  name: "csllimit",
                                  value: coverages.csllimit,
                                  onChange: handleCoveragesChange,
                                }}
                              />
                            </GridItem>
                          </GridContainer>

                          {/* Excess Liability */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LegalIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.excessLiability")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t(
                                        "step1.coverages.excessLiabilityTooltip"
                                      )}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomSelect
                                helperText={
                                  !loginCredentials.login && sended
                                    ? t("step1.coverages.vehicleTypeError")
                                    : null
                                }
                                menuItems={
                                  data?.catalogs
                                    ? data.catalogs.EXC_limits.map((item) => ({
                                      value: item.name,
                                      label:
                                        item.name == 0
                                          ? t("step1.coverages.none")
                                          : "$" + formatNumber(item.name),
                                    }))
                                    : []
                                }
                                error={
                                  !loginCredentials.login && sended ? true : null
                                }
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                name="login"
                                onChange={(e) => handleFormChange(e)}
                                inputProps={{
                                  name: "excessliab",
                                  value: coverages.excessliab,
                                  onChange: handleCoveragesChange,
                                }}
                              />
                            </GridItem>
                          </GridContainer>

                          {/* Medical Expenses */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TheftIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.medicalExpenses")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t(
                                        "step1.coverages.medicalExpensesTooltip"
                                      )}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomSelect
                                menuItems={
                                  data?.catalogs
                                    ? data.catalogs.medical_limits.map(
                                      (item) => ({
                                        value: item.name,
                                        label:
                                          item.name == "none"
                                            ? t("step1.coverages.none")
                                            : "$" + formatNumber(item.name),
                                      })
                                    )
                                    : []
                                }
                                error={
                                  !loginCredentials.login && sended ? true : null
                                }
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                name="login"
                                onChange={(e) => handleFormChange(e)}
                                inputProps={{
                                  name: "medicallimit",
                                  value: coverages.medicallimit,
                                  onChange: handleCoveragesChange,
                                }}
                              />
                            </GridItem>
                          </GridContainer>

                          {/* Legal Aid */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LegalIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.legalAid")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t("step1.coverages.legalAidTooltip")}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {t("step1.coverages.included")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AccidentIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.roadsideAssistance")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t(
                                        "step1.coverages.roadsideAssistanceTooltip"
                                      )}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {t("step1.coverages.included")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Vehicle Value */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AccidentIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.vehicleValue")}
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? `$${formatNumber(
                                    vehicleData.total_value_vehicle
                                  )}`
                                  : t("step1.coverages.liabilityOnly")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Gap Coverage */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LegalIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.gapCoverage")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t("step1.coverages.gapCoverageTooltip")}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              {parseInt(vehicleData.total_value_vehicle) !== 0 ? (
                                <CustomToogle
                                  helperText={null}
                                  items={[
                                    { value: "yes", label: t("common.yes") },
                                    { value: "no", label: t("common.no") },
                                  ]}
                                  error={
                                    !loginCredentials.login && sended
                                      ? true
                                      : null
                                  }
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses,
                                  }}
                                  name="login"
                                  onChange={(e) => handleFormChange(e)}
                                  defaultValue={coverages.gapcoverage ? coverages.gapcoverage : "no"}
                                  inputProps={{
                                    required: true,
                                    name: "gapcoverage",
                                    value: coverages.gapcoverage,
                                    onChange: handleCoveragesChange,
                                  }}
                                />
                              ) : (
                                  <div
                                    className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                    style={{
                                      marginTop: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {t("step1.coverages.notApplicable")}
                                  </div>
                                )}
                            </GridItem>
                          </GridContainer>

                          {/* Physical Damage */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AccidentIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.physicalDamage")}
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.covered")
                                  : t("step1.coverages.notCovered")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TheftIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.theft")}
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.covered")
                                  : t("step1.coverages.notCovered")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Deductible */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LegalIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.deductible")}
                                <InformationTooltip
                                  text={
                                    <span>
                                      {t("step1.coverages.deductibleTooltip")}
                                    </span>
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.deductibleAmount")
                                  : t("step1.coverages.notApplicable")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Partial Theft and Vandalism */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TheftIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.partialTheft")}
                                <InformationTooltip
                                  text={t("step1.coverages.partialTheftTooltip")}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.covered")
                                  : t("step1.coverages.notCovered")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Uninsured Motorists */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TheftIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.uninsuredMotorists")}
                                <InformationTooltip
                                  text={t(
                                    "step1.coverages.uninsuredMotoristsTooltip"
                                  )}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.included")
                                  : t("step1.coverages.notCovered")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AccidentIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.rentalCar")}
                                <InformationTooltip
                                  text={t("step1.coverages.rentalCarTooltip")}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parseInt(vehicleData.total_value_vehicle) !== 0
                                  ? t("step1.coverages.rentalCarDays")
                                  : t("step1.coverages.notCovered")}
                              </div>
                            </GridItem>
                          </GridContainer>

                          {/* Coverage Territory */}
                          <GridContainer
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LegalIcon estilo={{ marginRight: "20px" }} />
                                {t("step1.coverages.coverageTerritory")}
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <div
                                className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {vehicleData.mexcoverage === "yes"
                                  ? t("step1.coverages.coverageTerritoryLimited")
                                  : t("step1.coverages.coverageTerritoryFull")}
                              </div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                    <GridContainer
                      container
                      direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      style={{ minHeight: "100px", marginTop: "20px" }}
                    >
                      <GridItem>
                        <Button
                          id="loginMobile"
                          type="submit"
                          onClick={handleSubmitStep}
                          variant="green"
                          //className={`${classesPrincipal.}`}
                          block
                        >
                          {t("step1.coverages.continue")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      <React.Fragment>
        <BootstrapDialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle
            className={`${classesPrincipal.titleAccordion} ${classesPrincipal.black} ${classesPrincipal.title} ${classesPrincipal.yellowBackground}`}
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          >
            {t("common.editQuote")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          ></IconButton>
          <DialogContent dividers>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.daysOfCoverage")}
                    menuItems={daysOptions}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    placeholder={t("step1.quote.daysPlaceholder")}
                    name="days"
                    information={"Tooltip Text"}
                    value={vehicleData.days}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "days",
                      value: vehicleData.days
                        ? vehicleData.days
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={8} sm={8} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.borderCrossing")}
                    placeholder={t("step1.quote.borderCrossing")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.borderCrossing.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="borderCrossing"
                    value={vehicleData.borderCrossing}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "borderCrossing",
                      value: vehicleData.borderCrossing
                        ? vehicleData.borderCrossing
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleType")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.vehicle_types.map((item) => ({
                          value: item.id,
                          label: item.name,
                          icon: item.icon,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="vehtype"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "vehtype",
                      value: vehicleData.vehtype,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleValue")}
                    placeholder={t("step1.quote.actualCash")}
                    menuItems={
                      valores
                        ? valores.map((item) => ({
                          value: item.value,
                          label: item.traduccion,
                        }))
                        : []
                    }
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.valueVehicleInfo"),
                        }}
                      />
                    }
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "total_value_vehicle",
                      value:
                        vehicleData.total_value_vehicle != null
                          ? vehicleData.total_value_vehicle
                          : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.towedUnitsValue")}
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.towedUnitsInfo"),
                        }}
                      />
                    }
                    menuItems={
                      valores
                        ? [
                          { value: -1, label: "No towed units" },
                          ...valores.map((item) => ({
                            value: item.value,
                            label: item.traduccion,
                          })),
                        ]
                        : [{ value: -1, label: "No towed units" }]
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "total_value_trailer",
                      value: vehicleData.total_value_trailer
                        ? vehicleData.total_value_trailer
                        : -1,

                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days >= 90 ? (
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomToogle
                      labelText={t("step1.quote.vehicleCoverageSonoraBC")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "mexcoverage",
                        value: vehicleData.mexcoverage,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days > 360 ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomToogle
                      labelText={t("step1.quote.sentriPass")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      defaultValue="no"
                      inputProps={{
                        name: "sentripass",
                        value: vehicleData.sentripass,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>

              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.selectAgent")}
                    information={t("step1.quote.selectAgentInfo")}
                    helperText={
                      !loginCredentials.login && sended
                        ? "Vehicle type field is required."
                        : null
                    }
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.agencies.map((item) => ({
                          value: item.id,
                          label: `${item.city} ${item.agency_name}`,
                        }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "agent",
                      value: vehicleData.agent,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                style={{ padding: "40px 0px 40px 0px" }}
                justify={isSmall ? "center" : "left"}
              >
                <GridItem xs={5} sm={5} md={2}>
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      disabled={!isCompleted}
                      id="loginMobile"
                      type="submit"
                      onClick={() => {
                        setOpen(false);
                        handleSubmit();
                      }}
                      variant="green"
                      block
                    >
                      {t("common.recalculate")}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
});

Step1.displayName = "Step1";

export default Step1;
