import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const getOrdersByClient = createAsyncThunk(
  "ordersView/getOrdersByClient",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Orders/getOrdersByClient/13`,
        { authorization: localStorage.getItem("userToken") }
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getQuotesByClient = createAsyncThunk(
  "ordersView/getQuotesByClient",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Orders/getQuotesByClient/13`,
        { authorization: localStorage.getItem("userToken") }
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteQuoteAPI = createAsyncThunk(
  "ordersView/deleteQuoteAPI",
  async (params, { rejectWithValue }) => {
    try {
      params.authorization = localStorage.getItem("userToken");
      const response = await axios.post(
        `${BASE_URL}/Orders/deleteQuote`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getActiveOrdersByClient = createAsyncThunk(
  "ordersView/getActiveOrdersByClient",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/Orders/activeOrders`, {
        authorization: localStorage.getItem("userToken"),
      });
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAgencyDataByClient = createAsyncThunk(
  "ordersView/getAgencyDataByClient",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/Orders/getAgencyData`, {
        authorization: localStorage.getItem("userToken"),
      });
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCustomerByToken = createAsyncThunk(
  "ordersView/getCustomerByToken",
  async (params, { rejectWithValue }) => {
    try {
      params.authorization = localStorage.getItem("userToken");
      if (localStorage.getItem("kiosko") === "true") {
        params.kiosko = true;
      }
      const response = await axios.post(
        `${BASE_URL}/Customer/getCustomerByToken`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const issue = createAsyncThunk(
  "ordersView/issuePolicy",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Orders/issuePolicy`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkQuote = createAsyncThunk(
  "ordersView/checkPolicyInfo",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Orders/checkPolicyInfo`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRates = createAsyncThunk(
  "ordersView/getRates",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/Orders/getRates?${params}`);
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDocument = createAsyncThunk(
  "orders/getDocument",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Orders/getDocumentPolicy?policyNumber=${params}`
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkExistPolicyNumber = createAsyncThunk(
  "guest/checkExitsPolicyNumber",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/Orders/checkExitsPolicyNumber`,
        params
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAppleTokenClient = createAsyncThunk(
  "orders/getAppleTokenClient",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Orders/getAppleTokenClient`
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
