import React, { useEffect, useState, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InformationTooltip from "components/CustomTooltips/InformationTooltip.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDialog from "components/CustomDialog/CustomDialog.js";
import PriceCard from "components/CardComponents/PriceCard";
import CustomToogle from "components/CustomToogle/CustomToogle.js";
import Button from "components/CustomButtons/CustomButton";
import AccidentIcon from "components/CustomIcons/Accident";
import LegalIcon from "components/CustomIcons/Legal";
import TheftIcon from "components/CustomIcons/Theft";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomAlerts from "components/Alerts/CustomAlerts";
import StepperComponent from "components/Stepper/StepperComponent";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getRates } from "views/Services/OrdersService";
import {
  formatNumber,
  fixDaysMonths,
  currentDateEffective,
  addDays,
  getDaysFromDates,
  formatDateToEffectiveAndExpiration,
  validValueCoverage,
  getUTCDate,
} from "components/Utils/utils.js";
import { useTranslation } from "react-i18next";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStylesPrincipal = makeStyles(stylePrincipal);
const useStyles = makeStyles(styles);
let current_date = new Date();

const Step1 = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    sendStepData,
  }));
  const sendStepData = () => {
    submitAction(coverages, resultRates, true);
  };
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const {
    isSmall,
    submitAction,
    setPaymentModal,
    previewAction,
    changeStep,
  } = props;
  const handlePreviewAction = async (event) => {
    previewAction();
  };
  const routeParams = useParams();
  const { userId, token, type } = routeParams;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const classesPrincipal = useStylesPrincipal();
  const [toastMessage, setToastMessage] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [refreshQuote, setRefreshQuote] = useState(false);
  const [oneClicValidate, setOneClicValidate] = useState(false);
  const [oneClickLogin, setOneClickLogin] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    login: "",
    password: "",
  });
  const [sended, setSended] = useState(false);
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState({ vehtype: 1, agent: 441 });
  const [valores, setValores] = useState([]);
  const [gettingQuote, setGettingQuote] = useState(false);
  const [vehTypeState, setVehTypeState] = useState("error");
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  const daysOptions = [
    { value: 1, label: t("daysOptions.1day") },
    { value: 2, label: t("daysOptions.2days") },
    { value: 3, label: t("daysOptions.3days") },
    { value: 4, label: t("daysOptions.4days") },
    { value: 5, label: t("daysOptions.5days") },
    { value: 6, label: t("daysOptions.6days") },
    { value: 7, label: t("daysOptions.7days") },
    { value: 8, label: t("daysOptions.8days") },
    { value: 9, label: t("daysOptions.9days") },
    { value: 10, label: t("daysOptions.10days") },
    { value: 11, label: t("daysOptions.11days") },
    { value: 12, label: t("daysOptions.12days") },
    { value: 13, label: t("daysOptions.13days") },
    { value: 14, label: t("daysOptions.14days") },
    { value: 15, label: t("daysOptions.15days") },
    { value: 16, label: t("daysOptions.16days") },
    { value: 17, label: t("daysOptions.17days") },
    { value: 18, label: t("daysOptions.18days") },
    { value: 19, label: t("daysOptions.19days") },
    { value: 20, label: t("daysOptions.20days") },
    { value: 21, label: t("daysOptions.21days") },
    { value: 22, label: t("daysOptions.22days") },
    { value: 23, label: t("daysOptions.23days") },
    { value: 24, label: t("daysOptions.24days") },
    { value: 25, label: t("daysOptions.25days") },
    { value: 26, label: t("daysOptions.26days") },
    { value: 27, label: t("daysOptions.27days") },
    { value: 28, label: t("daysOptions.28days") },
    { value: 29, label: t("daysOptions.29days") },
    { value: 30, label: t("daysOptions.30days") },
    { value: 90, label: t("daysOptions.90days") },
    { value: 182, label: t("daysOptions.6months") },
    { value: 365, label: t("daysOptions.1year") },
  ];
  const timeZoneList = {
    1: 12,
    2: 8,
    3: 17,
    4: 33,
    5: 12,
    6: 33,
  };
  const [coverages, setCoverages] = useState({
    csllimit: "500000",
    excessliab: "150000",
    country: 0,
    policy_type: 1,
    medicallimit: "5000",
    damageded: 2,
    theftded: 5,
    vehtype: 1,
    year_vehicle: 2025,
    specialdiscount: 1,
    effectivedate: "10-23-2024",
    bordercross: 2,
    gapcoverage: "no",
    premierepackage: true,
    fixeddeductible: true,
    policycaravan: false,
    roadside: true,
    physical_damage: true,
    theft: true,
    legalaid: true,
    hdiautoguard: false,
    accessories_and_safety: false,
    ter_ext: false,
    days: 3,
    //meridian: "am",
  });
  const [resultRates, setResultRates] = useState({});

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setValuesForTypeVehicle(1, {});
    }
    if (props.vehicleData) {
      setVehicleData(props.vehicleData);
    }
    if (props.coverages) {
      setCoverages(props.coverages);
    }
    if (props.resultRates) {
      setResultRates(props.resultRates);
    }
  }, [props]);

  useEffect(() => {
    if (type === "oneClickLogin") {
      setOneClickLogin(true);
    }

    if (window.location.href.includes("checkTokenOneClick")) {
      setOneClicValidate(true);
      validateToken();
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    getQuoteRates(coverages, vehicleData);
  };
  const handleSubmitStep = async (event) => {
    submitAction(coverages, resultRates, false);
  };

  const handleFormChange = (e) => {
    const valor = e.target.value;
    let vehData = { ...vehicleData };
    let coveragesData = { ...coverages };
    vehData[e.target.name] = valor;
    const sendedData = {};
    if (e.target.name === "days") {
      vehData.selectedDays = valor;
      coveragesData.days = valor;
    }
    if (e.target.name === "startTime") {
      coveragesData.timeset = valor;
      validateDateAndTime(
        coveragesData.startDate,
        coveragesData.timeset,
        coveragesData.meridian
      );
    }

    if (e.target.name === "startDate") {
      let expirationData = addDays(vehicleData.days, "effectivedate", {
        effectivedate: valor,
      });
      let formatValueDate = formatDateToEffectiveAndExpiration(valor, "quote");
      coveragesData.effectivedate = formatValueDate.date;
      coveragesData.startDate = valor;
      coveragesData.expirationdate = expirationData.expirationDate.date;
      validateDateAndTime(
        coveragesData.startDate,
        coveragesData.timeset,
        coveragesData.meridian
      );
    }
    if (e.target.name === "borderCrossing") {
      let timeZoneList = {
        1: 12,
        2: 8,
        3: 17,
        4: 33,
        5: 12,
        6: 33,
      };
      coveragesData.timezone = timeZoneList[e.target.value];
    }
    if (e.target.name === "meridian") {
      coveragesData.meridian = valor;
      validateDateAndTime(
        coveragesData.startDate,
        coveragesData.timeset,
        coveragesData.meridian
      );
    }
    if (e.target.name === "days") {
      vehData.selectedDays = valor;
      coveragesData.days = valor;
    }
    if (e.target.name === "csrinitials") {
      coveragesData.csrinitials = valor;
    }
    if (e.target.name === "specialdiscount") {
      coveragesData.specialdiscount = valor;
    }
    if (e.target.name === "vehtype" || e.target.name === "year_vehicle") {
      if (e.target.name === "vehtype") {
        sendedData.vehtype = valor;
        setVehTypeState("success");
        sendedData.year_vehicle = vehicleData.year_vehicle;
        vehData.type_name = e.nativeEvent.target.textContent;
      } else {
        sendedData.vehtype = vehicleData.vehtype;
        sendedData.year_vehicle = valor;
        setYearState("success");
      }
      setValuesForTypeVehicle(valor, vehData);
    } else if (e.target.name === "make_vehicle" && showSelectMake) {
      sendedData.vehtype = vehicleData.vehtype;
      sendedData.make_vehicle = valor;
      setMakeState("success");
      getModelsData(sendedData);
    }
    if (e.target.name === "year_vehicle") {
      vehData.year_model = e.nativeEvent.target.textContent;
    }
    if (e.target.name === "make_vehicle") {
      vehData.make_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.makeId = valor;
    }
    if (e.target.name === "model_vehicle") {
      vehData.model_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.modelId = valor;
    }
    if (e.target.name === "value") {
      setValueState("success");
      vehData.value = valor;
    }
    if (e.target.name === "insurancepolnumber") {
      setFullCoverageState("success");
      vehData.insurancepolnumber = valor;
      if (valor == "No") {
        vehData.value = "0";
        formatValueField(0);
      } else {
        setValuesForTypeVehicle(1, vehData);
      }
    }
    if (vehData.initDate && vehData.endDate) {
      const initDate = new Date(vehData.initDate);
      const endDate = new Date(vehData.endDate);
      const diffTime = Math.abs(endDate - initDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      vehData.days = diffDays;
    }
    setVehicleData(vehData);
    validateComplete(vehData);
    setCoverages(coveragesData);
    getQuoteRates(coveragesData, vehData);
    changeStep(vehData, coveragesData);
  };

  const handleCoveragesChange = (e) => {
    const valor = e.target.value;
    let coveragesData = { ...coverages };
    coveragesData[e.target.name] = valor;
    setCoverages(coveragesData);
    getQuoteRates(coveragesData, vehicleData);
  };
  const validateComplete = (data) => {
    const reqProperties = [
      "borderCrossing",
      "total_value_vehicle",
      "vehtype",
      "days",
      "agent",
    ];
    if (data && data.days && data.days > 360) {
      reqProperties.push("sentripass");
    }
    if (data && data.days && data.days >= 90) {
      reqProperties.push("mexcoverage");
    }
    const allPropertiesValid = reqProperties.every((prop) => {
      return (
        data[prop] !== undefined && data[prop] !== null && data[prop] !== ""
      );
    });
    setIsCompleted(allPropertiesValid);
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    formatValueField(limitValue);
  };

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...vehicleData };
      vehicleDataToEdit.value = 0;
      setVehicleData(vehicleDataToEdit);
    }
    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 &&
          arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };

  const getQuoteRates = async (coveragesData, vehicle) => {
    let valueVehicle =
      typeof vehicle.total_value_vehicle != "undefined"
        ? parseInt(vehicle.total_value_vehicle || 0)
        : 0;
    let valueTrailer =
      typeof vehicle.total_value_trailer != "undefined"
        ? parseInt(vehicle.total_value_trailer || 0)
        : 0;
    let valueOther =
      typeof vehicle.total_value_other != "undefined"
        ? parseInt(vehicle.total_value_other || 0)
        : 0;
    let totalValue = valueVehicle + valueTrailer + valueOther;
    if (data) {
      let stringRates = `csl=${coveragesData.csllimit}&excessliab=${
        coveragesData.excessliab
      }&value=${totalValue}&days=${vehicle.days}&
      country=${data.data_customer?.country_id}&policy_type=${
        coveragesData.policy_type
      }&
      medicallimit=${
        coveragesData.medicallimit
      }&damageded=2&theftded=5&vehtype=${vehicle.vehtype}&year_vehicle=${
        data.selectedVehicle?.vehicleYear
      }&
      specialdiscount=${coveragesData.specialdiscount}&effectivedate=${
        coveragesData.effectivedate
      }&vehicle_type_model=&bordercross=${vehicle.borderCrossing}&agency=${
        vehicle.agent
      }&
      gapcoverage=${
        coveragesData.gapcoverage == "no" ? false : true
      }&premierepackage=${coveragesData.premierepackage}&fixeddeductible=${
        coveragesData.fixeddeductible
      }&policycaravan=false&
      roadside=${coveragesData.roadside}&physical_damage=${
        coveragesData.physical_damage
      }&theft=${coveragesData.theft}&legalaid=${coveragesData.legalaid}&
      hdiautoguard=false&accessories_and_safety=false&ter_ext=false`;
      if (typeof data?.selectedVehicle?.trailer != "undefined") {
        stringRates +=
          data?.selectedVehicle?.trailer != "0" ? "&withtrailer=true" : "";
      }
      const resultSubmit = await dispatch(getRates(stringRates));
      if (resultSubmit.payload.result) {
        setResultRates(resultSubmit.payload.data);
        if (!gettingQuote) {
          setGettingQuote(true);
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    }
  };

  const checkSelectedQuote = (days) => {
    return vehicleData.days === parseInt(days) ? "yellow" : "gray";
  };

  const setDays = (days) => {
    if (
      (vehicleData.days < 90 && parseInt(days) >= 90) ||
      (vehicleData.days < 365 && parseInt(days) >= 365)
    ) {
      //setOpen(true);
    }
    let vehicle = { ...vehicleData };
    vehicle.days = parseInt(days);
    setVehicleData(vehicle);
    let cveragesData = { ...coverages };
    cveragesData.days = parseInt(days);
    setCoverages(cveragesData);
    validateComplete(vehicle);
    getQuoteRates(coverages, vehicle);
  };
  const getDate = (type) => {
    const [year, month, day] = vehicleData.startDate.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + vehicleData.days);

    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    let formattedDate = date.toLocaleDateString("en-US", options);

    const dayNumber = date.getDate();
    const suffix =
      dayNumber % 10 === 1 && dayNumber !== 11
        ? "st"
        : dayNumber % 10 === 2 && dayNumber !== 12
        ? "nd"
        : dayNumber % 10 === 3 && dayNumber !== 13
        ? "rd"
        : "th";

    formattedDate = formattedDate.replace(
      dayNumber.toString(),
      `${dayNumber}${suffix}`
    );

    return formattedDate;
  };

  const getDays = () => {
    return resultRates?.days1 != "undefined"
      ? daysOptions.find((e) => e.value == resultRates.days1).label
      : "0 days";
  };
  const timezones= {
    "8": "California - PST",
    "12": "Arizona - AT",
    "17": "New Mexico/West Texas - MST",
    "33": "Texas - CST"
  }
  
  const today = new Date().toISOString().split("T")[0];
  const validateDateAndTime = (startDate, startTime, meridian) => {
    if (!startDate || !startTime) return false;
    let [hour, minute] = startTime.split(":");
    hour = meridian === "PM" ? parseInt(hour) + 12 : hour;
    const formattedTime = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;
    const selectedDateTime = new Date(`${startDate}T${formattedTime}`);
    const actualDate = new Date();
    setValidDate(selectedDateTime >= actualDate);
  };

  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        <div className={`${classesPrincipal.title} ${classesPrincipal.black}`}>
          {t("step4.title")}
        </div>

        <GridContainer style={{ marginTop: "20px" }}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                {!validDate &&
                coverages.timeset &&
                coverages.startDate &&
                coverages.meridian ? (
                  <GridContainer>
                    <GridItem
                      style={{ marginTop: "30px", marginBottom: "20px" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <CustomAlerts
                        title={""}
                        text={t("step4.dateAlert")}
                        variant="dangerOutlined"
                        icon="warning_amber"
                        severity="error"
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("step4.policyStartDate")}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      min={today}
                      inputProps={{
                        required: true,
                        type: "date",
                        name: "startDate",
                        value: coverages.startDate,
                        onChange: handleFormChange,
                        //min: today,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={4}>
                    <CustomSelect
                      labelText={t("step4.startTime")}
                      menuItems={[
                        { value: "12:00", label: "12:00" },
                        { value: "12:30", label: "12:30" },
                        { value: "1:00", label: "1:00" },
                        { value: "1:30", label: "1:30" },
                        { value: "2:00", label: "2:00" },
                        { value: "2:30", label: "2:30" },
                        { value: "3:00", label: "3:00" },
                        { value: "3:30", label: "3:30" },
                        { value: "4:00", label: "4:00" },
                        { value: "4:30", label: "4:30" },
                        { value: "5:00", label: "5:00" },
                        { value: "5:30", label: "5:30" },
                        { value: "6:00", label: "6:00" },
                        { value: "6:30", label: "6:30" },
                        { value: "7:00", label: "7:00" },
                        { value: "7:30", label: "7:30" },
                        { value: "8:00", label: "8:00" },
                        { value: "8:30", label: "8:30" },
                        { value: "9:00", label: "9:00" },
                        { value: "9:30", label: "9:30" },
                        { value: "10:00", label: "10:00" },
                        { value: "10:30", label: "10:30" },
                        { value: "11:00", label: "11:00" },
                        { value: "11:30", label: "11:30" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="startTime"
                      inputProps={{
                        required: true,
                        name: "startTime",
                        value: coverages.timeset,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={2}>
                    <CustomToogle
                      helperText={null}
                      items={[
                        { value: "AM", label: "AM" },
                        { value: "PM", label: "PM" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      style={{ marginTop: "-20px" }}
                      name="meridian"
                      onChange={(e) => handleFormChange(e)}
                      defaultValue={coverages.meridian}
                      inline={true}
                      inputProps={{
                        required: true,
                        name: "meridian",
                        value: coverages.meridian,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                      labelText={t("step4.daysOfCoverage")}
                      helperText={
                        !loginCredentials.login && sended
                          ? "Border crossing field is required."
                          : null
                      }
                      menuItems={daysOptions}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      placeholder={"Days"}
                      name="days"
                      information={"Tooltip Text"}
                      value={coverages.days}
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "days",
                        value: coverages.days ? coverages.days : "placeholder",
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem
                  style={{
                    marginLeft: "-15px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  xs={12}
                  sm={12}
                  md={8}
                >
                  {vehicleData.days &&
                  vehicleData.startDate &&
                  vehicleData.startTime &&
                  coverages.meridian ? (
                    <CustomAlerts
                      title={t("step4.expireTitle")}
                      text={
                        <>
                          <span
                            className={classesPrincipal.red}
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {getDate()} at {vehicleData.startTime}{" "}
                            {coverages.meridian} {timezones[coverages.timezone]}.
                          </span>
                          <br />
                          {t("step4.expireText")}
                        </>
                      }
                      variant="successOutlined"
                      icon="warning_amber"
                      severity="success"
                    />
                  ) : null}
                </GridItem>
                {["1", "2"].includes(localStorage.getItem("userType")) && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={4}>
                      <CustomInput
                        labelText={t("step4.csrInitials")}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          name: "csrinitials",
                          value: coverages.csrinitials,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={8} sm={8} md={4}>
                      <CustomSelect
                        labelText={t("step4.specialDiscount")}
                        placeholder={t("step4.specialDiscount")}
                        helperText={
                          !loginCredentials.login && sended
                            ? "Border crossing field is required."
                            : null
                        }
                        menuItems={
                          data?.catalogs
                            ? data.catalogs.special_discounts.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))
                            : []
                        }
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="specialdiscount"
                        value={coverages.specialdiscount}
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          name: "specialdiscount",
                          value: coverages.specialdiscount
                            ? coverages.specialdiscount
                            : "placeholder",
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={8} sm={8} md={4}>
                    <CustomSelect
                      labelText={t("step4.borderCrossing")}
                      placeholder={"Border crossing"}
                      helperText={
                        !loginCredentials.login && sended
                          ? "Border crossing field is required."
                          : null
                      }
                      menuItems={
                        data?.catalogs
                          ? data.catalogs.borderCrossing.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="borderCrossing"
                      value={vehicleData.borderCrossing}
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        name: "borderCrossing",
                        value: vehicleData.borderCrossing
                          ? vehicleData.borderCrossing
                          : "placeholder",
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {vehicleData && vehicleData.days && vehicleData.days >= 90 ? (
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomToogle
                        labelText={t("step1.quote.vehicleCoverageSonoraBC")}
                        helperText={null}
                        items={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="login"
                        onChange={(e) => handleFormChange(e)}
                        inputProps={{
                          required: true,
                          name: "mexcoverage",
                          value: vehicleData.mexcoverage,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer>
                  {vehicleData && vehicleData.days && vehicleData.days > 360 ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomToogle
                        labelText={t("step1.quote.sentriPass")}
                        helperText={null}
                        items={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        error={!loginCredentials.login && sended ? true : null}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        name="login"
                        onChange={(e) => handleFormChange(e)}
                        defaultValue={vehicleData.sentripass || "no"}
                        inputProps={{
                          required: true,
                          name: "sentripass",
                          value: vehicleData.sentripass,
                          onChange: handleFormChange,
                        }}
                      />
                    </GridItem>
                  ) : null}
                </GridContainer>
              </GridItem>
            </GridContainer>
            {resultRates.days1 ? (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <PriceCard
                      size="medium"
                      edit={false}
                      variant={checkSelectedQuote(resultRates.days1)}
                      text={getDays()}
                      onEdit={() => setOpen(true)}
                      clicked={() => {
                        setDays(resultRates.days1);
                        //setPaymentModal(true);
                      }}
                      cost={formatNumber(resultRates.total1 || 0)}
                      buy={isCompleted && validDate}
                      buyEvent={handleSubmitStep}
                    />
                  </GridItem>
                </GridContainer>
                {vehicleData.days < 365 ? (
                  <>
                    <div
                      className={`${classesPrincipal.subTitle} ${classesPrincipal.black}`}
                    >
                      {t("step4.promotion")}
                    </div>
                  </>
                ) : null}
                <GridContainer style={{ marginTop: "20px" }}>
                  {vehicleData.days < 90 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days4)}
                        clicked={() => setDays(resultRates.days4)}
                        text={t("daysOptions.90days")}
                        cost={formatNumber(resultRates.total4)}
                      />
                    </GridItem>
                  ) : null}
                  {vehicleData.days < 182 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days2)}
                        clicked={() => setDays(resultRates.days2)}
                        text={t("daysOptions.6months")}
                        cost={formatNumber(resultRates.total2)}
                      />
                    </GridItem>
                  ) : null}
                  {vehicleData.days < 365 ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <PriceCard
                        size="small"
                        edit={false}
                        variant={checkSelectedQuote(resultRates.days3)}
                        clicked={() => setDays(resultRates.days3)}
                        text={t("daysOptions.1year")}
                        cost={formatNumber(resultRates.total3)}
                      />
                    </GridItem>
                  ) : null}
                </GridContainer>
              </>
            ) : null}
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <Accordion
                  defaultExpanded
                  sx={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    classes={{ content: classesPrincipal.titleAccordion }}
                    className={`${classesPrincipal.subTitle} ${classesPrincipal.white} ${classesPrincipal.greenBackground}`}
                  >
                    {t("step1.quotation.coverages")} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccidentIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.liabilityLimit")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.liabilityLimitTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomSelect
                            menuItems={
                              data?.catalogs
                                ? data.catalogs.CSL_limits.map((item) => ({
                                    value: item.name,
                                    label:
                                      item.name == 0
                                        ? t("step1.coverages.none")
                                        : "$" + formatNumber(item.name),
                                  }))
                                : []
                            }
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            name="login"
                            onChange={(e) => handleFormChange(e)}
                            inputProps={{
                              name: "csllimit",
                              value: coverages.csllimit,
                              onChange: handleCoveragesChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      {/* Excess Liability */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LegalIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.excessLiability")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.excessLiabilityTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomSelect
                            helperText={
                              !loginCredentials.login && sended
                                ? t("step1.coverages.vehicleTypeError")
                                : null
                            }
                            menuItems={
                              data?.catalogs
                                ? data.catalogs.EXC_limits.map((item) => ({
                                    value: item.name,
                                    label:
                                      item.name == 0
                                        ? t("step1.coverages.none")
                                        : "$" + formatNumber(item.name),
                                  }))
                                : []
                            }
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            name="login"
                            onChange={(e) => handleFormChange(e)}
                            inputProps={{
                              name: "excessliab",
                              value: coverages.excessliab,
                              onChange: handleCoveragesChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      {/* Medical Expenses */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TheftIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.medicalExpenses")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.medicalExpensesTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomSelect
                            menuItems={
                              data?.catalogs
                                ? data.catalogs.medical_limits.map((item) => ({
                                    value: item.name,
                                    label:
                                      item.name == "none"
                                        ? t("step1.coverages.none")
                                        : "$" + formatNumber(item.name),
                                  }))
                                : []
                            }
                            error={
                              !loginCredentials.login && sended ? true : null
                            }
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            name="login"
                            onChange={(e) => handleFormChange(e)}
                            inputProps={{
                              name: "medicallimit",
                              value: coverages.medicallimit,
                              onChange: handleCoveragesChange,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      {/* Legal Aid */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LegalIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.legalAid")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.legalAidTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {t("step1.coverages.included")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccidentIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.roadsideAssistance")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t(
                                    "step1.coverages.roadsideAssistanceTooltip"
                                  )}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {t("step1.coverages.included")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Vehicle Value */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccidentIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.vehicleValue")}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? `$${formatNumber(
                                  vehicleData.total_value_vehicle
                                )}`
                              : t("step1.coverages.liabilityOnly")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Gap Coverage */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LegalIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.gapCoverage")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.gapCoverageTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          {parseInt(vehicleData.total_value_vehicle) !== 0 ? (
                            <CustomToogle
                              helperText={null}
                              items={[
                                { value: "yes", label: t("common.yes") },
                                { value: "no", label: t("common.no") },
                              ]}
                              error={
                                !loginCredentials.login && sended ? true : null
                              }
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              name="login"
                              onChange={(e) => handleFormChange(e)}
                              defaultValue="no"
                              inputProps={{
                                required: true,
                                name: "gapcoverage",
                                value: coverages.gapcoverage,
                                onChange: handleCoveragesChange,
                              }}
                            />
                          ) : (
                            <div
                              className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                              style={{
                                marginTop: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t("step1.coverages.notApplicable")}
                            </div>
                          )}
                        </GridItem>
                      </GridContainer>

                      {/* Physical Damage */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccidentIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.physicalDamage")}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.covered")
                              : t("step1.coverages.notCovered")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TheftIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.theft")}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.covered")
                              : t("step1.coverages.notCovered")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Deductible */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LegalIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.deductible")}
                            <InformationTooltip
                              text={
                                <span>
                                  {t("step1.coverages.deductibleTooltip")}
                                </span>
                              }
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.deductibleAmount")
                              : t("step1.coverages.notApplicable")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Partial Theft and Vandalism */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TheftIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.partialTheft")}
                            <InformationTooltip
                              text={t("step1.coverages.partialTheftTooltip")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.covered")
                              : t("step1.coverages.notCovered")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Uninsured Motorists */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TheftIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.uninsuredMotorists")}
                            <InformationTooltip
                              text={t(
                                "step1.coverages.uninsuredMotoristsTooltip"
                              )}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.included")
                              : t("step1.coverages.notCovered")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccidentIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.rentalCar")}
                            <InformationTooltip
                              text={t("step1.coverages.rentalCarTooltip")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {parseInt(vehicleData.total_value_vehicle) !== 0
                              ? t("step1.coverages.rentalCarDays")
                              : t("step1.coverages.notCovered")}
                          </div>
                        </GridItem>
                      </GridContainer>

                      {/* Coverage Territory */}
                      <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LegalIcon estilo={{ marginRight: "20px" }} />
                            {t("step1.coverages.coverageTerritory")}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            className={`${classesPrincipal.labels} ${classesPrincipal.green}`}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {vehicleData.mexcoverage === "yes"
                              ? t("step1.coverages.coverageTerritoryLimited")
                              : t("step1.coverages.coverageTerritoryFull")}
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </AccordionDetails>
                </Accordion>
                <GridContainer
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ minHeight: "100px", marginTop: "20px" }}
                >
                  <GridItem xs={4}>
                    <Button
                      id="backButton"
                      onClick={handlePreviewAction}
                      variant="white"
                      style={{ width: "100%" }}
                    >
                      {t("common.previous")}
                    </Button>
                  </GridItem>

                  <GridItem xs={4} />
                  <GridItem xs={4}>
                    <Button
                      id="loginMobile"
                      type="submit"
                      onClick={handleSubmitStep}
                      disabled={!isCompleted || !validDate}
                      variant="green"
                      style={{ width: "100%" }}
                    >
                      {t("common.buy")}
                    </Button>
                  </GridItem>
                </GridContainer>
                {/*<GridContainer
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  style={{ minHeight: "100px", marginTop: "20px" }}
                >
                  <GridItem>
                    <Button
                      id="loginMobile"
                      type="submit"
                      onClick={handleSubmitStep}
                      variant="green"
                      //className={`${classesPrincipal.}`}
                      block
                    >
                      {t("common.buy")}
                    </Button>
                  </GridItem>
                </GridContainer>
                */}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
      <React.Fragment>
        <BootstrapDialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle
            className={`${classesPrincipal.titleAccordion} ${classesPrincipal.black} ${classesPrincipal.title} ${classesPrincipal.yellowBackground}`}
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          >
            {t("common.editQuote")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          ></IconButton>
          <DialogContent dividers>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.daysOfCoverage")}
                    menuItems={daysOptions}
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    placeholder={t("step1.quote.days")}
                    name="days"
                    information={"Tooltip Text"}
                    value={vehicleData.days}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "days",
                      value: vehicleData.days
                        ? vehicleData.days
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={8} sm={8} md={3}>
                  <CustomSelect
                    labelText={t("step1.quote.borderCrossing")}
                    placeholder={t("step1.quote.borderCrossing")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.borderCrossing.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="borderCrossing"
                    value={vehicleData.borderCrossing}
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "borderCrossing",
                      value: vehicleData.borderCrossing
                        ? vehicleData.borderCrossing
                        : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleType")}
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.vehicle_types.map((item) => ({
                            value: item.id,
                            label: item.name,
                            icon: item.icon,
                          }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="vehtype"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "vehtype",
                      value: vehicleData.vehtype,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.vehicleValue")}
                    placeholder={t("step1.quote.actualCash")}
                    menuItems={
                      valores
                        ? valores.map((item) => ({
                            value: item.value,
                            label: item.traduccion,
                          }))
                        : []
                    }
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.valueVehicleInfo"),
                        }}
                      />
                    }
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "total_value_vehicle",
                      value:
                        vehicleData.total_value_vehicle != null
                          ? vehicleData.total_value_vehicle
                          : "placeholder",
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.towedUnitsValue")}
                    information={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("step1.quote.towedUnitsInfo"),
                        }}
                      />
                    }
                    menuItems={
                      valores
                        ? [
                            { value: -1, label: "No towed units" },
                            ...valores.map((item) => ({
                              value: item.value,
                              label: item.traduccion,
                            })),
                          ]
                        : [{ value: -1, label: "No towed units" }]
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "total_value_trailer",
                      value: vehicleData.total_value_trailer
                        ? vehicleData.total_value_trailer
                        : -1,

                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days >= 90 ? (
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomToogle
                      labelText={t("step1.quote.vehicleCoverageSonoraBC")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      inputProps={{
                        required: true,
                        name: "mexcoverage",
                        value: vehicleData.mexcoverage,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
              <GridContainer>
                {vehicleData && vehicleData.days && vehicleData.days > 360 ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomToogle
                      labelText={t("step1.quote.sentriPass")}
                      helperText={null}
                      items={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      error={!loginCredentials.login && sended ? true : null}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      name="login"
                      onChange={(e) => handleFormChange(e)}
                      defaultValue="no"
                      inputProps={{
                        required: true,
                        name: "sentripass",
                        value: vehicleData.sentripass,
                        onChange: handleFormChange,
                      }}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>

              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomSelect
                    labelText={t("step1.quote.selectAgent")}
                    information={t("step1.quote.selectAgentInfo")}
                    helperText={
                      !loginCredentials.login && sended
                        ? "Vehicle type field is required."
                        : null
                    }
                    menuItems={
                      data?.catalogs
                        ? data.catalogs.agencies.map((item) => ({
                            value: item.id,
                            label: `${item.city} ${item.agency_name}`,
                          }))
                        : []
                    }
                    error={!loginCredentials.login && sended ? true : null}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    name="login"
                    onChange={(e) => handleFormChange(e)}
                    inputProps={{
                      name: "agent",
                      value: vehicleData.agent,
                      onChange: handleFormChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                style={{ padding: "40px 0px 40px 0px" }}
                justify={isSmall ? "center" : "left"}
              >
                <GridItem xs={5} sm={5} md={2}>
                  <div style={{ marginTop: "10px" }} className={classes.center}>
                    <Button
                      disabled={!isCompleted || !validDate}
                      id="loginMobile"
                      type="submit"
                      onClick={() => {
                        setOpen(false);
                        handleSubmit();
                      }}
                      variant="green"
                      block
                    >
                      {t("common.recalculate")}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
});

Step1.displayName = "Step1";

export default Step1;
