import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import "../../assets/css/stylesPage.css";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Card from "react-credit-cards";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/CustomButton.js";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CardHeader from "components/Card/CardHeader.js";
import CreditCardList from "components/CreditCard/CreditCardList.js";

import { styled } from "@mui/material/styles";

const useStyles = makeStyles(styles);

import "react-credit-cards/es/styles-compiled.css";
import "../../assets/css/stylesPage.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(stylesDialogs);

export default function DeleteCreditCard(props) {
  const classes = useStyles();
  const classesDialogs = useStylesDialog();
  const {
    closeModal,
    deleteCardModal,
    handlerDeleteCard,
    idCard
  } = props;

  console.log("props en componente custom delete credit card", props);

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteCardModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      id={"paymentDialogItems"}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h3
          className={
            classesDialogs.modalTitle + " " + classesDialogs.modalTitlePayment
          }
        >
          Delete Card
        </h3>
        <Button
          justIcon
          className={
            classesDialogs.modalCloseButton +
            " " +
            classesDialogs.buttonPaymentClose
          }
          key="close"
          aria-label="Close"
          onClick={closeModal}
        >
          <Close className={classesDialogs.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12}>Do you want delete this card?</GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={6}>
                <Button
                  type="submit"
                  onClick={() => {
                    handlerDeleteCard(idCard)
                  }}
                  variant="green"
                  //className={`${classesPrincipal.}`}
                  block
                >
                  Yes
                </Button>
              </GridItem>
              <GridItem xs={6}>
              <Button
                  type="submit"
                  onClick={() => closeCardListModal()}
                  variant="yellow"
                  //className={`${classesPrincipal.}`}
                  block
                >
                  No
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions
        className={classesDialogs.modalFooter}
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <GridItem xs={12} className={classesDialogs.center}>
          <a href="#" className={classesDialogs.linkPayment}>
            Privacy Policy
          </a>{" "}
          | Disclaimer
        </GridItem>
      </DialogActions>
    </Dialog>
  );
}
