import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../environment/environment";
const BASE_URL = `${environment.API}`;
//axios.defaults.headers.common.Authorization = `Bearer ${}`;

export const getAgencyData = createAsyncThunk(
  "agency/getAgencyData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Agency${params}`
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAgencyIdByCode = createAsyncThunk(
  "agency/getAgencyIdByCode",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Agency/getAgencyIdByCode${params}`
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);