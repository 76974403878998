import { useState, useEffect, useRef, createContext, useContext } from "react";

// Crea el contexto para compartir el estado de inactividad
const InactivityContext = createContext();

export const InactivityMonitor = ({
  inactivityTime = 30000,
  checkInterval = 5000,
  children,
}) => {
  const [isInactive, setIsInactive] = useState(false);
  const lastActivityRef = useRef(Date.now()); // Referencia del último tiempo de actividad

  const resetTimer = () => {
    lastActivityRef.current = Date.now(); // Reinicia la referencia de última actividad
    if (isInactive) setIsInactive(false); // Si estaba inactivo, lo marca como activo
  };

  useEffect(() => {
    // Escucha eventos globales de la ventana
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    // Intervalo para chequear la inactividad
    const intervalId = setInterval(() => {
      const timeSinceLastActivity = Date.now() - lastActivityRef.current;

      if (timeSinceLastActivity > inactivityTime) {
        setIsInactive(true); // Si el tiempo supera el límite de inactividad, se marca como inactivo
      }
    }, checkInterval); // Cada intervalo definido (checkInterval) verifica la inactividad

    // Limpieza de eventos y el intervalo al desmontar el componente
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      clearInterval(intervalId); // Limpia el intervalo cuando se desmonta
    };
  }, [isInactive, inactivityTime, checkInterval]);

  return (
    <InactivityContext.Provider value={isInactive}>
      {children}
    </InactivityContext.Provider>
  );
};

// Hook para acceder al estado de inactividad desde cualquier componente
export const useInactivity = () => {
  return useContext(InactivityContext);
};
