/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { InactivityMonitor } from "components/Inactivity/Inactivity";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import DocumentsLayout from "layouts/Documents.js";
import GuestLayout from "layouts/Guest.js";
import GuestRedesingLayout from "layouts/GuestRedesing";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <InactivityMonitor inactivityTime={180000} checkInterval={180000}>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route path="/rtl" component={RtlLayout} />
              <Route path="/auth" component={AuthLayout} />
              <Route path="/admin" component={AdminLayout} />
              <Route path="/documents" component={DocumentsLayout} />
              <Route path="/guest" component={GuestLayout} />
              <Route path="/guestRedesing" component={GuestRedesingLayout} />
              <Redirect from="/" to="/auth" />
            </Switch>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </InactivityMonitor>
  </I18nextProvider>,
  document.getElementById("root")
);
