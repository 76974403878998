import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { rtlActive } = props;
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  return (
    <>
      <div className={wrapper} style={{ textAlign:"center", marginTop: '20px'}}>
        <Button justIcon round color="facebook" style={{marginRight: '7px'}}>
          <a href="https://www.facebook.com/sanborns.insurance/" target="_blank" style={{color:"#FFF"}}><i className={"fab fa-facebook"} /></a>
        </Button>
        <Button justIcon round color="twitter" style={{marginRight: '7px'}}>
          <a href="https://twitter.com/sanbornsmexins" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-twitter"}/></a>
        </Button>
        <Button justIcon round color="pinterest" style={{marginRight: '7px'}}>
          <a href="https://www.pinterest.com/sanbornsins/" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-pinterest"}/></a>
        </Button>
        <Button className="icons-li" justIcon round color="linkedin" style={{marginRight: '7px'}}>
          <a href="https://www.linkedin.com/company/sanborn%27s-insurance" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-linkedin"}/></a>
        </Button>
        <Button className="icons-li" justIcon round color="pinterest" style={{marginRight: '7px'}}>
          <a href="https://www.instagram.com/mexicoinsurance/" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-instagram"}/></a>
        </Button>
      </div>
      <div className={wrapper} style={{ textAlign:"center"}}>
        <Button className="icons-lil" justIcon round color="linkedin" style={{marginRight: '7px'}}>
          <a href="https://www.linkedin.com/company/sanborn%27s-insurance" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-linkedin"}/></a>
        </Button>
        <Button className="icons-lil" justIcon round color="pinterest" style={{marginRight: '20px'}}>
          <a href="https://www.instagram.com/mexicoinsurance/" target="_blank" style={{color:"#FFF"}}><i className={classes.socialButtonsIcons + " fab fa-instagram"}/></a>
        </Button>
        <br>
        </br>
      </div>
    </>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
