import React from "react";
export default function Icon(props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.60005 9.3V8.38571C6.60005 5.3468 9.00862 2.9 12 2.9C14.9915 2.9 17.4001 5.3468 17.4001 8.38571V9.3M6.60005 9.3C5.61005 9.3 4.80005 10.1229 4.80005 11.1286V20.2714C4.80005 21.2771 5.61005 22.1 6.60005 22.1H17.4001C18.3901 22.1 19.2001 21.2771 19.2001 20.2714V11.1286C19.2001 10.1229 18.3901 9.3 17.4001 9.3M6.60005 9.3H17.4001M12 16.7V14.3" stroke="#13816F" stroke-width="2" stroke-linecap="round"/>
</svg>


  );
}
