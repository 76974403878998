import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/CustomButton.js";
import VehicleIcon from "components/CustomIcons/Vehicle.js";
import MotorhomeIcon from "components/CustomIcons/Motorhome.js";
import MotorcycleIcon from "components/CustomIcons/Motorcycle";
import WhiteCalendarIcon from "components/CustomIcons/WhiteCalendar";
import WhiteCarIcon from "components/CustomIcons/WhiteCar";
import WhiteCheckIcon from "components/CustomIcons/WhiteCheck";
import TruckIcon from "components/CustomIcons/Truck";
import logo from "assets/img/logo.png";
import logo4 from "assets/img/logo_4.png";
import CustomInput from "components/CustomInput/CustomOutlinedInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CardHeader from "components/Card/CardHeader.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylePrincipal from "assets/jss/material-dashboard-pro-react/components/principalStyle.js";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Step1 from "./GuestRedesing/Step1.js";
import Step2 from "./GuestRedesing/Step2.js";
import Step3 from "./GuestRedesing/Step3.js";
import Step4 from "./GuestRedesing/Step4.js";
import PaymentType from "components/PaymentType/PaymentType.js";
import CreditCardList from "components/CreditCard/CreditCardList.js";
import SweetAlert from "react-bootstrap-sweetalert";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { RotatingLines } from "react-loader-spinner";
import { CircularProgress } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { IconButton } from "@material-ui/core";
import CloseIcon from "../../../src/assets/img/error.gif";
import { getTokenClient } from "components/Utils/applePay.js";
import { AccountCircle, Phone, Email, Opacity } from "@material-ui/icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//Componentes
import CustomAlerts from "components/Alerts/CustomAlerts";
import StepperComponent from "components/Stepper/StepperComponent";
import CustomCreditCard from "components/CustomCreditCard/CustomCreditCard.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import DeleteCreditCard from "components/DeleteCreditCard/DeleteCreditCard.js";

import "../../assets/css/freewayStyle.css";

import {
  formatCreditCardNumber,
  formatExpirationDate,
  setDataPolicy,
  formatNumber,
  getMaskNumberCard,
  currentDateEffective,
  addDays,
  sanatizeMessageHtml,
  formatDateToEffectiveAndExpiration,
  getEffectiveTime,
  verifyLength,
} from "components/Utils/utils.js";

import { Catalogs } from "views/Services/CatalogsService.js";
import { getVehiclesList } from "views/Services/VehiclesService.js";
import {
  getAgencyData,
  getAgencyIdByCode,
} from "views/Services/AgencyService.js";

import {
  issue,
  checkQuote,
  getAppleTokenClient,
  getCustomerByToken,
} from "views/Services/OrdersService";

import {
  addCard,
  getCardsByClient,
  deleteCard,
} from "../Services/CreditCardService";
import { useTranslation } from "react-i18next";
import { getPolicyData } from "views/Services/PoliciesService";

const monthList = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

const timeZoneList = {
  1: 12,
  2: 8,
  3: 17,
  4: 33,
  5: 12,
  6: 33,
};
const trailerTypes = [
  { id: 1, name: "Trailer" },
  { id: 2, name: "Boat" },
  { id: 3, name: "Other" },
  { id: 4, name: "ATV/UTV" },
];
let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const currentDate = currentDateEffective();
const expirationData = addDays(3, "effectivedate", {
  effectivedate: currentDate,
});

const useStylesPrincipal = makeStyles(stylePrincipal);
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);
const useExtFormStyles = makeStyles(extendedFormstyles);
const useFormStyles = makeStyles(formStyles);
const useStylesDialog = makeStyles(stylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function WizardView() {
  const { t, i18n } = useTranslation();
  const routeParams = useParams();
  const { language, id, producerCode, ChooseVehicle } = routeParams;
  const childRef = useRef(null);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  useEffect(() => {
    setSteps([
      { title: t("steps.quote") },
      { title: t("steps.insuredInformation") },
      { title: t("steps.vehicleInformation") },
      { title: t("steps.coverageAndPayment") },
    ]);
  }, [i18n.language]); // Observamos solo el idioma

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const getVehicleType = urlParams.get("ChooseVehicle");
  const getDays = urlParams.get("txtDays");
  const getValue = urlParams.get("txtValue");
  const [resultRates, setResultRates] = useState({
    policy_fee: "15",
    csl_rate: 7.8,
    premium: 89,
    total: 104,
    iva: 0,
    value: 7000,
    pd_ded: 500,
    theft_ded: 1000,
    additional_charges: false,
    total1: 104,
    days1: "6",
    total2: 332,
    days2: 182,
    total3: 468,
    days3: 365,
    total4: 302,
    days4: 90,
    debug: false,
    agency: "81001",
    agency_fee: "0",
  });

  const dispatch = useDispatch();
  const [showButtonCheckout, setShowButtonCheckout] = React.useState(false);
  const [data, setData] = useState(null);
  const classesExtForm = useExtFormStyles();
  const [agencyData, setAgencyData] = useState(null);
  const [quoteId, setQuoteId] = useState(0);
  const [fastQuote, setFastQuote] = useState(true);
  const [fullQuoteVehicle, setFullQuoteVehicle] = useState(false);
  const [fullQuoteRates, setFullQuoteRates] = useState(false);
  const [calculateRates, setCalculateRates] = useState(false);
  const [addVehicle, setAddVehicle] = useState(false);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [openModalWelcome, setOpenModalWelcome] = useState(true);
  const [saveCard, setSaveCard] = useState(false);
  const [vehicleDataFromSite, setVehicleDataFromSite] = useState(false);
  const [paymentTypeState, setPaymentTypeState] = React.useState("success");
  const [paymentType, setPaymentType] = React.useState(2);
  const [paymentTypeList, setPaymentTypeList] = React.useState([{}]);
  const [editCardData, setEditCardData] = React.useState({});
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [tokenApple, setTokenApple] = useState("");
  const [terms, setTerms] = useState(false);
  const [savingCard, setSavingCard] = useState(false);
  const [creditCardListModal, setCreditCardListModal] = useState(false);
  const [vinPolicy, setVinPolicy] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [vehicleData, setVehicleData] = useState({
    vehtype: "1",
    vehicle_type_id: "1",
    year_vehicle: 2025,
    vehicleYear: 2025,
    agent: 441,
    hasLienholder: "no",
    towingUnits: "no",
    model_vehicle: "",
    make_vehicle: "",
    makeVehicle: "",
    type_name: "",
    vehicle_type: null,
    id: "",
    lienholder: "",
    insurance_company: "",
    insurance_policy_number: "none",
    mileage: "",
    plates: "",
    sp_equip_list: null,
    sp_equip_value: null,
    total_value: "",
    trailer: "",
    vin: "",
    year_model: "",
    client_id: "",
    model: " ",
    vehicle_type_id: "",
    sentri_number: "",
    other: "",
    year_model_trailer: "",
    model_trailer: "",
    vin_trailer: "",
    total_value_trailer: "",
    plates_trailer: "",
    lienholder_trailer: null,
    sp_equip_list_trailer: null,
    sp_equip_value_trailer: null,
    year_model_other: "",
    model_other: "",
    vin_other: "",
    total_value_other: "",
    plates_other: "",
    lienholder_other: null,
    sp_equip_list_other: null,
    sp_equip_value_other: null,
    make_trailer: "",
    make_other: "",
    make: "",
    lienholder_vehicle_address: "",
    lienholder_trailer_address: null,
    lienholder_other_address: null,
    vehtype_trailer: "",
    vehtype_other: "",
    description_trailer: "",
    description_other: "",
    other_policy_company_trailer: "",
    other_policy_number_trailer: "",
    other_policy_company_other: "",
    other_policy_number_other: "",
    commercial: "",
    client_have_lienholder: "",
    vehicle_type_model: "",
    makeId: "",
    name: "",
    modelId: "",
  });
  const [personData, setPersonData] = useState({
    id: "New Client",
    vehtype: 1,
    agent: 441,
  });
  const now = new Date();
  const hours24 = now.getHours();
  const meridian = hours24 >= 12 ? "PM" : "AM";
  const hours12 = String(hours24 % 12 || 12).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const utcNow = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  );

  //const currentTime = `${hours12}:${00}`;
  const [coverages, setCoverages] = useState({
    days: 0,
    csllimit: "500000",
    excessliab: "150000",
    country: 0,
    policy_type: 1,
    medicallimit: "5000",
    damageded: 2,
    theftded: 5,
    vehtype: "1",
    vehicle_type_id: "1",
    year_vehicle: 2025,
    vehicleYear: 2025,
    startDate: utcNow.toISOString().split("T")[0],
    //timeset: "12:00",
    //meridian: meridian,
    specialdiscount: 1,
    effectivedate: "",
    bordercross: 2,
    gapcoverage: "no",
    premierepackage: true,
    fixeddeductible: true,
    policycaravan: false,
    roadside: true,
    physical_damage: true,
    theft: true,
    legalaid: true,
    hdiautoguard: false,
    accessories_and_safety: false,
    ter_ext: false,
  });
  const setAlertAgencyData = async (agencyId) => {
    const resultSubmit = await dispatch(getAgencyData(`?id=${agencyId}`));
    setAgencyData(resultSubmit.payload.data);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const sendStepData = () => {
    childRef.current.sendStepData(); // Llamar la función del hijo
  };

  const getAgencyId = async (agencyCode) => {
    const resultSubmit = await dispatch(
      getAgencyIdByCode(`?agencyCode=${agencyCode}`)
    );
    if (typeof resultSubmit.payload.data.id != "undefined") {
      setAgency(resultSubmit.payload.data.id);
    } else {
      window.location = "/auth/login";
    }
  };

  React.useEffect(() => {
    addPayment(false);
    if (window.ApplePaySession) {
      setIsAppleDevice(true);
      var merchantIdentifier = "merchant.sanborns.applepay";
      var promise = ApplePaySession.canMakePaymentsWithActiveCard(
        merchantIdentifier
      );
      promise.then(function (canMakePayments) {
        getAppleClientToken();
        addPayment(true);
      });
    }

    getCustomer();

    if (Number(id)) {
      //window.location.href = "/auth/login";
    } else {
      getCatalogs();
    }
  }, [dispatch]);

  React.useEffect(() => {
    console.log("data", data);
  }, [data]);

  React.useEffect(() => {
    console.log("dataPerson", personData);
  }, [personData]);

  React.useEffect(() => {
    console.log("changed", creditCardListModal);
  }, [creditCardListModal]);

  const classes = useStyles();
  const classesAlerts = useStylesAlert();
  const classesPrincipal = useStylesPrincipal();
  const classesForm = useFormStyles();
  const classesDialogs = useStylesDialog();
  const cardDataInitial = {
    "cnumber-card_string": "",
    "fname-card": "",
    "lname-card": "",
    "month-card": "",
    creditcardverificator_string: "",
    "year-card": "",
    expirationCard: "",
    focused: "",
    "id-card": "none",
  };
  const [card, setCard] = React.useState(cardDataInitial);
  const [showWizard, setshowWizard] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [clientCards, setClientCards] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [alertLoading, setAlertLoading] = React.useState(null);
  const [alertAgency, setAlertAgency] = React.useState(false);
  const [openModalError, setOpenModalError] = React.useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [testing, setTesting] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [checked, setChecked] = React.useState([]);
  const [deleteCardModal, setDeleteCardModal] = React.useState(false);
  const [idCardToDelete, setIdCardToDelete] = React.useState(null);
  const [loadingCircle, setLoadingCircle] = React.useState(false);

  const handleToggle = (event, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const addPayment = (isApplePayAvailable) => {
    let paymentList = [
      { value: 2, label: "Credit Card" },
      { value: 5, label: "PayPal" },
    ];
    if (isApplePayAvailable) {
      paymentList.push({ value: 6, label: "Apple Pay" });
    }
    setPaymentTypeList(paymentList);
  };

  const getAppleClientToken = async () => {
    const resultSubmit = await dispatch(getAppleTokenClient({}));
    const token = resultSubmit.payload;
    setTokenApple(token);
  };

  const setCardData = (cards, cardId) => {
    let cardData = {};
    cards.forEach((element) => {
      if (element.id == cardId) {
        cardData["id-card"] = element.id;
        cardData["fname-card"] = element.first_name;
        cardData["lname-card"] = element.last_name;
        cardData["cnumber-card_string"] = element.number;
        cardData["type-ccard"] = element.card_type;
        let monthExpDate =
          typeof monthList[element.expiration_month] == "undefined"
            ? ""
            : monthList[element.expiration_month];
        cardData[
          "expirationCard"
        ] = `${monthExpDate}/${element.expiration_year}`;
        cardData["month-card"] = monthList[element.expiration_month];
        cardData["year-card"] = element.expiration_year;
        cardData["street-card"] = element.street;
        cardData["co-card"] = element.co;
        cardData["city-card"] = element.last_name;
        cardData["state-card"] = element.state;
        cardData["code-card"] = element.zipcode;
      }
    });
    return cardData;
  };

  const verifyPaymentType = () => {
    const paymentTypeValue = parseInt(paymentType);
    switch (paymentTypeValue) {
      case 2:
        handlerSaveCard();
        break;
      case 5:
        issuePolicy();
        break;
      case 6:
        onApplePayButtonClicked();
        break;
    }
  };

  const handleChangeRadio = (value) => {
    let dataPolicy = { ...data };
    let cardData = setCardData(clientCards, value);
    dataPolicy.card = cardData;
    setSelectedValue(value);
    setCard(cardData);
    setCreditCardListModal(false);
    setData(dataPolicy);
  };

  const updateDataStepToData = (dataStep) => {
    setData(dataStep);
  };

  const setSummary = () => {
    setshowWizard(!showWizard);
  };

  const toggleCardListModal = () => {
    setCreditCardListModal((prevState) => !prevState);
  };

  const toggleDeleteCardModal = () => {
    setDeleteCardModal((prevState) => !prevState);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setMessageToast("");
    setPaymentModal(false);
  };

  const closeDialogWelcome = () => {
    setOpenModalWelcome(false);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successPolicy = (orderData) => {
    setAlert(null);
    if (
      typeof orderData.data?.dataLogin !== "undefined" &&
      ![1, 2].includes(
        localStorage.getItem("userType") && orderData.message != "Quote saved"
      )
    ) {
      localStorage.setItem("userToken", orderData.data.dataLogin.data);
      localStorage.setItem("userType", orderData.data.dataLogin.userType);
    }
    window.location.href =
      orderData.message == "Quote saved"
        ? `/guestRedesing/en/${orderData.data.orderId}`
        : `/guestRedesing/summary/${orderData.data.orderId}`;
  };

  const callBackCard = ({ issuer }, isValid) => {};

  const handlerCardData = (cardId) => {
    if (typeof cardId != "undefined") {
      let cardData = setCardData(clientCards, cardId);
      setCard(cardData);
      toggleCardListModal(false);
      setSavingCard(true);
      setSelectedValue(null);
    }
  };

  const inputHandler = ({ target }) => {
    let cardData = { ...card };
    let dataPolicy = { ...data };
    if (target.id === "cnumber-card_string") {
      let formatCreditCard = formatCreditCardNumber(target.value);
      target.value = formatCreditCard.value;
      cardData["type-ccard"] = formatCreditCard.cardTypeId;
    }
    if (target.id === "expirationCard") {
      target.value = formatExpirationDate(target.value);
      let value = target.value.split("/");
      cardData["month-card"] = value[0] ?? "";
      cardData["year-card"] = value[1] ?? "";
    }
    cardData[target.id] = target.value;
    dataPolicy.card = cardData;
    setData(dataPolicy);
    setCard(cardData);
  };

  const inputHandlerCoverage = ({ target }) => {
    let dataHandler = { ...data };
    if (typeof dataHandler.coverages == "undefined") {
      dataHandler.coverages = {};
    }
    dataHandler.coverages[target.name] = target.value;
    setData(dataHandler);
  };

  const inputHandlerCcv = ({ target }) => {
    let cardData = { ...card };
    let dataPolicy = { ...data };
    cardData["creditcardverificator_string"] = target.value;
    dataPolicy.card = cardData;
    setData(dataPolicy);
    setCard(cardData);
  };

  const setAgency = (value) => {
    let dataPolicy = { ...data };
    dataPolicy.data_customer.agency_id = value;
    setAlertAgencyData(value);
    //setData(dataPolicy);
  };

  const successAlert = (orderData) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{orderData.message}</h5>}
        onConfirm={() => successPolicy(orderData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          "issuePolicyGuest " +
          classesAlerts.button +
          " " +
          classesAlerts.success
        }
        allowEscape={false}
        closeOnClickOutside={false}
      ></SweetAlert>
    );
  };

  const loadingAlert = () => {
    setAlertLoading(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<h5 style={{ color: "black" }}>{"Loading"}</h5>}
        allowEscape={false}
        closeOnClickOutside={false}
        showConfirm={false}
        showCancel={false}
      >
        <RotatingLines
          strokeColor="gray"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </SweetAlert>
    );
  };

  const showAlertCard = (cardId) => {
    setIdCardToDelete(cardId);
    setDeleteCardModal(true);
  };

  const handlerSaveCard = (toSave) => {
    if (selectedValue == null) {
      let cardDataSave = { ...card };
      setSelectedValue(null);
      setSaveCard(true);
      let validCard = checkDataCard(cardDataSave);
      if (validCard) {
        toSave ? sendCardToSave(cardDataSave) : issuePolicy();
      }
    } else {
      issuePolicy();
    }
  };

  const cancelSaveCard = () => {
    setSelectedValue(null);
    setSavingCard(false);
    setCard(cardDataInitial);
  };

  const sendCardToSave = async (cardDataSave) => {
    let monthList = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };
    let cardData = {
      "client-id-card": data?.data_customer.id,
      "id-card": cardDataSave["id-card"],
      "fname-card": cardDataSave["fname-card"],
      "lname-card": cardDataSave["lname-card"],
      "cnumber-card": cardDataSave["cnumber-card_string"].replaceAll(" ", ""),
      "type-ccard": cardDataSave["type-ccard"],
      "month-card": monthList[parseInt(cardDataSave["month-card"])],
      "year-card": "20" + cardDataSave["year-card"],
      "street-card": cardDataSave["street-card"],
      "co-card": cardDataSave["co-card"],
      "city-card": cardDataSave["city-card"],
      "state-card": cardDataSave["state-card"],
      "code-card": cardDataSave["code-card"],
    };
    const resultSubmit = await dispatch(addCard(cardData));

    if (resultSubmit.payload.result) {
      getClientCards(data.data_customer.id);
      if (cardData["id-card"] > 0) {
        setCard(cardDataInitial);
      }
      setIsModalOpen(false);
      setEditCardData(false);
      setSavingCard(false);
      setSaveCard(false);
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const checkDataCard = (cardDataSave) => {
    let valid = true;
    cardDataSave["month-card"] =
      typeof cardDataSave["month-card"] == "undefined"
        ? ""
        : parseInt(cardDataSave["month-card"]);
    if (
      cardDataSave["cnumber-card_string"].replaceAll(" ", "") < 16 ||
      cardDataSave["cnumber-card_string"] == "" ||
      cardDataSave["cnumber-card_string"] == null ||
      typeof cardDataSave["month-card"] == "undefined" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["fname-card"] == "" ||
      cardDataSave["fname-card"] == null ||
      typeof cardDataSave["fname-card"] == "undefined" ||
      cardDataSave["lname-card"] == "" ||
      cardDataSave["lname-card"] == null ||
      typeof cardDataSave["lname-card"] == "undefined" ||
      cardDataSave["type-ccard"] == "" ||
      cardDataSave["type-ccard"] == null ||
      typeof cardDataSave["type-ccard"] == "undefined" ||
      cardDataSave["month-card"] == "" ||
      cardDataSave["month-card"] == null ||
      cardDataSave["month-card"] > 12 ||
      cardDataSave["month-card"] == 0 ||
      cardDataSave["year-card"] == "" ||
      cardDataSave["year-card"] == null ||
      typeof cardDataSave["year-card"] == "undefined"
    ) {
      let messageToast = "";
      messageToast +=
        cardDataSave["month-card"] > 12 ||
        cardDataSave["month-card"] == 0 ||
        cardDataSave["month-card"] == ""
          ? "Please enter a valid month expiration date"
          : "";
      messageToast +=
        cardDataSave["year-card"] == ""
          ? "Please enter a valid year expiration date"
          : "";
      messageToast =
        messageToast == ""
          ? "Please check the information in your credit card"
          : messageToast;
      setMessageToast(messageToast);
      valid = false;
    }
    return valid;
  };

  const onApplePayButtonClicked = async () => {
    tokenClient = getTokenClient(data, issuePolicy, tokenApple);
  };

  const processResponseIssue = (resultSubmit) => {
    if (resultSubmit.payload) {
      if (resultSubmit.payload.message.errors) {
        let messageToast = "";
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `${error.Message}<br>
            `)
        );
        setMessageToast(messageToast);
      } else {
        if (typeof resultSubmit.payload.approvalUrl != "undefined") {
          window.location.href = resultSubmit.payload.approvalUrl;
        } else {
          window.dataLayer.push({
            event: "gtm.formSubmit",
            hostname: window.location.origin,
            customer: "new",
            eventLabel: "Sale",
          });
          setPaymentModal(false);
          successAlert(resultSubmit.payload);
        }
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const issuePolicy = async (appleData) => {
    let ccvValid =
      typeof data.card?.creditcardverificator_string == "undefined"
        ? false
        : data.card.creditcardverificator_string == ""
        ? false
        : true;
    let validCard = selectedValue != null ? checkDataCard(data.card) : true;

    let paymentValid = true;

    if (paymentType == 2) {
      paymentValid = ccvValid && validCard ? true : false;
    }
    if (paymentValid) {
      setToastMessage(false);
      let dataQuote = { ...data };
      dataQuote.paymentType = paymentType;
      let dataPolicy = setDataPolicy(dataQuote);
      dataPolicy.view =
        localStorage.getItem("userToken") !== null ? "" : "guest";
      if (typeof appleData != "undefined") {
        dataPolicy.nonce = appleData.nonce;
        dataPolicy.deviceData = appleData.deviceData;
      }
      setLoadingCircle(true);
      const resultSubmit = await dispatch(issue(dataPolicy));
      setLoadingCircle(false);
      processResponseIssue(resultSubmit);
    } else {
      let errorMessageIssue = "";
      if (validCard) {
        errorMessageIssue += !ccvValid
          ? "<li>Please enter a ccv valid</li>"
          : "";
        setMessageToast(errorMessageIssue);
        setOpenModalError(true);
      }
    }
  };

  const assignCustomer = (customerId) => {};

  const checkInfoQuote = async (event) => {
    let dataPolicy = setDataPolicy(data);
    loadingAlert();
    const resultSubmit = await dispatch(checkQuote(dataPolicy));
    setAlertLoading(null);
    if (resultSubmit.payload) {
      if (!resultSubmit.payload.result) {
        let messageToast = "<ul>";
        resultSubmit.payload.message.errors.map(
          (error, index) =>
            (messageToast += `<li key=${index}>${error.Message}</li>`)
        );
        messageToast += `</ul>`;
        setMessageToast(messageToast);
        setOpenModalError(true);
      } else {
        setSummary(event);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const getCatalogs = async () => {
    const resultSubmit = await dispatch(Catalogs());

    if (resultSubmit.payload.result) {
      let dataPolicy = {
        data_customer: {
          id: "New Client",
          dl_number: "",
          dl_country_id: 0,
          valid_license: "",
          dl_state_id: 0,
          first_name: "",
          middle_name: "",
          last_name: "",
          address: "",
          zipcode: "",
          city: "",
          country_id: 0,
          state_id: 0,
          phone: "",
          email: "",
          additional_insured: "",
          agency_id: "",
        },
        coverages: {},
        selectedVehicle: {},
      };
      dataPolicy["catalogs"] = resultSubmit.payload.catalogs;
      if (dataPolicy["catalogs"].vehicle_types) {
        let vehicle = dataPolicy["catalogs"].vehicle_types.find(
          (item) => item.id === "1"
        );
        vehicle &&
          (vehicle.icon = <VehicleIcon className={classes.optionInput} />);
        vehicle = dataPolicy["catalogs"].vehicle_types.find(
          (item) => item.id === "4"
        );
        vehicle &&
          (vehicle.icon = <MotorhomeIcon className={classes.optionInput} />);
        vehicle = dataPolicy["catalogs"].vehicle_types.find(
          (item) => item.id === "5"
        );
        vehicle &&
          (vehicle.icon = <MotorcycleIcon className={classes.optionInput} />);
        //vehicle = dataPolicy["catalogs"].vehicle_types.find(item => item.id === "10");
        //vehicle && (vehicle.icon = <TruckIcon className={classes.optionInput} />);
        dataPolicy["catalogs"].vehicle_types.splice(6, 1);
        dataPolicy["catalogs"].vehicle_types.splice(4, 1);
        dataPolicy["catalogs"].vehicle_types.splice(3, 1);
      }
      dataPolicy.coverages = coverages;
      const AgencyCode = parseInt(producerCode);
      if (Number(AgencyCode)) {
        getAgencyId(AgencyCode);
      }
      if (getVehicleType != null) {
        dataPolicy.selectedVehicle = {
          client_have_lienholder: undefined,
          description_other: "",
          description_trailer: "",
          insurance_company: undefined,
          insurance_policy_number: undefined,
          lienholder: "",
          lienholder_vehicle_address: "",
          makeId: "",
          make_name: "",
          make_other: "",
          make_trailer: "",
          make_vehicle: "",
          mileage: "",
          modelId: "",
          model_name: "",
          model_other: "",
          model_trailer: "",
          model_vehicle: "",
          other: "0",
          other_policy_company_other: "",
          other_policy_company_trailer: "",
          other_policy_number_other: "",
          other_policy_number_trailer: "",
          plates: "",
          plates_other: "",
          plates_trailer: "",
          sentri_number: "",
          total_value: getValue,
          total_value_other: "",
          total_value_trailer: "",
          trailer: "0",
          type_name: dataPolicy["catalogs"]["vehicle_types"].find(
            (e) => e.id == getVehicleType
          ).name,
          value: getValue,
          vehicle_type_id: getVehicleType,
          vehtype: getVehicleType,
          vehtype_other: "",
          vehtype_trailer: "",
          vin: undefined,
          vin_other: "",
          vin_trailer: "",
          year_model: "",
          year_model_other: "",
          year_model_trailer: "",
          year_vehicle: "",
        };
        dataPolicy.coverages.days = getDays;
        let effectiveData = addDays(getDays, "effectivedate", {
          effectivedate: currentDate,
        });
        dataPolicy.coverages.expirationdate = effectiveData.expirationDate.date;
        setVehicleDataFromSite(true);
      }
      setData(dataPolicy);
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const getCustomer = async (customerId) => {
    if (localStorage.getItem("userToken") !== null) {
      let orden = {};
      if (parseInt(id) > 0) {
        const resultSubmitPolicy = await dispatch(getPolicyData({ id: id }));
        orden = resultSubmitPolicy.payload.data;
        customerId = orden.client_id;
      }

      const resultSubmit = await dispatch(
        getCustomerByToken({ customerId: customerId })
      );

      getClientCards(resultSubmit.payload.data?.data_customer?.id);
      if (typeof id == "undefined") {
        setClientData(resultSubmit.payload.data);
      } else {
        getData(resultSubmit.payload.data, orden);
      }
      getVehicles(resultSubmit.payload.data?.data_customer?.id);
    }
  };

  const getData = (datosIniciales, orden) => {
    const selectedVehicle = {
      model_vehicle: "",
      make_vehicle: "",
      type_name: "",
      vehicle_type: "",
      id: "",
      lienholder: "",
      insurance_company: "",
      insurance_policy_number: "",
      mileage: "",
      plates: "",
      sp_equip_list: null,
      sp_equip_value: null,
      total_value: "",
      trailer: "",
      vin: "",
      year_model: "",
      client_id: "",
      model: " ",
      vehicle_type_id: "",
      sentri_number: "",
      other: "",
      year_model_trailer: "",
      model_trailer: "",
      vin_trailer: "",
      total_value_trailer: "",
      plates_trailer: "",
      lienholder_trailer: null,
      sp_equip_list_trailer: null,
      sp_equip_value_trailer: null,
      year_model_other: "",
      model_other: "",
      vin_other: "",
      total_value_other: "",
      plates_other: "",
      lienholder_other: null,
      sp_equip_list_other: null,
      sp_equip_value_other: null,
      make_trailer: "",
      make_other: "",
      make: "",
      lienholder_vehicle_address: "",
      lienholder_trailer_address: null,
      lienholder_other_address: null,
      vehtype_trailer: "",
      vehtype_other: "",
      description_trailer: "",
      description_other: "",
      other_policy_company_trailer: "",
      other_policy_number_trailer: "",
      other_policy_company_other: "",
      other_policy_number_other: "",
      commercial: "",
      active: "",
      client_have_lienholder: "",
      vehicle_type_model: "",
      makeId: "",
      name: "",
      modelId: "",
    };

    if (orden) {
      for (const key in datosIniciales.data_customer) {
        if (key !== "id" && key !== "agency_id") {
          datosIniciales.data_customer[key] = orden[key];
        }
      }
      for (const key in selectedVehicle) {
        if (key !== "id") {
          selectedVehicle[key] = orden[key];
        }
      }

      if ([1, 5].includes(parseInt(orden["vehicle_type_id"]))) {
        selectedVehicle["makeId"] = orden["make"];
        selectedVehicle["modelId"] = orden["model"];
      }

      for (const key in coverages) {
        if (!["id", "expirationdate", "effectivedate"].includes(key)) {
          coverages[key] = orden[key];
        }
      }

      coverages["timezone"] = timeZoneList[coverages["bordercross"]];
      coverages["damageded_customer"] = orden["damageded"];
      coverages["theftded_customer"] = orden["theftded"];
      coverages["policynotes"] = orden["notes"];
      coverages["csllimit"] = cslLimitCheck(orden["csllimit"]);

      if (orden.order_status_id == "3") {
        datosIniciales.orderData = {};
        datosIniciales.orderData.quote_id = orden.order_id;
        datosIniciales.orderData.vehicle_id = orden.vehicle_id;
        datosIniciales.orderData.vehicle_order_id = orden.vehicle_order_id;
        datosIniciales.orderData.client_id = orden.client_id;
        datosIniciales.orderData.client_order_id = orden.client_order_id;
        coverages["timeset"] = orden.effective_time;
        coverages["days"] = orden.num_of_days;
        coverages["policy_type"] = orden.policy_type;
        if (orden.effective_date != null) {
          const startData = formatDateToEffectiveAndExpiration(
            orden.effective_date,
            "quote"
          ).date;
          coverages["effectivedate"] = startData;
          coverages["startDate"] = "12-10-2014";
          selectedVehicle["startData"] = "12-10-2014";
        }
        if (orden.expiration_date != null) {
          coverages["expirationdate"] = formatDateToEffectiveAndExpiration(
            orden.expiration_date,
            "quote"
          ).date;
        }
        if (typeof orden.effective_time !== "undefined") {
          let timesetData = orden.effective_time.split(" ");
          coverages["startTime"] = timesetData[0];
          coverages["meridian"] = timesetData[1];
          coverages["timeset"] = timesetData[0];
        }
      }

      coverages.physical_damage =
        coverages.physical_damage == "1" ? "true" : "false";
      coverages.roadside = coverages.roadside == "1" ? "true" : "false";
      coverages.gapcoverage = coverages.gapcoverage == "1" ? "true" : "false";
      coverages.fixeddeductible =
        coverages.fixeddeductible == "1" ? "true" : "false";
      coverages.theft = coverages.theft == "1" ? "true" : "false";
      coverages.legalaid = coverages.legalaid == "1" ? "true" : "false";
      coverages.premierepackage =
        coverages.premierepackage == "0" || coverages.csllimit == "50000"
          ? "false"
          : "true";
      datosIniciales.selectedVehicle = selectedVehicle;
      datosIniciales.coverages = coverages;
      datosIniciales.coverages.specialdiscount = orden.special_discount;
      setCoverages(datosIniciales.coverages);
      setPersonData(datosIniciales.data_customer);
      datosIniciales.selectedVehicle.days = orden.num_of_days;
      datosIniciales.selectedVehicle.borderCrossing = coverages.bordercross;
      datosIniciales.selectedVehicle.vehtype = selectedVehicle.vehicle_type_id;
      datosIniciales.selectedVehicle.total_value_vehicle =
        selectedVehicle.total_value;
      datosIniciales.selectedVehicle.agent = orden.agent_id;
      datosIniciales.selectedVehicle.licensePlate = selectedVehicle.plates;
      datosIniciales.selectedVehicle.insuranceCompany =
        selectedVehicle.insurance_company;
      datosIniciales.selectedVehicle.fullCoverage = selectedVehicle.insurance_policy_number.toLowerCase();
      datosIniciales.selectedVehicle.vehicleType = selectedVehicle.type_name;
      datosIniciales.selectedVehicle.vehicleTypeId =
        selectedVehicle.vehicle_type_id;
      datosIniciales.selectedVehicle.vehicleYear = selectedVehicle.year_model;
      datosIniciales.selectedVehicle.vehicleMakeId = selectedVehicle.makeId;
      datosIniciales.selectedVehicle.vehicleMakeName =
        selectedVehicle.make_vehicle;
      datosIniciales.selectedVehicle.vehicleModelId = selectedVehicle.modelId;
      datosIniciales.selectedVehicle.vehicleModelName = selectedVehicle.model;
      datosIniciales.selectedVehicle.makeVehicle = selectedVehicle.makeId;
      datosIniciales.selectedVehicle.vehicleModel = selectedVehicle.modelId;
      datosIniciales.selectedVehicle.vehicleMake = selectedVehicle.makeId;
      datosIniciales.selectedVehicle.towingUnits =
        selectedVehicle.trailer == "1" || selectedVehicle.other == "1"
          ? "yes"
          : "no";
      datosIniciales.selectedVehicle.vinPolicy = {
        result: true,
        message: "Validated",
        vehicleData: {
          vehicleType: selectedVehicle.type_name,
          vehicleTypeId: selectedVehicle.vehicle_type_id,
          vehicleYear: selectedVehicle.year_model,
          vehicleMakeId: selectedVehicle.makeId,
          vehicleMakeName: selectedVehicle.make_vehicle,
          vehicleModelId: selectedVehicle.modelId,
          vehicleModelName: selectedVehicle.model,
        },
      };
      setVinPolicy(true);
      setVehicleData(datosIniciales.selectedVehicle);
      setData(datosIniciales);
    }
  };

  const cslLimitCheck = (value) => {
    let newValueCsl = value || "";
    newValueCsl = ["100000", "200000", "300000"].includes(newValueCsl)
      ? ""
      : newValueCsl;
    return newValueCsl;
  };

  const setClientData = (apiCustomerData) => {
    if (typeof apiCustomerData.data_customer != "undefined") {
      apiCustomerData.coverages = coverages;
      apiCustomerData.data_customer.addressG =
        apiCustomerData.data_customer.address;
      setPersonData(apiCustomerData.data_customer);
      setData(apiCustomerData);
    }
  };

  const getClientCards = async (clientId) => {
    setClientCards([]);
    setCard(cardDataInitial);
    const resultSubmit = await dispatch(
      getCardsByClient({ clientId: clientId })
    );

    if (resultSubmit.payload.result) {
      if (resultSubmit.payload.result) {
        if (resultSubmit.payload.data.length > 0) {
          setClientCards(resultSubmit.payload.data);
        } else {
          setClientCards([]);
        }
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
    setDeleteCardModal(false);
  };

  const closeDialogError = () => {
    setOpenModalError(false);
  };

  const handlerDeleteCard = async (cardId) => {
    const resultSubmit = await dispatch(deleteCard({ id: cardId }));
    setAlert(null);
    if (resultSubmit.payload.result) {
      if (resultSubmit.payload.result) {
        setCreditCardListModal(false);
        setSelectedValue(null);
        setCard(cardDataInitial);
        getClientCards(data.data_customer?.id);
      }
    } else {
      let mensaje = "\n";
      for (const key in resultSubmit.payload.message) {
        mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
      }
    }
  };

  const saveQuote = async (dataSend) => {
    if (dataSend.selectedVehicle != null) {
      setToastMessage(false);
      dataSend.flag_draft = 1;
      let dataPolicy = setDataPolicy(dataSend);
      loadingAlert();
      const resultSubmit = await dispatch(issue(dataPolicy));
      setAlertLoading(null);
      if (resultSubmit.payload) {
        if (resultSubmit.payload.message.errors) {
          let messageToast = "<ul>";
          resultSubmit.payload.message.errors.map(
            (error, index) =>
              (messageToast += `<li key=${index}>${error.Message}</li>`)
          );
          messageToast += `</ul>`;
          setMessageToast(messageToast);
          setOpenModalError(true);
        } else {
          successAlert(resultSubmit.payload);
        }
      } else {
        let mensaje = "\n";
        for (const key in resultSubmit.payload.message) {
          mensaje += "\u2B24" + " " + resultSubmit.payload.message[key] + "\n";
        }
      }
    } else {
      setTypeToast("danger");
      let errorMessageIssue = "Please select a vehicle valid";
      setMessageToast(errorMessageIssue);
      setToastMessage(true);
    }
  };

  const getVehicles = async (customerId) => {
    const resultSubmit = await dispatch(
      getVehiclesList({ customerId: customerId })
    );
    if (resultSubmit.payload.result) {
      setVehicles(resultSubmit.payload.data);
    }
  };

  const setStepData = (type, dataStep) => {
    let dataGuest = { ...data };
    let insertStep = true;
    if (type == "fastquote") {
      setFastQuote(dataStep);
      insertStep = false;
    }
    if (type == "fullQuoteVehicle") {
      setFullQuoteVehicle(dataStep);
      insertStep = false;
    }
    if (type == "fullQuoteRates") {
      setFullQuoteRates(dataStep);
      insertStep = false;
    }
    if (type == "addVehicle") {
      setAddVehicle(dataStep);
      insertStep = false;
    }
    if (type == "vehicleDataFromSite") {
      setVehicleDataFromSite(dataStep);
      insertStep = false;
    }
    if (type == "showAddVehicle") {
      setShowAddVehicle(dataStep);
      insertStep = false;
    }
    if (type == "delete selectedVehicle") {
      let dataPolicy = { ...data };
      delete dataPolicy["selectedVehicle"];
      setData(dataPolicy);
      insertStep = false;
    }

    if (type == "calculateRates") {
      setCalculateRates(dataStep);
      insertStep = false;
    }
    if (type == "coveragesRates") {
      dataGuest.coverages.premium = dataStep.premium;
      dataGuest.coverages.policy_fee = dataStep.policy_fee;
      dataGuest.coverages.iva = dataStep.iva;
      dataGuest.coverages.total = dataStep.total;
      dataGuest.coverages.additional_charges = dataStep.additional_charges;
      dataGuest.coverages.pd_ded = dataStep.pd_ded;
      dataGuest.coverages.theft_ded = dataStep.theft_ded;
    }
    if (insertStep) {
      dataGuest[type] = dataStep;
      setData(dataGuest);
    }
  };

  const handleViewButtonCheckout = (valor) => {
    setShowButtonCheckout(valor);
  };
  const [steps, setSteps] = React.useState([
    { title: t("steps.quote") },
    {
      title: t("steps.insuredInformation"),
    },
    {
      title: t("steps.vehicleInformation"),
    },
    { title: t("steps.coverageAndPayment") },
  ]);
  useEffect(() => {
    /*let tempSteps = steps;
    tempSteps[0].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        id="descriptionContent"
      >
        <WhiteCalendarIcon></WhiteCalendarIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>7 Days</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Starting:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-15</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Ending:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-22</span>
      </div>
    );
    tempSteps[1].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        id="descriptionContent"
      >
        <WhiteCalendarIcon></WhiteCalendarIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>7 Days</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Starting:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-15</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Ending:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-22</span>
      </div>
    );
    tempSteps[2].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        id="descriptionContent"
      >
        <WhiteCalendarIcon></WhiteCalendarIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>7 Days</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Starting:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-15</span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon> Ending:{" "}
        <span style={{ fontWeight: "bold", color: "#000" }}>2024-12-22</span>
      </div>
    );
    setSteps(tempSteps);
    */
  }, [steps]);

  const daysOptions = [
    { value: 1, label: t("daysOptions.1day") },
    { value: 2, label: t("daysOptions.2days") },
    { value: 3, label: t("daysOptions.3days") },
    { value: 4, label: t("daysOptions.4days") },
    { value: 5, label: t("daysOptions.5days") },
    { value: 6, label: t("daysOptions.6days") },
    { value: 7, label: t("daysOptions.7days") },
    { value: 8, label: t("daysOptions.8days") },
    { value: 9, label: t("daysOptions.9days") },
    { value: 10, label: t("daysOptions.10days") },
    { value: 11, label: t("daysOptions.11days") },
    { value: 12, label: t("daysOptions.12days") },
    { value: 13, label: t("daysOptions.13days") },
    { value: 14, label: t("daysOptions.14days") },
    { value: 15, label: t("daysOptions.15days") },
    { value: 16, label: t("daysOptions.16days") },
    { value: 17, label: t("daysOptions.17days") },
    { value: 18, label: t("daysOptions.18days") },
    { value: 19, label: t("daysOptions.19days") },
    { value: 20, label: t("daysOptions.20days") },
    { value: 21, label: t("daysOptions.21days") },
    { value: 22, label: t("daysOptions.22days") },
    { value: 23, label: t("daysOptions.23days") },
    { value: 24, label: t("daysOptions.24days") },
    { value: 25, label: t("daysOptions.25days") },
    { value: 26, label: t("daysOptions.26days") },
    { value: 27, label: t("daysOptions.27days") },
    { value: 28, label: t("daysOptions.28days") },
    { value: 29, label: t("daysOptions.29days") },
    { value: 30, label: t("daysOptions.30days") },
    { value: 90, label: t("daysOptions.90days") },
    { value: 182, label: t("daysOptions.6months") },
    { value: 365, label: t("daysOptions.1year") },
  ];

  const getFormattedDate = (days = 0, dateInitial = null) => {
    let utcDate = new Date();
    if (dateInitial) {
      const baseDate = new Date(dateInitial);
      utcDate = new Date(
        Date.UTC(
          baseDate.getUTCFullYear(),
          baseDate.getUTCMonth(),
          baseDate.getUTCDate()
        )
      );
    }
    utcDate.setUTCDate(utcDate.getUTCDate() + days);
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(utcDate.getUTCDate()).padStart(2, "0");
    const year = utcDate.getUTCFullYear();
    return `${month}-${day}-${year}`;
  };

  const previewStep2 = (personDataTemp) => {
    setActiveStep(0);
    setPersonData(personDataTemp);
  };
  const previewStep3 = () => {
    setActiveStep(1);
  };
  const previewStep4 = () => {
    setActiveStep(2);
  };
  const submitStep1 = (vehicleData, coverages, resultRates) => {
    setVehicleData(vehicleData);
    let customerData = { ...personData };
    customerData.agency_id = vehicleData.agent;
    coverages.days = vehicleData.days;
    if (vehicleData.total_value_vehicle == "0") {
      coverages.physical_damage = false;
      coverages.theft = false;
    }
    setCoverages(coverages);
    setPersonData(customerData);
    setResultRates(resultRates);
    setActiveStep(1);
    completeStep1(vehicleData, coverages);
  };
  const completeStep1 = (vehicleData, coverages) => {
    const updatedSteps = steps.map((step, index) => {
      if (index === 0) {
        const option = daysOptions.find((e) => e.value === vehicleData.days);
        const formattedLabel = option
          ? option.label
              .split(" ")
              .map((part, idx) =>
                idx === 0 ? (
                  <span style={{ fontWeight: "bold", color: "#000" }}>
                    {part}
                  </span>
                ) : (
                  ` ${part}`
                )
              )
          : null;

        return {
          ...step,
          descriptions: (
            <div
              style={{
                marginLeft: "10px",
                color: "#6B7280",
                paddingLeft: "1em",
              }}
              className={"descriptionContent"}
            >
              <WhiteCalendarIcon /> {formattedLabel}
              <br />
              <WhiteCheckIcon /> Starting:{" "}
              <span style={{ fontWeight: "bold", color: "#000" }}>
                {getFormattedDate(0, coverages.startDate)}
              </span>
              <br />
              <WhiteCheckIcon /> Ending:{" "}
              <span style={{ fontWeight: "bold", color: "#000" }}>
                {getFormattedDate(vehicleData.days, coverages.startDate)}
              </span>
            </div>
          ),
        };
      }
      return step;
    });
    setSteps(updatedSteps);
    setVehicleData(vehicleData);
    setCoverages(coverages);
  };

  const submitStep2 = (personData, vehicleData) => {
    setPersonData(personData);
    setVehicleData(vehicleData);
    setStepData("data_customer", personData);
    let cardData = { ...card };
    cardData["fname-card"] = personData.first_name;
    cardData["lname-card"] = personData.last_name;
    setCard(cardData);
    let tempSteps = steps;
    tempSteps[1].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        className={"descriptionContent"}
      >
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${personData.first_name} ${personData.last_name}`}
        </span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${personData.email}`}
        </span>
      </div>
    );
    setActiveStep(2);
    setSteps(tempSteps);
  };
  const submitStep3 = (vehicleData) => {
    setVehicleData(vehicleData);
    setStepData("selectedVehicle", vehicleData);
    setActiveStep(3);
    if (vehicleData.total_value_vehicle == "0") {
      let coveragesData = { ...coverages };
      coveragesData.physical_damage = false;
      coveragesData.theft = false;
      setCoverages(coveragesData);
    }
    let tempSteps = steps;
    const option = data["catalogs"].vehicle_types.find(
      (e) => e.id === vehicleData.vehtype + ""
    );
    tempSteps[2].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        className={"descriptionContent"}
      >
        <WhiteCarIcon></WhiteCarIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${option ? option.name : ""}`}
        </span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${vehicleData.vehText}`}
        </span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {vehicleData.total_value_vehicle
            ? ` $${formatNumber(vehicleData.total_value_vehicle)}`
            : " Liability only"}
        </span>
      </div>
    );
    setSteps(tempSteps);
    setActiveStep(3);
  };
  const submitStep4 = (coverages, resultRates, quote) => {
    setResultRates(resultRates);
    setCoverages(coverages);
    let policyData = { ...data };
    policyData.coverages = coverages;
    policyData.coverages.premium = resultRates.premium;
    policyData.coverages.policy_fee = resultRates.policy_fee;
    policyData.coverages.iva = resultRates.iva;
    policyData.coverages.total = resultRates.total;
    policyData.coverages.additional_charges = resultRates.additional_charges;
    policyData.coverages.pd_ded = resultRates.pd_ded;
    policyData.coverages.theft_ded = resultRates.theft_ded;
    policyData.resultRates = resultRates;
    setData(policyData);
    let tempSteps = steps;
    const option = data["catalogs"].vehicle_types.find(
      (e) => e.id === vehicleData.vehtype + ""
    );
    tempSteps[2].descriptions = (
      <div
        style={{
          marginLeft: "10px",
          color: "#6B7280",
          paddingLeft: "1em",
        }}
        className={"descriptionContent"}
      >
        <WhiteCarIcon></WhiteCarIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${option ? option.name : ""}`}
        </span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` ${vehicleData.vehText}`}
        </span>
        <br />
        <WhiteCheckIcon></WhiteCheckIcon>
        <span style={{ fontWeight: "bold", color: "#000" }}>
          {` $${formatNumber(vehicleData.total_value_vehicle)}`}
        </span>
      </div>
    );
    setSteps(tempSteps);
    quote ? saveQuote(policyData) : setPaymentModal(true);
    setActiveStep(3);
  };
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [activeStep]);
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const redesign = process.env.REACT_APP_REDESIGN;
  return (
    <div className={classes.container}>
      {alert}
      {alertLoading}
      <LoadScript
        googleMapsApiKey={googleMapsKey}
        libraries={["places"]}
        language="en"
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              style={{ padding: "40px 0px 40px 0px" }}
              justify="center"
            >
              <GridItem xs={12} md={3}>
                <StepperComponent
                  steps={steps}
                  activeStep={activeStep}
                  orientation={isXs || isSm ? "horizontal" : "vertical"}
                />
                {["1", "2", "3"].includes(localStorage.getItem("userType")) && (
                  <GridItem xs={12} sm={12} md={10} lg={6}>
                    <Button
                      id="loginMobile"
                      variant="green"
                      onClick={sendStepData}
                      style={{ width: "100%", marginTop: "25px" }}
                    >
                      {t("saveQuote")}
                    </Button>
                  </GridItem>
                )}
              </GridItem>
              <GridItem xs={12} md={9}>
                {data && data.catalogs && (
                  <>
                    <div
                      style={{
                        display: activeStep === 0 ? "block" : "none",
                      }}
                    >
                      <Step1
                        data={data}
                        vehicleData={vehicleData}
                        coverages={coverages}
                        submitAction={submitStep1}
                        isSmall={isXs || isSm ? true : false}
                      />
                    </div>

                    <div
                      style={{
                        display: activeStep === 1 ? "block" : "none",
                      }}
                    >
                      <Step2
                        data={data}
                        personData={personData}
                        vehicleData={vehicleData}
                        getCustomer={getCustomer}
                        previewAction={previewStep2}
                        submitAction={submitStep2}
                        isSmall={isXs || isSm ? true : false}
                      />
                    </div>

                    <div
                      style={{
                        display: activeStep === 2 ? "block" : "none",
                      }}
                    >
                      <Step3
                        data={data}
                        vehicleData={vehicleData}
                        coverages={coverages}
                        vehicles={vehicles}
                        vinPolicy={vinPolicy}
                        setVinPolicy={setVinPolicy}
                        previewAction={previewStep3}
                        submitAction={submitStep3}
                        isSmall={isXs || isSm ? true : false}
                      />
                    </div>
                    <div
                      style={{
                        display: activeStep === 3 ? "block" : "none",
                      }}
                    >
                      <Step4
                        data={data}
                        vehicleData={vehicleData}
                        coverages={coverages}
                        resultRates={resultRates}
                        previewAction={previewStep4}
                        submitAction={submitStep4}
                        setStepData={setStepData}
                        ref={childRef}
                        changeStep={completeStep1}
                        isSmall={isXs || isSm ? true : false}
                      />
                    </div>
                  </>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
          <Dialog
            classes={{
              root: classesDialogs.center + " " + classesDialogs.modalRoot,
              paper: classesDialogs.modal,
            }}
            open={alertAgency}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAlertCustomer(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesDialogs.modalHeader}
            >
              <Button
                justIcon
                className={classesDialogs.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setAlertCustomer(false)}
              >
                <Close className={classesDialogs.modalClose} />
              </Button>
              <h4 className={classesDialogs.modalTitle}>Account Check</h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classesDialogs.modalBody}
            ></DialogContent>
            <DialogActions className={classesDialogs.modalFooter}>
              <Button
                onClick={() => setAlertCustomer(false)}
                color="danger"
                simple
              >
                Close
              </Button>
              <Button onClick={() => checkCustomer()} color="info" simple>
                Check
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            classes={{
              root: classesDialogs.center + " " + classesDialogs.modalRoot,
              paper: classesDialogs.modal,
            }}
            open={alertAgency}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAlertAgency(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesDialogs.modalHeader}
            >
              <Button
                justIcon
                className={classesDialogs.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setAlertAgency(false)}
              >
                <Close className={classesDialogs.modalClose} />
              </Button>
              <h4 className={classesDialogs.modalTitle}>Agency Information</h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classesDialogs.modalBody}
            >
              <strong>{`Address: `}</strong>
              <br />
              {agencyData?.address} {agencyData?.city} {`,`}{" "}
              {agencyData?.state_name} {agencyData?.zipcode} <br />
              <strong>{`Phone: `}</strong>
              {agencyData?.phone}
              <br />
              <strong>{`Hours of Operation: `}</strong>
              <br />
              {agencyData?.hours_opp1} {agencyData?.hours_opp2}
              {agencyData?.hours_opp3}
              <br />
              <strong>{`Fax: `}</strong>
              {agencyData?.fax}
              <br />
              <strong>{`E-mail: `}</strong>
              {agencyData?.email}
            </DialogContent>
            <DialogActions className={classesDialogs.modalFooter}>
              <Button
                onClick={() => setAlertAgency(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            style={{ zIndex: "3000" }}
            open={openModalError}
            onClose={closeDialogError}
            classes={{
              root: classesDialogs.center + " " + classesDialogs.modalRoot,
              paper: classesDialogs.modal,
            }}
            keepMounted
            TransitionComponent={Transition}
          >
            <DialogTitle
              disableTypography
              className={classesDialogs.modalHeaderError}
              style={{ color: "#e8403c" }}
            >
              <Button
                justIcon
                className={classesDialogs.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={closeDialogError}
              >
                <Close
                  className={classesDialogs.modalClose}
                  style={{ color: "white" }}
                />
              </Button>
              <h4
                className={classesDialogs.modalTitle}
                style={{ color: "white" }}
              >
                ERROR
              </h4>
            </DialogTitle>
            <DialogContent style={{ textAlign: "left" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanatizeMessageHtml(messageToast),
                }}
              ></div>
            </DialogContent>
          </Dialog>

          <PaymentType
            isOpen={paymentModal}
            onClose={closeModal}
            inputHandler={inputHandler}
            callBackCard={callBackCard}
            cardData={card}
            handlerSaveCard={handlerSaveCard}
            handlerCardData={handlerCardData}
            saveCard={saveCard}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            paymentTypeList={paymentTypeList}
            messageToast={messageToast}
            clientCards={clientCards}
            handleChangeRadio={handleChangeRadio}
            inputHandlerCcv={inputHandlerCcv}
            selectedValue={selectedValue}
            creditCardListModal={creditCardListModal}
            closeCardListModal={toggleCardListModal}
            verifyPaymentType={verifyPaymentType}
            savingCard={savingCard}
            cancelSaveCard={cancelSaveCard}
            data={data}
            loadingCircle={loadingCircle}
          ></PaymentType>

          <CreditCardList
            selectedValue={selectedValue}
            creditCardListModal={creditCardListModal}
            onClose={toggleCardListModal}
            handleChangeRadio={handleChangeRadio}
            inputHandler={inputHandlerCcv}
            handlerCardData={handlerCardData}
            handlerDeleteCard={showAlertCard}
            clientCards={clientCards}
          ></CreditCardList>

          <DeleteCreditCard
            deleteCardModal={deleteCardModal}
            handlerDeleteCard={handlerDeleteCard}
            closeModal={toggleDeleteCardModal}
            idCard={idCardToDelete}
          ></DeleteCreditCard>
        </GridContainer>
      </LoadScript>
      <Snackbar
        style={{ zIndex: "3000" }}
        place="br"
        color={typeToast}
        message={messageToast}
        open={toastMessage}
        closeNotification={() => setToastMessage(false)}
        close
      />
    </div>
  );
}
