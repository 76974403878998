import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/stylesPage.css";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import PropTypes from 'prop-types';
import {
  insertVehicle,
  getMakes,
  getModels,
} from "views/Services/VehiclesService";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { SettingsOverscanTwoTone } from "@material-ui/icons";

const useStyles = makeStyles(styles);
const useExtFormStyles = makeStyles(extendedFormstyles);

let current_date = new Date();

function Vehicle(props) {
  const { guest } = props;
  const dispatch = useDispatch();
  const [vehicleData, setVehicleData] = useState(null);
  const trailerTypes = [
    { id: 1, name: "Trailer" },
    { id: 2, name: "Boat" },
    { id: 3, name: "Other" },
    { id: 4, name: "ATV/UTV" },
  ];
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
    if (!props.showAddVehicle) {
      setVehicleData({});
    }
    if (
      props.vehicleDataEdit &&
      (vehicleData == null ||
        typeof vehicleData.vehtype == "undefined" ||
        props.sendToAPI)
    ) {
      const vehicleTemp = props.vehicleDataEdit;
      if (vehicleTemp.vehtype_trailer && vehicleTemp.vehtype_trailer != "0") {
        setAddTow(true);
      }
      if (vehicleTemp.vehtype_other && vehicleTemp.vehtype_other != "0") {
        setAddTow2(true);
      }
      if ([1, 5].includes(parseInt(vehicleTemp.vehicle_type_id))) {
        getMakesData(vehicleTemp);
        getModelsData(vehicleTemp);
      }
      setValuesForTypeVehicle(vehicleTemp.vehicle_type_id, vehicleTemp);
      vehicleTemp.value_trailer =
        vehicleTemp.total_value_trailer != ""
          ? vehicleTemp.total_value_trailer
          : vehicleTemp.value_trailer || "";
      vehicleTemp.value_other =
        vehicleTemp.total_value_other != ""
          ? vehicleTemp.total_value_other
          : vehicleTemp.value_other || "";
      vehicleTemp.year_trailer =
        vehicleTemp.year_model_trailer != ""
          ? vehicleTemp.year_model_trailer
          : vehicleTemp.year_trailer || "";
      vehicleTemp.year_other =
        vehicleTemp.year_model_other != ""
          ? vehicleTemp.year_model_other
          : vehicleTemp.year_other || "";
      setVehicleData(vehicleTemp);
      setVehTypeState(vehicleTemp.vehicle_type_id ? "success" : "error");
      setUSCanCompanyState(vehicleTemp.insurancecompany ? "success" : "error");
      setFullCoverageState(
        vehicleTemp.insurancepolnumber ? "success" : "error"
      );
      setYearState(vehicleTemp.year_vehicle ? "success" : "error");
      setMakeState(vehicleTemp.make_vehicle ? "success" : "error");
      setModelState(vehicleTemp.model_vehicle ? "success" : "error");
      setValueState(vehicleTemp.value !== "" ? "success" : "error");
      setPlatesState(vehicleTemp.plates_vehicle ? "success" : "error");
      setVINState(vehicleTemp.vin_vehicle ? "success" : "error");

      setVehTypeTrailerState(vehicleTemp.vehtype_trailer ? "success" : "error");
      setYearTrailerState(vehicleTemp.year_trailer ? "success" : "error");
      setMakeTrailerState(vehicleTemp.make_trailer ? "success" : "error");
      setModelTrailerState(vehicleTemp.model_trailer ? "success" : "error");
      setValueTrailerState(vehicleTemp.value_trailer ? "success" : "error");
      setPlatesTrailerState(vehicleTemp.plates_trailer ? "success" : "error");

      setVehTypeOtherState(vehicleTemp.vehtype_other ? "success" : "error");
      setYearOtherState(vehicleTemp.year_other ? "success" : "error");
      setMakeOtherState(vehicleTemp.make_other ? "success" : "error");
      setModelOtherState(vehicleTemp.model_other ? "success" : "error");
      setValueOtherState(vehicleTemp.value_other ? "success" : "error");
      setPlatesOtherState(vehicleTemp.plates_other ? "success" : "error");
    }
    if (!years.length) {
      const currentYear = new Date().getFullYear() + 1;
      const arreglo = [];
      for (let index = currentYear; index >= currentYear - 59; index--) {
        arreglo.push(index);
      }
      setYears(arreglo);
    }
  }, [props]);

  const classesExtForm = useExtFormStyles();
  const [data, setData] = useState(null);
  const [yearVehicle, setYearVehicle] = useState(null);
  const [vehtype, setVehtype] = useState(null);
  const [addTow, setAddTow] = useState(false);
  const [addTow2, setAddTow2] = useState(false);

  const [showSelectMake, setShowSelectMake] = useState(false);
  const [years, setYears] = useState([]);
  const [makesCatalog, setMakesCatalog] = useState([]);
  const [modelsCatalog, setModelsCatalog] = useState([]);
  const [valores, setValores] = useState([]);
  const [vehTypeState, setVehTypeState] = useState("error");
  const [USCanCompanyState, setUSCanCompanyState] = useState("error");
  const [fullCoverageState, setFullCoverageState] = useState("error");
  const [yearState, setYearState] = useState("error");
  const [makeState, setMakeState] = useState("error");
  const [modelState, setModelState] = useState("error");
  const [valueState, setValueState] = useState("error");
  const [plates, setPlatesState] = useState("error");
  const [LNVE, setLNVEState] = useState("error");
  const [LNAD, setLNADState] = useState("error");
  const [VIN, setVINState] = useState("error");

  const [vehTypeTrailerState, setVehTypeTrailerState] = useState("error");
  const [yearTrailerState, setYearTrailerState] = useState("error");
  const [makeTrailerState, setMakeTrailerState] = useState("error");
  const [modelTrailerState, setModelTrailerState] = useState("error");
  const [valueTrailerState, setValueTrailerState] = useState("error");
  const [platesTrailerState, setPlatesTrailerState] = useState("error");

  const [vehTypeOtherState, setVehTypeOtherState] = useState("error");
  const [yearOtherState, setYearOtherState] = useState("error");
  const [makeOtherState, setMakeOtherState] = useState("error");
  const [modelOtherState, setModelOtherState] = useState("error");
  const [valueOtherState, setValueOtherState] = useState("error");
  const [platesOtherState, setPlatesOtherState] = useState("error");

  const [toastMessage, setToastMessage] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const handleFormChange = (e) => {
    const valor = e.target.value;
    let vehData = { ...vehicleData };
    vehData[e.target.name] = valor;
    const sendedData = {};
    if (e.target.name === "vehtype" || e.target.name === "year_vehicle") {
      if (e.target.name === "vehtype") {
        sendedData.vehtype = valor;
        setVehTypeState("success");
        sendedData.year_vehicle = vehicleData.year_vehicle;
        vehData.type_name = e.nativeEvent.target.textContent;
      } else {
        sendedData.vehtype = vehicleData.vehtype;
        sendedData.year_vehicle = valor;
        setYearState("success");
      }
      setValuesForTypeVehicle(valor, vehData);
      getMakesData(sendedData);
    } else if (e.target.name === "make_vehicle" && showSelectMake) {
      sendedData.vehtype = vehicleData.vehtype;
      sendedData.make_vehicle = valor;
      setMakeState("success");
      getModelsData(sendedData);
    }
    if (e.target.name === "year_vehicle") {
      vehData.year_model = e.nativeEvent.target.textContent;
    }
    if (e.target.name === "make_vehicle") {
      vehData.make_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.makeId = valor;
    }
    if (e.target.name === "model_vehicle") {
      vehData.model_name = ["1", "5"].includes(vehData.vehtype)
        ? e.nativeEvent.target.textContent
        : valor;
      vehData.modelId = valor;
    }
    if (e.target.name === "value") {
      setValueState("success");
      vehData.value = valor;
    }
    if (e.target.name === "insurancepolnumber") {
      setFullCoverageState("success");
      vehData.insurancepolnumber = valor;
      if (valor == "No") {
        vehData.value = "0";
        formatValueField(0);
      } else {
        setValuesForTypeVehicle(1, vehData);
      }
    }
    setVehicleData(vehData);
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const resetValuesRequired = () => {
    setPlatesState("error");
    setVINState("error");
    setToastMessage(false);
    setMessageToast("");
    setTypeToast("");
  };

  const createVehicle = () => {
    let vinVehicle =
      typeof vehicleData.vin_vehicle == "undefined"
        ? ""
        : vehicleData.vin_vehicle;
    if (checkErrorAddVehicle(vinVehicle)) {
      setToastMessage(true);
      setTypeToast("danger");
      let messageError = "Complete all required fields";
      messageError =
        vinVehicle.length != 17 ?
        "Vin vehicle invalid. It should have 17 characters." : messageError;
      console.log(messageError);
      setMessageToast(messageError);
    } else {
      let sendedData = setVehicleDataToApi();
      if (props.sendToAPI) {
        sendVehicleToApi(sendedData);
      } else {
        props.setSelectedVehicle(sendedData);
        setVehicleData({ vehtype: "" });
        props.closeCreateVehicle();
      }
    }
  };

  const setVehicleDataToApi = () => {
    const newDataVehicle = vehicleData;
      newDataVehicle.client_id = data.data_customer.id;
      newDataVehicle.insurancecompany = newDataVehicle.insurancecompany
        ? newDataVehicle.insurancecompany
        : "";
      newDataVehicle.sentri = newDataVehicle.sentri ? newDataVehicle.sentri : "";
      if (newDataVehicle.haveLienholder != "Yes") {
        newDataVehicle.lienholder_vehicle = "";
        newDataVehicle.lienholder_vehicle_address = "";
      }
      if (props.vehicleDataEdit.id) {
        newDataVehicle.vehicle_id = props.vehicleDataEdit.id;
      }
      newDataVehicle.insurance_policy_number = newDataVehicle.insurancepolnumber;
      return newDataVehicle;
  }

  const sendVehicleToApi = async (sendedData) => {
    const resultSubmit = await dispatch(insertVehicle(sendedData));
    if (resultSubmit.payload.result) {
      setVehicleData({ vehtype: "" });
      setToastMessage(true);
      setTypeToast("success");
      setMessageToast("Success create vehicle.");
      if (
        [1, 5].includes(
          parseInt(resultSubmit.payload.vehicle[0].vehicle_type_id)
        )
      ) {
        resultSubmit.payload.vehicle[0].makeId =
          resultSubmit.payload.vehicle[0].make;
        resultSubmit.payload.vehicle[0].modelId =
          resultSubmit.payload.vehicle[0].model;
      }
      props.setSelectedVehicle(resultSubmit.payload.vehicle[0]);
      resetValuesRequired();
      props.closeCreateVehicle();
    } else {
      setToastMessage(true);
      setTypeToast("danger");
      setMessageToast("Null value in vehicle.");
    }
  };

  const checkErrorAddVehicle = (vinVehicle) => {
    return (
      vehicleData.vehtype === "" ||
      vehicleData.insurancecompany === "" ||
      vehicleData.insurancepolnumber === "" ||
      !vehicleData.year_model ||
      vehicleData.make_vehicle === undefined ||
      vehicleData.model_vehicle === undefined ||
      isNaN(vehicleData.value) ||
      vehicleData.plates_vehicle === "" ||
      vehicleData.plates_vehicle === undefined ||
      vinVehicle.length < 17 ||
      vinVehicle.length > 17 ||
      (vehicleData.haveLienholder == "Yes" &&
        (vehicleData.lienholder_vehicle == null ||
          vehicleData.lienholder_vehicle == "" ||
          vehicleData.lienholder_vehicle_address == null ||
          vehicleData.lienholder_vehicle_address == "")) ||
      (addTow &&
        (vehicleData.vehtype_trailer == null ||
          vehicleData.vehtype_trailer == "" ||
          vehicleData.year_trailer == null ||
          vehicleData.year_trailer == "" ||
          vehicleData.make_trailer == null ||
          vehicleData.make_trailer == "" ||
          vehicleData.model_trailer == null ||
          vehicleData.model_trailer == "" ||
          vehicleData.value_trailer == null ||
          vehicleData.value_trailer == "")) ||
      (addTow2 &&
        (vehicleData.vehtype_other == null ||
          vehicleData.vehtype_other == "" ||
          vehicleData.year_other == null ||
          vehicleData.year_other == "" ||
          vehicleData.make_other == null ||
          vehicleData.make_other == "" ||
          vehicleData.model_other == null ||
          vehicleData.model_other == "" ||
          vehicleData.value_other == null ||
          vehicleData.value_other == ""))
    );
  };

  const getMakesData = async (sendedData) => {
    if (sendedData.vehtype && sendedData.year_vehicle) {
      setVehtype(sendedData.vehtype);
      setYearVehicle(sendedData.year_vehicle);
      const sendedVehicle =
        sendedData.vehtype === "1"
          ? "P"
          : sendedData.vehtype === "5"
          ? "M"
          : null;
      if (sendedVehicle) {
        setShowSelectMake(true);
        const resultSubmit = await dispatch(
          getMakes({
            year: sendedData.year_vehicle,
            type_vehicle: sendedVehicle,
          })
        );
        let makeList = resultSubmit.payload.makes;
        if (!makeList) {
          makeList = [{ id: "Other", name: "Other" }];
        } else {
          makeList.unshift({ id: "Other", name: "Other" });
        }
        setMakesCatalog(makeList);
      } else {
        setShowSelectMake(false);
      }
    }
  };
  const getModelsData = async (sendedData) => {
    const sendedVehicle =
      sendedData.vehtype === "1"
        ? "P"
        : sendedData.vehtype === "5"
        ? "M"
        : null;
    const resultSubmit = await dispatch(
      getModels({
        name: sendedData.make_vehicle,
        type_vehicle: sendedVehicle,
      })
    );
    let modelList = resultSubmit.payload.makes;
    if (!modelList) {
      modelList = [{ id: "Other", name: "Other" }];
    } else {
      modelList.unshift({ id: "Other", name: "Other" });
    }
    setModelsCatalog(modelList);
  };

  const setValuesForTypeVehicle = (type, vehData) => {
    let limitValue = 300000;
    type = parseInt(type);
    if ([1, 7, 13].includes(type)) {
      limitValue = 100000;
    }
    if (type == 5) {
      limitValue = 50000;
    }
    if (type == 11) {
      limitValue = 40000;
    }
    if (
      [10, 12].includes(type) ||
      current_date.getFullYear() - vehData.year_vehicle > 25
    ) {
      limitValue = 0;
    }
    formatValueField(limitValue);
  };

  const formatValueField = (limitValue) => {
    if (limitValue == 0) {
      let vehicleDataToEdit = { ...vehicleData };
      vehicleDataToEdit.value = 0;
      setVehicleData(vehicleDataToEdit);
    }

    const arreglov = [];
    for (let index = 0; index <= limitValue; index += 1000) {
      if (index === 0) {
        arreglov.push({ value: index, traduccion: "$0 - Liability only" });
      } else {
        index >= 3000 &&
          arreglov.push({ value: index, traduccion: USDollar.format(index) });
      }
    }
    setValores(arreglov);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h4>Vehicle</h4>
      </GridItem>
      {data && props.showAddVehicle ? (
        <>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <FormControl
                fullWidth
                style={{ marginTop: "10px" }}
                error={vehTypeState === "error" ? true : false}
              >
                <InputLabel
                  htmlFor="vehtype"
                  className={
                    vehTypeState !== "error"
                      ? classesExtForm.selectLabel
                      : classesExtForm.selectLabelDanger
                  }
                  id="simple-select"
                >
                  Vehicle Type <small>(required)</small>
                </InputLabel>
                <Select
                  id="simple-select"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  defaultValue={props.vehicleDataEdit.vehtype}
                  value={vehicleData.vehtype}
                  onChange={(event) => {
                    if (!verifyLength(event.target.value, 3)) {
                      setVehTypeState("error");
                    } else {
                      setVehTypeState("success");
                    }
                    handleFormChange(event);
                  }}
                  inputProps={{
                    name: "vehtype",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classesExtForm.selectMenuItem,
                    }}
                  >
                    Vehicle Type:
                  </MenuItem>
                  {data?.catalogs?.vehicle_types?.map((item) => (
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <CustomInput
                success={USCanCompanyState === "success"}
                error={USCanCompanyState === "error"}
                labelText={
                  <span>
                    US/Canada Auto Ins Company <small>(required)</small>
                  </span>
                }
                id="insurancecompany"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "insurancecompany",
                  value: vehicleData.insurancecompany,
                  onChange: (event) => {
                    if (!verifyLength(event.target.value, 1)) {
                      setUSCanCompanyState("error");
                    } else {
                      setUSCanCompanyState("success");
                    }
                    handleFormChange(event);
                  },
                }}
              />
              <FormControl
                fullWidth
                style={{ marginTop: "20px" }}
                error={fullCoverageState === "error" ? true : false}
              >
                <InputLabel
                  htmlFor="insurancepolnumber"
                  className={
                    fullCoverageState !== "error"
                      ? classesExtForm.selectLabel
                      : classesExtForm.selectLabelDanger
                  }
                  id="insurancepolnumber"
                >
                  Do you carry "full coverage" in the US/Canada?{" "}
                  <small>(required)</small>
                </InputLabel>
                <Select
                  id="insurancepolnumber"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  defaultValue={props.vehicleDataEdit.insurancepolnumber}
                  value={vehicleData.insurancepolnumber}
                  onChange={handleFormChange}
                  inputProps={{
                    name: "insurancepolnumber",
                    id: "insurancepolnumber",
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={"Yes"}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={"No"}
                  >
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                error={yearState === "error" ? true : false}
              >
                <InputLabel
                  htmlFor="year_vehicle"
                  className={
                    yearState !== "error"
                      ? classesExtForm.selectLabel
                      : classesExtForm.selectLabelDanger
                  }
                  id="year_vehicle"
                >
                  Year <small>(required)</small>
                </InputLabel>
                <Select
                  id="year_vehicle"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  defaultValue={props.vehicleDataEdit.year_vehicle}
                  value={vehicleData.year_vehicle}
                  onChange={handleFormChange}
                  inputProps={{
                    name: "year_vehicle",
                    id: "year_vehicle",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classesExtForm.selectMenuItem,
                    }}
                  >
                    Year:
                  </MenuItem>
                  {years.map((item) => (
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {showSelectMake && makesCatalog.length > 0 ? (
                <FormControl
                  fullWidth
                  className={classesExtForm.selectFormControl}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="make_vehicle"
                    className={classesExtForm.selectLabel}
                  >
                    Make <small>(required)</small>
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.make_vehicle}
                    value={vehicleData.make_vehicle}
                    onChange={handleFormChange}
                    inputProps={{
                      name: "make_vehicle",
                      id: "make_vehicle",
                    }}
                  >
                    {makesCatalog.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <CustomInput
                  success={makeState === "success"}
                  error={makeState === "error"}
                  labelText={
                    <span>
                      Make <small>(required)</small>
                    </span>
                  }
                  id="make_vehicle"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "make_vehicle",
                    value: vehicleData.make_vehicle,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setMakeState("error");
                      } else {
                        setMakeState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
              )}
              {showSelectMake && modelsCatalog.length > 0 ? (
                <FormControl
                  fullWidth
                  className={classesExtForm.selectFormControl}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="model_vehicle"
                    className={classesExtForm.selectLabel}
                  >
                    Model <small>(required)</small>
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.model_vehicle}
                    value={vehicleData.model_vehicle}
                    onChange={handleFormChange}
                    inputProps={{
                      name: "model_vehicle",
                      id: "model_vehicle",
                    }}
                  >
                    {modelsCatalog.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <CustomInput
                  success={modelState === "success"}
                  error={modelState === "error"}
                  labelText={
                    <span>
                      Model <small>(required)</small>
                    </span>
                  }
                  id="model_vehicle"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "model_vehicle",
                    value: vehicleData.model_vehicle,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setModelState("error");
                      } else {
                        setModelState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
              )}

              <FormControl
                fullWidth
                style={{ marginTop: "10px" }}
                error={valueState === "error" ? true : false}
              >
                <InputLabel
                  htmlFor="value"
                  className={
                    valueState !== "error"
                      ? classesExtForm.selectLabel
                      : classesExtForm.selectLabelDanger
                  }
                  id="value"
                >
                  Value <small>(required)</small>
                </InputLabel>
                <Select
                  id="value"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  defaultValue={props.vehicleDataEdit.value}
                  value={vehicleData.value}
                  onChange={handleFormChange}
                  inputProps={{
                    name: "value",
                    id: "value",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classesExtForm.selectMenuItem,
                    }}
                  >
                    Value:
                  </MenuItem>
                  {valores.map((item) => (
                    <MenuItem
                      classes={{
                        root: classesExtForm.selectMenuItem,
                        selected: classesExtForm.selectMenuItemSelected,
                      }}
                      value={item.value}
                    >
                      {item.traduccion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <CustomInput
                success={plates === "success"}
                error={plates === "error"}
                labelText={
                  <span>
                    Plates <small>(required)</small>
                  </span>
                }
                id="plates_vehicle"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "plates_vehicle",
                  value: vehicleData.plates_vehicle,
                  onChange: (event) => {
                    if (!verifyLength(event.target.value, 1)) {
                      setPlatesState("error");
                    } else {
                      setPlatesState("success");
                    }
                    handleFormChange(event);
                  },
                }}
              />
              <CustomInput
                success={VIN === "success"}
                error={VIN === "error"}
                labelText={
                  <span>
                    VIN <small>(required)</small>
                  </span>
                }
                id="vin_vehicle"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "vin_vehicle",
                  value: vehicleData.vin_vehicle,
                  onChange: (event) => {
                    if (!verifyLength(event.target.value, 17)) {
                      setVINState("error");
                    } else {
                      setVINState("success");
                    }
                    handleFormChange(event);
                  },
                }}
              />
              <FormControl
                fullWidth
                className={classesExtForm.selectFormControl}
                style={{ marginTop: "20px" }}
              >
                <InputLabel
                  htmlFor="haveLienholder"
                  className={classesExtForm.selectLabel}
                >
                  Do you have a lienholder?
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: classesExtForm.select,
                  }}
                  defaultValue={props.vehicleDataEdit.haveLienholder}
                  value={vehicleData.haveLienholder}
                  onChange={handleFormChange}
                  inputProps={{
                    name: "haveLienholder",
                    id: "haveLienholder",
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={"Yes"}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={"No"}
                  >
                    No
                  </MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText={
                  <span>
                    Trusted Traveler # <small>(if applicable)</small>
                  </span>
                }
                id="sentri"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "sentri",
                  value: vehicleData.sentri,
                  onChange: handleFormChange,
                }}
              />
              {vehicleData.haveLienholder &&
              vehicleData.haveLienholder === "Yes" ? (
                <>
                  <CustomInput
                    labelText={<span>Lienholder</span>}
                    success={LNVE === "success"}
                    error={LNVE === "error"}
                    id="lienholder_vehicle"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "lienholder_vehicle",
                      value: vehicleData.lienholder_vehicle,
                      onChange: (event) => {
                        if (!verifyLength(event.target.value, 1)) {
                          setLNVEState("error");
                        } else {
                          setLNVEState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  />
                  <CustomInput
                    labelText={<span>Lienholder address</span>}
                    success={LNAD === "success"}
                    error={LNAD === "error"}
                    id="lienholder_vehicle_address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "lienholder_vehicle_address",
                      value: vehicleData.lienholder_vehicle_address,
                      onChange: (event) => {
                        if (!verifyLength(event.target.value, 1)) {
                          setLNADState("error");
                        } else {
                          setLNADState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  />
                </>
              ) : null}
            </GridItem>
          </GridContainer>
          {addTow ? (
            <GridContainer justify="center" style={{ marginTop: "20px" }}>
              <h4>Other Vehicle (1)</h4>
              <GridItem xs={12}>
                <FormControl
                  fullWidth
                  style={{ marginTop: "10px" }}
                  error={vehTypeTrailerState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      vehTypeTrailerState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Other Type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.vehtype_trailer}
                    value={vehicleData.vehtype_trailer}
                    inputProps={{
                      name: "vehtype_trailer",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setVehTypeTrailerState("error");
                        } else {
                          setVehTypeTrailerState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {trailerTypes.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <CustomInput
                  labelText={
                    <span>
                      Insurance Company <small>(If Different)</small>
                    </span>
                  }
                  id="clientnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "other_policy_company_trailer",
                    value: vehicleData.other_policy_company_trailer,
                    onChange: handleFormChange,
                  }}
                />
                <CustomInput
                  labelText={<span>Ins. Policy #</span>}
                  id="clientnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "other_policy_number_trailer",
                    value: vehicleData.other_policy_number_trailer,
                    onChange: handleFormChange,
                  }}
                />
                <FormControl
                  fullWidth
                  error={yearTrailerState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      yearTrailerState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Year
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.year_trailer}
                    value={vehicleData.year_trailer}
                    inputProps={{
                      name: "year_trailer",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setYearTrailerState("error");
                        } else {
                          setYearTrailerState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {years.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <CustomInput
                  success={makeTrailerState === "success"}
                  error={makeTrailerState === "error"}
                  labelText={
                    <span>
                      Make <small>(required)</small>
                    </span>
                  }
                  id="make_trailer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "make_trailer",
                    value: vehicleData.make_trailer,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setMakeTrailerState("error");
                      } else {
                        setMakeTrailerState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <CustomInput
                  success={modelTrailerState === "success"}
                  error={modelTrailerState === "error"}
                  labelText={<span>Model</span>}
                  id="model_trailer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "model_trailer",
                    value: vehicleData.model_trailer,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setModelTrailerState("error");
                      } else {
                        setModelTrailerState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <FormControl
                  fullWidth
                  style={{ marginTop: "10px" }}
                  error={valueTrailerState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      valueTrailerState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Value
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.value_trailer}
                    value={vehicleData.value_trailer}
                    inputProps={{
                      name: "value_trailer",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setValueTrailerState("error");
                        } else {
                          setValueTrailerState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {valores.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.value}
                      >
                        {item.traduccion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <CustomInput
                  success={platesTrailerState === "success"}
                  error={platesTrailerState === "error"}
                  labelText={<span>Plates</span>}
                  id="driverlicense"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "plates_trailer",
                    value: vehicleData.plates_trailer,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setPlatesTrailerState("error");
                      } else {
                        setPlatesTrailerState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <CustomInput
                  labelText={<span>VIN</span>}
                  id="vin_trailer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "vin_trailer",
                    value: vehicleData.vin_trailer,
                    onChange: handleFormChange,
                  }}
                />
                <CustomInput
                  labelText={<span>Description</span>}
                  id="description_trailer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "description_trailer",
                    value: vehicleData.description_trailer,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : null}
          {addTow2 ? (
            <GridContainer justify="center" style={{ marginTop: "20px" }}>
              <h4>Other Vehicle (2)</h4>
              <GridItem xs={12}>
                <FormControl
                  fullWidth
                  style={{ marginTop: "10px" }}
                  error={vehTypeOtherState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      vehTypeOtherState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Other Type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.vehtype_other}
                    value={vehicleData.vehtype_other}
                    inputProps={{
                      name: "vehtype_other",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setVehTypeOtherState("error");
                        } else {
                          setVehTypeOtherState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {trailerTypes.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <CustomInput
                  labelText={
                    <span>
                      Insurance Company <small>(If Different)</small>
                    </span>
                  }
                  id="clientnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "other_policy_company_other",
                    value: vehicleData.other_policy_company_other,
                    onChange: handleFormChange,
                  }}
                />
                <CustomInput
                  labelText={<span>Ins. Policy #</span>}
                  id="clientnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "other_policy_number_other",
                    value: vehicleData.other_policy_number_other,
                    onChange: handleFormChange,
                  }}
                />
                <FormControl
                  fullWidth
                  error={yearOtherState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      yearOtherState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Year
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.year_other}
                    value={vehicleData.year_other}
                    inputProps={{
                      name: "year_other",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setYearOtherState("error");
                        } else {
                          setYearOtherState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {years.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <CustomInput
                  success={makeOtherState === "success"}
                  error={makeOtherState === "error"}
                  labelText={<span>Make</span>}
                  id="make_other"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "make_other",
                    value: vehicleData.make_other,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setMakeOtherState("error");
                      } else {
                        setMakeOtherState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <CustomInput
                  success={modelOtherState === "success"}
                  error={modelOtherState === "error"}
                  labelText={<span>Model</span>}
                  id="model_other"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "model_other",
                    value: vehicleData.model_other,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setModelOtherState("error");
                      } else {
                        setModelOtherState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <FormControl
                  fullWidth
                  style={{ marginTop: "10px" }}
                  error={valueOtherState === "error" ? true : false}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={
                      valueOtherState !== "error"
                        ? classesExtForm.selectLabel
                        : classesExtForm.selectLabelDanger
                    }
                  >
                    Value
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classesExtForm.select,
                    }}
                    defaultValue={props.vehicleDataEdit.value_other}
                    value={vehicleData.value_other}
                    inputProps={{
                      name: "value_other",
                      id: "simple-select",
                      onChange: (event) => {
                        if (!verifyLength(event.target.value.toString(), 1)) {
                          setValueOtherState("error");
                        } else {
                          setValueOtherState("success");
                        }
                        handleFormChange(event);
                      },
                    }}
                  >
                    {valores.map((item) => (
                      <MenuItem
                        classes={{
                          root: classesExtForm.selectMenuItem,
                          selected: classesExtForm.selectMenuItemSelected,
                        }}
                        value={item.value}
                      >
                        {item.traduccion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <CustomInput
                  success={platesOtherState === "success"}
                  error={platesOtherState === "error"}
                  labelText={<span>Plates</span>}
                  id="driverlicense"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "plates_other",
                    value: vehicleData.plates_other,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setPlatesOtherState("error");
                      } else {
                        setPlatesOtherState("success");
                      }
                      handleFormChange(event);
                    },
                  }}
                />
                <CustomInput
                  labelText={<span>VIN</span>}
                  id="vin_other"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "vin_other",
                    value: vehicleData.vin_other,
                    onChange: handleFormChange,
                  }}
                />
                <CustomInput
                  labelText={<span>Description</span>}
                  id="description_other"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "description_other",
                    value: vehicleData.description_other,
                    onChange: handleFormChange,
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : null}
          <div style={{ textAlign: "right", paddingBottom: "20px" }}>
            {!addTow2 ? (
              <Button
                id={props.unitButtonId ? unitButtonId : "unitTowButton"}
                color="info"
                onClick={() => {
                  if (!addTow) {
                    setAddTow(true);
                  } else {
                    setAddTow2(true);
                  }
                }}
              >
                Add Unit in Tow
              </Button>
            ) : null}
            {addTow ? (
              <Button
                color="info"
                onClick={() => {
                  let vehData = { ...vehicleData };
                  if (addTow2) {
                    setAddTow2(false);
                    vehData.vehtype_other = "";
                    vehData.other_policy_company_other = "";
                    vehData.other_policy_number_other = "";
                    vehData.year_other = "";
                    vehData.make_other = "";
                    vehData.model_other = "";
                    vehData.value_other = "";
                    vehData.plates_other = "";
                    vehData.vin_other = "";
                    vehData.description_other = "";
                  } else {
                    setAddTow(false);
                    vehData.vehtype_trailer = "";
                    vehData.other_policy_company_trailer = "";
                    vehData.other_policy_number_trailer = "";
                    vehData.year_trailer = "";
                    vehData.make_trailer = "";
                    vehData.model_trailer = "";
                    vehData.value_trailer = "";
                    vehData.plates_trailer = "";
                    vehData.vin_trailer = "";
                    vehData.description_trailer = "";
                  }
                  setVehicleData(vehData);
                }}
              >
                Remove Unit in Tow
              </Button>
            ) : null}
          </div>
        </>
      ) : null}
      <div style={{ textAlign: "right", paddingBottom: "20px" }}>
        <Button
          id={props.saveButtonId ? saveButtonId : "saveVehicle"}
          color="info"
          onClick={() => {
            resetValuesRequired();
            props.closeCreateVehicle();
          }}
        >
          Cancel
        </Button>
        <Button
          id={props.saveButtonId ? saveButtonId : "saveVehicle"}
          color="danger"
          onClick={() => {
            createVehicle();
          }}
        >
          Save
        </Button>
      </div>
      <GridItem xs={12} sm={12} md={3}>
        <Snackbar
          place="br"
          color={typeToast}
          message={messageToast}
          open={toastMessage}
          closeNotification={() => setToastMessage(false)}
          close
        />
      </GridItem>
    </GridContainer>
  );
}

Vehicle.propTypes = {
  setSelectedVehicle: PropTypes.func,
  sendToAPI: PropTypes.bool,
  closeCreateVehicle: PropTypes.func,
  vehicleDataEdit: PropTypes.array,
};

export default Vehicle;
