import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import PolicySummary from "components/Policy/PolicySummaryRedesign";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { getPolicyData } from "views/Services/PoliciesService";

const useStyles = makeStyles(styles);

export default function WizardView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const isLogged = localStorage.getItem("userToken") !== undefined;
  const [data, setData] = useState(null);
  const [email, setEmail] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [typeToast, setTypeToast] = useState("");
  const [messageToast, setMessageToast] = useState("");

  useEffect(() => {
    getData();
  }, [dispatch]);

  const getData = async () => {
    const dataSend = { id, kiosko: true };
    const resultSubmit = await dispatch(getPolicyData(dataSend));
    if (resultSubmit.payload.result) {
      setData(resultSubmit.payload.data);
      setEmail(resultSubmit.payload.data.email);
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ padding: "40px 0px" }} justify="center">
            <GridItem>
              <PolicySummary
                isLogged={false}
                id={id}
                data={data}
                email={email}
                setEmail={setEmail}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>

      <Snackbar
        style={{ zIndex: 3000 }}
        place="br"
        color={typeToast}
        message={messageToast}
        open={toastMessage}
        closeNotification={() => setToastMessage(false)}
        close
      />
    </div>
  );
}
