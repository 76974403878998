/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { t, i18n } = useTranslation();
  const urlPage = window.location.href;
  const isRedesign =
    urlPage.includes("login-customer") || urlPage.includes("login-agent")
      ? true
      : false;
  const classes = useStyles();
  const { fluid, white, rtlActive, color } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });

  const footerContent = () => {
    return (
      <>
        &copy; {1900 + new Date().getYear()}{" "}
        {rtlActive ? t("footer.rtlActive") : t("footer.rtlDeactive")}
      </>
    );
  };

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={`${classes.right} ${color ? classes.blackColor : ""}`} >
          {!isRedesign && footerContent()}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
