import { React } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import logo from "assets/img/logo.png";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

function LoginBase({ children }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardHeader
              image
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="white"
            >
              <div className={classes.socialLine}>&nbsp;</div>
              <img
                src={logo}
                alt="Sanborn's Mexico Auto Insurance"
                style={{ boxShadow: "none", width: "50%" }}
              />
            </CardHeader>
            {children}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginBase.propTypes = {
  children: PropTypes.object,
};

export default LoginBase;
