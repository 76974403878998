import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";

import jwt_decode from "jwt-decode";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import logo from "assets/img/logo.png";
import logo4 from "assets/img/logo_4.png";

const useStyles = makeStyles(styles);
export default function AdminNavbar(props) {
  const classes = useStyles();
  const token = localStorage.getItem("userToken");
  let decoded = jwt_decode(token);
  const pathName = window.location.pathname;
  const isKiosko = pathName.includes("kiosko") && pathName.includes("/admin/view") ? true : false;
  const agencyCode = decoded.producerCode;
  const { color, rtlActive } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  return (
    !isKiosko && (
      <AppBar
        className={classes.appBar + appBarClasses}
        style={{
          background: decoded.producerCode == "77001" ? "#0046b4" : "#FFF",
          position: "fixed",
          color: decoded.producerCode == "77001" ? "#FFF" : "",
        }}
      >
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <div id="testing" className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <img
              src={agencyCode == "77001" ? logo4 : logo}
              alt="Sanborn's Mexico Auto Insurance"
              style={{ boxShadow: "none", width: "100px" }}
            />
          </div>
          <Hidden smDown implementation="css">
            <AdminNavbarLinks rtlActive={rtlActive} />
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
