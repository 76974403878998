import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { styled } from "@mui/material/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

const CustomInputStyled = styled(OutlinedInput)(
  ({ theme, error, required, value }) => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#888888", // Rojo si hay error, azul si es requerido y tiene valor
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#008670", // Mismos colores al pasar el mouse
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: error ? "red" : required && value ? "#008670" : "#008670", // Mismos colores al enfocar
        borderWidth: "1px",
      },
    },
  })
);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    secondaryText,
    changeFunction,
  } = props;

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });

  const formControlClasses = classNames(
    formControlProps?.className,
    classes.formControl
  );

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  const today = new Date().toISOString().split("T")[0];

  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      variant="outlined"
    >
      {labelText && (
        <Typography component="pre">
          <InputLabel
            className={classNames(
              classes.labelRoot,
              classes.labelOutlined,
              labelClasses
            )}
            htmlFor={id}
            {...labelProps}
            shrink
          >
            {labelText}
          </InputLabel>
        </Typography>
      )}
      {secondaryText && (
        <FormHelperText
          id={`${id}-secondaryText`}
          className={classNames(classes.secondaryText, classes.labelOutlined)}
        >
          {secondaryText}
        </FormHelperText>
      )}
      <CustomInputStyled
        id={id}
        required={inputProps?.required}
        value={inputProps?.value}
        error={inputProps?.required && !inputProps?.value ? true : false}
        inputProps={{
          ...inputProps,
          min: inputProps?.type === "date" ? today : undefined,
        }}
        classes={{
          input: inputClasses,
          root: inputRootCustomClasses,
          disabled: classes.disabled,
        }}
      />
      {helperText && (
        <FormHelperText
          id={`${id}-text`}
          className={classNames({
            [classes.labelRootError]: error,
            [classes.labelRootSuccess]: success && !error,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
  secondaryText: PropTypes.node,
};
