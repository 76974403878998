import React from "react";
import cx from "classnames";
import clsx from "clsx";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from '@mui/material';
import Icon from "@material-ui/core/Icon";
import styles from "assets/jss/material-dashboard-pro-react/components/alertStyle.js";

const useStyles = makeStyles(styles);
export default function CustomAlerts(props) {
  const classes = useStyles();
  const { variant, text, title, icon, severity } = props;
  const alertClasses = classNames({
    [classes.alert]: true,
    [classes[variant]]: variant,

  });
return <Alert icon={<Icon>{icon}</Icon>} severity={severity} className={alertClasses}>
    {title ?
      <AlertTitle>{title}</AlertTitle>
      : null}
    {text}
  </Alert>;
}

CustomAlerts.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};
