import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";

import { checkExistPolicyNumber } from "../Services/OrdersService";

import { verifyLength } from "components/Utils/utils";

import stylesDialogs from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import LoginBase from "components/LoginBase/LoginBase";
import Snackbar from "components/Snackbar/Snackbar.js";

const useStylesDialog = makeStyles(stylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const pathName = window.location.pathname;

export default function LoginPage() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { producerCode } = routeParams;
  const [messageErrorPolicy, setMessageErrorPolicy] = useState("");
  const [showErrorPolicy, setShowErrorPolicy] = useState(false);
  const [policyState, setPolicyState] = useState(false);
  const [policy, setPolicy] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [typeToast, setTypeToast] = useState("danger");
  const handlePolicy = (e) => {
    setPolicy(e.target.value);
  };

  const searchPolicy = async () => {
    const resultSubmit = await dispatch(
      checkExistPolicyNumber({ policyNumber: policy, kiosko: true })
    );
    if (resultSubmit.payload.result) {
      localStorage.setItem("producerCode", producerCode);
      window.location.href = `/guest/viewPolicy/${resultSubmit.payload.policyId}/kiosko`;
    } else {
      setTypeToast("danger");
      setShowErrorPolicy(true);
      setMessageErrorPolicy(resultSubmit.payload.message);
    }
  };

  const classesDialogs = useStylesDialog();
  return (
    <LoginBase>
      <CardBody>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Button
              id="buyKiosko"
              type="button"
              style={{ backgroundColor: "#008670" }}
              block
              onClick={() => {
                window.location.href = "/auth/login-page/kiosko/" + producerCode;
              }}
            >
              Buy {pathName.includes("sale") && "Another"}
            </Button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Button
              id="viewKiosko"
              type="button"
              style={{ backgroundColor: "#008670" }}
              block
              onClick={() => {
                setOpenModal(true);
              }}
            >
              View
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Dialog
              classes={{
                root: classesDialogs.center + " " + classesDialogs.modalRoot,
                paper: classesDialogs.modal,
              }}
              open={openModal}
              TransitionComponent={Transition}
              keepMounted
              fullWidth
              onClose={() => setOpenModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classesDialogs.modalHeader}
              >
                <Button
                  justIcon
                  className={classesDialogs.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => setOpenModal(false)}
                >
                  <Close className={classesDialogs.modalClose} />
                </Button>
                <h4 className={classesDialogs.modalTitle}>Search policy</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classesDialogs.modalBody}
              >
                <CustomInput
                  success={
                    policyState === "success" || policy !== null
                      ? "success"
                      : ""
                  }
                  error={
                    policyState === "error" || policy === null ? "error" : ""
                  }
                  labelText={<span>Policy</span>}
                  a
                  id="policy"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "policy",
                    value: policy,
                    onChange: (event) => {
                      if (!verifyLength(event.target.value, 1)) {
                        setPolicyState("error");
                      } else {
                        setPolicyState("success");
                      }
                      handlePolicy(event);
                    },
                  }}
                />
              </DialogContent>
              <DialogActions className={classesDialogs.modalFooter}>
                <Button
                  id="closeCheckMobileGuest"
                  onClick={() => setOpenModal(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
                <Button
                  id="checkMobileGuest"
                  onClick={() => searchPolicy()}
                  color="info"
                  simple
                >
                  Check
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              place="bc"
              color={typeToast}
              message={messageErrorPolicy}
              open={showErrorPolicy}
              closeNotification={() => setShowErrorPolicy(false)}
              close
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </LoginBase>
  );
}
