import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  iconEdit: {
    position: "absolute",
    top: 70,
    right: 50,
    color: "black",
    with: 30,
    heigth: 30,
  },
};

import extendedFormstyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useExtFormStyles = makeStyles(extendedFormstyles);

const Step1 = React.forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      const customer = props.data.data_customer;
      if (customer.email && verifyEmail(customer.email)) {
        setemailState("success");
      }
      if (customer.first_name) {
        setfirstnameState("success");
      }
      if (customer.last_name) {
        setlastnameState("success");
      }
      if (customer.dl_number) {
        setLicenceState("success");
      }
      if (customer.address) {
        setAddressState("success");
      }
      if (customer.zipcode) {
        setZipCodeState("success");
      }
      if (customer.city) {
        setCityState("success");
      }
      if (customer.phone) {
        setPhoneState("success");
      }
      setCustomerData(customer);
      if (props.data?.data_customer?.dl_country_id) {
        setStatesDl(
          props.data.catalogs.states.filter(
            (e) => e.country_id == props.data.data_customer.dl_country_id
          )
        );
      }
      if (props.data?.data_customer?.country_id) {
        setStates(
          props.data.catalogs.states.filter(
            (e) => e.country_id == props.data.data_customer.country_id
          )
        );
      }
    }
  }, [props]);
  useEffect(() => {
    if (customerData) {
      const tempData = data;
      tempData.data_customer = customerData;
      props.setData(tempData);
    }
  }, [customerData]);
  const classes = useStyles();

  const classesExtForm = useExtFormStyles();
  const [licence, setLicence] = React.useState("");
  const [licenceState, setLicenceState] = React.useState("");
  const [firstname, setfirstname] = React.useState("");
  const [firstnameState, setfirstnameState] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [lastnameState, setlastnameState] = React.useState("");
  const [email, setemail] = React.useState("");
  const [emailState, setemailState] = React.useState("");
  const [zipCodeState, setZipCodeState] = React.useState("");
  const [cityState, setCityState] = React.useState("");
  const [addressState, setAddressState] = React.useState("");
  const [phoneState, setPhoneState] = React.useState("");
  const [phoneWithMask, setPhoneWithMask] = React.useState("");
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    isValidatedZipCode: () => {
      return isValidated();
    },
    state: {
      licence,
      licenceState,
      firstname,
      firstnameState,
      lastname,
      lastnameState,
      email,
      emailState,
      zipCodeState,
      cityState,
      addressState,
      phoneState,
      phoneWithMask,
    },
  }));
  const sendState = () => {
    return {
      licence,
      licenceState,
      firstname,
      firstnameState,
      lastname,
      lastnameState,
      email,
      emailState,
      zipCodeState,
      cityState,
      addressState,
      phoneState,
      phoneWithMask,
    };
  };
  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const verifyZipCode = (value) => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyPhone = (value) => {
    // Eliminar todos los caracteres no numéricos del valor de entrada
    const numericValue = value.replace(/\D/g, "");

    // Aplicar la máscara al número de teléfono
    if (numericValue.length >= 10) {
      const areaCode = numericValue.slice(0, 3);
      const firstPart = numericValue.slice(3, 6);
      const secondPart = numericValue.slice(6, 10);
      setPhoneState(true);
      return `(${areaCode}) ${firstPart}-${secondPart}`;
    } else {
      setPhoneState(false);
    }
    setPhoneWithMask(numericValue);
    return numericValue;
  };
  const isValidated = () => {
    if (
      firstnameState === "success" &&
      lastnameState === "success" &&
      emailState === "success" &&
      addressState === "success" &&
      zipCodeState === "success" &&
      cityState === "success" &&
      phoneState === "success"
    ) {
      return true;
    } else {
      if (firstnameState !== "success") {
        setfirstnameState("error");
      }
      if (lastnameState !== "success") {
        setlastnameState("error");
      }
      if (emailState !== "success") {
        setemailState("error");
      }
      if (addressState !== "success") {
        setAddressState("error");
      }
      if (zipCodeState !== "success") {
        setZipCodeState("error");
      }
      if (cityState !== "success") {
        setCityState("error");
      }
      if (phoneState !== "success") {
        setPhoneState("error");
      }
    }
    return false;
  };
  const [country, setCountry] = React.useState("");
  const [dlCountry, setDlCountry] = React.useState("");
  const [validDL, setvalidDL] = React.useState("");
  const [dlState, setdlState] = React.useState("");
  const [state, setState] = React.useState("");
  const [statesDl, setStatesDl] = React.useState([]);
  const [states, setStates] = React.useState([]);

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleChangeDlCountry = (event) => {
    setDlCountry(event.target.value);

    setStatesDl(
      data.catalogs.states.filter((e) => e.country_id == event.target.value)
    );
  };
  const handleChangevalidDL = (event) => {
    setvalidDL(event.target.value);
  };
  const handleChangedlState = (event) => {
    setdlState(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "dl_country_id") {
      setStatesDl(data.catalogs.states.filter((e) => e.country_id == value));
    }
    if (name === "country_id") {
      setStates(data.catalogs.states.filter((e) => e.country_id == value));
    }
    if (name === "phone") {
      setCustomerData({ ...customerData, [name]: phoneWithMask });
    }
    setCustomerData({ ...customerData, [name]: value });
  };

  return (
    <GridContainer justify="center">
      {data ? (
        !showEdit ? (
          <GridItem xs={12}>
            <EditIcon
              onClick={() => setShowEdit(true)}
              style={styles.iconEdit}
            />
            <h4 style={{ paddingBottom: "10px" }}>
              <strong>Client Info </strong>
            </h4>

            <GridContainer >
              <GridItem xs={5}>
                <strong>Driver licensed:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.dl_number}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Client Number:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.id}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Valid driver license?</strong>
              </GridItem>
              <GridItem xs={7}>
                {customerData.valid_license == "1" ? "Yes" : "No"}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>D.L. Country:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data.catalogs.countries.find(
                    (e) => e.id == customerData.dl_country_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>D.L. State:</strong>
              </GridItem>
              <GridItem xs={7}>
                {statesDl.find((e) => e.id == customerData.dl_state_id).name}
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>First Name:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.first_name}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Middle Name:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.middle_name}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Last Name:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.last_name}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Address:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.address}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Zip Code:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.zipcode}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>City:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.city}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>State:</strong>
              </GridItem>
              <GridItem xs={7}>
                {states.find((e) => e.id == customerData.state_id).name}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Country:</strong>
              </GridItem>
              <GridItem xs={7}>
                {
                  data.catalogs.countries.find(
                    (e) => e.id == customerData.country_id
                  ).name
                }
              </GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Phone #:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.phone}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={5}>
                <strong>Email:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.email}</GridItem>
            </GridContainer>
            <GridContainer style={{ background: "rgb(170 170 170 / 15%)" }}>
              <GridItem xs={5}>
                <strong>Additional Insured:</strong>
              </GridItem>
              <GridItem xs={7}>{customerData.additional_insured}</GridItem>
            </GridContainer>
          </GridItem>
        ) : (
          <GridItem xs={12}>
            <CustomInput
              labelText={<span>Client Number</span>}
              id="clientnumber"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.id,
              }}
            />
            <CustomInput
              success={
                licenceState === "success" || customerData.dl_number !== ""
                  ? "success"
                  : ""
              }
              error={
                licenceState === "error" || customerData.dl_number === ""
                  ? "error"
                  : ""
              }
              labelText={
                <span>
                  Driver License <small>(required)</small>
                </span>
              }
              id="driverlicense"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.dl_number,
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 3)) {
                    setLicenceState("error");
                  } else {
                    setLicenceState("success");
                  }
                  setLicence(event.target.value);
                },
              }}
            />
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                D.L. Country
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.dl_country_id}
                name="dl_country_id"
                onChange={handleFormChange}
                inputProps={{
                  name: "dl_country_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  Country:
                </MenuItem>
                {data.catalogs.countries.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                Do you have a valid D.L.?
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.valid_license}
                onChange={handleFormChange}
                inputProps={{
                  name: "valid_license",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value="1"
                >
                  Yes
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesExtForm.selectMenuItem,
                    selected: classesExtForm.selectMenuItemSelected,
                  }}
                  value="0"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                D.L. State
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.dl_state_id}
                onChange={handleFormChange}
                inputProps={{
                  name: "dl_state_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  D.L. State:
                </MenuItem>
                {statesDl.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomInput
              success={firstnameState === "success"}
              error={firstnameState === "error"}
              labelText={
                <span>
                  First Name <small>(required)</small>
                </span>
              }
              id="firstname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.first_name,
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 3)) {
                    setfirstnameState("error");
                  } else {
                    setfirstnameState("success");
                  }
                  setfirstname(event.target.value);
                },
              }}
            />
            <CustomInput
              labelText={<span>Middle Name</span>}
              id="middlename"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.middle_name,
              }}
            />
            <CustomInput
              success={lastnameState === "success"}
              error={lastnameState === "error"}
              labelText={
                <span>
                  Last Name <small>(required)</small>
                </span>
              }
              id="lastname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: customerData.last_name,
              }}
            />
            <CustomInput
              success={
                addressState === "success" || customerData.address !== ""
                  ? "success"
                  : ""
              }
              error={
                addressState === "error" || customerData.address === ""
                  ? "error"
                  : ""
              }
              labelText={
                <span>
                  Address <small>(required)</small>
                </span>
              }
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "address",
                value: customerData.address,
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 1)) {
                    setAddressState("error");
                  } else {
                    setAddressState("success");
                  }
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              success={
                zipCodeState === "success" || customerData.zipcode !== ""
                  ? "success"
                  : ""
              }
              error={
                zipCodeState === "error" || customerData.zipcode === ""
                  ? "error"
                  : ""
              }
              labelText={
                <span>
                  Zip Code <small>(required)</small>
                </span>
              }
              id="zipcode"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "zipcode",
                value: customerData.zipcode,
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 5)) {
                    setZipCodeState("error");
                  } else {
                    setZipCodeState("success");
                  }
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              success={
                cityState === "success" || customerData.city !== ""
                  ? "success"
                  : ""
              }
              error={
                cityState === "error" || customerData.city === "" ? "error" : ""
              }
              labelText={
                <span>
                  City <small>(required)</small>
                </span>
              }
              id="city"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "city",
                value: customerData.city,
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 1)) {
                    setCityState("error");
                  } else {
                    setCityState("success");
                  }
                  handleFormChange(event);
                },
              }}
            />
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                Country
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.country_id}
                name="country_id"
                onChange={handleFormChange}
                inputProps={{
                  name: "country_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  Country:
                </MenuItem>
                {data.catalogs.countries.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classesExtForm.selectFormControl}
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classesExtForm.selectLabel}
              >
                State
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                classes={{
                  select: classesExtForm.select,
                }}
                value={customerData.state_id}
                onChange={handleFormChange}
                inputProps={{
                  name: "state_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesExtForm.selectMenuItem,
                  }}
                >
                  D.L. State:
                </MenuItem>
                {states.map((item) => (
                  <MenuItem
                    classes={{
                      root: classesExtForm.selectMenuItem,
                      selected: classesExtForm.selectMenuItemSelected,
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomInput
              success={
                phoneState === "success" || customerData.phone !== ""
                  ? "success"
                  : ""
              }
              error={
                phoneState === "error" || customerData.phone == ""
                  ? "error"
                  : ""
              }
              labelText={
                <span>
                  Phone # <small>(required)</small>
                </span>
              }
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "phone",
                value: customerData.phone,
                onChange: (event) => {
                  if (
                    !verifyPhone(event.target.value) ||
                    !verifyLength(event.target.value, 10)
                  ) {
                    setPhoneState("error");
                  } else {
                    setPhoneState("success");
                  }
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              success={emailState === "success"}
              error={emailState === "error"}
              labelText={
                <span>
                  Email <small>(required)</small>
                </span>
              }
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "email",
                value: customerData.email,
                onChange: (event) => {
                  if (!verifyEmail(event.target.value)) {
                    setemailState("error");
                  } else {
                    setemailState("success");
                  }
                  handleFormChange(event);
                },
              }}
            />
            <CustomInput
              labelText={<span>Additional Insured</span>}
              id="additionalInsured"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "additional_insured",
                value: customerData.additional_insured,
                onChange: handleFormChange,
              }}
            />
            <div style={{ textAlign: "right", paddingBottom: "20px" }}>
              <Button
                color="danger"
                onClick={() => {
                  setShowEdit(false);
                }}
              >
                Save
              </Button>
            </div>
          </GridItem>
        )
      ) : null}
    </GridContainer>
  );
});

Step1.displayName = "Step1";

export default Step1;
