import React from "react";
export default function Icon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16667 11.4801V11.4286M8.16667 11.4801V11.4286M8.16667 8.68571V8.63415M10.8333 8.68571V8.63415M3.16667 5.94284H12.5M4.37302 2V3.02869M11.1667 2V3.02857M11.1667 3.02857H4.5C3.39543 3.02857 2.5 3.94958 2.5 5.0857V11.9429C2.5 13.079 3.39543 14 4.5 14H11.1667C12.2712 14 13.1667 13.079 13.1667 11.9429L13.1667 5.0857C13.1667 3.94958 12.2712 3.02857 11.1667 3.02857Z"
        stroke="#C2C2C2"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
